/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAuth } from '../../hooks/auth';
import Language from '../../language/language.json';
import { Container } from './styles';

interface TooltipProps {
  title: 'cadastros' | 'usuarios' | 'relatorios' | 'perfil' | 'relatoriosv2';
  type?: 'drawer' | 'header';
  onMouseHover?: (e: boolean) => void;
}

export const TooltipMenu: React.FC<TooltipProps> = ({
  title,
  type = 'drawer',
  children,
  onMouseHover = (e: boolean) => {
    return e;
  },
}) => {
  const {
    user,
    empresaPrincipal,
    nivelAcesso,
    cpfUserMaster,
    languageStorage,
  } = useAuth();

  return (
    <Container
      typeProps={type}
      onMouseOver={() => onMouseHover(true)}
      onMouseLeave={() => onMouseHover(false)}
    >
      {children}

      <ul>
        {title === 'cadastros' && (
          <>
            <li>
              <Link
                to="/"
                onClick={() => {
                  Swal.fire({
                    icon: 'info',
                    title: `${Language[languageStorage]['Permissão negada']}!`,
                    text:
                      Language[languageStorage][
                        'Para cadastrar uma nova EMPRESA MATRIZ entre em contato com o suporte do sistema'
                      ],
                    showCloseButton: true,
                    showConfirmButton: false,
                  });
                }}
              >
                {Language[languageStorage]['Empresa matriz']}
              </Link>
            </li>
            <li>
              <Link
                to="/filiais"
                onClick={() => {
                  if (!cpfUserMaster.length) {
                    Swal.fire({
                      icon: 'info',
                      title: `${Language[languageStorage]['Permissão negada']}!`,
                      text: `${Language[languageStorage]['Para cadastrar uma nova FILIAL entre em contato com o administrador do sistema']}!`,
                      showCloseButton: true,
                      showConfirmButton: false,
                    });
                  }
                }}
              >
                {Language[languageStorage].Filiais}
              </Link>
            </li>
            <li>
              <Link to="/procedimentos">
                {Language[languageStorage].Procedimentos}
              </Link>
            </li>
            <li>
              <Link to="/riscos">{Language[languageStorage].Riscos}</Link>
            </li>
            <li>
              <Link to="/setores">{Language[languageStorage].Setores}</Link>
            </li>
            <li>
              <Link to="/categorias">
                {Language[languageStorage].Categorias}
              </Link>
            </li>
            <li>
              <Link to="/ambientes">{Language[languageStorage].Ambientes}</Link>
            </li>
            {/* <li>
              <Link to="/periodicidade">
                {Language[languageStorage].Periodiciade}
              </Link>
            </li> */}
            <li>
              <Link to="/agendamentos">
                {Language[languageStorage].Agendamento}
              </Link>
            </li>
          </>
        )}
        {title === 'usuarios' && (
          <>
            <li>
              <Link to="/pre-cadastro-usuarios">
                {Language[languageStorage]['Pré-cadastro']}
              </Link>
            </li>
            <li>
              <Link to="/listagem-usuarios">
                {Language[languageStorage].Listagem}
              </Link>
            </li>
          </>
        )}
        {title === 'relatorios' && (
          <>
            <li>
              <Link to="/historico-colaboradores">
                {Language[languageStorage].Colaboradores}
              </Link>
            </li>
            <li>
              <Link to="/historico-ambientes">
                {Language[languageStorage].Ambientes}
              </Link>
            </li>
            <li>
              <Link to="/historico-agenda">
                {Language[languageStorage].Agenda}
              </Link>
            </li>
            <li>
              <Link to="/historico-tarefas-incompletas">
                {Language[languageStorage]['Tarefas incompletas']}
              </Link>
            </li>
          </>
        )}

        {title === 'relatoriosv2' && (
          <>
            <li>
              <Link to="/v2/historico-colaboradores">
                {Language[languageStorage].Colaboradores}
              </Link>
            </li>
            <li>
              <Link to="/v2/historico-ambientes">
                {Language[languageStorage].Ambientes}
              </Link>
            </li>
            <li>
              <Link to="/v2/historico-agenda">
                {Language[languageStorage].Agenda}
              </Link>
            </li>
            <li>
              <Link to="/v2/historico-tarefas-incompletas">
                {Language[languageStorage]['Tarefas incompletas']}
              </Link>
            </li>
          </>
        )}

        {title === 'perfil' && (
          <>
            <li>
              <Link to="/profile" className="linkProfile">
                {Language[languageStorage]['Acessar meu perfil']}
              </Link>
            </li>
            <li>
              <p>
                <strong>
                  {`${Language[languageStorage]['Empresa principal']}:`}{' '}
                </strong>
                {empresaPrincipal.length
                  ? empresaPrincipal[0].NOME
                  : 'Todas as empresas'}
              </p>
            </li>
            <li>
              <p>
                <strong>
                  {`${Language[languageStorage]['Nível de acesso']}:`}{' '}
                </strong>
                {user.coordenador && nivelAcesso.length
                  ? nivelAcesso[0].Nome_NivelAcesso
                  : user.coordenador && !nivelAcesso.length
                  ? Language[languageStorage].Coordenador
                  : Language[languageStorage]['Sem nível de acesso']}
              </p>
            </li>
          </>
        )}
      </ul>
    </Container>
  );
};
