import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.colors.grayWhite};
  background-color: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 3px 3px rgb(0 0 0 / 20%);
  border-radius: 1rem;
  padding-right: 0.4rem;
  align-items: flex-end;

  div.line {
    background-color: ${props => props.theme.colors.bluePrimary};
    width: 1rem;
    height: 100%;
    border-top-left-radius: 1rem;
    border-end-start-radius: 1rem;
  }
  div.info {
    display: flex;
    width: 170px;
    height: 90%;
    min-height: 60px
    margin: 0.4rem;
    flex-direction: column;
    text-align: left;
    font-family: Poppins, sans-serif;
    align-items: center;
    justify-content: center;

    p {
      font-weight: 600;
      font-size: 16px;
      color: ${props => props.theme.colors.textBlack};
    }
    svg {
      color: ${props => props.theme.colors.grayBlack};
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
    }
  }
`;
