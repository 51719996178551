import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  padding: 30px 40px 20px 40px;
`;

export const ContainerTitle = styled.div`
  margin-bottom: 30px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: ${props => props.theme.colors.textBlack};
  }

  span {
    margin-left: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
      height: 20px;
      color: ${props => props.theme.colors.bluePrimary};
    }
  }
`;

export const ContainerListItens = styled.div`
  max-height: calc(100vh - 460px);
  max-width: calc(100vw - 388px);
  padding: 0 5px 10px 5px;
  margin-top: 30px;
  overflow: auto;
  animation: ${appearFromLeft} 1s;

  @media screen and (max-width: 1152px) {
    max-width: 100vw;
  }
`;

interface ContainerItemProps {
  isColor: boolean;
}

export const ContainerItem = styled.div<ContainerItemProps>`
  background: ${props =>
    props.isColor
      ? props.theme.colors.grayWhite
      : props.theme.colors.whitePrimary};
  padding: 0 31px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 1400px) {
    overflow: auto;
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;

  button {
    border: none;
    background: transparent;

    img {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      object-fit: cover;

      transition: transform 0.2s;

      &:hover {
        transform: scale(1.06);
      }
    }

    & + button {
      flex: 1;

      ul {
        padding: 21px 31px;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        overflow: auto;

        li {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-size: 16px;
          line-height: 24px;
          color: ${props => props.theme.colors.textBlack};
          min-width: 150px;

          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          & + li {
            margin-left: 50px;
          }

          strong {
            font-weight: 600;
            color: ${props => props.theme.colors.textBlack};
            margin-bottom: 10px;
          }

          span {
            font-weight: normal;
          }
        }
      }
    }
  }

  svg {
    margin-left: auto;
    color: ${props => props.theme.colors.bluePrimary};
    width: 32px;
    height: 32px;

    transition: color 0.2s;

    &:hover {
      color: ${props => shade(0.2, props.theme.colors.bluePrimary)};
    }
  }
`;

export const ContainerPagination = styled.div`
  width: 100%;
  padding: 40px 60px 10px 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .divPaginacao {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn {
    background: ${props => props.theme.colors.bluePrimary};
    border: none;
    border-radius: 16px;
    padding: 12px 35px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: ${props => props.theme.colors.whitePrimary};

    transition: background 0.2s;

    &:hover {
      background: ${props => shade(0.1, props.theme.colors.bluePrimary)};
    }
  }
`;

interface ContainerSearchProps {
  isFilterDateInterval: boolean;
}

export const ContainerSearch = styled.div<ContainerSearchProps>`
  width: 100%;

  display: grid;
  grid-template-columns: ${props =>
    props.isFilterDateInterval ? '1fr 1fr 0.05fr' : '1fr 0.05fr'};
  gap: 20px;

  button {
    width: 50px;
    height: 50px;
    background: transparent;
    border: none;
    padding: 16px 14px 17px 14px;
    background: ${props => props.theme.colors.whitePrimary};
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.95);
    }
  }
`;

export const ContainerSemResultados = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-top: 30px;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: ${props => props.theme.colors.textBlack};
  }
`;
