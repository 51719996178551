import React from 'react';
import Lottie from 'lottie-react-web';

import animationLoadingBorifador from './loading-borifador.json';
import Language from '../../language/language.json';
import { useAuth } from '../../hooks/auth';
import ProgressBar from '../ProgressBar';

import { Container, Content, ContentItens, Text } from './styles';

interface ProgressBarInterface {
  porcentagem?: number;
  component?: boolean;
}

export const Loading: React.FC<ProgressBarInterface> = ({
  porcentagem,
  component,
}) => {
  const { languageStorage } = useAuth();

  return (
    <Container>
      <Content component={component}>
        <Lottie
          options={{
            animationData: animationLoadingBorifador,
            loop: true,
          }}
          width={300}
          speed={1}
        />
      </Content>
      <ContentItens component={component}>
        <Text component={component}>
          {porcentagem ? (
            <ProgressBar percentage={porcentagem} />
          ) : (
            `${Language[languageStorage].Carregando} ...`
          )}
        </Text>
      </ContentItens>
    </Container>
  );
};
