import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-70px);
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  padding: 20px 30px;

  @media screen and (max-width: 1152px) {
    padding: 20px 30px;
  }
`;

export const ContainerTitle = styled.div`
  margin-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 33px;
      color: ${props => props.theme.colors.textBlack};
    }

    span {
      margin-left: 20px;

      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 20px;
        height: 20px;
        color: ${props => props.theme.colors.bluePrimary};
      }
    }
  }

  div {
    display: flex;
    column-gap: 1rem;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      border: none;
      border-radius: 50%;
      background-color: ${props => props.theme.colors.whitePrimary};
      box-shadow: 0px 3px 3px rgb(0 0 0 / 20%);
      transition: 0.2s;

      svg {
        height: 22px;
        width: 22px;
        color: ${props => props.theme.colors.grayBlack};
      }

      &.selected {
        background-color: ${props => props.theme.colors.bluePrimary};
        svg {
          color: ${props => props.theme.colors.whitePrimary};
        }
      }

      &:hover {
        background-color: ${props =>
          shade(0.2, props.theme.colors.bluePrimary)};
        svg {
          color: ${props => props.theme.colors.whitePrimary};
        }
      }
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  max-height: calc(100vh - 190px);
  animation: ${appearFromLeft} 1s;
  overflow: auto;
`;

export const ModeLateral = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: ${props => props.theme.colors.textBlack};
  }
  div.items {
    display: flex;
    column-gap: 1rem;
    margin: 10px 0px;
    row-gap: 1rem;
    max-width: calc(100vw - 400px);
    overflow: auto;

    @media screen and (max-width: 1152px) {
      max-width: calc(100vw - 100px);
    }
  }
`;

export const ModeVertical = styled.div`
  margin-top: 20px;
  max-width: calc(100vw - 400px);
  overflow-x: visible;
  display: flex;
  column-gap: 1rem;

  @media screen and (max-width: 1152px) {
    max-width: calc(100vw - 100px);
  }

  > div {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
    width: 240px;

    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: ${props => props.theme.colors.textBlack};
    }
  }
`;

interface ContainerSearchProps {
  isFilterDateInterval: boolean;
}

export const ContainerSearch = styled.div<ContainerSearchProps>`
  width: 100%;
  max-width: calc(100vw - 350px);
  animation: ${appearFromTop};
  animation-duration: 1s;
  animation-fill-mode: both;

  display: grid;
  grid-template-columns: ${props =>
    props.isFilterDateInterval ? '1fr 1fr 0.05fr' : '1fr 0.05fr'};
  gap: 20px;

  button {
    width: 50px;
    height: 50px;
    background: transparent;
    border: none;
    padding: 16px 14px 17px 14px;
    background: ${props => props.theme.colors.whitePrimary};
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.95);
    }
  }
`;

export const HeaderDate = styled.div`
  margin: 16px 0;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: ${props => props.theme.colors.textBlack};
    margin-right: 45px;
  }
`;
