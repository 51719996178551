import LogoTappBR from '../assets/LogoTapp.svg';
import LogoTappEN from '../assets/LogoTappEN.svg';
import TappBR from '../assets/Tapp.svg';
import TappEN from '../assets/TappEN.svg';

export type EnumLanguage = 'pt-BR' | 'en-US';

interface LanguageObj {
  value: EnumLanguage;
  name: string;
  id: number;
}

export const listLanguage: LanguageObj[] = [
  { id: 0, value: 'pt-BR', name: 'Português - BR' },
  { id: 1, value: 'en-US', name: 'English - US' },
];

export function LogoTapp(language: string): any {
  switch (language) {
    case 'pt-BR':
      return LogoTappBR;
    case 'en-US':
      return LogoTappEN;
    default:
      return LogoTappBR;
  }
}

export function TappImage(language: string): any {
  switch (language) {
    case 'pt-BR':
      return TappBR;
    case 'en-US':
      return TappEN;
    default:
      return TappBR;
  }
}
