import React from 'react';
import { FiUser } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';
import Language from '../../language/language.json';
import { Agendamentos } from '../../models/Agendamentos';
import { Card } from './styles';

interface Props {
  item: Agendamentos;
  onPress(): void;
}

export const CardAgenda: React.FC<Props> = ({ item, onPress }) => {
  const { languageStorage } = useAuth();
  return (
    <Card type="button" onClick={onPress}>
      <div className="line" />
      <div className="info">
        <p>
          {item.Agenda.ID} - {item.Agenda.Environment.name}
        </p>
        <span>{`${Language[languageStorage].Setor}: ${item.Agenda.Environment.Setor.name} ${item.Agenda.Environment.andar}`}</span>
        {item.Agenda.plus && (
          <span>{Language[languageStorage]['Fora da Agenda']}</span>
        )}
      </div>
      <div className="image">
        {item.User.avatar ? (
          <img
            src={`https://app-qrcode-avatar.s3.amazonaws.com/${item.User.avatar}`}
            alt={Language[languageStorage].Foto}
          />
        ) : (
          <FiUser />
        )}
      </div>
    </Card>
  );
};
