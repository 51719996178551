import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import { IoIosArrowForward } from 'react-icons/io';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import api from '../../../services/api';
import Search from '../../../components/Search';
import ModalConexao from '../../../components/ModalConexao';
import { ModalFilter, StatusButton } from '../../../components/ModalFilter';
import IconFilter from '../../../assets/IconFilter.svg';
import Pagination from '../../../components/Pagination';
import HeaderTable from '../../../components/HeaderTable';
import DropDownPagination from '../../../components/DropDownPagination';
import Language from '../../../language/language.json';

import {
  Container,
  ContainerTitle,
  ContainerItem,
  ContainerSearch,
  ContainerSemResultados,
  ContainerListItens,
  ContainerPagination,
} from './styles';
import {
  HistoryProcedimentoSlim,
  HistoryTaskAgendamento,
} from '../../../models/Historic';
// import {
//   HistAmbienteAgendamento,
//   ModalHistoricoAmbienteV2,
// } from '../../../components/ModalHistoricoAmbienteV2';
import { User } from '../../../models/User';
import {
  HistAmbienteAgendamento,
  ModalHistoricoAmbienteV2,
} from '../../../components/ModalHistoricoAmbienteV2';
import { Empresa } from '../../../models/Empresa';
import { ContainerSelect } from '../DashboardColaboradores/styles';

interface ResponseGetAgendamento {
  horaRevFinal: string[];
  horaRevInicial: string[];
  revisoes: number[];
}

interface ResponseGetProcedimentos {
  idProc: number[];
  procedimentoNome: string[];
}

export interface ResponseAgenda {
  agendaAtiva: boolean;
  agendamento: ResponseGetAgendamento;
  andar: string;
  avatar: string;
  // avatar_url: string;
  cpfUser: string;
  diasSemana: string;
  horaBaseFinal: string;
  horaBaseInicial: string;
  idAgenda: number;
  idAmbientes: number;
  idAmbienteEmAberto: boolean;
  idCategoria: number;
  idEmpresaFilial: number;
  idEmpresaPrincipal: number;
  idPeriodicidade: number;
  idSetor: number;
  idUsers: number;
  nomeAmbiente: string;
  nomeCategoria: string;
  nomePeriodicidade: string;
  nomeSetor: string;
  nomeUser: string;
  procedimentos: ResponseGetProcedimentos;
  qtdLimpezas: number;
  // isRevisao?: boolean;
}

export interface HistoryUserDate {
  User: User;
  historys: DateHistory[];
}

export interface DateHistory {
  dataAgend: string;
  historys: HistoryProcedimentoSlim[];
}

interface HistAgendamentoExcel {
  idAgend: number;
  idAmbiente: number;
  empresa: string;
  ambiente: string;
  categoria: string;
  setor: string;
  andar: string;
  user: string;
  dataAgend: string;
  dataIniAgend: string;
  dataFimAgend: string;
  dataIniDone: string;
  dataFimDone: string;
  timeLimpeza: string;
  observacao: string;
  status: 'Completo' | 'Incompleto' | 'A iniciar';
  type: 'Principal' | 'Revisão' | 'Extra';
}

interface FirstCreatedAgendament {
  id: number;
  day: string;
  createdAt: string;
}

export const DashboardAmbientesV2: React.FC = () => {
  const { empresaPrincipal, languageStorage } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [filialId, setFilialId] = useState(-1);
  const [filiais, setFiliais] = useState<Empresa[]>([]);
  const [response, setResponse] = useState<HistAmbienteAgendamento[]>([]);
  const [responseExcel, setResponseExcel] = useState<HistAgendamentoExcel[]>(
    [],
  );
  const [modalDetalhesAmbiente, setModalDetalhesAmbiente] = useState(false);
  const [dadosModal, setDadosModal] = useState<HistAmbienteAgendamento>();
  const [dadosHistoricoModal, setDadosHistoricoModal] = useState<
    HistoryUserDate[]
  >([]);
  const [dadosHistoricoDatasModal, setDadosHistoricoDatasModal] = useState('');
  const [dataFilterModal, setDataFilterModal] = useState<StatusButton[]>([
    {
      status: true,
      name: Language[languageStorage].Ambiente,
    },
    {
      status: false,
      name: Language[languageStorage].Categoria,
    },
    {
      status: false,
      name: Language[languageStorage].Setor,
    },
    {
      status: false,
      name: Language[languageStorage].Andar,
    },
    {
      status: false,
      name: Language[languageStorage]['Intervalo de datas'],
    },
  ]);

  const [search, setSearch] = useState('');
  const [searchDataInicial, setSearchDataInicial] = useState('');
  const [searchDataFinal, setSearchDataFinal] = useState('');
  const [showModalFilter, setShowModalFilter] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);

  // const dataInicio = moment().format('YYYY-MM-01');
  // const dataFim = moment().format('YYYY-MM-DD');
  const [dataInicioModal, setDataInicioModal] = useState('');
  const [dataFinalModal, setDataFinalModal] = useState('');

  const dropDown = [
    { valor: '20', id: '20' },
    { valor: '40', id: '40' },
    { valor: '80', id: '80' },
    { valor: '160', id: '160' },
    { valor: Language[languageStorage].Todos, id: '1' },
  ];

  const headers = [
    { name: 'ID', field: 'REVISAO_ID', sortable: false },
    {
      name: Language[languageStorage].Empresa,
      field: 'EMPRESA',
      sortable: false,
    },
    {
      name: Language[languageStorage].Ambiente,
      field: 'AMBIENTE_NOME',
      sortable: false,
    },
    {
      name: Language[languageStorage].Categoria,
      field: 'CATEGORIA',
      sortable: false,
    },
    { name: Language[languageStorage].Setor, field: 'SETOR', sortable: false },
    { name: Language[languageStorage].Andar, field: 'ANDAR', sortable: false },
    {
      name: Language[languageStorage].Usuário,
      field: 'nome_users',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Tipo da Limpeza'],
      field: 'type_limpeza',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Dia da Limpeza (Marcado)'],
      field: 'DIA_PRINCIPAL_AMBIENTE',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Horário inicial da Limpeza (Marcado)'],
      field: 'HORARIO_INICIAL_LIMPEZA_AMBIENTE',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Horário final da Limpeza (Marcado)'],
      field: 'HORARIO_FINAL_LIMPEZA_AMBIENTE',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Tempo médio da Limpeza (Marcado)'],
      field: 'tempo_limpeza_marcado',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Horário inicial da Limpeza (Realizado)'],
      field: 'DATA_CRIACAO_HISTORICO',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Horário final da Limpeza (Realizado)'],
      field: 'DATA_FINAL_CRIACAO_HISTORICO',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Tempo da Limpeza'],
      field: 'tempo_limpeza',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Tempo resultante'],
      field: 'tempo_resultante',
      sortable: false,
    },
    {
      name: Language[languageStorage].Observacao,
      field: 'OBSERVACAO',
      sortable: false,
    },
    {
      name: Language[languageStorage].Status,
      field: 'STATUS',
      sortable: false,
    },
  ];

  const getEmpresas = useCallback(async () => {
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
    api
      .get(`empresas/${companyId}`)
      .then(resp => {
        setFiliais(resp.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [empresaPrincipal]);

  const getItens = useCallback(
    async (dateStart: string, dateEnd: string) => {
      setLoading(true);
      const companyId =
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
      api
        .get(
          `/historic/v2/company?companyId=${companyId}&dataIni=${dateStart}&dataFim=${dateEnd}`,
        )
        .then(resp => {
          // console.log(resp.data);
          const histTask: HistoryTaskAgendamento[] = resp.data;
          const histResponse: HistAmbienteAgendamento[] = [];

          histTask.forEach(item => {
            const findIndex = histResponse.findIndex(
              i => i.idAmbiente === item.Agendamento.Agenda.Id_Environments,
            );
            if (findIndex !== -1) {
              histResponse[findIndex].history.push({
                createdAt: item.createdAt,
                idProc: item.tasksId,
                imageUrl: item.image
                  ? `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${item.image}`
                  : item.image,
                isRevisao: true,
                nameProcedimento: item.Procedure.name,
                observacao: item.observacao ? item.observacao : '-',
                revisionId: item.revisionId,
                updatedAt: item.updatedAt,
                isPlus: item.revisionId === null,
                idHist: item.id,
              });
            } else {
              // 198 / 66-3
              const newObj: HistAmbienteAgendamento = {
                andar: item.Agendamento.Agenda.Environment.andar,
                categoria: item.Agendamento.Agenda.Environment.Categoria.name,
                companyId: item.Agendamento.companyId,
                history: [
                  {
                    createdAt: item.createdAt,
                    idProc: item.tasksId,
                    imageUrl: item.image
                      ? `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${item.image}`
                      : item.image,
                    isRevisao: false,
                    nameProcedimento: item.Procedure.name,
                    observacao: item.observacao ? item.observacao : '',
                    revisionId: item.revisionId,
                    updatedAt: item.updatedAt,
                    isPlus: false,
                    idHist: item.id,
                  },
                ],
                idAmbiente: item.Agendamento.Agenda.Id_Environments,
                nameAmbiente: item.Agendamento.Agenda.Environment.name,
                setor: item.Agendamento.Agenda.Environment.Setor.name,
                horaFim: item.Agendamento.Agenda.Periodicidade.HORA_BASE_FINAL,
                horaIni:
                  item.Agendamento.Agenda.Periodicidade.HORA_BASE_INICIAL,
                horaRevisoes: item.Agendamento.Agenda.Periodicidade.Revisoes.map(
                  i => ({
                    horaIni: i.HORA_INICIAL,
                    horaEnd: i.HORA_FINAL,
                  }),
                ),
                procedimentos: item.Agendamento.Agenda.Environment.Procedures.map(
                  i => i.name,
                ),
              };
              histResponse.push(newObj);
            }
          });

          const histExcel: HistAgendamentoExcel[] = [];
          histTask.forEach(item => {
            if (item.User && item.Agendamento) {
              const find = histExcel.find(
                i =>
                  i.dataAgend === item.createdAt.slice(0, 10) &&
                  item.Agendamento.Agenda.Id_Environments === i.idAmbiente,
              );
              if (!find) {
                const newObj: HistAgendamentoExcel = {
                  ambiente: item.Agendamento.Agenda.Environment.name,
                  andar: item.Agendamento.Agenda.Environment.andar,
                  categoria: item.Agendamento.Agenda.Environment.Categoria.name,
                  dataAgend: item.createdAt.slice(0, 10),
                  dataFimAgend:
                    item.Agendamento.Agenda.Periodicidade.HORA_BASE_FINAL,
                  dataFimDone: item.updatedAt.slice(0, 19).replace('T', ' '),
                  dataIniAgend:
                    item.Agendamento.Agenda.Periodicidade.HORA_BASE_INICIAL,
                  dataIniDone: item.createdAt.slice(0, 19).replace('T', ' '),
                  empresa: item.Agendamento.Empresa
                    ? item.Agendamento.Empresa.NOME
                    : '',
                  idAgend: item.agendamentoId,
                  idAmbiente: item.Agendamento.Agenda.Id_Environments,
                  observacao: item.observacao ? item.observacao : '-',
                  setor: item.Agendamento.Agenda.Environment.Setor.name,
                  status: item.observacao ? 'Incompleto' : 'Completo',
                  timeLimpeza: '',
                  user: item.User.nome,
                  type: 'Principal',
                };
                histExcel.push(newObj);
                item.Agendamento.Agenda.Periodicidade.Revisoes.forEach(rev => {
                  const newObjRev: HistAgendamentoExcel = {
                    ...newObj,
                    dataFimAgend: rev.HORA_FINAL,
                    dataIniAgend: rev.HORA_INICIAL,
                    dataFimDone: '',
                    dataIniDone: '',
                    status: 'A iniciar',
                    observacao: '-',
                    type: 'Revisão',
                  };
                  histExcel.push(newObjRev);
                });
              } else {
                const findIndex = histExcel.findIndex(
                  i =>
                    i.dataAgend === item.createdAt.slice(0, 10) &&
                    item.Agendamento.Agenda.Id_Environments === i.idAmbiente &&
                    i.status === 'A iniciar',
                );
                if (findIndex !== -1) {
                  histExcel[findIndex] = {
                    ...histExcel[findIndex],
                    dataFimDone: item.updatedAt.slice(0, 19).replace('T', ' '),
                    dataIniDone: item.createdAt.slice(0, 19).replace('T', ' '),
                    observacao: item.observacao ? item.observacao : '-',
                    status: item.observacao ? 'Incompleto' : 'Completo',
                  };
                } else {
                  const find2 = histExcel.find(
                    i =>
                      i.dataAgend === item.createdAt.slice(0, 10) &&
                      item.Agendamento.Agenda.Id_Environments ===
                        i.idAmbiente &&
                      i.dataIniDone ===
                        item.createdAt.slice(0, 19).replace('T', ' '),
                  );
                  if (!find2) {
                    const newObj: HistAgendamentoExcel = {
                      ambiente: item.Agendamento.Agenda.Environment.name,
                      andar: item.Agendamento.Agenda.Environment.andar,
                      categoria:
                        item.Agendamento.Agenda.Environment.Categoria.name,
                      dataAgend: item.createdAt.slice(0, 10),
                      dataFimAgend: '',
                      dataFimDone: item.updatedAt
                        .slice(0, 19)
                        .replace('T', ' '),
                      dataIniAgend: '',
                      dataIniDone: item.createdAt
                        .slice(0, 19)
                        .replace('T', ' '),
                      empresa: '',
                      idAgend: item.agendamentoId,
                      idAmbiente: item.Agendamento.Agenda.Id_Environments,
                      observacao: item.observacao ? item.observacao : '-',
                      setor: item.Agendamento.Agenda.Environment.Setor.name,
                      status: item.observacao ? 'Incompleto' : 'Completo',
                      timeLimpeza: '',
                      user: item.User.nome,
                      type: 'Extra',
                    };
                    histExcel.push(newObj);
                  }
                }
              }
            }
          });

          setResponse(histResponse);
          setResponseExcel(histExcel);
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: Language[languageStorage].Erro,
            description:
              Language[languageStorage][
                'Erro ao carregar dados, por favor atualize a página'
              ],
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [addToast, empresaPrincipal, languageStorage],
  );

  const loadingItens = useCallback(() => {
    const mesAtual = moment().format('YYYY-MM-DD');
    const mesDataInicial = moment(searchDataInicial).format('YYYY-MM-DD');
    const mesDataFinal = moment(searchDataFinal).format('YYYY-MM-DD');
    const dataInicio = moment().format('YYYY-MM-01');
    const dataFim = moment().format('YYYY-MM-DD');

    const dateStart =
      searchDataInicial.length === 10 && searchDataInicial.substr(0, 1) !== '0'
        ? mesDataInicial
        : dataInicio;
    const dateEnd =
      searchDataFinal.length === 10 && searchDataFinal.substr(0, 1) !== '0'
        ? mesDataFinal
        : dataFim;
    if (dateStart <= mesAtual && dateEnd <= mesAtual) {
      getItens(dateStart, dateEnd);
    } else {
      addToast({
        type: 'info',
        title: Language[languageStorage]['Filtro inválido'],
        description:
          Language[languageStorage][
            'Intervalo de datas inválidos, favor verificar'
          ],
      });
    }
  }, [addToast, getItens, languageStorage, searchDataFinal, searchDataInicial]);

  useEffect(() => {
    loadingItens();
  }, [loadingItens]);

  useEffect(() => {
    setSearchDataInicial('');
    setSearchDataFinal('');
  }, [search]);

  useEffect(() => {
    setSearch('');
  }, [searchDataInicial, searchDataFinal]);

  const handleMaskDate = useCallback(data => {
    const dataTemp = data.split('-').reverse().join('/');

    return dataTemp;
  }, []);

  const requestHistoricoAmbiente = useCallback(
    (dado: HistAmbienteAgendamento) => {
      const dataInicio = moment().format('YYYY-MM-01');
      const dataFim = moment().format('YYYY-MM-DD');
      const mesAtual = moment().format('YYYY-MM-DD');
      const mesDataInicial = moment(searchDataInicial).format('YYYY-MM-DD');
      const mesDataFinal = moment(searchDataFinal).format('YYYY-MM-DD');

      const dateStart =
        searchDataInicial.length === 10 &&
        searchDataFinal.length === 10 &&
        searchDataInicial.substr(0, 1) !== '0' &&
        searchDataFinal.substr(0, 1) !== '0'
          ? mesDataInicial
          : dataInicio;
      const dateEnd =
        searchDataInicial.length === 10 &&
        searchDataFinal.length === 10 &&
        searchDataInicial.substr(0, 1) !== '0' &&
        searchDataFinal.substr(0, 1) !== '0'
          ? mesDataFinal
          : dataFim;

      if (dateStart <= mesAtual && dateEnd <= mesAtual) {
        setDadosHistoricoModal([]);

        setLoading(true);
        api
          .get(
            `/historic/v2/environment?environmentId=${dado.idAmbiente}&dataIni=${dateStart}&dataFim=${dateEnd}`,
          )
          .then(resp => {
            const histTaskAll: HistoryTaskAgendamento[] = resp.data;
            const userHist: HistoryUserDate[] = [];
            const firstCreatedAgenda: FirstCreatedAgendament[] = [];

            histTaskAll.forEach(item => {
              if (!!item.User && !!item.Agendamento) {
                // console.log(item.User);
                const findIndex = userHist.findIndex(
                  i => i.User.id === item.userId,
                );
                if (findIndex !== -1) {
                  const findIndexHist = userHist[findIndex].historys.findIndex(
                    i => i.dataAgend === item.createdAt.slice(0, 10),
                  );
                  if (findIndexHist !== -1) {
                    const hasRev = !firstCreatedAgenda.find(
                      i =>
                        i.createdAt === item.createdAt &&
                        i.day === item.createdAt.slice(0, 10) &&
                        i.id === item.userId,
                    );
                    userHist[findIndex].historys[findIndexHist].historys.push({
                      createdAt: item.createdAt.slice(0, 19).replace('T', ' '),
                      idProc: item.tasksId,
                      imageUrl: item.image
                        ? `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${item.image}`
                        : item.image,
                      isRevisao: hasRev,
                      nameProcedimento: item.Procedure.name,
                      observacao: item.observacao ? item.observacao : '-',
                      revisionId: item.revisionId,
                      updatedAt: item.updatedAt.slice(0, 19).replace('T', ' '),
                      isPlus: !item.revisionId && hasRev,
                      idHist: item.id,
                    });
                  } else {
                    firstCreatedAgenda.push({
                      createdAt: item.createdAt,
                      day: item.createdAt.slice(0, 10),
                      id: item.userId,
                    });
                    userHist[findIndex].historys.push({
                      dataAgend: item.createdAt.slice(0, 10),
                      historys: [
                        {
                          createdAt: item.createdAt
                            .slice(0, 19)
                            .replace('T', ' '),
                          idProc: item.tasksId,
                          imageUrl: item.image
                            ? `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${item.image}`
                            : item.image,
                          isRevisao: false,
                          nameProcedimento: item.Procedure.name,
                          observacao: item.observacao ? item.observacao : '-',
                          revisionId: item.revisionId,
                          updatedAt: item.updatedAt
                            .slice(0, 19)
                            .replace('T', ' '),
                          isPlus: false,
                          idHist: item.id,
                        },
                      ],
                    });
                  }
                } else {
                  firstCreatedAgenda.push({
                    createdAt: item.createdAt,
                    day: item.createdAt.slice(0, 10),
                    id: item.userId,
                  });
                  const newObj: HistoryUserDate = {
                    historys: [
                      {
                        dataAgend: item.createdAt.slice(0, 10),
                        historys: [
                          {
                            createdAt: item.createdAt
                              .slice(0, 19)
                              .replace('T', ' '),
                            idProc: item.tasksId,
                            imageUrl: item.image
                              ? `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${item.image}`
                              : item.image,
                            isRevisao: false,
                            nameProcedimento: item.Procedure.name,
                            observacao: item.observacao ? item.observacao : '-',
                            revisionId: item.revisionId,
                            updatedAt: item.updatedAt
                              .slice(0, 19)
                              .replace('T', ' '),
                            isPlus: false,
                            idHist: item.id,
                          },
                        ],
                      },
                    ],
                    User: item.User,
                  };

                  userHist.push(newObj);
                }
              }
            });

            setDadosHistoricoModal(userHist);
            setDadosHistoricoDatasModal(
              `${Language[languageStorage].DeHorario} ${handleMaskDate(
                dateStart,
              )} ${Language[languageStorage].ateHorario} ${handleMaskDate(
                dateEnd,
              )}`,
            );
            setModalDetalhesAmbiente(true);
            setDataInicioModal(dateStart);
            setDataFinalModal(dateEnd);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: Language[languageStorage].Erro,
              description:
                Language[languageStorage][
                  'Erro ao carregar dados, por favor atualize a página'
                ],
            });
          });
      } else {
        setLoading(false);
        addToast({
          type: 'info',
          title: Language[languageStorage]['Filtro inválido'],
          description:
            Language[languageStorage][
              'Intervalo de datas inválidos, favor verificar'
            ],
        });
      }
    },
    [
      addToast,
      handleMaskDate,
      languageStorage,
      searchDataFinal,
      searchDataInicial,
    ],
  );

  const tempoLimpeza = useCallback((dado: string, dado2: string): string => {
    if (dado && dado2) {
      const diff = moment(new Date(dado), 'DD/MM/YYYY HH:mm:ss').diff(
        moment(new Date(dado2), 'DD/MM/YYYY HH:mm:ss'),
      );

      const horas =
        moment.duration(diff).hours().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).hours().toString().replace('-', '')}`
          : moment.duration(diff).hours().toString().replace('-', '');
      const minutos =
        moment.duration(diff).minutes().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).minutes().toString().replace('-', '')}`
          : moment.duration(diff).minutes().toString().replace('-', '');
      const segundos =
        moment.duration(diff).seconds().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).seconds().toString().replace('-', '')}`
          : moment.duration(diff).seconds().toString().replace('-', '');
      const horario = `${horas}:${minutos}:${segundos}`;
      return horario;
    }
    return ' - ';
  }, []);

  const timeResult = useCallback((timeHMS: string, timeHMS2: string) => {
    function segToHMS(segs: number): string {
      const hours = Math.trunc(segs / 3600);
      const minute = Math.trunc((segs - 3600 * hours) / 60);
      const seconds = segs - (3600 * hours + 60 * minute);
      // console.log(data, data2, hours, minute, seconds);
      return `${hours < 10 ? `0${hours}` : hours}:${
        minute < 10 ? `0${minute}` : minute
      }:${seconds < 10 ? `0${seconds}` : seconds}`;
    }
    if (timeHMS !== ' - ' && timeHMS2 !== ' - ') {
      const [hour1, min1, seg1] = timeHMS.split(':');
      const [hour2, min2, seg2] = timeHMS2.split(':');
      const time1 = Number(seg1) + 60 * (Number(min1) + 60 * Number(hour1));
      const time2 = Number(seg2) + 60 * (Number(min2) + 60 * Number(hour2));
      const diff = time1 - time2;
      if (diff < 0) {
        const diff2 = time2 - time1;
        return `- ${segToHMS(diff2)}`;
      }
      return `+ ${segToHMS(diff)}`;
    }
    return ' - ';
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [search, searchDataInicial, searchDataFinal]);

  useEffect(() => {
    getEmpresas();
  }, [getEmpresas]);

  const responseData = useMemo(() => {
    let computedResponses = response;

    if (filialId !== -1) {
      computedResponses = computedResponses.filter(
        i => filialId === i.companyId,
      );
    }

    if (search) {
      computedResponses = computedResponses.filter(res => {
        const dataFilter: any = [];

        dataFilterModal.forEach(filtro => {
          if (filtro.status) {
            switch (filtro.name) {
              case Language[languageStorage].Ambiente:
                dataFilter.push(res.nameAmbiente);
                break;
              case Language[languageStorage].Categoria:
                dataFilter.push(res.categoria);
                break;
              case Language[languageStorage].Setor:
                dataFilter.push(res.setor);
                break;
              case Language[languageStorage].Andar:
                dataFilter.push(res.andar);
                break;
              default:
                break;
            }
          }
        });

        return dataFilter.some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        );
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    filialId,
    search,
    ITEMS_PER_PAGE,
    currentPage,
    dataFilterModal,
    languageStorage,
  ]);

  const traductionType = useCallback(
    (type: 'Revisão' | 'Principal' | 'Extra') => {
      switch (type) {
        case 'Principal':
          return Language[languageStorage].Principal;
        case 'Revisão':
          return Language[languageStorage].Revisão;
        default:
          return Language[languageStorage].Extra;
      }
    },
    [languageStorage],
  );

  const traductionStatus = useCallback(
    (status: 'Completo' | 'Incompleto' | 'A iniciar') => {
      switch (status) {
        case 'Completo':
          return Language[languageStorage].Feito;
        case 'Incompleto':
          return Language[languageStorage].Incompleto;
        default:
          return Language[languageStorage]['A iniciar'];
      }
    },
    [languageStorage],
  );

  return (
    <>
      <ModalConexao />

      <ModalFilter
        isOpen={showModalFilter}
        type="dashAmbientes"
        onRequestClose={() => setShowModalFilter(false)}
        onReturnData={e => setDataFilterModal(e)}
      />

      {!!dadosModal &&
        !!dadosHistoricoModal &&
        // !!dadosHistoricoObsModal &&
        !!dataInicioModal &&
        !!dataFinalModal && (
          <ModalHistoricoAmbienteV2
            isOpen={modalDetalhesAmbiente}
            dados={dadosModal}
            dadosHistorico={dadosHistoricoModal}
            dadosHistoricoDatas={dadosHistoricoDatasModal}
            onRequestClose={() => setModalDetalhesAmbiente(false)}
          />
        )}

      <Container>
        <ContainerTitle>
          <h1>{Language[languageStorage].Ambientes}</h1>

          <Tooltip
            title={`${Language[languageStorage]['Nessa página você tem acesso a visualização detalhada do histórico de atividades realizadas pelos ASGs. Filtragem a partir dos ambientes']}.`}
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </ContainerTitle>

        <ContainerSelect>
          <select
            value={filialId}
            onChange={event => {
              const val = parseInt(event.target.value, 10);
              setFilialId(val);
            }}
          >
            <option value={-1}>{Language[languageStorage].Todos}</option>
            {filiais.map(i => (
              <option key={i.ID} value={i.ID}>
                {i.NOME}
              </option>
            ))}
          </select>
        </ContainerSelect>

        <ContainerSearch isFilterDateInterval={dataFilterModal[4].status}>
          {!dataFilterModal[4].status ? (
            <Search
              onSearch={(value: string) => {
                setSearch(value);
              }}
              nomePlaceHolder={Language[languageStorage].Buscar}
            />
          ) : (
            <>
              <Search
                onSearch={(value: string) => {
                  setSearchDataInicial(value);
                }}
                nomePlaceHolder={
                  Language[languageStorage]['Buscar data inicial']
                }
                type="date"
              />

              <Search
                onSearch={(value: string) => {
                  setSearchDataFinal(value);
                }}
                nomePlaceHolder={Language[languageStorage]['Buscar data final']}
                type="date"
              />
            </>
          )}

          <button type="button" onClick={() => setShowModalFilter(true)}>
            <img src={IconFilter} alt="iconFilter" />
          </button>
        </ContainerSearch>

        {response.length === 0 && (
          <ContainerSemResultados>
            <h2>
              {
                Language[languageStorage][
                  'Não existe um histórico de ambientes para o intervalo de datas atual'
                ]
              }
            </h2>
          </ContainerSemResultados>
        )}

        <ContainerListItens>
          <>
            {responseData.map((res, index) => (
              <ContainerItem
                key={`${res.idAmbiente}-${res.nameAmbiente}`}
                isColor={index % 2 !== 0}
              >
                <button
                  type="button"
                  onClick={() => {
                    setDadosModal(res);
                    requestHistoricoAmbiente(res);
                  }}
                >
                  <ul>
                    <li>
                      <strong>{Language[languageStorage].Ambiente}</strong>
                      <span>{res.nameAmbiente}</span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage].Categoria}</strong>
                      <span>{res.categoria}</span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage].Setor}</strong>
                      <span>{res.setor}</span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage].Andar}</strong>
                      <span>{res.andar}</span>
                    </li>
                  </ul>
                </button>

                <IoIosArrowForward />
              </ContainerItem>
            ))}

            <table id="tableListagemAmbientes" style={{ display: 'none' }}>
              <HeaderTable headers={headers} />
              <tbody>
                {responseExcel.map(res => (
                  <tr key={`${res.idAgend}-${res.idAmbiente}-${res.dataAgend}`}>
                    <td>{res.idAgend}</td>
                    <td>{res.empresa}</td>
                    <td>{res.ambiente}</td>
                    <td>{res.categoria}</td>
                    <td>{res.setor}</td>
                    <td>{res.andar}</td>
                    <td>{res.user}</td>
                    <td>{traductionType(res.type)}</td>
                    <td>{moment(res.dataAgend).format('DD/MM/YYYY')}</td>
                    <td>{res.dataIniAgend}</td>
                    <td>{res.dataFimAgend}</td>
                    <td>
                      {tempoLimpeza(
                        `2022-01-01 ${res.dataIniAgend}`,
                        `2022-01-01 ${res.dataFimAgend}`,
                      )}
                    </td>
                    <td>{res.dataIniDone.split(' ')[1]}</td>
                    <td>{res.dataFimDone.split(' ')[1]}</td>
                    <td>{tempoLimpeza(res.dataIniDone, res.dataFimDone)}</td>
                    <td>
                      {timeResult(
                        tempoLimpeza(
                          `2022-01-01 ${res.dataIniAgend}`,
                          `2022-01-01 ${res.dataFimAgend}`,
                        ),
                        tempoLimpeza(res.dataIniDone, res.dataFimDone),
                      )}
                    </td>
                    <td>{res.observacao}</td>
                    <td>{traductionStatus(res.status)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        </ContainerListItens>

        <ContainerPagination>
          {response.length > 0 && (
            <>
              <div className="divPaginacao">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
                <DropDownPagination
                  onChangeItems={(value: string) => {
                    setCurrentPage(1);
                    if (value === Language[languageStorage].Todos) {
                      setITEMS_PER_PAGE(1);
                    } else {
                      setITEMS_PER_PAGE(Number(value));
                    }
                  }}
                  objetoEnum={dropDown}
                  minWidth={30}
                />
              </div>

              <ReactHTMLTableToExcel
                id="export-excel"
                className="btn"
                table="tableListagemAmbientes"
                filename={`${
                  Language[languageStorage]['listagem-ambientes']
                }-${moment().format('DD-MM-YYYY')}`}
                sheet="tablexls"
                buttonText={Language[languageStorage]['Exportar Excel']}
              />
            </>
          )}
        </ContainerPagination>
      </Container>

      {loading && <Loading />}
    </>
  );
};
