import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  overflow: auto;

  @media screen and (max-width: 1152px) {
    width: 100vw;

    flex-direction: column;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
