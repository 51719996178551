import React, { ButtonHTMLAttributes } from 'react';
import { useAuth } from '../../hooks/auth';
import Language from '../../language/language.json';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  widthProps?: string;
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  widthProps = '',
  ...rest
}) => {
  const { languageStorage } = useAuth();
  return (
    <Container type="button" {...rest} style={{ width: widthProps }}>
      {loading ? `${Language[languageStorage].Carregando}...` : children}
    </Container>
  );
};

export default Button;
