import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import Select from 'react-select';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { InputOverview } from '../InputOverview';

import { Content } from './styles';
import { ResponseGetUsuario } from '../../pages/Cadastros/Agendamentos';
import { SelectInterface } from '../../models/SelectInterface';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';
import { Loading } from '../Loading';
import api from '../../services/api';
import Language from '../../language/language.json';
import { useAuth } from '../../hooks/auth';

Modal.setAppElement('#root');

interface DataFormSubmit {
  justificativa: string;
  dataInicial: string;
  datafinal: string;
}

interface DataSubmit extends DataFormSubmit {
  userId: string;
}

interface PostSubmit {
  id?: number; // agenda
  userId?: number; // user
  dataIni: string;
  dataFim: string;
  active: boolean;
  justificativa: string;
}

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  dadosUsers: ResponseGetUsuario[];
}

export const ModalRemoveAgenda: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  dadosUsers,
}) => {
  const { languageStorage } = useAuth();
  const { go } = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [usersDataSelect, setUsersDataSelect] = useState<SelectInterface[]>([]);
  const [selectUser, setSelectUser] = useState<SelectInterface>({
    label: '',
    value: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUsersDataSelect(
      dadosUsers.map(i => ({ label: i.nome, value: String(i.id) })),
    );
  }, [dadosUsers]);

  const handleSubmit = useCallback(
    async (data: DataFormSubmit) => {
      try {
        const obj: DataSubmit = {
          ...data,
          userId: selectUser.value,
        };

        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          userId: Yup.string().required(
            Language[languageStorage]['Colaborador é obrigatório'],
          ),
          justificativa: Yup.string().required(
            Language[languageStorage]['Justificativa é obrigatória'],
          ),
          dataInicial: Yup.string().required(
            Language[languageStorage]['Data inicial é obrigatória'],
          ),
          datafinal: Yup.string().required(
            Language[languageStorage]['Empresa é obrigatória'],
          ),
        });

        await schema.validate(obj, {
          abortEarly: false,
        });
        if (obj.dataInicial > obj.datafinal) {
          addToast({
            type: 'error',
            title: Language[languageStorage]['Data inválida'],
            description:
              Language[languageStorage][
                'A data inicial é maior que a data final'
              ],
          });
        }
        const objPost: PostSubmit = {
          active: false,
          dataFim: obj.datafinal,
          dataIni: obj.dataInicial,
          userId: Number(obj.userId),
          justificativa: obj.justificativa,
        };
        // console.log(objPost);
        await api
          .post('/agendas/v2/controlAgenda', objPost)
          .then(() => {
            // console.log(resp.data);
            setLoading(false);
            addToast({
              type: 'success',
              title: Language[languageStorage].Sucesso,
              description: `${Language[languageStorage]['A agenda do usuário foi retirada com sucesso']}.`,
            });

            setTimeout(() => {
              go(0);
            }, 1000);
          })
          .catch(err => {
            console.log(err.message);
            // console.log(err.response);
            if (err.response.status === 400) {
              addToast({
                type: 'error',
                title: 'Erro',
                description: `${err.response.data.message}`,
              });
            } else {
              addToast({
                type: 'error',
                title: 'Erro',
                description:
                  'Ocorreu um erro ao retirar a agenda do usuário, tente novamente',
              });
            }

            setLoading(false);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: Language[languageStorage]['Campos em branco'],
          description:
            Language[languageStorage][
              'Existem campos obrigatórios vazios, favor preencher'
            ],
        });
      }
    },
    [addToast, go, languageStorage, selectUser.value],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={() => onRequestClose()}
        className="react-modal-close"
      >
        <AiOutlineClose size={22} />
      </button>
      <Content>
        <header>
          <h1>{Language[languageStorage]['Cancelar agendas do usuário']}</h1>
        </header>
        <section>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <p>{Language[languageStorage]['Selecione o usuário']}</p>
            <Select
              name="selectUsers"
              menuPortalTarget={document.querySelector('body')}
              options={usersDataSelect}
              placeholder={`${Language[languageStorage].Usuário}...`}
              classNamePrefix="select"
              value={selectUser}
              onChange={e => {
                if (e) {
                  setSelectUser(e);
                }
              }}
            />

            <p>{Language[languageStorage].Justificativa}</p>
            <InputOverview
              name="justificativa"
              placeholder={`${Language[languageStorage].Justificativa}...`}
            />

            <p>
              {Language[languageStorage]['Período de cancelamento das agendas']}
            </p>
            <div className="dates">
              <InputOverview name="dataInicial" type="date" />
              <InputOverview name="datafinal" type="date" />
            </div>
            <button type="submit">
              <div>{Language[languageStorage]['Encerrar Agenda']}</div>
            </button>
          </Form>
        </section>
      </Content>
      {loading && <Loading />}
    </Modal>
  );
};
