import { FormHandles } from '@unform/core';
import React, { useCallback, useRef, useState } from 'react';
import { AiOutlineClose, AiOutlineQuestionCircle } from 'react-icons/ai';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import moment from 'moment';
import { FiPlus } from 'react-icons/fi';

import Language from '../../../../language/language.json';
import { useAuth } from '../../../../hooks/auth';
import { SelectCustom } from '../../../../components/SelectCustom';
import { Calendario } from '../../Periodicidade/Calendario';
import { InputOverview } from '../../../../components/InputOverview';
import { InputTime, ListaHorarios } from '../../Periodicidade/ListaHorarios';
import Button from '../../../../components/Button';
import { CheckLabel } from '../Cadastro/styles';
import { useToast } from '../../../../hooks/toast';
import { YupValidations } from './yupValidation';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { ResponseGetAmbiente } from '..';

import {
  ButtonPlus,
  Container,
  DivMensal,
  DivToot,
  ValSelectedButton,
} from './styles';

Modal.setAppElement('#root');

export interface AmbienteEPeriodicidade {
  ambienteId: number;
  nomeAmbiente: string;
  dataSemana: string;
  dataSemana2: string;
  qtdLimpeza: number;
  type: string;
  horaPrincIni: string;
  horaPrinFim: string;
  revisoes: {
    horaIni: string;
    horaFim: string;
  }[];
  photoUnic: boolean;
  vigIni: string;
  vigFim: string;
}

interface SignUpFormData {
  ambiente: string;
  quantLimpezas: number;
  inicio: string;
  fim: string;
  vigIni: string;
  vigFim: string;
}

interface Props {
  visible: boolean;
  onClose(): void;
  dadosAmbiente: ResponseGetAmbiente[];
  getNewObj(obj: AmbienteEPeriodicidade): void;
}

export const ModalAmbientePeriodicidade: React.FC<Props> = ({
  onClose,
  visible,
  dadosAmbiente,
  getNewObj,
}) => {
  const { languageStorage } = useAuth();
  const formRefIndividual = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [postWeek, setPostWeek] = useState('');
  const [postWeek2, setPostWeek2] = useState('');
  const [quantLimpezas, setQuantLimpezas] = useState(1);
  const [horariosInicio, setHorariosInicio] = useState<InputTime[]>([]);
  const [horariosFim, setHorariosFim] = useState<InputTime[]>([]);
  const [isPhotoUniq, setIsPhotoUniq] = useState(false);
  const optionType = [Language[languageStorage].Semanal, '12X']; // 'Semana Alternada', 'Mensal', 'Anual'];
  const optionMesAno = [
    Language[languageStorage].Calendario.Janeiro,
    Language[languageStorage].Calendario.Fevereiro,
    Language[languageStorage].Calendario.Marco,
    Language[languageStorage].Calendario.Abril,
    Language[languageStorage].Calendario.Maio,
    Language[languageStorage].Calendario.Junho,
    Language[languageStorage].Calendario.Julho,
    Language[languageStorage].Calendario.Agosto,
    Language[languageStorage].Calendario.Setembro,
    Language[languageStorage].Calendario.Outubro,
    Language[languageStorage].Calendario.Novembro,
    Language[languageStorage].Calendario.Dezembro,
  ];
  const [type, setType] = useState(optionType[0]);
  const [day, setDay] = useState(1);
  const [mouth, setMouth] = useState(optionMesAno[0]);
  const [selectedDias, setSelectedDias] = useState<string[]>([]);
  const [selectedMeses, setSelectedMeses] = useState<string[]>([]);

  const handleAddDia = useCallback(() => {
    const diaString = day <= 9 ? `0${day}` : String(day);
    if (selectedDias.length === 0) {
      setSelectedDias([diaString]);
    } else {
      const newSelectDias: string[] = [];
      let aux = false;
      selectedDias.forEach(i => {
        if (i === diaString) {
          newSelectDias.push(i);
          aux = true;
        } else if (Number(i) < day) {
          newSelectDias.push(i);
        } else {
          if (!aux) {
            newSelectDias.push(diaString);
            aux = true;
          }
          newSelectDias.push(i);
        }
      });
      if (!aux) {
        newSelectDias.push(diaString);
      }
      setSelectedDias(newSelectDias);
    }
    setDay(1);
  }, [day, selectedDias]);

  const handleRemoveDia = useCallback(
    (item: string) => {
      const filter = selectedDias.filter(i => i !== item);
      setSelectedDias(filter);
    },
    [selectedDias],
  );

  const getNumberMes = useCallback(
    (name: string): string => {
      switch (name) {
        case Language[languageStorage].Calendario.Janeiro:
          return '01';
        case Language[languageStorage].Calendario.Fevereiro:
          return '02';
        case Language[languageStorage].Calendario.Marco:
          return '03';
        case Language[languageStorage].Calendario.Abril:
          return '04';
        case Language[languageStorage].Calendario.Maio:
          return '05';
        case Language[languageStorage].Calendario.Junho:
          return '06';
        case Language[languageStorage].Calendario.Julho:
          return '07';
        case Language[languageStorage].Calendario.Agosto:
          return '08';
        case Language[languageStorage].Calendario.Setembro:
          return '09';
        case Language[languageStorage].Calendario.Outubro:
          return '10';
        case Language[languageStorage].Calendario.Novembro:
          return '11';
        case Language[languageStorage].Calendario.Dezembro:
          return '12';
        default:
          return '';
      }
    },
    [languageStorage],
  );

  const handleAddMes = useCallback(() => {
    const numberMes = getNumberMes(mouth);
    if (numberMes !== '' && day) {
      let auxDia = day;
      if (auxDia > 28 && numberMes === '02') {
        auxDia = 28;
      }
      if (auxDia > 30 && ['04', '06', '09', '10'].find(i => i === numberMes)) {
        auxDia = 30;
      }
      const diaString = auxDia < 10 ? `0${auxDia}` : `${auxDia}`;
      const dateString = `${diaString}/${numberMes}`;
      const newSelectedMeses: string[] = [];
      let aux = false;
      selectedMeses.forEach(i => {
        const [dia, mes] = i.split('/');
        if (!aux) {
          if (
            Number(mes) > Number(numberMes) ||
            (Number(dia) > auxDia && Number(mes) === Number(numberMes))
          ) {
            newSelectedMeses.push(...[dateString, i]);
            aux = true;
          } else {
            newSelectedMeses.push(i);
          }
        } else {
          newSelectedMeses.push(i);
        }
      });
      if (!aux) {
        newSelectedMeses.push(dateString);
        aux = true;
      }
      setSelectedMeses(newSelectedMeses);
    }
  }, [day, getNumberMes, mouth, selectedMeses]);

  const handleRemoveMes = useCallback(
    (val: string) => {
      const filter = selectedMeses.filter(i => i !== val);
      setSelectedMeses(filter);
    },
    [selectedMeses],
  );

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        // console.log(data);
        formRefIndividual.current?.setErrors({});

        const schema = YupValidations(
          Number(data.quantLimpezas),
          languageStorage,
        );
        await schema.validate(data, {
          abortEarly: false,
        });

        if (type === Language[languageStorage].Semanal && postWeek === '')
          throw new Error('Nenhum dia da semana foi selecionado');

        let cont = '';
        horariosInicio.forEach((horario, index) => {
          if (horario.horario >= horariosFim[index].horario) {
            throw new Error(
              `Horário incorreto - ${Language[languageStorage].O} ${
                index + 1
              }º ${
                Language[languageStorage][
                  'horário inicial está maior ou igual ao horário final'
                ]
              }`,
            );
          }

          if (cont <= horario.horario) {
            cont = horario.horario;
          } else {
            throw new Error(
              'A ordem de horários está incorreta ou sua periodicidade está abrangendo mais de 1 dia. Favor corrigir.',
            );
          }
        });

        const today = parseInt(moment().format('YYYYMMDD'), 10);
        const numberVigIni = parseInt(data.vigIni.split('-').join(''), 10);
        const todayToOneYear = numberVigIni + 10000;
        // console.log(today, todayToOneYear);
        if (numberVigIni < today) {
          throw new Error(
            'A data de vigência inicial está menor que a data atual.',
          );
        }
        const numberVigFim = parseInt(data.vigFim.split('-').join(''), 10);
        if (numberVigIni > numberVigFim) {
          throw new Error(
            'A data de vigencia final é menor que a data de vigencia inicial.',
          );
        }
        if (numberVigFim > todayToOneYear) {
          throw new Error(
            'O range das datas de vigencia é maior que o esperado, valor máximo de um ano.',
          );
        }
        const dataSeman = postWeek;

        const newObj: AmbienteEPeriodicidade = {
          ambienteId: parseInt(data.ambiente.split(' - ')[0], 10),
          nomeAmbiente: data.ambiente.split(' - ')[1],
          type,
          dataSemana: dataSeman,
          dataSemana2:
            type === Language[languageStorage]['Semana Alternada']
              ? postWeek2
              : '',
          horaPrincIni: horariosInicio[0].horario,
          horaPrinFim: horariosFim[0].horario,
          photoUnic: isPhotoUniq,
          qtdLimpeza: data.quantLimpezas,
          revisoes: horariosInicio
            .filter(i => i.posicao !== 0)
            .map(item => ({
              horaFim: horariosFim[item.posicao].horario,
              horaIni: item.horario,
            })),
          vigFim: data.vigFim,
          vigIni: data.vigIni,
        };
        getNewObj(newObj);
        setPostWeek('');
        setQuantLimpezas(1);
        setIsPhotoUniq(false);
        setHorariosFim([]);
        setHorariosInicio([]);
        onClose();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRefIndividual.current?.setErrors(errors);
        }

        if (err.message === 'Nenhum dia da semana foi selecionado!') {
          addToast({
            type: 'error',
            title: Language[languageStorage]['Campos em branco'],
            description:
              Language[languageStorage]['Nenhum dia da semana foi selecionado'],
          });
        } else if (err.message.split('-')[0].trim() === 'Horário incorreto') {
          addToast({
            type: 'error',
            title: Language[languageStorage]['Horário incorreto'],
            description: err.message.split('-')[1],
          });
        } else if (
          err.message ===
          'A ordem de horários está incorreta ou sua periodicidade está abrangendo mais de 1 dia. Favor corrigir.'
        ) {
          addToast({
            type: 'error',
            title: Language[languageStorage]['Horários incorretos'],
            description: `${Language[languageStorage]['A ordem de horários está incorreta ou sua periodicidade está abrangendo mais de 1 dia. Favor corrigir']}.`,
          });
        } else if (
          err.message ===
          'A data de vigencia final é menor que a data de vigencia inicial.'
        ) {
          addToast({
            type: 'error',
            title: Language[languageStorage].Erro,
            description: `${Language[languageStorage]['A data de vigência final é menor que a data de vigência inicial']}.`,
          });
        } else if (
          err.message ===
          'A data de vigência inicial está menor que a data atual.'
        ) {
          addToast({
            type: 'error',
            title: Language[languageStorage].Erro,
            description: `${Language[languageStorage]['A data de vigência inicial está menor que a data atual']}.`,
          });
        } else if (
          err.message ===
          'O range das datas de vigencia é maior que o esperado, valor máximo de um ano.'
        ) {
          addToast({
            type: 'error',
            title: Language[languageStorage].Erro,
            description: `${Language[languageStorage]['O range das datas de vigência é maior que o esperado, valor máximo de um ano']}.`,
          });
        } else {
          addToast({
            type: 'error',
            title: Language[languageStorage]['Campos em branco'],
            description:
              Language[languageStorage][
                'Existem campos obrigatórios não preenchidos'
              ],
          });
        }
      }
    },
    [
      addToast,
      getNewObj,
      horariosFim,
      horariosInicio,
      isPhotoUniq,
      languageStorage,
      onClose,
      postWeek,
      postWeek2,
      type,
    ],
  );

  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => onClose()}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={() => onClose()}
        className="react-modal-close"
      >
        <AiOutlineClose size={22} />
      </button>
      <Container>
        <Form ref={formRefIndividual} onSubmit={handleSubmit}>
          <p>{Language[languageStorage].Ambiente}</p>
          <SelectCustom
            name="ambiente"
            defaultValue={Language[languageStorage].Ambientes}
            type="environments"
            optionsDataEnvironments={dadosAmbiente}
          />
          <DivToot>
            <p>{Language[languageStorage]['Tipo de Periodicidade']}</p>
            <Tooltip
              title={
                Language[languageStorage][
                  "Semanal refere-se aos dias da semana e a opção 12X refere-se ao 'dia sim, dia não'"
                ]
              }
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </DivToot>
          <SelectCustom
            name="type"
            type="week"
            optionsDataStatus={optionType}
            value={type}
            onChange={event => setType(event.target.value)}
          />
          {type === Language[languageStorage].Semanal && (
            <Calendario onWeekSelect={e => setPostWeek(e)} />
          )}
          {type === Language[languageStorage]['Semana Alternada'] && (
            <>
              <p style={{ marginTop: 18, marginBottom: -24 }}>
                {Language[languageStorage].Semana} 1
              </p>
              <Calendario onWeekSelect={e => setPostWeek(e)} />
              <p style={{ marginTop: 10, marginBottom: -24 }}>
                {Language[languageStorage].Semana} 2
              </p>
              <Calendario onWeekSelect={e => setPostWeek2(e)} />
            </>
          )}
          {type === Language[languageStorage].Mensal && (
            <DivMensal>
              <p>Selecione os dias</p>
              <div className="input">
                <div style={{ flex: 1 }}>
                  <InputOverview
                    name="dayCalendar"
                    placeholder={Language[languageStorage]['Dia mensal']}
                    type="number"
                    value={day}
                    onValue={e => {
                      const val = Number(e);
                      if (val > 0 && val <= 31) {
                        setDay(val);
                      }
                    }}
                  />
                </div>
                <ButtonPlus type="button" onClick={handleAddDia}>
                  <FiPlus size={26} />
                </ButtonPlus>
              </div>
              <ValSelectedButton>
                {selectedDias.map((item, index) => {
                  if (selectedDias.length === index + 1) {
                    return (
                      <button
                        key={index.toString()}
                        type="button"
                        onClick={() => handleRemoveDia(item)}
                      >
                        <p>{item}</p>
                      </button>
                    );
                  }
                  return (
                    <div style={{ display: 'flex' }} key={index.toString()}>
                      <button
                        type="button"
                        onClick={() => handleRemoveDia(item)}
                      >
                        <p>{item}</p>
                      </button>
                      <p style={{ marginTop: 5 }}>-</p>
                    </div>
                  );
                })}
              </ValSelectedButton>
            </DivMensal>
          )}
          {type === Language[languageStorage].Anual && (
            <DivMensal>
              <p>{Language[languageStorage]['Adicione as datas']}</p>
              <div className="input">
                <InputOverview
                  name="dayCalendar"
                  placeholder={Language[languageStorage]['Dia mensal']}
                  type="number"
                  value={day}
                  onValue={e => {
                    const val = Number(e);
                    if (val > 0 && val <= 31) {
                      setDay(val);
                    }
                  }}
                />
                <p
                  style={{
                    paddingRight: 10,
                    paddingLeft: 10,
                    margin: 0,
                    marginTop: 8,
                  }}
                >
                  {Language[languageStorage].DeHorario}
                </p>
                <SelectCustom
                  name="type"
                  type="week"
                  optionsDataStatus={optionMesAno}
                  value={mouth}
                  onChange={event => setMouth(event.target.value)}
                />
                <div>
                  <ButtonPlus type="button" onClick={handleAddMes}>
                    <FiPlus size={26} />
                  </ButtonPlus>
                </div>
              </div>
              <ValSelectedButton>
                {selectedMeses.map((item, index) => {
                  if (selectedMeses.length === index + 1) {
                    return (
                      <button
                        key={index.toString()}
                        type="button"
                        onClick={() => handleRemoveMes(item)}
                      >
                        <p>{item}</p>
                      </button>
                    );
                  }
                  return (
                    <div style={{ display: 'flex' }} key={index.toString()}>
                      <button
                        type="button"
                        onClick={() => handleRemoveMes(item)}
                      >
                        <p>{item}</p>
                      </button>
                      <p style={{ marginTop: 5 }}>-</p>
                    </div>
                  );
                })}
              </ValSelectedButton>
            </DivMensal>
          )}
          <p>{Language[languageStorage]['Quantidade de limpezas']}</p>
          <InputOverview
            name="quantLimpezas"
            placeholder={Language[languageStorage]['Nº de limpezas']}
            type="number"
            value={quantLimpezas}
            onValue={e => {
              const val = Number(e);
              if (val <= 20) {
                setQuantLimpezas(val);
              }
            }}
          />

          <ListaHorarios
            quantLimpezas={quantLimpezas}
            onStartTime={e => setHorariosInicio(e)}
            onEndTime={e => setHorariosFim(e)}
          />

          <CheckLabel style={{ marginBottom: 45 }}>
            <input
              checked={isPhotoUniq}
              type="checkbox"
              onChange={() => setIsPhotoUniq(!isPhotoUniq)}
            />
            <p>
              {Language[languageStorage]['As Revisões serão com foto única']}?
            </p>
          </CheckLabel>

          <p>{Language[languageStorage]['Datas de vigência']}</p>
          <div
            style={{
              display: 'flex',
              columnGap: 74,
              justifyContent: 'space-between',
            }}
          >
            <div style={{ flex: 1 }}>
              <InputOverview
                name="vigIni"
                placeholder={Language[languageStorage]['Data inicial']}
                type="date"
              />
            </div>
            <div style={{ flex: 1 }}>
              <InputOverview
                name="vigFim"
                placeholder={Language[languageStorage]['Data final']}
                type="date"
              />
            </div>
          </div>

          <Button type="submit" widthProps="100%">
            {Language[languageStorage].Salvar}
          </Button>
        </Form>
      </Container>
    </Modal>
  );
};
