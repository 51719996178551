import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Loading } from '../../../components/Loading';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import CreditsSpan from '../../../components/Credits';
import { InputOverview } from '../../../components/InputOverview';
import Button from '../../../components/Button';
import api from '../../../services/api';
import Language from '../../../language/language.json';

import { Container, Content, AnimationContainer, Background } from './styles';
import { useAuth } from '../../../hooks/auth';
import { TappImage } from '../../../language/interface';

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const { languageStorage } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required(Language[languageStorage]['E-mail obrigatório'])
            .email(`${Language[languageStorage]['Digite um email válido']}!`),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        await api
          .post('/password/forgot', {
            email: data.email,
            tapp: true,
          })
          .then(() => {
            setLoading(false);
            addToast({
              type: 'success',
              title: Language[languageStorage]['E-mail de recuperação enviado'],
              description:
                Language[languageStorage][
                  'Enviamos um e-mail para confirmar a recuperação de senha, cheque sua caixa de entrada'
                ],
            });
          })
          .catch(() => {
            addToast({
              type: 'error',
              title: Language[languageStorage]['Falha ao recuperar e-mail'],
              description: Language[languageStorage]['E-mail não cadastrado'],
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: Language[languageStorage]['Erro na recuperação de senha'],
          description:
            Language[languageStorage][
              'Ocorreu um ao tentar realizar a recuperação de senha, tente novamente'
            ],
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, languageStorage],
  );

  return (
    <>
      <Container>
        <Background>
          <img
            src={TappImage(languageStorage)}
            className="logo"
            alt="ImgLogo"
          />
        </Background>

        <Content>
          <AnimationContainer>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>{Language[languageStorage]['Recuperar senha']}</h1>

              <p>E-mail</p>
              <InputOverview
                name="email"
                placeholder={Language[languageStorage]['Insira seu e-mail']}
              />

              <article>
                <Button loading={loading} type="submit">
                  {Language[languageStorage].Recuperar}
                </Button>

                <hr />

                <Link to="/">
                  {Language[languageStorage]['Voltar ao início']}
                </Link>
              </article>
            </Form>
          </AnimationContainer>

          <CreditsSpan />
        </Content>
      </Container>

      {loading && <Loading />}
    </>
  );
};

export default ForgotPassword;
