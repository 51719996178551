import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';

import { IoIosArrowForward } from 'react-icons/io';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Pagination from '../../../components/Pagination';
import { ModalUserDetails } from '../../../components/ModalDetalhesUser';
import {
  HistUser,
  ModalHistoricoColaboradorV2,
  HistoryAmbienteDate,
} from '../../../components/ModalHistoricoColaboradorV2';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import api from '../../../services/api';
import Search from '../../../components/Search';
import imgAvatar from '../../../assets/IconAvatar.svg';
import { maskCpf, maskTel } from '../../../components/InputOverview/mask';
import ModalConexao from '../../../components/ModalConexao';
import IconFilter from '../../../assets/IconFilter.svg';
import HeaderTable from '../../../components/HeaderTable';
import DropDownPagination from '../../../components/DropDownPagination';
import { ModalFilter, StatusButton } from '../../../components/ModalFilter';
import Language from '../../../language/language.json';

import {
  Container,
  ContainerTitle,
  ContainerItem,
  ContainerSearch,
  ContainerSemResultados,
  ContainerListItens,
  ContainerPagination,
  ContainerSelect,
} from './styles';
import { HistoryTaskAgendamento } from '../../../models/Historic';
import { Empresa } from '../../../models/Empresa';

interface FirstCreatedAgendament {
  id: number;
  day: string;
  createdAt: string;
}

export const DashboardColaboradoresV2: React.FC = () => {
  const { empresaPrincipal, languageStorage } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<HistUser[]>([]);
  const [filiais, setFiliais] = useState<Empresa[]>([]);
  const [filialId, setFilialId] = useState(-1);
  const [showModalDetalhesUser, setShowModalDetalhesUser] = useState(false);
  const [showModalDetalhesAmbiente, setShowModalDetalhesAmbiente] = useState(
    false,
  );
  const [dadosModal, setDadosModal] = useState<HistUser>();
  const [dadosHistoricoModal, setDadosHistoricoModal] = useState<
    HistoryAmbienteDate[]
  >([]);
  const [dadosHistoricoDatasModal, setDadosHistoricoDatasModal] = useState('');
  const [dataFilterModal, setDataFilterModal] = useState<StatusButton[]>([
    {
      status: true,
      name: Language[languageStorage].Colaborador,
    },
    {
      status: false,
      name: Language[languageStorage].CPF,
    },
    {
      status: false,
      name: Language[languageStorage]['E-mail'],
    },
    {
      status: false,
      name: Language[languageStorage].Telefone,
    },
    {
      status: false,
      name: Language[languageStorage]['Intervalo de datas'],
    },
  ]);
  const [search, setSearch] = useState('');
  const [searchDataInicial, setSearchDataInicial] = useState('');
  const [searchDataFinal, setSearchDataFinal] = useState('');
  const [showModalFilter, setShowModalFilter] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);

  const dropDown = [
    { valor: '20', id: '20' },
    { valor: '40', id: '40' },
    { valor: '80', id: '80' },
    { valor: '160', id: '160' },
    { valor: Language[languageStorage].Todos, id: '1' },
  ];

  const headers = [
    { name: Language[languageStorage].Nome, field: 'nome', sortable: false },
    { name: Language[languageStorage].CPF, field: 'cpf', sortable: false },
    {
      name: Language[languageStorage]['E-mail'],
      field: 'email',
      sortable: false,
    },
    {
      name: Language[languageStorage].Telefone,
      field: 'telefone',
      sortable: false,
    },
  ];

  const getItens = useCallback(
    async (dateStart: string, dateEnd: string) => {
      setLoading(true);
      const companyId =
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
      Promise.all([
        api.get(`empresas/${companyId}`),
        api.get(
          `/historic/v2/company?companyId=13&dataIni=${dateStart}&dataFim=${dateEnd}`,
        ),
      ])
        .then(resp => {
          const filAll: Empresa[] = resp[0].data;
          setFiliais(filAll);
          const histTask: HistoryTaskAgendamento[] = resp[1].data;
          const histUserResponse: HistUser[] = [];

          histTask.forEach(item => {
            if (item.User) {
              const find = histUserResponse.find(i => i.id === item.userId);
              if (!find) {
                const newObj: HistUser = {
                  avatar: item.User.avatar
                    ? `https://app-qrcode-avatar.s3.amazonaws.com/${item.User.avatar}`
                    : '',
                  cpf: item.User.cpf,
                  email: item.User.email,
                  id: item.userId,
                  name: item.User.nome,
                  telefone: item.User.telefone,
                  createdAt: item.User.dataNascimento,
                  dataNascimento: item.User.dataNascimento,
                  nameFilial:
                    filAll.find(i => i.ID === item.Agendamento.companyId)
                      ?.NOME || '',
                  companyId: item.Agendamento.companyId,
                };
                histUserResponse.push(newObj);
              }
            }
          });

          setResponse(histUserResponse);
          setLoading(false);
        })
        .catch(() => {
          // console.log(err);
          // console.log(err.message);
          // console.log(err.response.data);
          addToast({
            type: 'error',
            title: Language[languageStorage].Erro,
            description:
              Language[languageStorage][
                'Erro ao carregar dados, por favor atualize a página'
              ],
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [addToast, empresaPrincipal, languageStorage],
  );

  const loadingItens = useCallback(() => {
    const mesAtual = moment().format('YYYY-MM-DD');
    const mesDataInicial = moment(searchDataInicial).format('YYYY-MM-DD');
    const mesDataFinal = moment(searchDataFinal).format('YYYY-MM-DD');
    const dataInicio = moment().format('YYYY-MM-01');
    const dataFim = moment().format('YYYY-MM-DD');

    const dateStart =
      searchDataInicial.length === 10 && searchDataInicial.substr(0, 1) !== '0'
        ? mesDataInicial
        : dataInicio;
    const dateEnd =
      searchDataFinal.length === 10 && searchDataFinal.substr(0, 1) !== '0'
        ? mesDataFinal
        : dataFim;
    if (dateStart <= mesAtual && dateEnd <= mesAtual) {
      getItens(dateStart, dateEnd);
    } else {
      addToast({
        type: 'info',
        title: Language[languageStorage]['Filtro inválido'],
        description:
          Language[languageStorage][
            'Intervalo de datas inválidos, favor verificar'
          ],
      });
    }
  }, [addToast, getItens, languageStorage, searchDataFinal, searchDataInicial]);

  useEffect(() => {
    loadingItens();
  }, [loadingItens]);

  useEffect(() => {
    setSearchDataInicial('');
    setSearchDataFinal('');
  }, [search]);

  useEffect(() => {
    setSearch('');
  }, [searchDataInicial, searchDataFinal]);

  const handleMaskDate = useCallback(data => {
    const dataTemp = data.split('-').reverse().join('/');

    return dataTemp;
  }, []);

  const requestHistoricoUsuario = useCallback(
    (dado: HistUser) => {
      const dataInicio = moment().format('YYYY-MM-01');
      const dataFim = moment().format('YYYY-MM-DD');
      const mesAtual = moment().format('YYYY-MM-DD');
      const mesDataInicial = moment(searchDataInicial).format('YYYY-MM-DD');
      const mesDataFinal = moment(searchDataFinal).format('YYYY-MM-DD');

      const dateStart =
        searchDataInicial.length === 10 &&
        searchDataFinal.length === 10 &&
        searchDataInicial.substr(0, 1) !== '0' &&
        searchDataFinal.substr(0, 1) !== '0'
          ? mesDataInicial
          : dataInicio;
      const dateEnd =
        searchDataInicial.length === 10 &&
        searchDataFinal.length === 10 &&
        searchDataInicial.substr(0, 1) !== '0' &&
        searchDataFinal.substr(0, 1) !== '0'
          ? mesDataFinal
          : dataFim;

      if (dateStart <= mesAtual && dateEnd <= mesAtual) {
        setDadosHistoricoModal([]);

        setLoading(true);
        api
          .get(
            `/historic/v2/user?userId=${dado.id}&dataIni=${dateStart}&dataFim=${dateEnd}`,
          )
          .then(resp => {
            const histTaskAll: HistoryTaskAgendamento[] = resp.data;
            const histAmbiente: HistoryAmbienteDate[] = [];
            const firstCreatedAgenda: FirstCreatedAgendament[] = [];
            histTaskAll.forEach(item => {
              if (item.User !== null && item.Agendamento !== null) {
                const findIndex = histAmbiente.findIndex(
                  i =>
                    i.ambiente.id === item.Agendamento.Agenda.Id_Environments,
                );
                if (findIndex !== -1) {
                  const findIndexHist = histAmbiente[
                    findIndex
                  ].historys.findIndex(
                    i => i.dataAgend === item.createdAt.slice(0, 10),
                  );
                  if (findIndexHist !== -1) {
                    const hasRev = !firstCreatedAgenda.find(
                      i =>
                        i.createdAt === item.createdAt &&
                        i.day === item.createdAt.slice(0, 10) &&
                        i.id === item.userId,
                    );
                    histAmbiente[findIndex].historys[
                      findIndexHist
                    ].historys.push({
                      createdAt: item.createdAt.slice(0, 19).replace('T', ' '),
                      idProc: item.tasksId,
                      imageUrl: item.image
                        ? `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${item.image}`
                        : item.image,
                      isRevisao: hasRev,
                      nameProcedimento: item.Procedure.name,
                      observacao: item.observacao ? item.observacao : '-',
                      revisionId: item.revisionId,
                      updatedAt: item.updatedAt.slice(0, 19).replace('T', ' '),
                      isPlus: !item.revisionId && hasRev,
                      idHist: item.id,
                    });
                  } else {
                    firstCreatedAgenda.push({
                      createdAt: item.createdAt,
                      day: item.createdAt.slice(0, 10),
                      id: item.userId,
                    });
                    histAmbiente[findIndex].historys.push({
                      dataAgend: item.createdAt.slice(0, 10),
                      historys: [
                        {
                          createdAt: item.createdAt
                            .slice(0, 19)
                            .replace('T', ' '),
                          idProc: item.tasksId,
                          imageUrl: item.image
                            ? `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${item.image}`
                            : item.image,
                          isRevisao: false,
                          nameProcedimento: item.Procedure.name,
                          observacao: item.observacao ? item.observacao : '-',
                          revisionId: item.revisionId,
                          updatedAt: item.updatedAt
                            .slice(0, 19)
                            .replace('T', ' '),
                          isPlus: false,
                          idHist: item.id,
                        },
                      ],
                    });
                  }
                } else {
                  firstCreatedAgenda.push({
                    createdAt: item.createdAt,
                    day: item.createdAt.slice(0, 10),
                    id: item.userId,
                  });
                  const newObj: HistoryAmbienteDate = {
                    historys: [
                      {
                        dataAgend: item.createdAt.slice(0, 10),
                        historys: [
                          {
                            createdAt: item.createdAt
                              .slice(0, 19)
                              .replace('T', ' '),
                            idProc: item.tasksId,
                            imageUrl: item.image
                              ? `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${item.image}`
                              : item.image,
                            isRevisao: false,
                            nameProcedimento: item.Procedure.name,
                            observacao: item.observacao ? item.observacao : '-',
                            revisionId: item.revisionId,
                            updatedAt: item.updatedAt
                              .slice(0, 19)
                              .replace('T', ' '),
                            isPlus: false,
                            idHist: item.id,
                          },
                        ],
                      },
                    ],
                    ambiente: item.Agendamento.Agenda.Environment,
                    periodicidade: {
                      horaEnd:
                        item.Agendamento.Agenda.Periodicidade.HORA_BASE_FINAL,
                      horaIni:
                        item.Agendamento.Agenda.Periodicidade.HORA_BASE_INICIAL,
                      revisoes: item.Agendamento.Agenda.Periodicidade.Revisoes.map(
                        i => ({
                          id: i.ID,
                          horaIni: i.HORA_INICIAL,
                          horaEnd: i.HORA_FINAL,
                        }),
                      ),
                    },
                  };
                  histAmbiente.push(newObj);
                }
              }
            });
            setLoading(false);
            setDadosHistoricoDatasModal(
              `${Language[languageStorage].DeHorario} ${handleMaskDate(
                dateStart,
              )} ${Language[languageStorage].ateHorario} ${handleMaskDate(
                dateEnd,
              )}`,
            );
            setDadosHistoricoModal(histAmbiente);
            setShowModalDetalhesAmbiente(true);
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: Language[languageStorage].Erro,
              description:
                Language[languageStorage][
                  'Erro ao carregar dados, por favor atualize a página'
                ],
            });
          });
      } else {
        setLoading(false);
        addToast({
          type: 'info',
          title: Language[languageStorage]['Filtro inválido'],
          description:
            Language[languageStorage][
              'Intervalo de datas inválidos, favor verificar'
            ],
        });
      }
    },
    [
      addToast,
      handleMaskDate,
      languageStorage,
      searchDataFinal,
      searchDataInicial,
    ],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [search, searchDataInicial, searchDataFinal]);

  const responseData = useMemo(() => {
    let computedResponses = response;

    if (search) {
      computedResponses = computedResponses.filter(res => {
        const dataFilter: any = [];

        dataFilterModal.forEach(filtro => {
          if (filtro.status) {
            switch (filtro.name) {
              case Language[languageStorage].Colaborador:
                dataFilter.push(res.name);
                break;
              case Language[languageStorage].CPF:
                dataFilter.push(res.cpf);
                break;
              case Language[languageStorage]['E-mail']:
                dataFilter.push(res.email);
                break;
              case Language[languageStorage].Telefone:
                dataFilter.push(res.telefone);
                break;
              default:
                break;
            }
          }
        });

        return dataFilter.some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        );
      });
    }

    if (filialId !== -1) {
      computedResponses = computedResponses.filter(
        i => i.companyId === filialId,
      );
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    search,
    filialId,
    ITEMS_PER_PAGE,
    currentPage,
    dataFilterModal,
    languageStorage,
  ]);

  return (
    <>
      <ModalConexao />

      <ModalFilter
        isOpen={showModalFilter}
        type="dashColaboradores"
        onRequestClose={() => setShowModalFilter(false)}
        onReturnData={e => setDataFilterModal(e)}
      />

      {!!dadosModal && (
        <ModalUserDetails
          isOpen={showModalDetalhesUser}
          dados={{
            avatarURL: dadosModal.avatar,
            cpf: dadosModal.cpf,
            dataNascimento: dadosModal.dataNascimento,
            email: dadosModal.email,
            nome: dadosModal.name,
            telefone: dadosModal.telefone,
          }}
          onRequestClose={() => setShowModalDetalhesUser(false)}
        />
      )}

      {!!dadosModal && !!dadosHistoricoModal && !!dadosHistoricoDatasModal && (
        <ModalHistoricoColaboradorV2
          isOpen={showModalDetalhesAmbiente}
          dados={dadosModal}
          dadosHistorico={dadosHistoricoModal}
          dadosHistoricoDatas={dadosHistoricoDatasModal}
          onRequestClose={() => {
            setShowModalDetalhesAmbiente(false);
          }}
        />
      )}

      <Container>
        <ContainerTitle>
          <h1>{Language[languageStorage].Colaboradores}</h1>

          <Tooltip
            title={`${Language[languageStorage]['Nessa página você tem acesso a visualização detalhada do histórico de atividades realizadas pelos ASGs. Filtragem a partir dos colaboradores']}.`}
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </ContainerTitle>

        <ContainerSelect>
          <select
            value={filialId}
            onChange={event => {
              const val = parseInt(event.target.value, 10);
              setFilialId(val);
            }}
          >
            <option value={-1}>{Language[languageStorage].Todos}</option>
            {filiais.map(i => (
              <option key={i.ID} value={i.ID}>
                {i.NOME}
              </option>
            ))}
          </select>
        </ContainerSelect>

        <ContainerSearch isFilterDateInterval={dataFilterModal[4].status}>
          {!dataFilterModal[4].status ? (
            <Search
              onSearch={(value: string) => {
                setSearch(value);
              }}
              nomePlaceHolder={Language[languageStorage].Buscar}
            />
          ) : (
            <>
              <Search
                onSearch={(value: string) => {
                  setSearchDataInicial(value);
                }}
                nomePlaceHolder={
                  Language[languageStorage]['Buscar data inicial']
                }
                type="date"
              />

              <Search
                onSearch={(value: string) => {
                  setSearchDataFinal(value);
                }}
                nomePlaceHolder={Language[languageStorage]['Buscar data final']}
                type="date"
              />
            </>
          )}

          <button type="button" onClick={() => setShowModalFilter(true)}>
            <img src={IconFilter} alt="iconFilter" />
          </button>
        </ContainerSearch>

        {response.length === 0 && (
          <ContainerSemResultados>
            <h2>
              {
                Language[languageStorage][
                  'Não existe um histórico de colaboradores para o intervalo de datas atual'
                ]
              }
              .
            </h2>
          </ContainerSemResultados>
        )}

        <ContainerListItens>
          <>
            {responseData.map((res, index) => (
              <ContainerItem
                key={`${res.id}-${res.createdAt}/Colaboradores`}
                isColor={index % 2 !== 0}
              >
                <button
                  type="button"
                  onClick={() => {
                    setShowModalDetalhesUser(true);
                    setDadosModal(res);
                  }}
                >
                  <img
                    src={res.avatar === null ? imgAvatar : res.avatar}
                    alt={res.name}
                  />
                </button>

                <button
                  type="button"
                  onClick={() => {
                    setDadosModal(res);
                    requestHistoricoUsuario(res);
                  }}
                >
                  <ul>
                    <li>
                      <strong>{Language[languageStorage].Nome}</strong>
                      <span>{res.name}</span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage].CPF}</strong>
                      <span>{maskCpf(res.cpf)}</span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage]['E-mail']}</strong>
                      <span>{res.email}</span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage].Telefone}</strong>
                      <span>{maskTel(res.telefone)}</span>
                    </li>
                  </ul>
                </button>

                <IoIosArrowForward />
              </ContainerItem>
            ))}

            <table id="tableRelatorioColaboradores" style={{ display: 'none' }}>
              <HeaderTable headers={headers} />
              <tbody>
                {responseData.map(res => (
                  <tr key={`${res.id}-${res.createdAt}/Colaboradores`}>
                    <td>{res.name}</td>
                    <td>{maskCpf(res.cpf)}</td>
                    <td>{res.email}</td>
                    <td>{maskTel(res.telefone)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        </ContainerListItens>

        <ContainerPagination>
          {response.length > 0 && (
            <>
              <div className="divPaginacao">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
                <DropDownPagination
                  onChangeItems={(value: string) => {
                    setCurrentPage(1);
                    if (value === Language[languageStorage].Todos) {
                      setITEMS_PER_PAGE(1);
                    } else {
                      setITEMS_PER_PAGE(Number(value));
                    }
                  }}
                  objetoEnum={dropDown}
                  minWidth={30}
                />
              </div>

              <ReactHTMLTableToExcel
                id="export-excel"
                className="btn"
                table="tableRelatorioColaboradores"
                filename={`${
                  Language[languageStorage]['relatorio-colaboradores']
                }-${moment().format('DD-MM-YYYY')}`}
                sheet="tablexls"
                buttonText={Language[languageStorage]['Exportar Excel']}
              />
            </>
          )}
        </ContainerPagination>
      </Container>

      {loading && <Loading />}
    </>
  );
};
