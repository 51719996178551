export interface User {
  ativo: number;
  avatar: string;
  companyId: number;
  coordenador: number;
  cpf: string;
  createdAt: string;
  dataNascimento: string;
  email: string;
  id: number;
  nome: string;
  telefone: string;
  tipo: string;
  updatedAt: string;
}

export interface UserGet extends User {
  avatar_url: string;
}

export const EmptyUser: UserGet = {
  ativo: -1,
  avatar: '',
  avatar_url: '',
  companyId: -1,
  coordenador: -1,
  cpf: '',
  createdAt: '',
  dataNascimento: '',
  email: '',
  id: -1,
  nome: '',
  telefone: '',
  tipo: '',
  updatedAt: '',
};
