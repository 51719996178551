import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Loading } from '../../../components/Loading';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import ModalConexao from '../../../components/ModalConexao';
import { InputOverview } from '../../../components/InputOverview';
import Button from '../../../components/Button';
import AlertEmail from '../../../components/AlertEmail';
import CreditsSpan from '../../../components/Credits';
import Language from '../../../language/language.json';

import { Container, Content, AnimationContainer, Background } from './styles';
import SelectLanguage from '../../../components/SelectLanguage';
import { TappImage } from '../../../language/interface';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  // const languageStorage = localStorage.getItem('languageStorage');
  // const [languageStorage, setLanguage] = useState(languageStorage || 'pt-BR');
  // localStorage.setItem('languageStorage', languageStorage);

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const { signIn, languageStorage, updateLanguage } = useAuth();
  const { addToast } = useToast();
  const [email, setEmail] = useState('');

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required(Language[languageStorage]['E-mail obrigatório'])
            .email(Language[languageStorage]['Digite um email válido']),
          password: Yup.string().required(
            Language[languageStorage]['Senha obrigatória'],
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        await signIn({
          email: data.email,
          password: data.password,
        })
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: Language[languageStorage]['Erro na autenticação'],
              description: `${Language[languageStorage]['Ocorreu um erro ao fazer login, cheque as credenciais']}.`,
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          setLoading(false);
        }
        addToast({
          type: 'error',
          title: Language[languageStorage]['Erro na autenticação'],
          description: `${Language[languageStorage]['Ocorreu um erro ao fazer login, cheque as credenciais']}.`,
        });
      }
    },
    [languageStorage, signIn, addToast],
  );

  return (
    <>
      <ModalConexao />
      <Container>
        <Background>
          <img
            src={TappImage(languageStorage)}
            className="logo"
            alt="ImgLogo"
          />
        </Background>

        <Content>
          <AnimationContainer>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>{Language[languageStorage]['Bem vindo!']}</h1>

              <p>E-mail</p>
              <InputOverview
                name="email"
                placeholder={Language[languageStorage]['Insira seu e-mail']}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <AlertEmail email={email} />

              <p>{Language[languageStorage].Senha}</p>
              <InputOverview
                name="password"
                placeholder={Language[languageStorage]['Insira sua senha']}
              />

              <article>
                <Button type="submit">
                  {Language[languageStorage].Entrar}
                </Button>

                <Link to="forgot-password">
                  {Language[languageStorage]['Esqueci minha senha']}
                </Link>

                <hr />
              </article>
            </Form>

            <Link to="/signup">{Language[languageStorage].Cadastrar}</Link>
            <SelectLanguage defalt={updateLanguage} value={languageStorage} />
          </AnimationContainer>

          <CreditsSpan />
        </Content>
      </Container>

      {loading && <Loading />}
    </>
  );
};

export default SignIn;
