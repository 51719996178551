import React from 'react';
import Modal from 'react-modal';
import imgAvatar from '../../assets/IconAvatar.svg';
import { maskCpf, maskTel } from '../InputOverview/mask';
import Button from '../Button';
import Language from '../../language/language.json';
import { useAuth } from '../../hooks/auth';

import {
  ContainerModal,
  ContainerHeader,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
} from './styles';

Modal.setAppElement('#root');

interface ResponseGet {
  // ativo: number;
  // avatar: string;
  // avatar_url: string;
  avatarURL: string;
  // companyId: number;
  // coordenador: number;
  cpf: string;
  // createdAt: string;
  dataNascimento: string;
  email: string;
  // id: number;
  nome: string;
  // password: string;
  telefone: string;
  // tipo: string;
  // updatedAt: string;
}

interface ModalUserDetailsProps {
  isOpen: boolean;
  dados: ResponseGet;
  onRequestClose: () => void;
}
export const ModalUserDetails: React.FC<ModalUserDetailsProps> = ({
  isOpen,
  dados,
  onRequestClose,
}) => {
  const { languageStorage } = useAuth();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ContainerModal>
        <ContainerHeaderDados>
          {Language[languageStorage]['Detalhes do Colaborador']}
        </ContainerHeaderDados>

        <ContainerHeader>
          <img
            src={dados.avatarURL === null ? imgAvatar : dados.avatarURL}
            alt={dados.nome === null ? '-' : dados.nome}
          />

          <ul>
            <li>
              <strong>{`${Language[languageStorage].CPF}:`} </strong>
              <span>{dados.cpf === null ? '-' : maskCpf(dados.cpf)}</span>
            </li>
            <li>
              <strong>
                {`${Language[languageStorage]['Nome completo']}:`}{' '}
              </strong>
              <span>{dados.nome === null ? '-' : dados.nome}</span>
            </li>
            <li>
              <strong>
                {`${Language[languageStorage]['Data de nascimento']}:`}{' '}
              </strong>
              <span>
                {dados.dataNascimento === null ? '-' : dados.dataNascimento}
              </span>
            </li>
            <li>
              <strong>{`${Language[languageStorage].Telefone}:`} </strong>
              <span>
                {dados.telefone === null ? '-' : maskTel(dados.telefone)}
              </span>
            </li>
            <li>
              <strong>E-mail: </strong>
              <span>{dados.email === null ? '-' : dados.email}</span>
            </li>
          </ul>
        </ContainerHeader>

        <ContainerButtonAtualizar>
          <Button type="button" onClick={() => onRequestClose()}>
            {Language[languageStorage].Ok}
          </Button>
        </ContainerButtonAtualizar>
      </ContainerModal>
    </Modal>
  );
};
