import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  max-width: auto;
`;

interface LoadingProps {
  component?: boolean;
}

export const Content = styled.div<LoadingProps>`
  background: ${props =>
    props.component ? 'transparent' : 'rgba(0, 0, 0, 0.7)'};
  position: ${props => (props.component ? 'absolute' : 'fixed')};
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${props => (props.component ? '1' : '999')};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentItens = styled.div<LoadingProps>`
  position: ${props => (props.component ? 'absolute' : 'fixed')};
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${props => (props.component ? '1' : '999')};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div<LoadingProps>`
  font-size: 20px;
  color: ${props =>
    props.component ? 'inherit' : props.theme.colors.whitePrimary};
  margin-top: 240px;
`;
