/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import jsPDF from 'jspdf';
import moment from 'moment';
import { uuid } from 'uuidv4';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Zoom from 'react-img-zoom';

import { AiOutlineException } from 'react-icons/ai';
import { VscFilePdf } from 'react-icons/vsc';
import { RiFileExcel2Line } from 'react-icons/ri';

import { IoIosArrowForward } from 'react-icons/io';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import Button from '../Button';
import HeaderTable from '../HeaderTable';
import Search from '../Search';
import { maskCpf } from '../InputOverview/mask';
import Language from '../../language/language.json';

import {
  ContainerModal,
  ContainerHeader,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
  ContainerMap,
  ContainerMapDetails,
  ContainerButtonDetalhes,
  ContainerButtonsPdfExcel,
  ContainerSearch,
  ContainerTableMacon,
  ButtonDetalhes,
  ContainerListCards,
  ZoomImg,
  ButtonImgNull,
  ButtonImg,
  HistoricoVazio,
} from './styles';
import { Ambiente } from '../../models/Ambiente';
import { HistoryProcedimentoSlim } from '../../models/Historic';
import { useAuth } from '../../hooks/auth';

Modal.setAppElement('#root');

interface ResponseGetHistoric {
  id: number;
  Ambiente: {
    id: number;
    nome: string;
  };
  horaFinalAmbiente: string;
  horaInicialAmbiente: string;
  revisions: {
    horaIni: string;
    horaFim: string;
  }[];
  createdAt: string;
  finishAt: string;
  imagem: string;

  idTask: number;
  nameTask: string;
  observacao: string;
  isRevision: boolean;
}

export interface HistUser {
  name: string;
  avatar: string;
  cpf: string;
  telefone: string;
  email: string;
  id: number;
  createdAt: string;
  dataNascimento: string;
  nameFilial: string;
  companyId: number;
}

export interface HistoryAmbienteDate {
  ambiente: Ambiente;
  periodicidade: {
    horaIni: string;
    horaEnd: string;
    revisoes: {
      id: number;
      horaIni: string;
      horaEnd: string;
    }[];
  };
  historys: DateHistory[];
}

export interface DateHistory {
  dataAgend: string;
  historys: HistoryProcedimentoSlim[];
}

interface ModalDetalhesAmbientes {
  isOpen: boolean;
  dados: HistUser;
  dadosHistorico: HistoryAmbienteDate[];
  dadosHistoricoDatas: string;
  onRequestClose: () => void;
}

// interface ResponseGetTasks {
//   companyId: number;
//   id: number;
//   name: string;
//   empresa: number;
// }

export const ModalHistoricoColaboradorV2: React.FC<ModalDetalhesAmbientes> = ({
  isOpen,
  dados,
  dadosHistorico,
  dadosHistoricoDatas,
  onRequestClose,
}) => {
  const { languageStorage } = useAuth();
  const [search, setSearch] = useState('');
  const [searchDetails, setSearchDetails] = useState('');
  const [dadosHistoricoExcelPdf, setDadosHistoricoExcelPdf] = useState<
    ResponseGetHistoric[]
  >([]);
  const [detalhesHistoricoExcelPdf, setDetalhesHistoricoExcelPdf] = useState<
    ResponseGetHistoric[]
  >([]);
  const [newDetalhesHistorico, setNewDetalhesHistorico] = useState<
    ResponseGetHistoric[]
  >([]);
  const [exibirDetalhes, setExibirDetalhes] = useState(false);
  // const [dadosCopia, setDadosCopia] = useState<ResponseGetHistoric[]>([]);
  const [ambienteToHistory, setAmbienteToHistory] = useState<
    ResponseGetHistoric[]
  >([]);
  const [ambienteIdChoose, setAmbienteIdChoose] = useState(-1);
  const [dateChoose, setDateChoose] = useState('');
  const [zoomImg, setZoomImg] = useState('');
  const [img, setImg] = useState<string | ResponseGetHistoric[]>();

  const headers = [
    { name: Language[languageStorage].Colaborador, field: 'nomeColaborador' },
    { name: Language[languageStorage].CPF, field: 'cpf' },
    { name: Language[languageStorage]['E-mail'], field: 'email' },
    { name: Language[languageStorage].Telefone, field: 'telefone' },
    { name: Language[languageStorage].Ambiente, field: 'nomeAmbiente' },
    {
      name: Language[languageStorage]['Horário base (agendado)'],
      field: 'horarioMarcadoPrimeiraLimpeza',
    },
    {
      name: Language[languageStorage]['Data e hora inicial (realizado)'],
      field: 'dataInicial',
    },
    {
      name: Language[languageStorage]['Data e hora final (realizado)'],
      field: 'dataFinal',
    },
    { name: Language[languageStorage].Observacao, field: 'observacao' },
  ];

  const headersRevisions = [
    { name: Language[languageStorage].Colaborador, field: 'nomeColaborador' },
    { name: Language[languageStorage].CPF, field: 'cpf' },
    { name: Language[languageStorage]['E-mail'], field: 'email' },
    { name: Language[languageStorage].Telefone, field: 'telefone' },
    { name: Language[languageStorage].Ambiente, field: 'nomeAmbiente' },
    {
      name: Language[languageStorage]['Horário base (agendado)'],
      field: 'horarioMarcadoPrimeiraLimpeza',
    },
    {
      name: Language[languageStorage]['Horário da revisão (agendado)'],
      field: 'horarioMarcadoRevisao',
    },
    {
      name: Language[languageStorage]['Data e hora inicial (realizado)'],
      field: 'dataInicial',
    },
    {
      name: Language[languageStorage]['Data e hora final (realizado)'],
      field: 'dataFinal',
    },
    { name: Language[languageStorage].Procedimento, field: 'procedimento' },
    { name: Language[languageStorage].Observacao, field: 'observacao' },
    {
      name: Language[languageStorage]['Horários das revisões (realizado)'],
      field: 'horarioRealizadoRevisao',
    },
    { name: Language[languageStorage].Foto, field: 'foto' },
  ];

  useEffect(() => {
    const dadosHist: ResponseGetHistoric[] = [];

    dadosHistorico.forEach(element => {
      element.historys.forEach(item => {
        item.historys.forEach(i => {
          dadosHist.push({
            Ambiente: {
              id: element.ambiente.id,
              nome: element.ambiente.name,
            },
            createdAt: i.createdAt,
            finishAt: i.updatedAt,
            horaFinalAmbiente: element.periodicidade.horaEnd,
            horaInicialAmbiente: element.periodicidade.horaIni,
            id: i.idHist,
            idTask: i.idProc,
            imagem: i.imageUrl,
            nameTask: i.nameProcedimento,
            observacao: i.observacao,
            revisions: element.periodicidade.revisoes.map(rev => ({
              horaFim: rev.horaEnd,
              horaIni: rev.horaIni,
            })),
            isRevision: i.isRevisao,
          });
        });
      });
    });

    setAmbienteToHistory(dadosHist);
  }, [dadosHistorico]);

  const filterSchedulesReviewsDone = useCallback(
    (res: ResponseGetHistoric, numberHist: number) => {
      const result: string[] = [];
      res.revisions.forEach((i, index) => {
        if (index < numberHist - 1) {
          result.push(`${i.horaIni} - ${i.horaFim}`);
        }
      });

      return result.length ? result.join(', ') : '-';
    },
    [],
  );

  const handleDownloadPDF = useCallback(
    (dadosHistoricoParam: ResponseGetHistoric[], type: string) => {
      const doc = new jsPDF(); // eslint-disable-line
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      doc.setFontType('bold');
      doc.text(
        Language[languageStorage]['Histórico do Colaborador'],
        103,
        20,
        'center',
      );
      doc.setFontSize(14);
      // tamanho 207 tela a4
      let posY: number;
      posY = 30;

      doc.text(`${dados.name}`, 103, posY, 'center');

      // inicio bloco de dados pessoais
      dadosHistoricoParam.forEach((res, index) => {
        index === 0 ? (posY += 28) : (posY += 20);

        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text(`${Language[languageStorage].Ambiente}:`, 10, posY, 'left');
        let quebraLinha = doc.splitTextToSize(`${res.Ambiente.nome}`, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 33, posY, { align: 'left', maxWidth: 175 });

        posY += 6;
        doc.setFontType('bold');
        doc.text(
          `${Language[languageStorage]['Horário base (agendado)']}:`,
          10,
          posY,
          'left',
        );
        quebraLinha = doc.splitTextToSize(
          res.horaInicialAmbiente && res.horaFinalAmbiente
            ? `${res.horaInicialAmbiente} - ${res.horaFinalAmbiente}`
            : '-',
          70,
        );
        doc.setFontType('normal');
        doc.text(quebraLinha, 63, posY, { align: 'left', maxWidth: 175 });

        const filterScheduledReviewsAux = res.revisions
          .map(i => `${i.horaIni} - ${i.horaFim}`)
          .join(', ');
        const filterScheduledReviewsAuxLength = filterScheduledReviewsAux.split(
          '-',
        ).length;

        posY += 6;
        doc.setFontType('bold');
        doc.text(
          `${Language[languageStorage]['Horário da revisão (agendado)']}:`,
          10,
          posY,
          'left',
        );
        quebraLinha = doc.splitTextToSize(filterScheduledReviewsAux, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 74, posY, { align: 'left', maxWidth: 130 });
        let posYAux;

        if (Math.ceil(filterScheduledReviewsAuxLength / 9) > 1) {
          posYAux = (filterScheduledReviewsAuxLength / 9) * 11;
        } else {
          posYAux = 6;
        }

        posY += posYAux;
        doc.setFontType('bold');
        doc.text(
          `${Language[languageStorage]['Data e hora inicial (realizado)']}:`,
          10,
          posY,
          'left',
        );
        quebraLinha = doc.splitTextToSize(
          `${moment(new Date(res.createdAt)).format('DD/MM/YYYY HH:mm:ss')}`,
          70,
        );
        doc.setFontType('normal');
        doc.text(quebraLinha, 73, posY, { align: 'left', maxWidth: 175 });

        posY += 6;
        doc.setFontType('bold');
        doc.text(
          `${Language[languageStorage]['Data e hora final (realizado)']}:`,
          10,
          posY,
          'left',
        );
        quebraLinha = doc.splitTextToSize(
          `${moment(new Date(res.finishAt)).format('DD/MM/YYYY HH:mm:ss')}`,
          70,
        );
        doc.setFontType('normal');
        doc.text(quebraLinha, 70, posY, { align: 'left', maxWidth: 175 });

        if (type === 'detalhes') {
          posY += 6;
          doc.setFontType('bold');
          doc.text(
            `${Language[languageStorage].Procedimento}:`,
            10,
            posY,
            'left',
          );
          quebraLinha = doc.splitTextToSize(
            `${
              res.nameTask === null ||
              res.nameTask === undefined ||
              res.nameTask === ''
                ? '-'
                : res.nameTask
            }`,
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 41, posY, { align: 'left', maxWidth: 175 });
        }

        posY += 6;
        doc.setFontType('bold');
        doc.text(`${Language[languageStorage].Observacao}:`, 10, posY, 'left');
        quebraLinha = doc.splitTextToSize(res.observacao, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 39, posY, { align: 'left', maxWidth: 175 });

        posY += 6;
        doc.setFontType('bold');
        doc.text(
          `${Language[languageStorage]['Horários das revisões (realizado)']}:`,
          10,
          posY,
          'left',
        );
        quebraLinha = doc.splitTextToSize(
          `${filterSchedulesReviewsDone(res, dadosHistoricoParam.length)}`,
          70,
        );
        doc.setFontType('normal');
        doc.text(quebraLinha, 78, posY, { align: 'left', maxWidth: 120 });

        if (posY > 207) {
          doc.addPage();
          posY = 0;
        }
      });

      doc.autoPrint();
      doc.output('dataurlnewwindow');
    },
    [dados.name, filterSchedulesReviewsDone, languageStorage],
  );

  const responseData = useMemo(() => {
    let computedResponses = ambienteToHistory;

    computedResponses = [
      ...new Map(
        computedResponses.map(item => [
          `${item.Ambiente.id}-${item.createdAt.slice(0, 10)}`,
          item,
        ]),
      ).values(),
    ];

    if (search) {
      computedResponses = computedResponses.filter(
        (res: ResponseGetHistoric) => {
          const dataInicio = moment(new Date(res.createdAt)).format(
            'DD/MM/YYYY HH:mm:ss',
          );
          const dataFim = moment(new Date(res.finishAt)).format(
            'DD/MM/YYYY HH:mm:ss',
          );

          return [res.Ambiente.nome, dataInicio, dataFim].some(
            (item: any) =>
              item &&
              item
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase()),
          );
        },
      );
    }

    setDadosHistoricoExcelPdf(computedResponses);
    return computedResponses;
  }, [ambienteToHistory, search]);

  const responseDataDetalhes = useMemo(() => {
    let computedResponses: ResponseGetHistoric[] = ambienteToHistory;
    // const computedResponsesExcelPdf: ResponseGetHistoric[] = ambienteToHistory;

    computedResponses = computedResponses.filter(
      i =>
        i.Ambiente.id === ambienteIdChoose &&
        i.createdAt.slice(0, 10) === dateChoose,
    );

    if (searchDetails) {
      computedResponses = computedResponses.filter((res: ResponseGetHistoric) =>
        res.nameTask
          .toString()
          .toLowerCase()
          .includes(searchDetails.toLowerCase()),
      );
    }

    setDetalhesHistoricoExcelPdf(computedResponses);
    return computedResponses;
  }, [ambienteIdChoose, ambienteToHistory, dateChoose, searchDetails]);

  const handleZoomImg = useCallback((imgString: string) => {
    setZoomImg('principal');
    setImg(imgString);
  }, []);

  const handleZoomImgRevisions = useCallback(
    (imgObjects: ResponseGetHistoric[]) => {
      setZoomImg('revisoes');
      setImg(imgObjects);
    },
    [],
  );

  const onClose = useCallback(() => {
    setSearch('');
    setSearchDetails('');
    setZoomImg('');
    setNewDetalhesHistorico([]);
    setExibirDetalhes(false);
    onRequestClose();
  }, [onRequestClose]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ContainerModal>
        {!exibirDetalhes && (
          <>
            <ContainerHeaderDados>
              {Language[languageStorage]['Histórico do Colaborador']}
            </ContainerHeaderDados>

            <ContainerSearch>
              <Search
                onSearch={(value: string) => {
                  setSearch(value);
                }}
                nomePlaceHolder={Language[languageStorage].Buscar}
              />

              <strong>{dadosHistoricoDatas}</strong>
            </ContainerSearch>
          </>
        )}
        {exibirDetalhes && zoomImg === '' && (
          <>
            <ContainerHeaderDados>
              {Language[languageStorage]['Detalhes do Histórico']}
            </ContainerHeaderDados>

            <ContainerSearch>
              <Search
                onSearch={(value: string) => {
                  setSearchDetails(value);
                }}
                nomePlaceHolder={
                  Language[languageStorage]['Buscar procedimento']
                }
              />

              <strong>{dadosHistoricoDatas}</strong>
            </ContainerSearch>
          </>
        )}

        {!exibirDetalhes && (
          <ContainerHeader>
            {dadosHistorico.length !== 0 ? (
              <ContainerListCards>
                {responseData.map(res => (
                  <ContainerMap key={`${res.id}-${res.Ambiente.id}`}>
                    <ul>
                      <li>
                        <strong>{Language[languageStorage].Ambiente}</strong>
                        <span>
                          {res.Ambiente.nome === null ? '-' : res.Ambiente.nome}
                        </span>
                      </li>
                      <li>
                        <strong>
                          {
                            Language[languageStorage][
                              'Data e hora inicial (realizado)'
                            ]
                          }
                        </strong>
                        <span>
                          {res.createdAt === null
                            ? '-'
                            : moment(res.createdAt).format(
                                'DD/MM/YYYY HH:mm:ss',
                              )}
                        </span>
                      </li>
                      <li>
                        <strong>
                          {
                            Language[languageStorage][
                              'Data e hora final (realizado)'
                            ]
                          }
                        </strong>
                        <span>
                          {res.finishAt === null
                            ? '-'
                            : moment(res.finishAt).format(
                                'DD/MM/YYYY HH:mm:ss',
                              )}
                        </span>
                      </li>
                    </ul>

                    <article>
                      <ButtonDetalhes
                        type="button"
                        onClick={() => [
                          setAmbienteIdChoose(res.Ambiente.id),
                          setDateChoose(res.createdAt.slice(0, 10)),
                          setExibirDetalhes(true),
                        ]}
                      >
                        <IoIosArrowForward />
                      </ButtonDetalhes>
                    </article>
                  </ContainerMap>
                ))}
              </ContainerListCards>
            ) : (
              <HistoricoVazio>
                <p>
                  {
                    Language[languageStorage][
                      'Dados não encontrados para o colaborador atual'
                    ]
                  }
                </p>
              </HistoricoVazio>
            )}
          </ContainerHeader>
        )}

        {exibirDetalhes && zoomImg === '' && (
          <ContainerHeader>
            <ContainerListCards>
              {responseDataDetalhes
                .filter(i => !i.isRevision)
                .map(res => (
                  <ContainerMapDetails key={`${res.id}-${res.idTask}`}>
                    {res.imagem === null ||
                    res.imagem.split('/').pop() === 'null' ? (
                      <ButtonImgNull type="button">
                        <AiOutlineException size={80} />
                      </ButtonImgNull>
                    ) : (
                      <ButtonImg
                        type="button"
                        onClick={() => handleZoomImg(res.imagem)}
                      >
                        <img
                          src={res.imagem}
                          alt={
                            res.Ambiente.nome === null ? '-' : res.Ambiente.nome
                          }
                        />
                      </ButtonImg>
                    )}

                    <ul>
                      <li>
                        <strong>{Language[languageStorage].Ambiente}: </strong>
                        <span>
                          {res.Ambiente.nome === null ? '-' : res.Ambiente.nome}
                        </span>
                      </li>
                      <li>
                        <strong>
                          {Language[languageStorage]['Horário base (agendado)']}
                          :{' '}
                        </strong>
                        <span>
                          {res.horaInicialAmbiente && res.horaFinalAmbiente
                            ? `${res.horaInicialAmbiente} - ${res.horaFinalAmbiente}`
                            : '-'}
                        </span>
                      </li>
                      <li>
                        <strong>
                          {
                            Language[languageStorage][
                              'Horário da revisão (agendado)'
                            ]
                          }
                          :{' '}
                        </strong>
                        <span>
                          {res.revisions
                            .map(i => `${i.horaIni} - ${i.horaFim}`)
                            .join(', ')}
                        </span>
                      </li>
                      <li>
                        <strong>
                          {
                            Language[languageStorage][
                              'Data e hora inicial (realizado)'
                            ]
                          }
                          :{' '}
                        </strong>
                        <span>
                          {res.createdAt === null
                            ? '-'
                            : moment(new Date(res.createdAt)).format(
                                'DD/MM/YYYY HH:mm:ss',
                              )}
                        </span>
                      </li>
                      <li>
                        <strong>
                          {
                            Language[languageStorage][
                              'Data e hora final (realizado)'
                            ]
                          }
                          :{' '}
                        </strong>
                        <span>
                          {res.finishAt === null
                            ? '-'
                            : moment(new Date(res.finishAt)).format(
                                'DD/MM/YYYY HH:mm:ss',
                              )}
                        </span>
                      </li>
                      <li>
                        <strong>
                          {Language[languageStorage].Procedimento}:{' '}
                        </strong>
                        <span>
                          {res.nameTask === null || res.nameTask.trim() === ''
                            ? '-'
                            : res.nameTask}
                        </span>
                      </li>
                      <li>
                        <strong>
                          {Language[languageStorage].Observacao}:{' '}
                        </strong>
                        <span>{res.observacao}</span>
                      </li>
                      <li>
                        <strong>
                          {Language[languageStorage]['Revisões (realizado)']}:{' '}
                        </strong>
                        <Link
                          to="#"
                          onClick={() => {
                            handleZoomImgRevisions(
                              responseDataDetalhes.filter(i => i.isRevision),
                            );
                          }}
                        >
                          {Language[languageStorage]['Mostrar revisões']}
                        </Link>
                      </li>
                    </ul>
                  </ContainerMapDetails>
                ))}
            </ContainerListCards>
          </ContainerHeader>
        )}

        {zoomImg === 'principal' && (
          <ZoomImg>
            <Zoom img={img} zoomScale={1} width="100%" height={380} />
          </ZoomImg>
        )}
        {zoomImg === 'revisoes' && (
          <ZoomImg>
            {img &&
              typeof img === 'object' &&
              img.map(imgRevision => (
                <section key={imgRevision.imagem}>
                  <article>
                    {imgRevision.revisions
                      .map(i => `${i.horaIni} - ${i.horaFim}`)
                      .join(', ')}
                  </article>

                  <span>{`${Language[languageStorage].Observacao}: ${imgRevision.observacao}`}</span>

                  <Zoom
                    img={imgRevision.imagem}
                    zoomScale={1}
                    width="100%"
                    height={380}
                  />
                </section>
              ))}

            {img && !img.length && (
              <h1>
                {Language[languageStorage]['Histórico de revisões vazio']}
              </h1>
            )}
          </ZoomImg>
        )}

        {!exibirDetalhes && (
          <>
            <ContainerButtonAtualizar>
              <Button
                type="button"
                onClick={() => {
                  setSearch('');
                  setSearchDetails('');
                  setZoomImg('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  onRequestClose();
                }}
              >
                {Language[languageStorage].Ok}
              </Button>
            </ContainerButtonAtualizar>

            <ContainerButtonsPdfExcel>
              <button
                type="button"
                id="pdf"
                onClick={() => handleDownloadPDF(responseData, 'dados')}
              >
                <VscFilePdf size={22} />
              </button>

              <ReactHTMLTableToExcel
                type="button"
                id="excel"
                table="tableListagemDadosColaborador"
                filename={`${
                  Language[languageStorage]['relatorio-colaboradores']
                }-${moment().format('DD-MM-YYYY')}`}
                sheet="tablexls"
                buttonText={<RiFileExcel2Line size={22} />}
              />
            </ContainerButtonsPdfExcel>
          </>
        )}
        {exibirDetalhes && zoomImg === '' && (
          <>
            <ContainerButtonDetalhes>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setSearch('');
                  setSearchDetails('');
                  setZoomImg('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  onRequestClose();
                }}
              >
                {Language[languageStorage].Ok}
              </Button>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setExibirDetalhes(false);
                  setSearch('');
                  setSearchDetails('');
                  setZoomImg('');
                }}
              >
                {Language[languageStorage].Voltar}
              </Button>
            </ContainerButtonDetalhes>

            <ContainerButtonsPdfExcel>
              <button
                type="button"
                id="pdf"
                onClick={() => {
                  handleDownloadPDF(responseDataDetalhes, 'detalhes');
                }}
              >
                <VscFilePdf size={22} />
              </button>

              <ReactHTMLTableToExcel
                type="button"
                id="excel"
                table="tableListagemDetalhesColaborador"
                filename={`${
                  Language[languageStorage]['relatorio-colaboradores']
                }-${moment().format('DD-MM-YYYY')}`}
                sheet="tablexls"
                buttonText={<RiFileExcel2Line size={22} />}
              />
            </ContainerButtonsPdfExcel>
          </>
        )}

        {zoomImg !== '' && (
          <>
            <ContainerButtonDetalhes>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setSearch('');
                  setSearchDetails('');
                  setZoomImg('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  onRequestClose();
                }}
              >
                {Language[languageStorage].Ok}
              </Button>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setZoomImg('');
                }}
              >
                {Language[languageStorage].Voltar}
              </Button>
            </ContainerButtonDetalhes>

            {zoomImg === 'revisoes' && (
              <ContainerButtonsPdfExcel>
                <ReactHTMLTableToExcel
                  type="button"
                  id="excel"
                  table="tableListagemDetalhesRevisoesColaborador"
                  filename={`${
                    Language[languageStorage]['relatorio-revisoes-colaborador']
                  }-${moment().format('DD-MM-YYYY')}`}
                  sheet="tablexls"
                  buttonText={<RiFileExcel2Line size={22} />}
                />
              </ContainerButtonsPdfExcel>
            )}
          </>
        )}
      </ContainerModal>

      <ContainerTableMacon>
        <table id="tableListagemDadosColaborador">
          <HeaderTable headers={headers} />
          <tbody>
            {dadosHistoricoExcelPdf.map(res => (
              <tr key={uuid()}>
                <td>{dados.name === null ? '-' : dados.name}</td>
                <td>{dados.cpf === null ? '-' : maskCpf(dados.cpf)}</td>
                <td>{dados.email === null ? '-' : dados.email}</td>
                <td>{dados.telefone === null ? '-' : dados.telefone}</td>
                <td>{res.Ambiente.nome === null ? '-' : res.Ambiente.nome}</td>
                <td>
                  {res.horaInicialAmbiente === null &&
                  res.horaFinalAmbiente === null
                    ? '-'
                    : `${res.horaInicialAmbiente} - ${res.horaFinalAmbiente}`}
                </td>
                <td>
                  {res.createdAt === null
                    ? '-'
                    : moment(new Date(res.createdAt)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                </td>
                <td>
                  {res.finishAt === null
                    ? '-'
                    : moment(new Date(res.finishAt)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                </td>
                <td>{res.observacao}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTableMacon>

      <ContainerTableMacon>
        <table id="tableListagemDetalhesColaborador">
          <HeaderTable headers={headersRevisions} />
          <tbody>
            {detalhesHistoricoExcelPdf.map(res => (
              <tr key={uuid()}>
                <td>{dados.name === null ? '-' : dados.name}</td>
                <td>{dados.cpf === null ? '-' : maskCpf(dados.cpf)}</td>
                <td>{dados.email === null ? '-' : dados.email}</td>
                <td>{dados.telefone === null ? '-' : dados.telefone}</td>
                <td>{res.Ambiente.nome === null ? '-' : res.Ambiente.nome}</td>
                <td>
                  {res.horaInicialAmbiente === null &&
                  res.horaFinalAmbiente === null
                    ? '-'
                    : `${res.horaInicialAmbiente} - ${res.horaFinalAmbiente}`}
                </td>
                <td>
                  {res.revisions
                    .map(i => `${i.horaIni} - ${i.horaFim}`)
                    .join(', ')}
                </td>
                <td>
                  {res.createdAt === null
                    ? '-'
                    : moment(new Date(res.createdAt)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                </td>
                <td>
                  {res.finishAt === null
                    ? '-'
                    : moment(new Date(res.finishAt)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                </td>
                <td>
                  {res.nameTask === null || res.nameTask.trim() === ''
                    ? '-'
                    : res.nameTask}
                </td>
                <td>{res.observacao}</td>
                <td>
                  {filterSchedulesReviewsDone(
                    res,
                    detalhesHistoricoExcelPdf.length,
                  )}
                </td>
                <td>
                  {res.imagem === null ||
                  res.imagem.split('/').pop() === 'null' ? (
                    'Sem Foto'
                  ) : (
                    <a href={res.imagem === null ? '-' : res.imagem}>
                      {Language[languageStorage]['Ir para Foto']}
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTableMacon>

      <ContainerTableMacon>
        <table id="tableListagemDetalhesRevisoesColaborador">
          <HeaderTable headers={headersRevisions} />
          <tbody>
            {newDetalhesHistorico.map(res => (
              <tr key={uuid()}>
                <td>{dados.name === null ? '-' : dados.name}</td>
                <td>{dados.cpf === null ? '-' : maskCpf(dados.cpf)}</td>
                <td>{dados.email === null ? '-' : dados.email}</td>
                <td>{dados.telefone === null ? '-' : dados.telefone}</td>
                <td>{res.Ambiente.nome === null ? '-' : res.Ambiente.nome}</td>
                <td>
                  {res.horaInicialAmbiente === null &&
                  res.horaFinalAmbiente === null
                    ? '-'
                    : `${res.horaInicialAmbiente} - ${res.horaFinalAmbiente}`}
                </td>
                <td>
                  {res.revisions
                    .map(i => `${i.horaIni} - ${i.horaFim}`)
                    .join(', ')}
                </td>
                <td>
                  {res.createdAt === null
                    ? '-'
                    : moment(new Date(res.createdAt)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                </td>
                <td>
                  {res.finishAt === null
                    ? '-'
                    : moment(new Date(res.finishAt)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                </td>
                <td>
                  {res.nameTask === null || res.nameTask.trim() === ''
                    ? '-'
                    : res.nameTask}
                </td>
                <td>{res.observacao}</td>

                <td>
                  {filterSchedulesReviewsDone(res, newDetalhesHistorico.length)}
                </td>
                <td>
                  {res.imagem === null ||
                  res.imagem.split('/').pop() === 'null' ? (
                    Language[languageStorage]['Sem Foto']
                  ) : (
                    <a href={res.imagem}>
                      {Language[languageStorage]['Ir para Foto']}
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTableMacon>
    </Modal>
  );
};
