import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Zoom from '@material-ui/core/Zoom';
import { BsGridFill, BsListUl } from 'react-icons/bs';
import moment from 'moment';
import {
  Container,
  ContainerTitle,
  Content,
  ModeLateral,
  ModeVertical,
  ContainerSearch,
  HeaderDate,
} from './styles';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { CardAgenda } from '../../../components/CardAgenda';
import { Loading } from '../../../components/Loading';
import Search from '../../../components/Search';
import { ModalAgendaDash } from '../../../components/ModalAgendaDash';
import { Agendamentos, EmptyAgendamento } from '../../../models/Agendamentos';
import { useAuth } from '../../../hooks/auth';
import IconFilter from '../../../assets/IconFilter.svg';
import { ModalFilter, StatusButton } from '../../../components/ModalFilter';
import { CardEmpty } from '../../../components/CardEmpty';
import Language from '../../../language/language.json';

export const DashboardHistAgenda: React.FC = () => {
  const { empresaPrincipal, languageStorage } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showAgenda, setShowAgenda] = useState<Agendamentos>(EmptyAgendamento);
  const [isModeVisibleCanva, setIsModeVisibleCanva] = useState(false);
  const [agendasAll, setAgendasAll] = useState<Agendamentos[]>([]);
  const [search, setSearch] = useState('');
  const [searchDataInicial, setSearchDataInicial] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [searchDataFinal, setSearchDataFinal] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [dataFilterModal, setDataFilterModal] = useState<StatusButton[]>([
    {
      status: true,
      name: Language[languageStorage].Colaborador,
    },
    {
      status: false,
      name: Language[languageStorage].Filial,
    },
    {
      status: false,
      name: Language[languageStorage].Ambiente,
    },
    {
      status: false,
      name: Language[languageStorage]['Intervalo de datas'],
    },
  ]);

  const loadAgenda = useCallback(
    async (dataIniMes: string, dataEndMes: string) => {
      setLoading(true);
      const companyId =
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
      api
        .get(
          `agendas/v2/Agendamentos/company?dataIni=${dataIniMes}&dataFim=${dataEndMes}&companyId=${companyId}`, // ${empresaPrincipal[0].Id_Empresas}`,
        )
        .then(response => {
          // console.log(response[3].data);
          const dataAgenda: Agendamentos[] = response.data;
          // console.log(dataAgenda);
          dataAgenda.sort((a, b) => {
            const valueInitA = Number(
              a.Agenda.Periodicidade.HORA_BASE_INICIAL.split(':').join(''),
            );
            const valueInitB = Number(
              b.Agenda.Periodicidade.HORA_BASE_INICIAL.split(':').join(''),
            );
            if (valueInitA < valueInitB) return -1;
            if (valueInitA > valueInitB) return 1;

            const valueFimA = Number(
              a.Agenda.Periodicidade.HORA_BASE_FINAL.split(':').join(''),
            );
            const valueFimB = Number(
              b.Agenda.Periodicidade.HORA_BASE_FINAL.split(':').join(''),
            );
            if (valueFimA < valueFimB) return -1;
            if (valueFimA > valueFimB) return 1;
            return 0;
          });

          setAgendasAll(dataAgenda);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);

          addToast({
            type: 'error',
            title: Language[languageStorage].Erro,
            description:
              Language[languageStorage][
                'Erro ao carregar dados, por favor atualize a página'
              ],
          });
        });
    },
    [addToast, empresaPrincipal, languageStorage],
  );

  useEffect(() => {
    const dateStart = searchDataInicial || moment().format('YYYY-MM-DD');
    const dateEnd = searchDataFinal || moment().format('YYYY-MM-DD');
    loadAgenda(dateStart, dateEnd);
  }, [loadAgenda, searchDataFinal, searchDataInicial]);

  const agendaList = useMemo(() => {
    let agendaFilter = agendasAll;

    agendaFilter = agendaFilter.filter(item => {
      const timeDate = Number(item.dataAgend.split('-').join(''));
      const timeIni = Number(searchDataInicial.split('-').join(''));
      const timeEnd = Number(searchDataFinal.split('-').join(''));
      return timeDate >= timeIni && timeDate <= timeEnd;
    });

    if (search) {
      dataFilterModal.forEach(item => {
        if (
          item.status &&
          item.name === Language[languageStorage].Colaborador
        ) {
          agendaFilter = agendaFilter.filter(it =>
            it.User.nome.toLowerCase().includes(search.toLowerCase()),
          );
        } else if (
          item.status &&
          item.name === Language[languageStorage].Ambiente
        ) {
          agendaFilter = agendaFilter.filter(it =>
            it.Agenda.Environment.name
              .toLowerCase()
              .includes(search.toLowerCase()),
          );
        }
      });
    }

    return agendaFilter;
  }, [
    agendasAll,
    dataFilterModal,
    languageStorage,
    search,
    searchDataFinal,
    searchDataInicial,
  ]);

  const agendaListImcomplet = useMemo(() => {
    let agendaFilter: Agendamentos[] = agendaList;

    agendaFilter = agendaFilter.filter(i => i.status === 'Incompleto');

    return agendaFilter;
  }, [agendaList]);

  const agendaListComplete = useMemo(() => {
    let agendaFilter = agendaList;

    agendaFilter = agendaFilter.filter(i => i.status === 'Completo');

    return agendaFilter;
  }, [agendaList]);

  const agendaListCompleting = useMemo(() => {
    let agendaFilter = agendaList;

    agendaFilter = agendaFilter.filter(i => i.status === 'Andamento');

    return agendaFilter;
  }, [agendaList]);

  const agendaListStart = useMemo(() => {
    let agendaFilter = agendaList;

    agendaFilter = agendaFilter.filter(
      i =>
        i.status !== 'Andamento' &&
        i.status !== 'Incompleto' &&
        i.status !== 'Completo',
    );

    return agendaFilter;
  }, [agendaList]);

  const renderMouth = useCallback(
    (mouth: number): string => {
      switch (mouth) {
        case 1:
          return Language[languageStorage].Calendario.Janeiro;
        case 2:
          return Language[languageStorage].Calendario.Fevereiro;
        case 3:
          return Language[languageStorage].Calendario.Marco;
        case 4:
          return Language[languageStorage].Calendario.Abril;
        case 5:
          return Language[languageStorage].Calendario.Maio;
        case 6:
          return Language[languageStorage].Calendario.Junho;
        case 7:
          return Language[languageStorage].Calendario.Julho;
        case 8:
          return Language[languageStorage].Calendario.Agosto;
        case 9:
          return Language[languageStorage].Calendario.Setembro;
        case 10:
          return Language[languageStorage].Calendario.Outubro;
        case 11:
          return Language[languageStorage].Calendario.Novembro;
        case 12:
          return Language[languageStorage].Calendario.Dezembro;
        default:
          return '';
      }
    },
    [languageStorage],
  );

  const dateRender = useMemo(() => {
    const [yearStart, mouthStart, dayStart] = searchDataInicial.split('-');
    const [yearEnd, mouthEnd, dayEnd] = searchDataFinal.split('-');
    if (searchDataInicial === searchDataFinal)
      if (languageStorage === 'pt-BR') {
        return `${dayStart} de ${renderMouth(
          Number(mouthStart),
        )}, ${yearStart}`;
      } else {
        return `${renderMouth(Number(mouthStart))} ${dayStart}, ${yearStart}`;
      }
    if (yearStart === yearEnd && mouthStart === mouthEnd) {
      if (languageStorage === 'pt-BR') {
        return `${dayStart} a ${dayEnd} de ${renderMouth(
          Number(mouthStart),
        )}, ${yearStart}`;
      }
      return `${renderMouth(
        Number(mouthStart),
      )} ${dayStart} to ${dayEnd}, ${yearStart}`;
    }
    if (yearStart === yearEnd && mouthStart !== mouthEnd) {
      if (languageStorage === 'pt-BR') {
        return `${dayStart} de ${renderMouth(
          Number(mouthStart),
        )} a ${dayEnd} de ${renderMouth(Number(mouthEnd))}, ${yearStart}`;
      }
      return `${renderMouth(Number(mouthStart))} ${dayStart} to ${renderMouth(
        Number(mouthEnd),
      )} ${dayEnd}, ${yearStart}`;
    }
    if (languageStorage === 'pt-BR') {
      return `${dayStart} de ${renderMouth(
        Number(mouthStart),
      )}, ${yearStart} a ${dayEnd} de ${renderMouth(
        Number(mouthEnd),
      )}, ${yearEnd}`;
    }
    return `${renderMouth(
      Number(mouthStart),
    )} ${dayStart}, ${yearStart} to ${renderMouth(
      Number(mouthEnd),
    )} ${dayEnd}, ${yearEnd}`;
  }, [languageStorage, renderMouth, searchDataFinal, searchDataInicial]);

  return (
    <Container>
      <ModalAgendaDash
        agenda={showAgenda}
        onClose={() => setShowModal(false)}
        visible={showModal}
        date={showAgenda.dataAgend}
      />
      <ModalFilter
        isOpen={showModalFilter}
        type="dashAgenda"
        onRequestClose={() => setShowModalFilter(false)}
        onReturnData={e => setDataFilterModal(e)}
      />
      <ContainerTitle>
        <header>
          <h1>{Language[languageStorage].Dashboard}</h1>

          <Tooltip
            title={
              Language[languageStorage][
                'Nessa página você tem acesso a visualização da dashboard para fim de acompanhamento de métricas, integradada com uma apresentação do Power Bi a sua escolha'
              ]
            }
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </header>
        <div>
          <button
            type="button"
            className={isModeVisibleCanva ? 'selected' : 'notSelec'}
            onClick={() => setIsModeVisibleCanva(true)}
          >
            <BsGridFill />
          </button>
          <button
            type="button"
            className={!isModeVisibleCanva ? 'selected' : 'notSelec'}
            onClick={() => setIsModeVisibleCanva(false)}
          >
            <BsListUl />
          </button>
        </div>
      </ContainerTitle>
      <Content>
        <ContainerSearch isFilterDateInterval={dataFilterModal[3].status}>
          {!dataFilterModal[3].status ? (
            <Search
              onSearch={(value: string) => {
                setSearch(value);
              }}
              nomePlaceHolder={Language[languageStorage].Buscar}
            />
          ) : (
            <>
              <Search
                onSearch={(value: string) => {
                  setSearchDataInicial(value);
                }}
                nomePlaceHolder={
                  Language[languageStorage]['Buscar data inicial']
                }
                type="date"
              />

              <Search
                onSearch={(value: string) => {
                  setSearchDataFinal(value);
                }}
                nomePlaceHolder={Language[languageStorage]['Buscar data final']}
                type="date"
              />
            </>
          )}

          <button type="button" onClick={() => setShowModalFilter(true)}>
            <img src={IconFilter} alt="iconFilter" />
          </button>
        </ContainerSearch>
        <HeaderDate>
          <h2>{dateRender}</h2>
        </HeaderDate>
        {/* <CalendarDate dataRange={dataRange} setDataRange={setDataRange} /> */}
        {isModeVisibleCanva ? (
          <ModeLateral>
            <h1>{Language[languageStorage].Incompleto}</h1>
            <div className="items">
              {agendaListImcomplet.map((item, index) => (
                <CardAgenda
                  key={`${item.agendaId}-${index.toString()}`}
                  item={item}
                  onPress={() => {
                    setShowAgenda(item);
                    setShowModal(true);
                  }}
                />
              ))}
              {agendaListImcomplet.length === 0 && <CardEmpty />}
            </div>
            <h1>{Language[languageStorage].Feito}</h1>
            <div className="items">
              {agendaListComplete.map((item, index) => (
                <CardAgenda
                  key={`${item.agendaId}-${index.toString()}`}
                  item={item}
                  onPress={() => {
                    setShowAgenda(item);
                    setShowModal(true);
                  }}
                />
              ))}
              {agendaListComplete.length === 0 && <CardEmpty />}
            </div>
            <h1>{Language[languageStorage].Fazendo}</h1>
            <div className="items">
              {agendaListCompleting.map((item, index) => (
                <CardAgenda
                  key={`${item.agendaId}-${index.toString()}`}
                  item={item}
                  onPress={() => {
                    setShowAgenda(item);
                    setShowModal(true);
                  }}
                />
              ))}
              {agendaListCompleting.length === 0 && <CardEmpty />}
            </div>
            <h1>{Language[languageStorage]['A iniciar']}</h1>
            <div className="items">
              {agendaListStart.map((item, index) => (
                <CardAgenda
                  key={`${item.agendaId}-${index.toString()}`}
                  item={item}
                  onPress={() => {
                    setShowAgenda(item);
                    setShowModal(true);
                  }}
                />
              ))}
              {agendaListStart.length === 0 && <CardEmpty />}
            </div>
          </ModeLateral>
        ) : (
          <ModeVertical>
            <div>
              <h1>{Language[languageStorage].Incompleto}</h1>
              {agendaListImcomplet.map((item, index) => (
                <CardAgenda
                  key={`${item.agendaId}-${index.toString()}`}
                  item={item}
                  onPress={() => {
                    setShowAgenda(item);
                    setShowModal(true);
                  }}
                />
              ))}
              {agendaListImcomplet.length === 0 && <CardEmpty />}
            </div>
            <div>
              <h1>{Language[languageStorage].Feito}</h1>
              {agendaListComplete.map((item, index) => (
                <CardAgenda
                  key={`${item.agendaId}-${index.toString()}`}
                  item={item}
                  onPress={() => {
                    setShowAgenda(item);
                    setShowModal(true);
                  }}
                />
              ))}
              {agendaListComplete.length === 0 && <CardEmpty />}
            </div>
            <div>
              <h1>{Language[languageStorage].Fazendo}</h1>
              {agendaListCompleting.map((item, index) => (
                <CardAgenda
                  key={`${item.agendaId}-${index.toString()}`}
                  item={item}
                  onPress={() => {
                    setShowAgenda(item);
                    setShowModal(true);
                  }}
                />
              ))}
              {agendaListCompleting.length === 0 && <CardEmpty />}
            </div>
            <div>
              <h1>{Language[languageStorage]['A iniciar']}</h1>
              {agendaListStart.map((item, index) => (
                <CardAgenda
                  key={`${item.agendaId}-${index.toString()}`}
                  item={item}
                  onPress={() => {
                    setShowAgenda(item);
                    setShowModal(true);
                  }}
                />
              ))}
              {agendaListStart.length === 0 && <CardEmpty />}
            </div>
          </ModeVertical>
        )}
      </Content>
      {loading && <Loading />}
    </Container>
  );
};
