import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';

import styles from './styles/colors';

import { DrawerHeader } from './components/DrawerHeader';

import { HeaderMenu } from './components/HeaderMenu';

import { Container, Content } from './styles';

import GlobalStyle from './styles/global';

import AppProvider from './hooks';

import Routes from './routes';

const App: React.FC = () => {
  return (
    <Router>
      <ThemeProvider theme={styles}>
        <AppProvider>
          <Container>
            <DrawerHeader />

            <Content>
              <HeaderMenu />
              <Routes />
            </Content>
          </Container>
        </AppProvider>

        <GlobalStyle />
      </ThemeProvider>
    </Router>
  );
};
export default App;
