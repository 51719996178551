import React, { useState, useEffect, useMemo, useCallback } from 'react';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ModalHistoricoProcedimento } from '../../../components/ModalHistoricoProcedimento';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import api from '../../../services/api';
import Search from '../../../components/Search';
import ModalConexao from '../../../components/ModalConexao';
import { ModalFilter, StatusButton } from '../../../components/ModalFilter';
import Pagination from '../../../components/Pagination';
import IconFilter from '../../../assets/IconFilter.svg';
import DropDownPagination from '../../../components/DropDownPagination';
import HeaderTable from '../../../components/HeaderTable';
import Language from '../../../language/language.json';

import {
  Container,
  ContainerTitle,
  ContainerItem,
  ContainerSearch,
  ContainerSemResultados,
  ContainerListItens,
  ContainerPagination,
} from './styles';
import { ContainerSelect } from '../../RelatoriosV2/DashboardColaboradores/styles';
import { Empresa } from '../../../models/Empresa';

interface ResponseGet {
  ANDAR_ENV_PROCEDURES_NAO_REALIZADO: string;
  AVATAR_TASK_AMBIENTE_NAO_REALIZADO: string;
  CATEGORIA_ENV_PROCEDURES_NAO_REALIZADO: number;
  COMPANYID_TASK_AMBIENTE_NAO_REALIZADO: number;
  CPF_TASK_AMBIENTE_NAO_REALIZADO: string;
  CREATEDAT_ENV_PROCEDURES_NAO_REALIZADO: string;
  CompanyId_Ambiente: number;
  DATA_NASCIMENTO_TASK_AMBIENTE_NAO_REALIZADO: string;
  DIAS_LIMPEZA_ENV_PROCEDURES_NAO_REALIZADO: number;
  DataCriacaoAmbiente: string;
  DataHoraCreatedAt_task_nao_realizada_duplicada: string;
  DataHora_RevisaoFinalizada: string;
  EMAIL_TASK_AMBIENTE_NAO_REALIZADO: string;
  ID_AMBIENTE_HISTORICO: number;
  ID_AMBIENTE_TASK_NAO_REALIZADO: number;
  ID_ENV_PROCEDURES_NAO_REALIZADO: number;
  ID_PROCEDIMENTO_PREDEFINIDO: number;
  ID_TASK_PROCEDIMENTO_PREDEFINIDO: number;
  ID_TASK_REALIZADA: number;
  Id_Dom_StatusRevisao: number;
  NAME_ENV_PROCEDURES_NAO_REALIZADO: string;
  NAME_PROCEDIMENTO_NAO_REALIZADO: string;
  NOME_USER_TASK_AMBIENTE_NAO_REALIZADO: string;
  NomeCategoria: string;
  NomeProcedimentos: string;
  NomeSetor: string;
  Nome_Ambiente: string;
  QTD_DIAS_LIMPEZAE_ENV_PROCEDURES_NAO_REALIZADO: number;
  REVISAO_ENV_PROCEDURES_NAO_REALIZADO: boolean;
  REVISAO_HORA_FINAL_ENV_PROCEDURES_NAO_REALIZADO: string;
  REVISAO_HORA_INICIAL_ENV_PROCEDURES_NAO_REALIZADO: string;
  Revisao: boolean;
  RevisaoHoraFinal: string;
  RevisaoHoraInicial: string;
  SETORID_ENV_PROCEDURES_NAO_REALIZADO: number;
  TASK_ID_AMBIENTE_NAO_REALIZADO: number;
  TASK_NAO_REALIZADA: boolean;
  TELEFONE_TASK_AMBIENTE_NAO_REALIZADO: string;
  UPDATEDAT_ENV_PROCEDURES_NAO_REALIZADO: string;
  USER_ID_AMBIENTE_TASK_NAO_REALIZADO: number;
  UserId_Historico: number;
  andar: string;
  avatar: string;
  categoriaId: number;
  companyId: number;
  cpf: string;
  createdAt: string;
  createdAt_TASK_NAO_REALIZADO: string;
  dataNascimento: string;
  diasLimpezaId: number;
  email: string;
  image: string;
  nome: string;
  observacao: string;
  setorId: number;
  statusRevisaoNome: string;
  telefone: string;
  updatedAt: string;
  updatedAt_TASK_NAO_REALIZADO: string;
  escondido: boolean;
}

const DashboardProcedimentos: React.FC = () => {
  const { user, empresaPrincipal, languageStorage } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [filialId, setFilialId] = useState(-1);
  const [filiais, setFiliais] = useState<Empresa[]>([]);
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [modalDetalhesAmbiente, setModalDetalhesAmbiente] = useState(true);
  const [dadosModal, setDadosModal] = useState<ResponseGet[]>();
  const [dadosTaskIdModal, setDadosTaskIdModal] = useState<number>();
  const [dadosHistoricoDatasModal, setDadosHistoricoDatasModal] = useState('');

  const [dataFilterModal, setDataFilterModal] = useState<StatusButton[]>([
    {
      status: false,
      name: Language[languageStorage].Colaborador,
    },
    {
      status: false,
      name: Language[languageStorage].Ambiente,
    },
    {
      status: true,
      name: Language[languageStorage].Tarefa,
    },
    {
      status: false,
      name: Language[languageStorage]['Intervalo de datas'],
    },
  ]);

  const [search, setSearch] = useState('');
  const [searchDataInicial, setSearchDataInicial] = useState('');
  const [searchDataFinal, setSearchDataFinal] = useState('');
  const [showModalFilter, setShowModalFilter] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);

  const dataInicio = moment().format('YYYY-MM-01');
  const dataFim = moment().format('YYYY-MM-DD');

  const dropDown = [
    { valor: '20', id: '20' },
    { valor: '40', id: '40' },
    { valor: '80', id: '80' },
    { valor: '160', id: '160' },
    { valor: Language[languageStorage].Todos, id: '1' },
  ];

  const headers = [
    {
      name: Language[languageStorage]['Nome do procedimento'],
      field: 'NAME_PROCEDIMENTO_NAO_REALIZADO',
      sortable: false,
    },
  ];

  const handleMaskDate = useCallback(data => {
    const dataTemp = data.split('-').reverse().join('/');

    return dataTemp;
  }, []);

  const getEmpresas = useCallback(async () => {
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
    api
      .get(`empresas/${companyId}`)
      .then(resp => {
        setFiliais(resp.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [empresaPrincipal]);

  useEffect(() => {
    setLoading(true);
    getEmpresas();

    api
      .get(
        `/observacao?dataInicio=${dataInicio}&dataFim=${dataFim}&id=${
          empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
        }`,
      )
      .then(data => {
        setResponse(data.data.objetoFodaResponse);
        setDadosHistoricoDatasModal(
          `${Language[languageStorage].DeHorario} ${handleMaskDate(
            dataInicio,
          )} ${Language[languageStorage].ateHorario} ${handleMaskDate(
            dataFim,
          )}`,
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);

        addToast({
          type: 'error',
          title: Language[languageStorage].Erro,
          description: `${Language[languageStorage]['Erro ao carregar dados, por favor atualize a página']}!`,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const mesAtual = moment().format('YYYY-MM-DD');
    const mesDataInicial = moment(searchDataInicial).format('YYYY-MM-DD');
    const mesDataFinal = moment(searchDataFinal).format('YYYY-MM-DD');

    if (
      searchDataInicial.length === 10 &&
      searchDataFinal.length === 10 &&
      searchDataInicial.substr(0, 1) !== '0' &&
      searchDataFinal.substr(0, 1) !== '0'
    ) {
      if (mesDataInicial <= mesAtual && mesDataFinal <= mesAtual) {
        setLoading(true);
        api
          .get(
            `/observacao?dataInicio=${searchDataInicial}&dataFim=${searchDataFinal}&id=${
              empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
            }`,
          )
          .then(data => {
            setResponse(data.data.objetoFodaResponse);
            setDadosHistoricoDatasModal(
              `${Language[languageStorage].DeHorario} ${handleMaskDate(
                searchDataInicial,
              )} ${Language[languageStorage].ateHorario} ${handleMaskDate(
                searchDataFinal,
              )}`,
            );
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);

            addToast({
              type: 'error',
              title: Language[languageStorage].Erro,
              description: `${Language[languageStorage]['Erro ao carregar dados, por favor atualize a página']}!`,
            });
          });
      } else {
        addToast({
          type: 'info',
          title: Language[languageStorage]['Filtro inválido'],
          description:
            Language[languageStorage][
              'Intervalo de datas inválidos, favor verificar'
            ],
        });
      }
    }
  }, [
    searchDataInicial,
    searchDataFinal,
    handleMaskDate,
    user,
    empresaPrincipal,
    addToast,
    languageStorage,
  ]);

  useEffect(() => {
    setSearchDataInicial('');
    setSearchDataFinal('');
  }, [search]);

  useEffect(() => {
    setSearch('');
  }, [searchDataInicial, searchDataFinal]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search, searchDataInicial, searchDataFinal]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    let computedResponsesAux: ResponseGet[] = [];
    const arrayResponseFilter: ResponseGet[] = [];
    const arrayResponseIdsTasks: number[] = [];
    computedResponses = response;

    if (filialId !== -1) {
      computedResponses = computedResponses.filter(
        i => filialId === i.companyId,
      );
    }

    if (search) {
      computedResponses = computedResponses.filter((res: ResponseGet) => {
        const dataFilter: any = [];

        dataFilterModal.forEach(filtro => {
          if (filtro.status) {
            switch (filtro.name) {
              case Language[languageStorage].Colaborador:
                dataFilter.push(res.NOME_USER_TASK_AMBIENTE_NAO_REALIZADO);
                break;
              case Language[languageStorage].Ambiente:
                dataFilter.push(res.NAME_ENV_PROCEDURES_NAO_REALIZADO);
                break;
              case Language[languageStorage].Tarefa:
                dataFilter.push(res.NAME_PROCEDIMENTO_NAO_REALIZADO);
                break;
              default:
                break;
            }
          }
        });

        return dataFilter.some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        );
      });
    }

    computedResponses.forEach((element, index) => {
      if (
        element.TASK_NAO_REALIZADA === true &&
        element.createdAt_TASK_NAO_REALIZADO !== null &&
        element.updatedAt_TASK_NAO_REALIZADO !== null &&
        arrayResponseIdsTasks.indexOf(
          element.TASK_ID_AMBIENTE_NAO_REALIZADO,
        ) === -1
      ) {
        arrayResponseIdsTasks.push(element.TASK_ID_AMBIENTE_NAO_REALIZADO);
        arrayResponseFilter.push(element);
      }

      if (index === computedResponses.length - 1) {
        computedResponsesAux = arrayResponseFilter;
      }
    });

    setTotalItems(computedResponsesAux.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponsesAux;
    }

    return computedResponsesAux.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    ITEMS_PER_PAGE,
    currentPage,
    dataFilterModal,
    filialId,
    languageStorage,
    response,
    search,
  ]);

  return (
    <>
      <ModalConexao />

      <ModalFilter
        isOpen={showModalFilter}
        type="dashTarefasIncompletas"
        onRequestClose={() => setShowModalFilter(false)}
        onReturnData={e => setDataFilterModal(e)}
      />

      {!!dadosModal && !!dadosTaskIdModal && !!dadosHistoricoDatasModal && (
        <ModalHistoricoProcedimento
          isOpen={!modalDetalhesAmbiente}
          dados={dadosModal}
          dadosIdTask={dadosTaskIdModal}
          dadosHistoricoDatas={dadosHistoricoDatasModal}
          onRequestClose={() => setModalDetalhesAmbiente(true)}
        />
      )}

      <Container>
        <ContainerTitle>
          <h1>{Language[languageStorage]['Tarefas Incompletas']}</h1>

          <Tooltip
            title={`${Language[languageStorage]['Nessa página você tem acesso a visualização detalhada do histórico de atividades não realizadas pelos ASGs, tarefas incompletas com suas justificativas. Filtragem a partir dos procedimentos/tarefas']}.`}
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </ContainerTitle>

        <ContainerSelect>
          <select
            value={filialId}
            onChange={event => {
              const val = parseInt(event.target.value, 10);
              setFilialId(val);
            }}
          >
            <option value={-1}>{Language[languageStorage].Todos}</option>
            {filiais.map(i => (
              <option key={i.ID} value={i.ID}>
                {i.NOME}
              </option>
            ))}
          </select>
        </ContainerSelect>

        <ContainerSearch isFilterDateInterval={dataFilterModal[3].status}>
          {!dataFilterModal[3].status ? (
            <Search
              onSearch={(value: string) => {
                setSearch(value);
              }}
              nomePlaceHolder={Language[languageStorage].Buscar}
            />
          ) : (
            <>
              <Search
                onSearch={(value: string) => {
                  setSearchDataInicial(value);
                }}
                nomePlaceHolder={
                  Language[languageStorage]['Buscar data inicial']
                }
                type="date"
              />

              <Search
                onSearch={(value: string) => {
                  setSearchDataFinal(value);
                }}
                nomePlaceHolder={Language[languageStorage]['Buscar data final']}
                type="date"
              />
            </>
          )}

          <button type="button" onClick={() => setShowModalFilter(true)}>
            <img src={IconFilter} alt="iconFilter" />
          </button>
        </ContainerSearch>

        {response.length === 0 && (
          <ContainerSemResultados>
            <h2>
              {`${Language[languageStorage]['Não existe um histórico de tarefas incompletas para o intervalo de datas atual']}.`}
            </h2>
          </ContainerSemResultados>
        )}

        <ContainerListItens>
          <>
            {responseData.map(res => (
              <ContainerItem
                key={`${res.TASK_ID_AMBIENTE_NAO_REALIZADO}-${res.ID_AMBIENTE_TASK_NAO_REALIZADO}-${res.createdAt_TASK_NAO_REALIZADO}-${res.updatedAt_TASK_NAO_REALIZADO}`}
                type="button"
                onClick={() => {
                  setDadosModal(response);
                  setDadosTaskIdModal(res.ID_TASK_PROCEDIMENTO_PREDEFINIDO);
                  setModalDetalhesAmbiente(false);
                }}
              >
                <strong>{res.NAME_PROCEDIMENTO_NAO_REALIZADO}</strong>
              </ContainerItem>
            ))}

            <table id="tableRelatorioProcedimentos" style={{ display: 'none' }}>
              <HeaderTable headers={headers} />
              <tbody>
                {responseData.map(res => (
                  <tr
                    key={`${res.TASK_ID_AMBIENTE_NAO_REALIZADO}-${res.ID_AMBIENTE_TASK_NAO_REALIZADO}-${res.createdAt_TASK_NAO_REALIZADO}-${res.updatedAt_TASK_NAO_REALIZADO}`}
                  >
                    <td>{res.NAME_PROCEDIMENTO_NAO_REALIZADO}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        </ContainerListItens>

        <ContainerPagination>
          {response.length > 0 && (
            <>
              <div className="divPaginacao">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
                <DropDownPagination
                  onChangeItems={(value: string) => {
                    setCurrentPage(1);
                    if (value === Language[languageStorage].Todos) {
                      setITEMS_PER_PAGE(1);
                    } else {
                      setITEMS_PER_PAGE(Number(value));
                    }
                  }}
                  objetoEnum={dropDown}
                  minWidth={30}
                />
              </div>

              <ReactHTMLTableToExcel
                id="export-excel"
                className="btn"
                table="tableRelatorioProcedimentos"
                filename={`${
                  Language[languageStorage]['relatorio-procedimentos'] +
                  moment().format('DD-MM-YYYY')
                }`}
                sheet="tablexls"
                buttonText={Language[languageStorage]['Exportar Excel']}
              />
            </>
          )}
        </ContainerPagination>
      </Container>

      {loading && <Loading />}
    </>
  );
};

export default DashboardProcedimentos;
