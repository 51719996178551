import styled, { keyframes } from 'styled-components';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 30px 60px;
  max-height: calc(100vh - 200px);
  animation: ${appearFromRight} 1s;
  overflow: auto;

  @media screen and (max-width: 1152px) {
    padding: 25px 35px;
    max-height: calc(100vh - 225px);
  }

  form {
    p {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: ${props => props.theme.colors.textBlack};
      margin-bottom: 8px;
    }

    .p-multi-select {
      margin-top: 18px;
    }

    button[type='submit'] {
      margin-top: 40px;
    }
  }
`;

export const ContainerTable = styled.div`
  margin-top: 24px;
  max-height: calc(100vh - 340px);
  max-width: calc(100vw - 388px);
  overflow: auto;

  table {
    width: 100%;
    background: ${props => props.theme.colors.whitePrimary};
    border-collapse: collapse;

    thead {
      background: ${props => props.theme.colors.whitePrimary};

      th {
        padding: 20px 30px;
        text-align: left;
        //white-space: nowrap;
        position: relative !important;

        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: ${props => props.theme.colors.textBlack};
      }

      th:nth-last-child(-n + 1) {
        text-align: center;
      }
    }

    tbody {
      tr {
        td {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          color: ${props => props.theme.colors.textBlack};
          padding: 20px 30px;
          //white-space: nowrap;
        }

        &:nth-child(odd) {
          background-color: ${props => props.theme.colors.grayWhite};
        }
      }
    }

    button.option {
      border: none;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      width: 2rem;
    }
    svg {
      height: 2rem;
      width: 2rem;
    }
  }
`;

export const ConainerCadastroOption = styled.div`
  margin-bottom: 21px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
`;

export const RadioBox = styled.button`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 30px;
  border: none;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${props => props.theme.colors.textBlack};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  svg {
    margin-right: 20px;
  }

  transition: 0.2s;

  &:hover {
    filter: brightness(0.95);
  }
`;

export const CheckLabel = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.6rem;

  input {
    height: 1.2rem;
    width: 1.2rem;
  }

  p {
    margin: 0px !important;
  }
`;
