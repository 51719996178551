import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import Language from '../../language/language.json';
import { useAuth } from '../../hooks/auth';
import { Container, FileInfo, Preview } from './styles';

interface Uploaded {
  file: File | null;
  id: string;
  name: string;
  readableSize: string;
  preview: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  url: string;
}

interface FileListProps {
  file: Uploaded;
  onDelete?(): void;
}

export const FileListImg: React.FC<FileListProps> = ({
  onDelete = () => {
    ('');
  },
  file,
}) => {
  const { languageStorage } = useAuth();

  return (
    <Container>
      <li key={`${file.id}`}>
        <FileInfo>
          <Preview src={file.preview} />
          <div>
            <strong>{file.name}</strong>
            <span>
              {file.readableSize}
              {!file.uploaded && (
                <button
                  type="button"
                  onClick={() => {
                    onDelete();
                  }}
                >
                  {Language[languageStorage].Excluir}
                </button>
              )}
            </span>
          </div>
        </FileInfo>
      </li>
    </Container>
  );
};
