import { Ambiente, EmptyAmbiente } from './Ambiente';
import { EmptyPeriodicidade, Periodicidade } from './Periodicidade';

export interface Agendav2 {
  ID: number;
  Id_Environments: number;
  Environment: Ambiente;
  Id_Periodicidade: number;
  Periodicidade: Periodicidade;
  Id_Users: number;
  Id_EmpresasFilial: number;
  FINALIZADO: null;
  DATA_FINALIZACAO: null;
  ATIVO: boolean;
  revisionPhotos: boolean;
  vigIni: string;
  vigFim: string;
  plus?: boolean;
  isRevisao?: boolean;
}

export const EmptyAgendav2: Agendav2 = {
  ATIVO: false,
  DATA_FINALIZACAO: null,
  Environment: EmptyAmbiente,
  FINALIZADO: null,
  ID: -1,
  Id_EmpresasFilial: -1,
  Id_Environments: -1,
  Id_Periodicidade: -1,
  Id_Users: -1,
  Periodicidade: EmptyPeriodicidade,
  revisionPhotos: false,
  vigFim: '',
  vigIni: '',
};
