import React from 'react';

import { Mensage } from './Interface';
import Language from '../../../language/language.json';
import { EnumLanguage } from '../../../language/interface';

export const response = (languageStorage: EnumLanguage): Mensage[] => {
  return [
    {
      id: 0,
      title: `${Language[languageStorage]['Como fazer cadastro do funcionário']}?`,
      description: `${
        Language[languageStorage][
          `Primeiramente deve ser feito o pré-cadastro do funcionário, adicionando seu CPF à lista de CPF's habilitados para o cadastro no aplicativo. Nesse momento ele já vai ter acesso somente ao aplicativo como ASG, caso queira mudar seu acesso isso pode ser feito no menu Listagem`
        ]
      }.`,
    },
    {
      id: 1,
      title: `${Language[languageStorage]['Como fazer cadastro de coordenador']}?`,
      description: `${
        Language[languageStorage][
          `Primeiramente deve ser feito o pré-cadastro do funcionário, adicionando seu CPF à lista de CPF's habilitados para o cadastro no aplicativo. Nesse momento ele já vai ter acesso somente ao aplicativo como ASG, para mudar seu acesso para Coordenador isso deve ser feito no menu Listagem, clicando no botão com um ícone de cadeado`
        ]
      }.`,
    },
    {
      id: 2,
      title: `${Language[languageStorage]['Qual o passo a passo para começar a usar o TAPP']}?`,
      description: `${Language[languageStorage]['O primeiro passo é já ter seu CPF pré-cadastrado no sistema, realizado isso basta baixar o aplicativo na LINK ou acessar a plataforma web e fazer o seu cadastro de acesso pessoal. Pronto! Agora é só logar e começar a usar (caso seja ASG via mobile, caso seja Coordenador via mobile ou web)']}.`,
      linkGooglePlayStore: (
        <a
          href="https://play.google.com/store/apps/details?id=com.tappmobile"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Play Store
        </a>
      ),
    },
    {
      id: 3,
      title: `${Language[languageStorage]['Por onde começo os cadastros']}?`,
      description:
        Language[languageStorage][
          `Caso seja um usuário administrador, você deve inicialmente cadastrar sua(s) empresa(s) no menu Filiais, e posteriormente pré-cadastrar os usuários Coordenadores. Caso seja Coordenador, você deve primeiramente pré-cadastrar os usuários ASG's e posteriormente cadastrar os procedimentos, riscos, setores e categorias em seus menus específicos, para que seja liberado o cadastro dos ambientes (onde é gerado o QR Code). Finalizando todos esses cadastros fundamentais, já pode ser feito o cadastro das periodicidades e dos agendamentos das limpezas para os ASG's`
        ],
    },
    {
      id: 4,
      title: `${Language[languageStorage]['Qual o tipo de arquivo necessario para importação de dados']}?`,
      description: `${
        Language[languageStorage][
          `O tipo de arquivo deve ser único e exclusivamente no formato .CSV separado por vírgulas`
        ]
      }.`,
    },
    {
      id: 5,
      title: `${Language[languageStorage]['Onde encontro o modelo da planilha para importação']}?`,
      description: `${Language[languageStorage]['O arquivo modelo é disponibilizado nas páginas de cadastro em lote']}.`,
    },
    {
      id: 6,
      title: `${Language[languageStorage]['Quais as colunas que devem ser preenchidas para importação de dados']}?`,
      description: `${Language[languageStorage]['Todas as colunas que estiverem com título no arquivo modelo devem ser preenchidas corretamente']}.`,
    },
    {
      id: 7,
      title: `${Language[languageStorage]['O que fazer após importar os dados de cadastro']}?`,
      description: `${Language[languageStorage]['Após importar os dados, se no processo não ocorrer nenhum problema isso quer dizer que todos os registros contidos no arquivo foram cadastrados corretamente e estão prontos para utilização no sistema']}.`,
    },
    {
      id: 8,
      title: `${Language[languageStorage]['Qual as diferenças entre setor, categoria e ambiente']}?`,
      description: `${Language[languageStorage]['O setor é referente a uma divisão ou subdivisão da empresa ou de um estabelecimento qualquer (Ex.: Administrativo). A categoria é referente a um elemento utilizado principalmente para classificar e facilitar a ordem dos grupos de locais que apresentam similaridades (Ex.: Sala). E o ambiente é referente ao conjunto dos pontos anteriores juntamente com uma classificação de procedimentos e riscos, apresentando um maior detalhamento do local (Ex.: Sala secreta do presidente)']}.`,
    },
  ];
};
