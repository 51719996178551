import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import moment from 'moment';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import { Calendario, DataRange } from '../../../components/Calendario';
import IconAddBlue from '../../../assets/IconAddBlue.svg';
import Search from '../../../components/Search';
import ModalConexao from '../../../components/ModalConexao';
import Language from '../../../language/language.json';
import { Agendamentos } from '../../../models/Agendamentos';

import { Container, ContainerTitle } from './styles';

export interface ResponseGetEmpresas {
  ATIVO: boolean;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  ID: number;
  Id_Empresas: number;
  NOME: string;
  NUMERO: string;
}

export interface ResponseGetUsuario {
  ativo: number;
  avatar: string;
  avatar_url: string;
  companyId: number;
  coordenador: number;
  cpf: string;
  createdAt: string;
  dataNascimento: string;
  email: string;
  id: number;
  nome: string;
  password: string;
  telefone: string;
  tipo: string;
  updatedAt: string;
}

export interface ResponseGetAmbiente {
  AMBIENTE_NOME: string;
  CATEGORIA: string;
  DESC_LIMPEZA: string;
  companyId: number;
  EMPRESA: string;
  ID: number;
  PROCEDIMENTOS: [];
  RISCOS: [];
  SETOR: string;
  ANDAR: string;
}

interface ResponseRevisao {
  idTableRevisao: number[];
  horaInicial: string[];
  horaFinal: string[];
}

export interface ResponseGetPeriodicidade {
  ID: number;
  NOME: string;
  DIAS_SEMANA: string;
  QTD_DIAS: number;
  HORA_BASE_INICIAL: string;
  HORA_BASE_FINAL: string;
  Id_EmpresasFilial: number;
  revisaoHoras: ResponseRevisao;
}

export const DashboardAgenda: React.FC = () => {
  const { empresaPrincipal, languageStorage } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [loadingAgenda, setLoadingAgenda] = useState(false);
  const [responseAgendamentos, setResponseAgendamentos] = useState<
    Agendamentos[]
  >([]);
  // const [responseEmpresas, setResponseEmpresas] = useState<
  //   ResponseGetEmpresas[]
  // >([]);
  const [search, setSearch] = useState('');
  const [dataRange, setDataRange] = useState<DataRange>({
    dateChoose: moment().format('YYYY-MM-DD'),
    dateCurrent: moment().format('YYYY-MM-DD 00:00:00'),
    dayEnd: new Date(
      new Date().getUTCFullYear(),
      new Date().getMonth() + 1,
      0,
    ).getDate(),
    mouth: new Date().getMonth(),
    year: new Date().getUTCFullYear(),
  });

  const getItens = useCallback(async () => {
    setLoading(true);

    try {
      const companyId =
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';

      // const dataEmpresas = await api.get(`empresas/${companyId}`);
      const dataUsers = await api.get(`/allUsers/${companyId}`);

      // const empresasAtive: ResponseGetEmpresas[] = [];
      // dataEmpresas.data.forEach((filial: ResponseGetEmpresas) => {
      //   if (filial.ATIVO === true) {
      //     empresasAtive.push(filial);
      //   }
      // });
      // setResponseEmpresas(empresasAtive);

      const usersAux: ResponseGetUsuario[] = [];
      dataUsers.data.forEach((user: ResponseGetUsuario) => {
        user.coordenador === 0 && usersAux.push(user);
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);

      addToast({
        type: 'error',
        title: Language[languageStorage].Erro,
        description: `${Language[languageStorage]['Erro ao carregar dados, favor recarregue a página']}.`,
      });
    }
  }, [addToast, empresaPrincipal, languageStorage]);

  const getAgendaData = useCallback(
    async (dateIni: string, dateEnd: string) => {
      setLoadingAgenda(true);

      try {
        const companyId =
          empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';

        const dataAgenda = await api.get(
          `agendas/v2/Agendamentos/company?dataIni=${dateIni}&dataFim=${dateEnd}&companyId=${companyId}`,
        );

        setResponseAgendamentos(dataAgenda.data);

        setLoadingAgenda(false);
      } catch (err) {
        // console.log('ERR AGENDA', err.message);
        setLoadingAgenda(false);

        addToast({
          type: 'error',
          title: Language[languageStorage].Erro,
          description: `${Language[languageStorage]['Erro ao carregar dados, favor recarregue a página']}.`,
        });
      }
    },
    [addToast, empresaPrincipal, languageStorage],
  );

  useEffect(() => {
    getItens();
  }, [getItens]);

  useEffect(() => {
    const mesString =
      dataRange.mouth < 9
        ? `0${dataRange.mouth + 1}`
        : `${dataRange.mouth + 1}`;
    const dateIni = `${dataRange.year}-${mesString}-01`;
    const dateEnd = `${dataRange.year}-${mesString}-${dataRange.dayEnd}`;
    getAgendaData(dateIni, dateEnd);
  }, [dataRange.dayEnd, dataRange.mouth, dataRange.year, getAgendaData]);

  return (
    <>
      <ModalConexao />

      <Container>
        <ContainerTitle>
          <h1>{Language[languageStorage].Agenda}</h1>

          <Tooltip
            title={
              Language[languageStorage][
                'Nessa página você tem acesso a visualização de todos os agendamentos para as limpezas e tarefas diárias dos ASGs'
              ]
            }
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </ContainerTitle>

        <Search
          onSearch={(value: string) => {
            setSearch(value);
          }}
          nomePlaceHolder={Language[languageStorage].Buscar}
        />

        <Link to="/agendamentos">
          <img src={IconAddBlue} alt="IconAdd" />{' '}
          {Language[languageStorage].Criar}
        </Link>

        <Calendario
          data={responseAgendamentos}
          type="leitura"
          dateRange={dataRange}
          setRange={setDataRange}
          dataSearch={search}
        />

        {(loading || loadingAgenda) && <Loading />}
      </Container>
    </>
  );
};
