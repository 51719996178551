import styled from 'styled-components';
import { shade } from 'polished';

export const ContainerModal = styled.div`
  background: ${props => props.theme.colors.backgroundWhite};
  border-radius: 16px;
  position: relative;
`;

export const ContainerButton = styled.div`
  width: auto;
  display: flex;

  button {
    border: transparent;
    background-color: transparent;
    padding: 2px;
    margin-left: auto;
  }
`;

export const ContainerHeader = styled.div`
  max-height: calc(100vh - 430px);
  padding: 0 30px 5px 5px;
  overflow: auto;
`;

export const ContainerHeaderDados = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: ${props => props.theme.colors.textBlack};
`;

export const ContainerButtonAtualizar = styled.div`
  margin-top: 40px;

  display: flex;
  justify-content: center;

  @media screen and (max-width: 1152px) {
    justify-content: flex-start;

    button {
      width: 25% !important;
    }
  }
`;

export const ContainerButtonsPdfExcel = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;

  button {
    background: ${props => props.theme.colors.bluePrimary};
    border-radius: 8px;
    padding: 12px;
    border: none;

    svg {
      width: 32px;
      height: 32px;
      color: ${props => props.theme.colors.whitePrimary};

      @media screen and (max-width: 1152px) {
        width: 24px;
        height: 24px;
      }
    }

    & + button {
      margin-left: 12px;
    }

    transition: background 0.2s;

    &:hover {
      background: ${props => shade(0.1, props.theme.colors.bluePrimary)};
    }
  }
`;

export const ContainerButtonDetalhes = styled.div`
  margin-top: 40px;

  display: flex;
  justify-content: center;

  button:last-child {
    background: transparent;
    border: 1px solid ${props => props.theme.colors.textBlack};
    box-sizing: border-box;
    border-radius: 16px;
    margin-left: 12px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    color: ${props => props.theme.colors.textBlack};

    &:hover {
      background: ${props => shade(0.1, props.theme.colors.whitePrimary)};
    }
  }

  @media screen and (max-width: 1152px) {
    justify-content: flex-start;

    button {
      width: 25% !important;
    }
  }
`;

export const ContainerListCards = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
`;

export const ContainerMap = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 32px;
  border: none !important;
  border-left: 24px solid ${props => props.theme.colors.bluePrimary} !important;

  display: flex;
  align-items: center;

  ul {
    list-style-type: none;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    li {
      max-width: 600px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-size: 18px;
      line-height: 27px;

      strong {
        font-weight: 600;
        color: ${props => props.theme.colors.textBlack};
        margin-bottom: 5px;
      }

      span {
        font-weight: normal;
        color: ${props => props.theme.colors.textBlack};
        margin-left: 12px;
      }
    }
  }
`;

export const ContainerMapObservacao = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonImg = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  transition: transform 0.2s;
  width: 164px;
  height: 164px;
  margin-right: 32px;

  &:hover {
    transform: scale(1.08);
  }

  img {
    width: 164px;
    height: 164px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const ContainerSearch = styled.div`
  width: 100%;
  margin: 30px 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 1152px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  strong {
    border: 1px solid ${props => props.theme.colors.textBlack};
    box-sizing: border-box;
    border-radius: 20px;
    padding: 13px 34px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.colors.textBlack};
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerTableMacon = styled.div`
  display: none;
  width: auto;
  align-items: center;
  margin: 0px auto;
  margin-top: 15px;
  max-height: 35vh;
  overflow-x: auto;

  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid ${props => props.theme.colors.bluePrimary};
    border-top: 0px;

    th {
      border-bottom: 1px solid ${props => props.theme.colors.bluePrimary};
      justify-content: space-between;
      padding: 5px 10px;
      background-color: ${props => props.theme.colors.bluePrimary};
      color: ${props => props.theme.colors.whitePrimary};
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }

  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;

      button {
        background-color: transparent;
        color: ${props => props.theme.colors.whitePrimary};
        border: 0;

        & + button {
          margin-left: 20px;
        }
      }
    }

    tr {
      border-bottom: 1px solid ${props => props.theme.colors.bluePrimary};
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: ${props => props.theme.colors.textBlack};
      }
      &:hover {
        background-color: ${props => props.theme.colors.grayBlack};
      }
    }
  }
`;

export const ZoomImg = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  width: calc(100vw - 500px);
  max-height: 400px;

  @media screen and (max-width: 1152px) {
    width: calc(100vw - 100px);
    height: 380px;
  }
`;

export const ButtonDetalhes = styled.button`
  background: transparent;
  border: none;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${props => props.theme.colors.bluePrimary};
    width: 26px;
    height: 26px;

    transition: color 0.2s;

    &:hover {
      color: ${props => shade(0.2, props.theme.colors.bluePrimary)};
    }
  }
`;
