import React, {
  useMemo,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { AiOutlineClockCircle } from 'react-icons/ai';

import { ThemeContext } from 'styled-components';
import moment from 'moment';
import { uuid } from 'uuidv4';
import { useAuth } from '../../hooks/auth';
import Language from '../../language/language.json';
import IconEditWhite from '../../assets/IconEditWhite.svg';

import {
  HeaderMonth,
  HeaderWeek,
  BodyMonth,
  Content,
  ContainerLine,
  ContainerNotion,
  ButtonWeek,
  ButtonCard,
  ContainerSemResultados,
} from './styles';
import { Agendamentos } from '../../models/Agendamentos';

export interface DiaSemana {
  dia: number;
  diaDaSemana: number;
  dataFormat: Date;
}

export interface DataRange {
  dayEnd: number;
  mouth: number;
  year: number;
  dateCurrent: string;
  dateChoose: string;
}

interface CalendarioProps {
  data: Agendamentos[];
  type?: 'leitura' | 'escrita';
  dataSearch?: string;
  dateRange: DataRange;
  setRange: (x: DataRange) => void;
  onClickCardEdit?: (x: Agendamentos, y: string) => void;
  isVisibleRemoveAgenda?: boolean;
  onClickRemoveAgenda?: () => void;
}

export const Calendario: React.FC<CalendarioProps> = ({
  data,
  type = 'escrita',
  dataSearch = '',
  onClickCardEdit = (x: Agendamentos, y: string) => {
    return [x, y];
  },
  dateRange,
  setRange,
  isVisibleRemoveAgenda = false,
  onClickRemoveAgenda = () => console.log('Function'),
}) => {
  const { languageStorage } = useAuth();
  const { colors } = useContext(ThemeContext);
  const [daysAtual, setDaysAtual] = useState<number[]>([]);

  const mesAno = useMemo(() => {
    const meses = [
      { id: 0, name: Language[languageStorage].Calendario.Janeiro },
      { id: 1, name: Language[languageStorage].Calendario.Fevereiro },
      { id: 2, name: Language[languageStorage].Calendario.Marco },
      { id: 3, name: Language[languageStorage].Calendario.Abril },
      { id: 4, name: Language[languageStorage].Calendario.Maio },
      { id: 5, name: Language[languageStorage].Calendario.Junho },
      { id: 6, name: Language[languageStorage].Calendario.Julho },
      { id: 7, name: Language[languageStorage].Calendario.Agosto },
      { id: 8, name: Language[languageStorage].Calendario.Setembro },
      { id: 9, name: Language[languageStorage].Calendario.Outubro },
      { id: 10, name: Language[languageStorage].Calendario.Novembro },
      { id: 11, name: Language[languageStorage].Calendario.Dezembro },
    ];
    const findMonth = meses.find(mes => mes.id === dateRange.mouth);

    return findMonth || { id: 0, name: 'Janeiro' };
  }, [dateRange.mouth, languageStorage]);

  const dataResponseGetRevisions = useMemo(() => {
    const dataAux: Agendamentos[] = [];

    data.forEach(agendamento => {
      dataAux.push({
        ...agendamento,
        Agenda: {
          ...agendamento.Agenda,
          isRevisao: false,
        },
      });

      const horaInicialRevisaoTemp = agendamento.Agenda.Periodicidade.Revisoes.sort(
        (a, b) => {
          if (a.HORA_INICIAL > b.HORA_INICIAL) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          return 0;
        },
      );

      horaInicialRevisaoTemp.forEach(rev => {
        dataAux.push({
          ...agendamento,
          Agenda: {
            ...agendamento.Agenda,
            isRevisao: true,
            Periodicidade: {
              ...agendamento.Agenda.Periodicidade,
              HORA_BASE_INICIAL: rev.HORA_INICIAL,
              HORA_BASE_FINAL: rev.HORA_FINAL,
            },
          },
        });
      });
    });

    const dataAuxSort = dataAux.sort((a, b) => {
      if (
        a.Agenda.Periodicidade.HORA_BASE_INICIAL >
        b.Agenda.Periodicidade.HORA_BASE_INICIAL
      ) {
        return 1;
      }
      if (
        a.Agenda.Periodicidade.HORA_BASE_INICIAL <
        b.Agenda.Periodicidade.HORA_BASE_INICIAL
      ) {
        return -1;
      }
      if (
        a.Agenda.Periodicidade.HORA_BASE_INICIAL ===
          b.Agenda.Periodicidade.HORA_BASE_INICIAL &&
        a.Agenda.Periodicidade.HORA_BASE_FINAL >
          b.Agenda.Periodicidade.HORA_BASE_FINAL
      ) {
        return 1;
      }
      if (
        a.Agenda.Periodicidade.HORA_BASE_INICIAL ===
          b.Agenda.Periodicidade.HORA_BASE_INICIAL &&
        a.Agenda.Periodicidade.HORA_BASE_FINAL <
          b.Agenda.Periodicidade.HORA_BASE_FINAL
      ) {
        return -1;
      }
      return 0;
    });

    let computedResponses: Agendamentos[] = [];
    computedResponses = dataAuxSort;

    if (dataSearch) {
      computedResponses = computedResponses.filter(res =>
        [
          res.User.nome,
          res.Agenda.Environment.name,
          res.Empresa.NOME,
          // res.Agenda.Periodicidade.NOME,
          res.Agenda.Periodicidade.HORA_BASE_INICIAL,
          res.Agenda.Periodicidade.HORA_BASE_FINAL,
        ].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(dataSearch.toString().toLowerCase()),
        ),
      );
    }

    return computedResponses;
  }, [data, dataSearch]);

  const updateMonth = useCallback(
    (val: number) => {
      const date = new Date(
        new Date(dateRange.dateCurrent).setMonth(
          new Date(dateRange.dateCurrent).getMonth() + val,
        ),
      );
      const dayMouth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0,
      ).getDate();

      const days: number[] = [];
      for (let i = 0; i < dayMouth; i += 1) {
        days.push(i + 1);
      }
      setDaysAtual(days);
      setRange({
        dateChoose: dateRange.dateChoose,
        dateCurrent: moment(date).format('YYYY-MM-DD 00:00:00'),
        dayEnd: dayMouth,
        mouth: date.getMonth(),
        year: date.getFullYear(),
      });
    },
    [dateRange.dateChoose, dateRange.dateCurrent, setRange],
  );

  const timeList = useMemo(() => {
    let horarioInicialList: number;
    let horarioFinalList = 0;

    const dataTemp = dataResponseGetRevisions.filter(
      agendamento => agendamento.dataAgend === dateRange.dateChoose,
    );

    if (dataTemp.length) {
      const dataFilter = dataTemp.filter(
        element => element.Agenda.Periodicidade.HORA_BASE_FINAL !== '',
      );

      horarioInicialList = Number(
        dataFilter[0].Agenda.Periodicidade.HORA_BASE_INICIAL.split(':')[0],
      );
      // horarioFinalList = Number(
      //   dataFilter[dataFilter.length - 1].horaBaseFinal.split(':')[0],
      // );
      horarioFinalList = Number(
        dataFilter[
          dataFilter.length - 1
        ].Agenda.Periodicidade.HORA_BASE_INICIAL.split(':')[0],
      );

      let dataArray: number[] = Array.from(Array(horarioFinalList).keys());

      dataArray = dataArray.filter(
        element =>
          element >= horarioInicialList - 1 && element <= horarioFinalList,
      );

      return dataArray;
    }

    return [];
  }, [dataResponseGetRevisions, dateRange.dateChoose]);

  const checkDataAgendamento = useCallback(
    element => {
      const dataAgendamento = dataResponseGetRevisions.map(agendamento => {
        if (agendamento.dataAgend === dateRange.dateChoose) {
          const horarioLimpezaInicial =
            agendamento.Agenda.Periodicidade.HORA_BASE_INICIAL;
          // const horarioLimpezaFinal = agendamento.horaBaseFinal;

          if (!agendamento.Agenda.isRevisao) {
            if (String(element + 1).split('').length === 1) {
              const elementFormat = `0${element + 1}:00`;
              let elementFormatAux;

              if (String(element + 2).split('').length === 1) {
                elementFormatAux = `0${element + 2}:00`;
              } else {
                elementFormatAux = `${element + 2}:00`;
              }

              if (
                // (horarioLimpezaInicial >= elementFormat &&
                //   horarioLimpezaInicial < elementFormatAux) ||
                // (horarioLimpezaFinal >= elementFormat &&
                //   horarioLimpezaFinal <= elementFormatAux)
                horarioLimpezaInicial >= elementFormat &&
                horarioLimpezaInicial < elementFormatAux
              ) {
                return (
                  <ButtonCard
                    key={uuid()}
                    backgroundProps={colors.bluePrimary}
                    typeProps={type}
                    onClick={() => {
                      onClickCardEdit(agendamento, dateRange.dateChoose);
                    }}
                  >
                    <ul>
                      <li>{`${Language[languageStorage].Nome}: ${agendamento.User.nome}`}</li>
                      <li>{`${Language[languageStorage].Ambiente}: ${agendamento.Agenda.Environment.name}`}</li>
                      {/* <li>{`${Language[languageStorage].Periodicidade}: ${agendamento.Agenda.Periodicidade.NOME}`}</li> */}
                      <li>{`${Language[languageStorage].Filial}: ${agendamento.Empresa.NOME}`}</li>
                      <li>
                        {Language[languageStorage].Tipo}:{' '}
                        {Language[languageStorage].LimpezaPrincipal}
                      </li>
                      <li>{`${Language[languageStorage].Horario}: ${Language[languageStorage].DeHorario} ${agendamento.Agenda.Periodicidade.HORA_BASE_INICIAL}h até ${agendamento.Agenda.Periodicidade.HORA_BASE_FINAL}h`}</li>

                      {type === 'escrita' && (
                        <img src={IconEditWhite} alt="IconEditWhite" />
                      )}
                    </ul>
                  </ButtonCard>
                );
              }
              return <></>;
            }
            const elementFormat = `${element + 1}:00`;
            const elementFormatAux = `${element + 2}:00`;

            if (
              // (horarioLimpezaInicial >= elementFormat &&
              //   horarioLimpezaInicial < elementFormatAux) ||
              // (horarioLimpezaFinal >= elementFormat &&
              //   horarioLimpezaFinal <= elementFormatAux)
              horarioLimpezaInicial >= elementFormat &&
              horarioLimpezaInicial < elementFormatAux
            ) {
              return (
                <ButtonCard
                  key={uuid()}
                  backgroundProps={colors.bluePrimary}
                  typeProps={type}
                  onClick={() => {
                    onClickCardEdit(agendamento, dateRange.dateChoose);
                  }}
                >
                  <ul>
                    <li>{`${Language[languageStorage].Nome}: ${agendamento.User.nome}`}</li>
                    <li>{`${Language[languageStorage].Ambiente}: ${agendamento.Agenda.Environment.name}`}</li>
                    <li>{`${Language[languageStorage].Filial}: ${agendamento.Empresa.NOME}`}</li>
                    <li>
                      {Language[languageStorage].Tipo}:{' '}
                      {Language[languageStorage].LimpezaPrincipal}
                    </li>
                    <li>{`${Language[languageStorage].Horario}: ${Language[languageStorage].DeHorario} ${agendamento.Agenda.Periodicidade.HORA_BASE_INICIAL}h até ${agendamento.Agenda.Periodicidade.HORA_BASE_FINAL}h`}</li>

                    {type === 'escrita' && (
                      <img src={IconEditWhite} alt="IconEditWhite" />
                    )}
                  </ul>
                </ButtonCard>
              );
            }
            return <></>;
          }

          if (String(element + 1).split('').length === 1) {
            const elementFormat = `0${element + 1}:00`;
            let elementFormatAux;

            if (String(element + 2).split('').length === 1) {
              elementFormatAux = `0${element + 2}:00`;
            } else {
              elementFormatAux = `${element + 2}:00`;
            }

            if (
              // (horarioLimpezaInicial >= elementFormat &&
              //   horarioLimpezaInicial < elementFormatAux) ||
              // (horarioLimpezaFinal >= elementFormat &&
              //   horarioLimpezaFinal <= elementFormatAux)
              horarioLimpezaInicial >= elementFormat &&
              horarioLimpezaInicial < elementFormatAux
            ) {
              return (
                <ButtonCard
                  key={uuid()}
                  backgroundProps={colors.orangePrimary}
                  typeProps={type}
                  onClick={() => {
                    onClickCardEdit(agendamento, dateRange.dateChoose);
                  }}
                >
                  <ul>
                    <li>{`${Language[languageStorage].Nome}: ${agendamento.User.nome}`}</li>
                    <li>{`${Language[languageStorage].Ambiente}: ${agendamento.Agenda.Environment.name}`}</li>
                    <li>{`${Language[languageStorage].Filial}: ${agendamento.Empresa.NOME}`}</li>
                    <li>
                      {Language[languageStorage].Tipo}:{' '}
                      {Language[languageStorage]['Revisão']}
                    </li>
                    <li>{`${Language[languageStorage].Horario}: ${Language[languageStorage].DeHorario} ${agendamento.Agenda.Periodicidade.HORA_BASE_INICIAL}h até ${agendamento.Agenda.Periodicidade.HORA_BASE_FINAL}h`}</li>

                    {type === 'escrita' && (
                      <img src={IconEditWhite} alt="IconEditWhite" />
                    )}
                  </ul>
                </ButtonCard>
              );
            }
            return <></>;
          }
          const elementFormat = `${element + 1}:00`;
          const elementFormatAux = `${element + 2}:00`;

          if (
            // (horarioLimpezaInicial >= elementFormat &&
            //   horarioLimpezaInicial < elementFormatAux) ||
            // (horarioLimpezaFinal >= elementFormat &&
            //   horarioLimpezaFinal <= elementFormatAux)
            horarioLimpezaInicial >= elementFormat &&
            horarioLimpezaInicial < elementFormatAux
          ) {
            return (
              <ButtonCard
                key={uuid()}
                backgroundProps={colors.orangePrimary}
                typeProps={type}
                onClick={() => {
                  onClickCardEdit(agendamento, dateRange.dateChoose);
                }}
              >
                <ul>
                  <li>{`${Language[languageStorage].Nome}: ${agendamento.User.nome}`}</li>
                  <li>{`${Language[languageStorage].Ambiente}: ${agendamento.Agenda.Environment.name}`}</li>
                  <li>{`${Language[languageStorage].Filial}: ${agendamento.Empresa.NOME}`}</li>
                  <li>
                    {Language[languageStorage].Tipo}:{' '}
                    {Language[languageStorage]['Revisão']}
                  </li>
                  <li>{`${Language[languageStorage].Horario}: ${Language[languageStorage].DeHorario} ${agendamento.Agenda.Periodicidade.HORA_BASE_INICIAL}h até ${agendamento.Agenda.Periodicidade.HORA_BASE_FINAL}h`}</li>

                  {type === 'escrita' && (
                    <img src={IconEditWhite} alt="IconEditWhite" />
                  )}
                </ul>
              </ButtonCard>
            );
          }
          return <></>;
        }
        return <></>;
      });

      return <section>{dataAgendamento}</section>;
    },
    [
      dataResponseGetRevisions,
      dateRange.dateChoose,
      colors.bluePrimary,
      colors.orangePrimary,
      type,
      languageStorage,
      onClickCardEdit,
    ],
  );

  useEffect(() => {
    const date = new Date(new Date().setMonth(new Date().getMonth()));
    const dayMouth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0,
    ).getDate();
    const days: number[] = [];
    for (let i = 0; i < dayMouth; i += 1) {
      days.push(i + 1);
    }
    setDaysAtual(days);
  }, []);

  return (
    <>
      <HeaderMonth>
        <div>
          <h2>
            {mesAno.name}, {dateRange.year}
          </h2>

          <button type="button" onClick={() => updateMonth(-1)}>
            <IoIosArrowBack />
          </button>

          <button type="button" onClick={() => updateMonth(1)}>
            <IoIosArrowForward />
          </button>
        </div>

        {isVisibleRemoveAgenda && (
          <button
            className="removeModal"
            type="button"
            onClick={onClickRemoveAgenda}
          >
            {Language[languageStorage]['Remover Agendamento']}
          </button>
        )}
      </HeaderMonth>

      <Content typeProps={type}>
        <div style={{ overflow: 'auto' }}>
          <HeaderWeek>
            {daysAtual.map(item => (
              <ButtonWeek
                key={item.toString()}
                type="button"
                onClick={() => {
                  setRange({
                    ...dateRange,
                    dateChoose: `${dateRange.year}-${
                      mesAno.id < 9 ? `0${mesAno.id + 1}` : mesAno.id + 1
                    }-${item < 10 ? `0${item}` : item}`,
                  });
                }}
                isChecked={
                  dateRange.dateChoose ===
                  `${dateRange.year}-${
                    mesAno.id < 9 ? `0${mesAno.id + 1}` : mesAno.id + 1
                  }-${item < 10 ? `0${item}` : item}`
                }
              >
                <p>{item < 10 ? `0${item}` : item}</p>
              </ButtonWeek>
            ))}
          </HeaderWeek>
        </div>

        {!data.length && (
          <ContainerSemResultados>
            <h2>
              {
                Language[languageStorage][
                  'Não existem agendamentos cadastrados ainda'
                ]
              }
              .
            </h2>
          </ContainerSemResultados>
        )}

        <BodyMonth>
          <aside>
            {timeList.map(horario => (
              <div key={horario}>
                <article>
                  <AiOutlineClockCircle /> <p>{horario + 1}h</p>
                </article>
              </div>
            ))}
          </aside>

          <ContainerLine contTime={timeList.length} />

          <ContainerNotion>
            {timeList.map(horario => checkDataAgendamento(horario))}
          </ContainerNotion>
        </BodyMonth>
      </Content>
    </>
  );
};
