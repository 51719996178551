import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';

import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { ThemeContext } from 'styled-components';
import { uuid } from 'uuidv4';
import filesize from 'filesize';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import api from '../../../services/api';
import HeaderTable from '../../../components/HeaderTable';
import getValidationErrors from '../../../utils/getValidationErrors';
import ModalConexao from '../../../components/ModalConexao';
import { InputOverview } from '../../../components/InputOverview';
import Search from '../../../components/Search';
import Pagination from '../../../components/Pagination';
import { SelectCustom } from '../../../components/SelectCustom';
import Button from '../../../components/Button';
import IconEdit from '../../../assets/IconEdit.svg';
import IconRemove from '../../../assets/IconRemove.svg';
import IconFileUploadBtn from '../../../assets/IconFileUploadBtn.svg';
import FileList from '../../../components/FileList';
import Upload from '../../../components/Upload';
import { arquivoModeloSetoresUrlAws } from '../../../utils/urlsBucketAws';
import DropDownPagination from '../../../components/DropDownPagination';
import Language from '../../../language/language.json';

import {
  Container,
  Content,
  Aba,
  ContainerAba,
  ContainerButton,
  AnimationContainer,
  ContainerTable,
  ContainerPagination,
  ContainerSemResultados,
  ConainerCadastroOption,
  RadioBox,
} from './styles';

interface SignUpFormData {
  empresa: number;
  nome: string;
  setor: string;
}

interface ResponseGet {
  companyId: number;
  empresa: number;
  id: number;
  name: string;
}

interface EmpresasInterface {
  ATIVO: boolean;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  ID: number;
  Id_Empresas: number;
  NOME: string;
  NUMERO: string;
}

interface Uploaded {
  file: File | null;
  id: string;
  name: string;
  readableSize: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  type: string;
}

export const Setores: React.FC = () => {
  const { empresaPrincipal, languageStorage } = useAuth();
  const { addToast } = useToast();
  const { colors } = useContext(ThemeContext);
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [setorNomeEdit, setSetorNomeEdit] = useState('');
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [setor, setSetor] = useState<ResponseGet>();
  const [cadastrar, setCadastrar] = useState(true);
  const [editar, setEditar] = useState(false);

  const [search, setSearch] = useState('');

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);
  const [sorting, setSorting] = useState({ field: '', order: '' });

  const [empresas] = useState<EmpresasInterface[]>([]);
  const [empresaId, setEmpresaId] = useState<number>();
  const [editEmpresaId, setEditEmpresaId] = useState<number>();
  const [verificaResponse, setVerificaResponse] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState<Uploaded[]>([]);
  const [download, setDownload] = useState('');
  const [count, setCount] = useState(0);
  const [registerType, setRegisterType] = useState('individual');
  const formRefIndividual = useRef<FormHandles>(null);
  const formRefEmLote = useRef<FormHandles>(null);
  const [empresaIdLote, setEmpresaIdLote] = useState<number>();

  const dropDown = [
    { valor: '20', id: '20' },
    { valor: '40', id: '40' },
    { valor: '80', id: '80' },
    { valor: '160', id: '160' },
    { valor: Language[languageStorage].Todos, id: '1' },
  ];

  const headers = [
    {
      name: Language[languageStorage].Empresa,
      field: 'companyId',
      sortable: true,
    },
    { name: Language[languageStorage].Setor, field: 'name', sortable: true },
    {
      name: Language[languageStorage].Editar,
      field: 'editar',
      sortable: false,
    },
    {
      name: Language[languageStorage].Remover,
      field: 'remover',
      sortable: false,
    },
  ];

  const headersViewer = [
    {
      name: Language[languageStorage].Empresa,
      field: 'companyId',
      sortable: true,
    },
    { name: Language[languageStorage].Setor, field: 'name', sortable: true },
  ];

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `setor/?id=${
          empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
        }`,
      )
      .then(data => {
        api
          .get(
            `empresas/${
              empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
            }`,
          )
          .then(dataTemp => {
            setResponse(data.data);

            let cont = 0;
            dataTemp.data.forEach((filial: EmpresasInterface) => {
              if (filial.ATIVO === true) {
                empresas.push(filial);

                if (cont === 0) {
                  setEmpresaId(filial.ID);
                  setEmpresaIdLote(filial.ID);

                  cont += 1;
                }
              }
            });

            setVerificaResponse(true);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);

            addToast({
              type: 'error',
              title: Language[languageStorage].Erro,
              description:
                Language[languageStorage][
                  'Erro ao carregar dados, por favor atualize a página'
                ],
            });
          });
      })
      .catch(() => {
        setLoading(false);

        addToast({
          type: 'error',
          title: Language[languageStorage].Erro,
          description:
            Language[languageStorage][
              'Erro ao carregar dados, por favor atualize a página'
            ],
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRefIndividual.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required(
            Language[languageStorage]['Nome do setor é obrigatório'],
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let verificaSetorDuplicado = false;
        response.forEach(elementTemp => {
          if (
            elementTemp.name.toLowerCase() === data.nome.toLowerCase() &&
            elementTemp.companyId === empresaId
          )
            verificaSetorDuplicado = true;
        });

        if (!verificaSetorDuplicado) {
          await api
            .post('setor/', {
              name: data.nome,
              companyId: empresaId,
            })
            .then(() => {
              setLoading(false);

              addToast({
                type: 'success',
                title: Language[languageStorage].Sucesso,
                description:
                  Language[languageStorage]['Setor cadastrado com sucesso'],
              });

              setTimeout(() => {
                history.go(0);
              }, 1000);
            })
            .catch(() => {
              setLoading(false);

              addToast({
                type: 'error',
                title: Language[languageStorage].Erro,
                description:
                  Language[languageStorage]['Erro ao cadastrar setor'],
              });
            });
        } else {
          setLoading(false);

          addToast({
            type: 'info',
            title: Language[languageStorage]['Setor duplicado'],
            description:
              Language[languageStorage][
                'Já existe um setor cadastrado com o mesmo nome e empresa'
              ],
          });
        }
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRefIndividual.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: Language[languageStorage]['Campos em branco'],
            description: `${Language[languageStorage]['Existem campos obrigatórios não preenchidos']}.`,
          });
        } else {
          addToast({
            type: 'error',
            title: Language[languageStorage].Erro,
            description: Language[languageStorage]['Erro ao cadastrar o dados'],
          });
        }
      }
    },
    [addToast, empresaId, history, languageStorage, response],
  );

  const handleSubmitEditar = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          setor: Yup.string().required(
            Language[languageStorage]['Nome do setor é obrigatório'],
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let verificaSetorDuplicado = false;
        response.forEach(elementTemp => {
          if (
            elementTemp.name.toLowerCase() === data.setor.toLowerCase() &&
            elementTemp.companyId === editEmpresaId &&
            elementTemp.id !== setor?.id
          )
            verificaSetorDuplicado = true;
        });

        if (!verificaSetorDuplicado) {
          await api
            .put(`setor/${setor?.id}`, {
              name: data.setor,
              empresa: editEmpresaId,
            })
            .then(() => {
              setLoading(false);

              addToast({
                type: 'success',
                title: Language[languageStorage].Sucesso,
                description:
                  Language[languageStorage]['Setor editado com sucesso'],
              });

              setTimeout(() => {
                history.go(0);
              }, 1000);
            })
            .catch(() => {
              setLoading(false);

              addToast({
                type: 'error',
                title: Language[languageStorage].Erro,
                description: Language[languageStorage]['Erro ao editar setor'],
              });
            });
        } else {
          setLoading(false);

          addToast({
            type: 'error',
            title: Language[languageStorage]['Setor duplicado'],
            description:
              Language[languageStorage][
                'Já existe um setor cadastrado com o mesmo nome e empresa'
              ],
          });
        }
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: Language[languageStorage]['Campos em branco'],
            description: `${Language[languageStorage]['Existem campos obrigatórios não preenchidos']}.`,
          });
        } else {
          addToast({
            type: 'error',
            title: Language[languageStorage].Erro,
            description: Language[languageStorage]['Erro ao editar o dados'],
          });
        }
      }
    },
    [addToast, editEmpresaId, history, languageStorage, response, setor?.id],
  );

  const handleDelete = useCallback(
    async (dados: ResponseGet) => {
      try {
        setLoading(true);

        await api
          .delete(`/setor/${dados.id}`)
          .then(() => {
            setLoading(false);

            addToast({
              type: 'success',
              title: Language[languageStorage].Sucesso,
              description:
                Language[languageStorage]['Setor deletado com sucesso'],
            });

            setTimeout(() => {
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            setLoading(false);

            addToast({
              type: 'error',
              title: Language[languageStorage].Erro,
              description: Language[languageStorage]['Erro ao deletar setor'],
            });
          });
      } catch (err) {
        setLoading(false);

        addToast({
          type: 'error',
          title: Language[languageStorage].Erro,
          description: Language[languageStorage]['Erro ao deletar setor'],
        });
      }
    },
    [addToast, history, languageStorage],
  );

  //* ********************************************************************************************* */

  const handleUpload = useCallback(
    files => {
      const uploadedFilesHandleUpload: Uploaded[] = files.map((file: File) => ({
        file,
        id: uuid(),
        name: file.name,
        readableSize: filesize(file.size),
        progress: 0,
        uploaded: false,
        error: false,
        type: '.csv, application/vnd.ms-excel, text/csv',
      }));

      setUploadedFiles(uploadedFiles.concat(uploadedFilesHandleUpload));
    },
    [uploadedFiles],
  );

  const updateFile = useCallback((id, data) => {
    setUploadedFiles(estadoAnterior =>
      estadoAnterior.map(file =>
        id === file.id ? { ...file, ...data } : file,
      ),
    );
  }, []);

  const processUpload = useCallback(
    (uploadedFile: Uploaded) => {
      const data = new FormData();

      if (uploadedFile.file && empresaIdLote) {
        data.append('csvSetores', uploadedFile.file);
        data.append('idEmpresaFilial', empresaIdLote.toString());
      }

      api
        .post('cadastroViaCSV/setor', data, {
          onUploadProgress: e => {
            const progress = Math.round((e.loaded * 100) / e.total);

            updateFile(uploadedFile.id, {
              progress,
            });
          },
        })
        .then(() => {
          updateFile(uploadedFile.id, {
            uploaded: true,
          });

          setTimeout(() => {
            history.go(0);
          }, 1500);
        })
        .catch(() => {
          updateFile(uploadedFile.id, {
            error: true,
          });
        });
    },
    [empresaIdLote, history, updateFile],
  );

  const clickHandleUpload = useCallback(() => {
    if (!uploadedFiles.length) {
      addToast({
        type: 'error',
        title: `${Language[languageStorage]['Erro ao cadastrar lista de setores']}!`,
        description:
          Language[languageStorage][
            'É necessário inserir um arquivo .CSV válido. Favor baixar o arquivo modelo pré-formatado para o preenchimento correto'
          ],
      });
    } else {
      uploadedFiles.forEach(processUpload);
    }
  }, [addToast, languageStorage, processUpload, uploadedFiles]);

  const handleDelete2 = useCallback(
    async id => {
      uploadedFiles.splice(
        uploadedFiles.findIndex(element => element.id === id),
        1,
      );
      setUploadedFiles([...uploadedFiles]);
    },
    [uploadedFiles],
  );

  //* ********************************************************************************************* */

  const handleAlertMaster = useCallback(() => {
    if (verificaResponse) {
      setCadastrar(false);
      setDownload('');
      setSearch('');
    }
  }, [verificaResponse]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response.length ? response : [];

    if (search) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        [res.companyId, res.name].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        ),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (typeof a[sorting.field] === 'object' && a[sorting.field] != null) {
          return (
            reversed *
            a[sorting.field]
              .join(', ')
              .localeCompare(b[sorting.field].join(', '))
          );
        }
        if (typeof a[sorting.field] === 'string') {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        }

        const aTemp = a[sorting.field] != null ? a[sorting.field] : '';
        const bTemp = b[sorting.field] != null ? b[sorting.field] : '';
        return reversed * aTemp.toString().localeCompare(bTemp.toString());
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    search,
    sorting.field,
    sorting.order,
    ITEMS_PER_PAGE,
    currentPage,
  ]);

  return (
    <>
      <ModalConexao />

      <Container>
        {empresaPrincipal.length > 0 ? (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button type="button" onClick={() => setCadastrar(true)}>
                {Language[languageStorage]['Listar setores']}
              </button>
            </ContainerAba>
            <ContainerAba className="aba1" cor={!cadastrar}>
              <button type="button" onClick={handleAlertMaster}>
                {Language[languageStorage]['Cadastrar setor']}
              </button>
            </ContainerAba>

            <Tooltip
              title={
                Language[languageStorage][
                  'Nessa página você tem acesso a visualização, cadastro e edição de todos os setores que os ambientes cadastrados se encontram'
                ]
              }
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        ) : (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button type="button">
                {Language[languageStorage]['Listar setores']}
              </button>
            </ContainerAba>

            <Tooltip
              title={
                Language[languageStorage][
                  'Nessa página você tem acesso a visualização de todos os setores que os ambientes cadastrados se encontram'
                ]
              }
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        )}

        {cadastrar && !editar && (
          <Search
            onSearch={(value: string) => {
              setSearch(value);
            }}
            nomePlaceHolder={Language[languageStorage].Buscar}
          />
        )}

        {!cadastrar && (
          <ConainerCadastroOption>
            <RadioBox
              type="button"
              onClick={() => setRegisterType('individual')}
            >
              {Language[languageStorage]['Cadastro individual']}
            </RadioBox>
            <RadioBox
              type="button"
              onClick={() => {
                setDownload('');
                setRegisterType('lote');
              }}
            >
              {Language[languageStorage]['Cadastro em lote']}
            </RadioBox>
          </ConainerCadastroOption>
        )}

        {!cadastrar &&
          (registerType === 'individual' ? (
            <AnimationContainer>
              <Form ref={formRefIndividual} onSubmit={handleSubmit}>
                <p>{Language[languageStorage].Empresa}</p>
                <SelectCustom
                  name="Empresa"
                  defaultValue={Language[languageStorage].Empresa}
                  optionsDataCompany={empresas}
                  onValue={e => setEmpresaId(parseInt(e.split('-')[0], 10))}
                />

                <p>{Language[languageStorage].Nome}</p>
                <InputOverview
                  name="nome"
                  placeholder={Language[languageStorage]['Nome do setor']}
                />
                <Button type="submit" widthProps="100%">
                  {Language[languageStorage].Salvar}
                </Button>
              </Form>
            </AnimationContainer>
          ) : (
            <AnimationContainer>
              <Form ref={formRefEmLote} onSubmit={clickHandleUpload}>
                <p>{Language[languageStorage].Empresa}</p>
                <SelectCustom
                  name="Empresa"
                  defaultValue={Language[languageStorage].Empresa}
                  optionsDataCompany={empresas}
                  onValue={e => setEmpresaIdLote(parseInt(e.split('-')[0], 10))}
                />
                <Content>
                  <Upload onUpload={(e: any) => handleUpload(e)} />
                  {!!uploadedFiles.length && (
                    <FileList
                      files={uploadedFiles}
                      onDelete={(e: string) => handleDelete2(e)}
                    />
                  )}
                </Content>
                <ContainerButton>
                  <Button type="submit" widthProps="100%">
                    {Language[languageStorage].Salvar}
                  </Button>
                  <Button
                    type="button"
                    widthProps="100%"
                    className="divButtonDownload"
                    onClick={() => {
                      setDownload(arquivoModeloSetoresUrlAws);
                      setCount(old => old + 1);
                    }}
                  >
                    <img src={IconFileUploadBtn} alt="iconFileUploadBtn" />
                    {Language[languageStorage]['Baixar Modelo']}
                  </Button>
                  {download && (
                    <iframe
                      title="downloadModeloCsv"
                      src={`${download}?c=${count}`}
                      hidden
                    />
                  )}
                </ContainerButton>
              </Form>
            </AnimationContainer>
          ))}

        {cadastrar && !response.length && (
          <ContainerSemResultados>
            <h2>
              {
                Language[languageStorage][
                  'Não existem setores cadastrados ainda'
                ]
              }
              .
            </h2>
          </ContainerSemResultados>
        )}

        {cadastrar && !editar && !!response.length && (
          <>
            <ContainerTable>
              <table>
                <HeaderTable
                  headers={
                    empresaPrincipal.length > 0 ? headers : headersViewer
                  }
                  onSorting={(field: string, order: string) => {
                    setSorting({ field, order });
                  }}
                />
                <tbody>
                  {responseData.map(res => (
                    <tr key={res.id}>
                      <td>{res.companyId}</td>
                      <td>{res.name}</td>
                      {empresaPrincipal.length > 0 && (
                        <>
                          <td style={{ width: 'auto' }}>
                            <button
                              type="button"
                              onClick={() => {
                                setEditar(true);
                                setSetorNomeEdit(res.name);
                                setEditEmpresaId(res.companyId);
                                setSetor(res);
                              }}
                            >
                              <img src={IconEdit} alt="iconEdit" />
                            </button>
                          </td>
                          <td style={{ width: 'auto' }}>
                            <button
                              type="button"
                              onClick={() => {
                                Swal.fire({
                                  title: `${Language[languageStorage]['Tem certeza que deseja remover']}?`,
                                  icon: 'warning',
                                  showCancelButton: true,
                                  cancelButtonText:
                                    Language[languageStorage].Não,
                                  confirmButtonColor: colors.greenPrimary,
                                  cancelButtonColor: colors.redPrimary,
                                  confirmButtonText:
                                    Language[languageStorage].Sim,
                                }).then(result => {
                                  if (result.isConfirmed) {
                                    handleDelete(res);
                                  }
                                });
                              }}
                            >
                              <img src={IconRemove} alt="iconRemove" />
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>

              <table id="tableListagemSetores" style={{ display: 'none' }}>
                <HeaderTable
                  headers={
                    empresaPrincipal.length > 0 ? headers : headersViewer
                  }
                  onSorting={(field: string, order: string) => {
                    setSorting({ field, order });
                  }}
                />
                <tbody>
                  {responseData.map(res => (
                    <tr key={res.id}>
                      <td>{res.companyId}</td>
                      <td>{res.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ContainerTable>

            <ContainerPagination>
              {response.length > 0 && (
                <>
                  <div className="divPaginacao">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page: number) => setCurrentPage(page)}
                    />
                    <DropDownPagination
                      onChangeItems={(value: string) => {
                        setCurrentPage(1);
                        if (value === Language[languageStorage].Todos) {
                          setITEMS_PER_PAGE(1);
                        } else {
                          setITEMS_PER_PAGE(Number(value));
                        }
                      }}
                      objetoEnum={dropDown}
                      minWidth={30}
                    />
                  </div>

                  <ReactHTMLTableToExcel
                    id="export-excel"
                    className="btn"
                    table="tableListagemSetores"
                    filename={`${
                      Language[languageStorage]['listagem-setores']
                    }-${moment().format('DD-MM-YYYY')}`}
                    sheet="tablexls"
                    buttonText={Language[languageStorage]['Exportar Excel']}
                  />
                </>
              )}
            </ContainerPagination>
          </>
        )}

        {cadastrar && editar && (
          <AnimationContainer>
            <header>
              <h1>{Language[languageStorage]['Editar setor']}</h1>
            </header>

            <Form ref={formRef} onSubmit={handleSubmitEditar}>
              <p>{Language[languageStorage].Nome}</p>
              <InputOverview
                name="setor"
                placeholder={Language[languageStorage]['Nome do setor']}
                value={setorNomeEdit}
                onValue={value => setSetorNomeEdit(value)}
              />

              <ContainerButton>
                <Button
                  className="divButtonCancelar"
                  type="button"
                  widthProps="100%"
                  onClick={() => setEditar(false)}
                >
                  {Language[languageStorage].Cancelar}
                </Button>
                <Button type="submit" widthProps="100%">
                  {Language[languageStorage].Salvar}
                </Button>
              </ContainerButton>
            </Form>
          </AnimationContainer>
        )}
      </Container>

      {loading && <Loading />}
    </>
  );
};
