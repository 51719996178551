import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import IconFileUpload from '../../assets/IconFileUpload.svg';
import { useAuth } from '../../hooks/auth';
import Language from '../../language/language.json';

import { DropContainer, UploadMessage } from './styles';

interface UploadProps {
  onUpload?(e: File): void;
}

const Upload: React.FC<UploadProps> = ({
  onUpload = () => {
    ('');
  },
}) => {
  const { languageStorage } = useAuth();

  const renderDragMessage = useCallback(
    (isDragActive, isDragReject) => {
      if (!isDragActive) {
        return (
          <UploadMessage>
            <img src={IconFileUpload} alt="fileUpload" />
            {Language[languageStorage]['Arraste arquivos .CSV aqui']}
          </UploadMessage>
        );
      }

      if (isDragReject) {
        return (
          <UploadMessage type="error">
            {Language[languageStorage]['Arquivo não suportado']}
          </UploadMessage>
        );
      }

      return (
        <UploadMessage type="success">
          {Language[languageStorage]['Solte os arquivos aqui']}
        </UploadMessage>
      );
    },
    [languageStorage],
  );

  return (
    <Dropzone
      accept=".csv, application/vnd.ms-excel, text/csv"
      onDropAccepted={(files: any) => onUpload(files)}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <DropContainer
          {...getRootProps()}
          className="dropzone"
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps()} />
          {renderDragMessage(isDragActive, isDragReject)}
        </DropContainer>
      )}
    </Dropzone>
  );
};

export default Upload;
