import React, { useCallback, useContext, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import { useAuth } from '../../hooks/auth';
import Language from '../../language/language.json';
import { useToast } from '../../hooks/toast';
import { UserGet } from '../../models/User';
import api from '../../services/api';
import Button from '../Button';
import { maskCpf, maskTel } from '../InputOverview/mask';
import { Loading } from '../Loading';

import { Content } from './styles';

Modal.setAppElement('#root');

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  userEdit: UserGet;
}

export const ModalEditUser: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  userEdit,
}) => {
  const { languageStorage } = useAuth();
  const { colors } = useContext(ThemeContext);
  const { addToast } = useToast();
  const { go } = useHistory();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(userEdit.nome);
  const [telefone, setTelefone] = useState(
    maskTel(userEdit.telefone || '0000'),
  );
  const [status, setStatus] = useState(userEdit.ativo === 1);

  const handleSubmit = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const obj = {
        id: userEdit.id,
        nome: name,
        telefone,
        ativo: status,
      };
      // const schema = Yup.object().shape({
      //   email: Yup.string()
      //     .required('E-mail obrigatório')
      //     .email('Digite um email válido'),
      // });

      // await schema.validate(obj, {
      //   abortEarly: false,
      // });
      // if (password !== '-') {
      //   obj.password = password;
      // }

      // console.log(obj);

      await api
        .patch('users', obj)
        .then(() => {
          setLoading(false);

          addToast({
            title: `${Language[languageStorage].Sucesso}!`,
            description: `${Language[languageStorage]['O usuário foi alterado com sucesso']}.`,
            type: 'success',
          });

          setTimeout(() => {
            go(0);
          }, 1000);
        })
        .catch(err => {
          console.log(err);
          addToast({
            title: `${Language[languageStorage]['Erro ao editar usuário']}!`,
            description: `${Language[languageStorage]['Ocorreu um erro ao editar usuário, por favor tente novamente']}.`,
            type: 'error',
          });
        });
    } catch (err) {
      setLoading(false);

      if (err.response.data.errors) {
        addToast({
          title: `${Language[languageStorage]['Erro ao editar usuário']}!`,
          description: `${err.response.data.errors}.`,
          type: 'error',
        });
      } else {
        addToast({
          title: `${Language[languageStorage]['Erro ao editar usuário']}!`,
          description: `${Language[languageStorage]['Ocorreu um erro ao editar usuário, por favor tente novamente']}.`,
          type: 'error',
        });
      }
    }
  }, [addToast, go, languageStorage, name, status, telefone, userEdit.id]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose();
      }}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={() => onRequestClose()}
        className="react-modal-close"
      >
        <AiOutlineClose size={22} />
      </button>
      <Content>
        <header>
          <h1>Editar Usuário</h1>
        </header>
        <div className="name">
          <p>{userEdit.nome}</p>
          <p>{maskCpf(userEdit.cpf)}</p>
        </div>
        <p>{userEdit.email}</p>
        <section>
          <table>
            <thead>
              <tr>
                {/* <th>Email</th> */}
                <th>{Language[languageStorage].Nome}</th>
                <th>{Language[languageStorage].Telefone}</th>
              </tr>
            </thead>

            <tbody>
              <tr style={{ cursor: 'default' }}>
                <td style={{ width: 280 }}>
                  <input
                    name="nome"
                    value={name}
                    placeholder={`${Language[languageStorage]['Alterar nome']}...`}
                    onChange={event => {
                      setName(event.target.value);
                    }}
                  />
                </td>
                <td style={{ width: 280 }}>
                  <input
                    name="phone"
                    value={telefone}
                    type="phone"
                    placeholder={`${Language[languageStorage]['Alterar telefone']}...`}
                    onChange={event => {
                      setTelefone(event.target.value);
                    }}
                  />
                </td>
                {/* <td>
                  <div className="reset">
                    <p>{password}</p>
                    <button type="button" onClick={updateSenha}>
                      {password !== '-' ? 'Original' : 'Resetar'}
                    </button>
                  </div>
                </td> */}
                <td>
                  <aside style={{ width: 65 }}>
                    <button
                      type="button"
                      onClick={() => {
                        setStatus(!status);
                      }}
                    >
                      <BsCheckCircle
                        color={status ? colors.greenPrimary : ''}
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setStatus(!status);
                      }}
                    >
                      <BsXCircle color={!status ? colors.redPrimary : ''} />
                    </button>
                  </aside>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <div
          style={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            marginTop: 20,
          }}
        >
          <Button type="button" widthProps="80%" onClick={handleSubmit}>
            {Language[languageStorage]['Salvar alterações']}
          </Button>
        </div>
      </Content>
      {loading && <Loading />}
    </Modal>
  );
};
