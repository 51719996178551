import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  cor: boolean;
}

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 30px 40px 20px 40px;

  @media screen and (max-width: 1152px) {
    padding: 10px 20px;
  }
`;

export const Content = styled.div`
  margin-top: 30px;
`;

export const Aba = styled.div`
  margin-bottom: 33px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 1152px) {
    flex-direction: column;
  }

  span {
    margin-left: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1152px) {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: ${props => props.theme.colors.bluePrimary};
    }
  }
`;

export const ContainerAba = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    border: none;
    background: transparent;

    color: ${props =>
      props.cor ? props.theme.colors.textBlack : props.theme.colors.grayBlack};
    border-bottom: ${props =>
      props.cor
        ? `1px solid ${props.theme.colors.textBlack}`
        : '1px solid transparent'};

    &:hover {
      filter: brightness(1.1);
    }
  }

  & + div {
    margin-left: 30px;

    @media screen and (max-width: 1152px) {
      margin-left: 0;
    }
  }
`;

export const ContainerButton = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;

  .divButtonDownload {
    background: ${props => props.theme.colors.textBlack};

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 11px;
    }

    &:hover {
      background: ${props => shade(0.2, props.theme.colors.textBlack)};
    }
  }

  .divButtonCancelar {
    background: transparent;
    border: 1px solid ${props => props.theme.colors.textBlack};
    box-sizing: border-box;
    border-radius: 16px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    color: ${props => props.theme.colors.textBlack};

    &:hover {
      background: ${props => shade(0.1, props.theme.colors.whitePrimary)};
    }
  }
`;

export const AnimationContainer = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 35px 75px;
  max-height: calc(100vh - 327px);
  animation: ${appearFromRight} 1s;
  overflow: auto;

  @media screen and (max-width: 1152px) {
    padding: 25px;
    max-height: calc(100vh - 352px);
  }

  > header {
    padding-bottom: 16px;
    border-bottom: 0.6px solid ${props => props.theme.colors.grayBlack};

    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: ${props => props.theme.colors.textBlack};
    }
  }

  form {
    margin-top: 30px;

    p {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: ${props => props.theme.colors.textBlack};
      margin-bottom: 8px;
    }

    button {
      margin-top: 45px;
    }
  }
`;

export const ContainerTable = styled.div`
  margin-top: 30px;
  padding: 0 2px 15px 2px;
  max-width: calc(100vw - 388px);
  max-height: calc(100vh - 401px);
  overflow: auto;
  animation: ${appearFromLeft} 1s;

  @media screen and (max-width: 1152px) {
    max-width: 100vw;
    max-height: calc(100vh - 384px);
  }

  table {
    width: 100%;
    background: ${props => props.theme.colors.whitePrimary};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    border-collapse: collapse;

    thead {
      th {
        padding: 20px 30px;
        text-align: left;
        white-space: nowrap;
      }

      th:nth-last-child(-n + 2) {
        text-align: center;
      }
    }

    tbody {
      tr {
        border-radius: 20px !important;

        td {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: ${props => props.theme.colors.textBlack};
          padding: 20px 30px;
          white-space: nowrap;

          button {
            border: none;
            background: transparent;
            margin: 0 auto;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &:nth-child(odd) {
          background-color: ${props => props.theme.colors.grayWhite};
        }

        /* &:hover {
          background-color: ${shade(0.1, '#f3f3f3')};
        } */
      }
    }
  }
`;

export const ContainerPagination = styled.div`
  width: 100%;
  padding: 40px 60px 10px 60px;

  @media screen and (max-width: 1152px) {
    padding: 20px 0;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  .divPaginacao {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn {
    background: ${props => props.theme.colors.bluePrimary};
    border: none;
    border-radius: 16px;
    padding: 12px 35px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: ${props => props.theme.colors.whitePrimary};

    transition: background 0.2s;

    &:hover {
      background: ${props => shade(0.1, props.theme.colors.bluePrimary)};
    }
  }
`;

export const ContainerSemResultados = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-top: 30px;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: ${props => props.theme.colors.textBlack};
  }
`;

export const ConainerCadastroOption = styled.div`
  margin-bottom: 21px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
`;

export const RadioBox = styled.button`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 30px;
  border: none;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${props => props.theme.colors.textBlack};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  svg {
    margin-right: 20px;
  }

  transition: 0.2s;

  &:hover {
    filter: brightness(0.95);
  }
`;
