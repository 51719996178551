import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { BsFillLockFill, BsFillUnlockFill } from 'react-icons/bs';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Swal from 'sweetalert2';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { ThemeContext } from 'styled-components';
import { FiEdit } from 'react-icons/fi';
import Search from '../../../components/Search';
import ModalConexao from '../../../components/ModalConexao';
import api from '../../../services/api';
import Pagination from '../../../components/Pagination';
import HeaderTable from '../../../components/HeaderTable';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import { maskCpf, maskTel } from '../../../components/InputOverview/mask';
import Language from '../../../language/language.json';

import {
  Container,
  ContainerPagination,
  ContainerTable,
  ContainerSemResultados,
  ContainerTitle,
} from './styles';
import { UserGet } from '../../../models/User';
import { ModalEditUser } from '../../../components/ModalEditUser';

interface EmpresasInterface {
  ATIVO: boolean;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  ID: number;
  Id_Empresas: number;
  NOME: string;
  NUMERO: string;
}

export const Usuarios: React.FC = () => {
  const { user, empresaPrincipal, cpfUserMaster, languageStorage } = useAuth();
  const { addToast } = useToast();
  const { colors } = useContext(ThemeContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<UserGet[]>([]);
  const [empresas] = useState<EmpresasInterface[]>([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [userEdit, setUserEdit] = useState<UserGet | null>(null);

  const [search, setSearch] = useState('');

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE] = useState(20);
  const [sorting, setSorting] = useState({ field: '', order: '' });

  const headers = [
    {
      name: Language[languageStorage].Empresa,
      field: 'companyId',
      sortable: true,
    },
    { name: Language[languageStorage].Nome, field: 'nome', sortable: true },
    { name: Language[languageStorage].CPF, field: 'cpf', sortable: true },
    {
      name: Language[languageStorage]['E-mail'],
      field: 'email',
      sortable: true,
    },
    {
      name: Language[languageStorage].Telefone,
      field: 'telefone',
      sortable: true,
    },
    {
      name: Language[languageStorage]['Data de nascimento'],
      field: 'dataNascimento',
      sortable: true,
    },
    {
      name: Language[languageStorage].Coordenador,
      field: 'coordenador',
      sortable: true,
    },
    { name: Language[languageStorage].Editar, field: 'edit', sortable: true },
  ];

  const headersCoordenador = [
    {
      name: Language[languageStorage].Empresa,
      field: 'companyId',
      sortable: true,
    },
    { name: Language[languageStorage].Nome, field: 'nome', sortable: true },
    { name: Language[languageStorage].CPF, field: 'cpf', sortable: true },
    {
      name: Language[languageStorage]['E-mail'],
      field: 'email',
      sortable: true,
    },
    {
      name: Language[languageStorage].Telefone,
      field: 'telefone',
      sortable: true,
    },
    {
      name: Language[languageStorage]['Data de nascimento'],
      field: 'dataNascimento',
      sortable: true,
    },
    { name: Language[languageStorage].Editar, field: 'edit', sortable: true },
  ];

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `/allUsers/${
          empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
        }`,
      )
      .then(data => {
        api
          .get(
            `empresas/${
              empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
            }`,
          )
          .then(dataTemp => {
            setResponse(data.data);

            dataTemp.data.forEach((filial: EmpresasInterface) => {
              if (filial.ATIVO === true) {
                empresas.push(filial);
              }
            });

            setLoading(false);
          })
          .catch(() => {
            setLoading(false);

            addToast({
              type: 'error',
              title: Language[languageStorage].Erro,
              description:
                Language[languageStorage][
                  'Erro ao carregar dados, por favor atualize a página'
                ],
            });
          });
      })
      .catch(() => {
        setLoading(false);

        addToast({
          type: 'error',
          title: Language[languageStorage].Erro,
          description:
            Language[languageStorage][
              'Erro ao carregar dados, por favor atualize a página'
            ],
        });
      });
  }, [addToast, empresaPrincipal, empresas, history, languageStorage, user]);

  const handleChangeStatusCoordinator = useCallback(
    async data => {
      try {
        setLoading(true);

        await api
          .patch('users/inativarAtivarCoordenador', {
            id: data.id,
            parametro: data.coordenador === 1 ? 0 : 1,
          })
          .then(() => {
            setLoading(false);

            addToast({
              type: 'success',
              title: Language[languageStorage].Sucesso,
              description:
                Language[languageStorage]['Usuário atualizado com sucesso'],
            });

            setTimeout(() => {
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            setLoading(false);

            addToast({
              type: 'error',
              title: Language[languageStorage].Erro,
              description:
                Language[languageStorage][
                  'Erro ao atualizar status do usuário'
                ],
            });
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: Language[languageStorage].Erro,
          description:
            Language[languageStorage]['Erro ao atualizar status do usuário'],
        });
      }
    },
    [addToast, history, languageStorage],
  );

  const confirmHandleStatusCoordinator = useCallback(
    data => {
      if (cpfUserMaster[0].CPF === data.cpf) {
        addToast({
          type: 'info',
          title: Language[languageStorage]['Função bloqueada'],
          description:
            Language[languageStorage][
              'O usuário ADMINISTRADOR não pode alterar o PRÓPRIO nível de acesso'
            ],
        });
      } else {
        Swal.fire({
          title: `${Language[languageStorage]['Tem certeza que deseja atualizar o usuário']}?`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: Language[languageStorage]['Não'],
          confirmButtonColor: colors.greenPrimary,
          cancelButtonColor: colors.redPrimary,
          confirmButtonText: Language[languageStorage].Sim,
        }).then(result => {
          if (result.isConfirmed) {
            handleChangeStatusCoordinator(data);
          }
        });
      }
    },
    [
      addToast,
      colors.greenPrimary,
      colors.redPrimary,
      cpfUserMaster,
      handleChangeStatusCoordinator,
      languageStorage,
    ],
  );

  const handleCheckCompany = useCallback(
    (companyId: number): string => {
      let companyName = '';
      empresas.forEach(empresa => {
        if (empresa.ID === companyId) companyName = empresa.NOME;
      });
      return companyName;
    },
    [empresas],
  );

  const responseData = useMemo(() => {
    let computedResponses: UserGet[] = [];
    computedResponses = response.length > 0 ? response : [];

    if (search) {
      computedResponses = computedResponses.filter((res: UserGet) =>
        [res.cpf, res.nome, res.email, res.companyId].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        ),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (typeof a[sorting.field] === 'object' && a[sorting.field] != null) {
          return (
            reversed *
            a[sorting.field]
              .join(', ')
              .localeCompare(b[sorting.field].join(', '))
          );
        }
        if (typeof a[sorting.field] === 'string') {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        }

        const aTemp = a[sorting.field] != null ? a[sorting.field] : '';
        const bTemp = b[sorting.field] != null ? b[sorting.field] : '';
        return reversed * aTemp.toString().localeCompare(bTemp.toString());
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    search,
    sorting.field,
    sorting.order,
    ITEMS_PER_PAGE,
    currentPage,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  const handleEditUser = useCallback((userGet: UserGet) => {
    // console.log(userGet);
    setUserEdit(userGet);
    setShowModalEdit(true);
  }, []);

  return (
    <>
      <ModalConexao />

      <Container>
        <ContainerTitle>
          <h1>{Language[languageStorage].Listagem}</h1>

          <Tooltip
            title={
              Language[languageStorage][
                'Nessa página você tem acesso a visualização e atualização de status (coordenador) de todos os usuário cadastrados'
              ]
            }
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </ContainerTitle>

        <Search
          onSearch={(value: string) => {
            setSearch(value);
          }}
          nomePlaceHolder={Language[languageStorage].Buscar}
        />

        {!response.length && (
          <ContainerSemResultados>
            <h2>
              {`${Language[languageStorage]['Não existem usuários cadastrados ainda']}.`}
            </h2>
          </ContainerSemResultados>
        )}

        {!!response.length && (
          <>
            <ContainerTable>
              <table id="tableListagemUsuarios">
                <HeaderTable
                  headers={cpfUserMaster.length ? headers : headersCoordenador}
                  onSorting={(field: string, order: string) => {
                    setSorting({ field, order });
                  }}
                />
                <tbody>
                  {responseData.map(res => (
                    <tr key={`${res.id}-${res.cpf}-${res.createdAt}`}>
                      <td>{handleCheckCompany(res.companyId)}</td>
                      <td>{res.nome}</td>
                      <td>{res.cpf ? maskCpf(res.cpf) : '-'}</td>
                      <td>{res.email}</td>
                      <td>{res.telefone ? maskTel(res.telefone) : '-'}</td>
                      <td>{res.dataNascimento}</td>
                      {!!cpfUserMaster.length && (
                        <td>
                          <button
                            type="button"
                            onClick={() => {
                              if (empresaPrincipal.length > 0) {
                                confirmHandleStatusCoordinator(res);
                              }
                            }}
                          >
                            {res.coordenador === 1 && <BsFillUnlockFill />}
                            {res.coordenador === 0 && <BsFillLockFill />}
                          </button>
                          <p hidden>
                            {res.coordenador === 1 &&
                              Language[languageStorage].Sim}
                          </p>
                          <p hidden>
                            {res.coordenador === 0 &&
                              Language[languageStorage]['Não']}
                          </p>
                        </td>
                      )}
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            handleEditUser(res);
                          }}
                        >
                          <FiEdit size={20} color={colors.bluePrimary} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ContainerTable>

            <ContainerPagination>
              {response.length > 0 && (
                <>
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page: number) => setCurrentPage(page)}
                  />

                  <ReactHTMLTableToExcel
                    id="export-excel"
                    className="btn"
                    table="tableListagemUsuarios"
                    filename={`${
                      Language[languageStorage]['listagem-usuarios']
                    }-${moment().format('DD-MM-YYYY')}`}
                    sheet="tablexls"
                    buttonText={Language[languageStorage]['Exportar Excel']}
                  />
                </>
              )}
            </ContainerPagination>
          </>
        )}
      </Container>

      {userEdit && (
        <ModalEditUser
          isOpen={showModalEdit}
          onRequestClose={() => setShowModalEdit(false)}
          userEdit={userEdit}
        />
      )}

      {loading && <Loading />}
    </>
  );
};
