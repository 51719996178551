import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { uuid } from 'uuidv4';
import filesize from 'filesize';
import {
  AiFillCaretLeft,
  AiFillCaretDown,
  AiOutlineQuestionCircle,
} from 'react-icons/ai';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Checkbox } from 'antd';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { ThemeContext } from 'styled-components';
import Search from '../../../components/Search';
import ModalConexao from '../../../components/ModalConexao';
import api from '../../../services/api';
import FileList from '../../../components/FileList';
import Upload from '../../../components/Upload';
import Pagination from '../../../components/Pagination';
import HeaderTable from '../../../components/HeaderTable';
import { InputOverview } from '../../../components/InputOverview';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import Button from '../../../components/Button';
import { SelectCustom } from '../../../components/SelectCustom';
import getValidationErrors from '../../../utils/getValidationErrors';
import { maskCpf, maskCnpj } from '../../../components/InputOverview/mask';
import IconFileUploadBtn from '../../../assets/IconFileUploadBtn.svg';
import { arquivoModeloPreCadastroUrlAws } from '../../../utils/urlsBucketAws';
import Language from '../../../language/language.json';

import {
  Container,
  Content,
  Aba,
  ContainerAba,
  ContainerSearch,
  ContainerPagination,
  ContainerTable,
  AnimationContainer,
  ContainerButton,
  AbaMenu,
  ContainerSemResultados,
  ConainerCadastroOption,
  RadioBox,
} from './styles';

interface Uploaded {
  // file: File | null;
  // id: string;
  // name: string;
  // readableSize: string;
  // preview: string;
  // progress: number;
  // uploaded: boolean;
  // error: boolean;
  // url: string;

  file: File | null;
  id: string;
  name: string;
  readableSize: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  type: string;
}

interface SignUpFormData {
  cpf: string;
}

interface ResponseGet {
  ATIVO: boolean;
  CNPJ: string;
  CPF: string;
  ENDERECO: string;
  ID_FILIAL: number;
  Id_Empresas: number;
  NOME_FILIAL: string;
  NUMERO: string;
  checked: boolean;
}

interface EmpresasInterface {
  ATIVO: boolean;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  ID: number;
  Id_Empresas: number;
  NOME: string;
  NUMERO: string;
}

export const PreCadastroUsuarios: React.FC = () => {
  const { user, cpfUserMaster, empresaPrincipal, languageStorage } = useAuth();
  const { addToast } = useToast();
  const { colors } = useContext(ThemeContext);
  const history = useHistory();
  const formRefIndividual = useRef<FormHandles>(null);
  const formRefEmLote = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [responseCpf, setResponseCpf] = useState<string[]>([]);

  const [uploadedFiles, setUploadedFiles] = useState<Uploaded[]>([]);
  const [uploadedFilesTemp, setUploadedFilesTemp] = useState<Uploaded[]>([]);
  const [cadastrar, setCadastrar] = useState(true);

  const [setaExportar, setSetaExportar] = useState('AiFillCaretLeft');

  const [search, setSearch] = useState('');

  const [empresas] = useState<EmpresasInterface[]>([]);
  const [empresaId, setEmpresaId] = useState<number>();
  const [empresaIdLote, setEmpresaIdLote] = useState<number>();

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE] = useState(20);
  const [sorting, setSorting] = useState({ field: '', order: '' });

  const [download, setDownload] = useState('');
  const [count, setCount] = useState(0);
  const [verificaResponse, setVerificaResponse] = useState(false);
  const [registerType, setRegisterType] = useState('individual');

  const headers = [
    { name: Language[languageStorage].CPF, field: 'CPF', sortable: true },
    {
      name: Language[languageStorage].Empresa,
      field: 'NOME_FILIAL',
      sortable: true,
    },
    { name: Language[languageStorage].CNPJ, field: 'CNPJ', sortable: true },
    { name: Language[languageStorage].Status, field: 'ATIVO', sortable: true },
    { name: '', field: 'checkbox', sortable: false },
  ];

  const headersViewer = [
    { name: Language[languageStorage].CPF, field: 'CPF', sortable: true },
    {
      name: Language[languageStorage].Empresa,
      field: 'NOME_FILIAL',
      sortable: true,
    },
    { name: Language[languageStorage].CNPJ, field: 'CNPJ', sortable: true },
    { name: Language[languageStorage].Status, field: 'ATIVO', sortable: true },
  ];

  useEffect(() => {
    setLoading(true);

    let param;

    if (empresaPrincipal.length > 0) param = user.companyId;

    api
      .get(`empresas/funcionarios/${param}`)
      .then(data => {
        const dadosCopiaTemp = data.data.map((dado: ResponseGet) => {
          return { ...dado, checked: false };
        });

        api
          .get(
            `empresas/${
              empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
            }`,
          )
          .then(dataTemp => {
            setResponse(dadosCopiaTemp);

            let cont = 0;
            dataTemp.data.forEach((filial: EmpresasInterface) => {
              if (filial.ATIVO === true) {
                empresas.push(filial);

                if (cont === 0) {
                  setEmpresaId(filial.ID);
                  setEmpresaIdLote(filial.ID);

                  cont += 1;
                }
              }
            });

            setVerificaResponse(true);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);

            addToast({
              type: 'error',
              title: Language[languageStorage].Erro,
              description:
                Language[languageStorage][
                  'Erro ao carregar dados, por favor atualize a página'
                ],
            });
          });
      })
      .catch(() => {
        setLoading(false);

        addToast({
          type: 'error',
          title: Language[languageStorage].Erro,
          description:
            Language[languageStorage][
              'Erro ao carregar dados, por favor atualize a página'
            ],
        });
      });
  }, [addToast, empresaPrincipal, empresas, languageStorage, user.companyId]);

  const updateFile = useCallback((id, data) => {
    setUploadedFiles(estadoAnterior =>
      estadoAnterior.map(file =>
        id === file.id ? { ...file, ...data } : file,
      ),
    );
  }, []);

  const processUpload = useCallback(
    (uploadedFile: Uploaded) => {
      const data = new FormData();

      if (uploadedFile.file && cpfUserMaster[0].Id_Empresas && empresaIdLote) {
        data.append('csv', uploadedFile.file);
        data.append('idEmpresa', cpfUserMaster[0].Id_Empresas.toString());
        data.append('idEmpresaFilial', empresaIdLote.toString());
      }

      api
        .post('empresas/saveCSVFuncionarios', data, {
          onUploadProgress: e => {
            const progress = Math.round((e.loaded * 100) / e.total);

            updateFile(uploadedFile.id, {
              progress,
            });
          },
        })
        .then(() => {
          updateFile(uploadedFile.id, {
            uploaded: true,
          });

          setUploadedFilesTemp([]);

          setTimeout(() => {
            history.go(0);
          }, 1500);
        })
        .catch(() => {
          updateFile(uploadedFile.id, {
            error: true,
          });
        });
    },
    [cpfUserMaster, empresaIdLote, history, updateFile],
  );

  const handleUpload = useCallback(
    files => {
      const uploadedFilesHandleUpload: Uploaded[] = files.map((file: File) => ({
        // PARA ARQUIVO DE IMAGEM
        // file,
        // id: uuid(),
        // name: file.name,
        // readableSize: filesize(file.size),
        // preview: <GrDocumentCsv />, // URL.createObjectURL(file),
        // progress: 0,
        // uploaded: false,
        // error: false,
        // url: '',

        // PARA ARQUIVO CSV
        file,
        id: uuid(),
        name: file.name,
        readableSize: filesize(file.size),
        progress: 0,
        uploaded: false,
        error: false,
        type: '.csv, application/vnd.ms-excel, text/csv',
      }));

      setUploadedFiles(uploadedFiles.concat(uploadedFilesHandleUpload));

      setUploadedFilesTemp(uploadedFilesHandleUpload);
    },
    [uploadedFiles],
  );

  const clickHandleUpload = useCallback(() => {
    if (!uploadedFilesTemp.length) {
      addToast({
        type: 'error',
        title:
          Language[languageStorage]["Erro ao pré-cadastrar lista de CPF's"],
        description:
          Language[languageStorage][
            'É necessário inserir um arquivo .CSV válido. Favor baixar o arquivo modelo pré-formatado para o preenchimento correto'
          ],
      });
    } else {
      uploadedFilesTemp.forEach(processUpload);
    }
  }, [addToast, languageStorage, processUpload, uploadedFilesTemp]);

  const handleDelete = useCallback(
    async id => {
      uploadedFiles.splice(
        uploadedFiles.findIndex(element => element.id === id),
        1,
      );

      uploadedFilesTemp.splice(
        uploadedFilesTemp.findIndex(element => element.id === id),
        1,
      );
      setUploadedFiles([...uploadedFiles]);
      setUploadedFilesTemp([...uploadedFilesTemp]);
    },
    [uploadedFiles, uploadedFilesTemp],
  );

  /*-------------------------------------------------------------------------*/

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRefIndividual.current?.setErrors({});

        const schema = Yup.object().shape({
          cpf: Yup.string().required(
            Language[languageStorage]['CPF do usuário é obrigatório'],
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let CPF = data.cpf.replace(/\./g, '');
        CPF = CPF.replace(/-/g, '');

        if (CPF.split('').length !== 11) {
          addToast({
            type: 'error',
            title: Language[languageStorage].Erro,
            description:
              Language[languageStorage]['Favor inserir um CPF válido'],
          });
        } else {
          await api
            .post('empresas/saveFuncionarios', {
              cpf: CPF,
              idEmpresa: empresaPrincipal[0].Id_Empresas,
              idEmpresaFilial: empresaId,
            })
            .then(() => {
              setLoading(false);

              addToast({
                type: 'success',
                title: Language[languageStorage].Sucesso,
                description:
                  Language[languageStorage]['CPF pré-cadastrado com sucesso'],
              });

              setTimeout(() => {
                history.go(0);
              }, 1000);
            })
            .catch(() => {
              setLoading(false);

              addToast({
                type: 'error',
                title: Language[languageStorage].Erro,
                description:
                  Language[languageStorage]['Erro ao pré-cadastrar CPF'],
              });
            });
        }
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRefIndividual.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: Language[languageStorage]['Campos em branco'],
            description: `${Language[languageStorage]['Existem campos obrigatórios não preenchidos']}.`,
          });
        } else {
          addToast({
            type: 'error',
            title: Language[languageStorage].Erro,
            description: `${Language[languageStorage]['Ocorreu um erro, por favor tente novamente']}.`,
          });
        }
      }
    },
    [addToast, empresaId, empresaPrincipal, history, languageStorage],
  );

  const handleState = useCallback(
    async dataAction => {
      if (!responseCpf.length) {
        addToast({
          type: 'info',
          title: Language[languageStorage]['Nenhum registro selecionado'],
          description:
            Language[languageStorage][
              'Favor selecionar pelo menos um registro'
            ],
        });
      } else {
        try {
          setLoading(true);

          let cont = 0;

          const arrayAction: string[] = [];
          for (let i = 0; i < responseCpf.length; i += 1) {
            if (cpfUserMaster[0].CPF === responseCpf[i]) cont += 1;

            if (dataAction === 1) {
              arrayAction.push('1');
            } else if (dataAction === 2) {
              arrayAction.push('2');
            } else if (dataAction === 3) {
              arrayAction.push('3');
            }
          }

          if (cont === 0) {
            api
              .put('empresas/ativaInativaDeleteFuncionarios', {
                acao: arrayAction,
                cpf: responseCpf,
              })
              .then(() => {
                setLoading(false);

                addToast({
                  type: 'success',
                  title: Language[languageStorage].Sucesso,
                  description:
                    Language[languageStorage][
                      'Usuário(s) atualizado(s) com sucesso'
                    ],
                });

                setTimeout(() => {
                  history.go(0);
                }, 1000);
              })
              .catch(() => {
                setLoading(false);

                addToast({
                  type: 'error',
                  title: Language[languageStorage].Erro,
                  description:
                    Language[languageStorage][
                      'Erro ao atualizar status do(s) usuário(s)'
                    ],
                });
              });
          } else {
            setLoading(false);

            addToast({
              type: 'info',
              title: Language[languageStorage]['Função bloqueada'],
              description:
                Language[languageStorage][
                  'O usuário ADMINISTRADOR não pode alterar o PRÓPRIO status'
                ],
            });
          }
        } catch (err) {
          setLoading(false);

          addToast({
            type: 'error',
            title: Language[languageStorage].Erro,
            description:
              Language[languageStorage][
                'Erro ao atualizar status do(s) usuário(s)'
              ],
          });
        }
      }
    },
    [addToast, cpfUserMaster, history, languageStorage, responseCpf],
  );

  const onButtonClick = useCallback(
    dadoParam => {
      const newResponse = response.map(dado => {
        return JSON.stringify(dado) === JSON.stringify(dadoParam)
          ? { ...dado, checked: !dado.checked }
          : dado;
      });

      const arrayCpfResponse: string[] = [];
      newResponse.forEach(data => {
        if (data.checked) arrayCpfResponse.push(data.CPF);
      });

      setResponse(newResponse);
      setResponseCpf(arrayCpfResponse);
    },
    [response],
  );

  const confirmHandleStatus = useCallback(
    (dataAction: number) => {
      if (dataAction === 3) {
        Swal.fire({
          title: `${Language[languageStorage]['Tem certeza que deseja ativar o(s) usuário(s)']}?`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: Language[languageStorage].Não,
          confirmButtonColor: colors.greenPrimary,
          cancelButtonColor: colors.redPrimary,
          confirmButtonText: Language[languageStorage].Sim,
        }).then(result => {
          if (result.isConfirmed) {
            handleState(dataAction);
          }
        });
      } else if (dataAction === 1) {
        Swal.fire({
          title: `${Language[languageStorage]['Tem certeza que deseja inativar o(s) usuário(s)']}?`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: Language[languageStorage].Não,
          confirmButtonColor: colors.greenPrimary,
          cancelButtonColor: colors.redPrimary,
          confirmButtonText: Language[languageStorage].Sim,
        }).then(result => {
          if (result.isConfirmed) {
            handleState(dataAction);
          }
        });
      } else if (dataAction === 2) {
        Swal.fire({
          title: `${Language[languageStorage]['Tem certeza que deseja remover o(s) usuário(s)']}?`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: Language[languageStorage].Não,
          confirmButtonColor: colors.greenPrimary,
          cancelButtonColor: colors.redPrimary,
          confirmButtonText: Language[languageStorage].Sim,
        }).then(result => {
          if (result.isConfirmed) {
            handleState(dataAction);
          }
        });
      }
    },
    [colors.greenPrimary, colors.redPrimary, handleState, languageStorage],
  );

  const handleAlertMaster = useCallback(() => {
    if (verificaResponse) {
      setCadastrar(false);
      setDownload('');
      setSearch('');
    }
  }, [verificaResponse]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response.length ? response : [];

    if (search) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        [res.CPF, res.CNPJ, res.NOME_FILIAL].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        ),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (typeof a[sorting.field] === 'object') {
          return (
            reversed *
            a[sorting.field]
              .join(', ')
              .localeCompare(b[sorting.field].join(', '))
          );
        }
        if (typeof a[sorting.field] === 'string') {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        }
        return (
          reversed *
          a[sorting.field].toString().localeCompare(b[sorting.field].toString())
        );
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    search,
    sorting.field,
    sorting.order,
    ITEMS_PER_PAGE,
    currentPage,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  return (
    <>
      <ModalConexao />

      <Container>
        {empresaPrincipal.length > 0 ? (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button type="button" onClick={() => setCadastrar(true)}>
                {Language[languageStorage][`Listar CPF's`]}
              </button>
            </ContainerAba>
            <ContainerAba className="aba1" cor={!cadastrar}>
              <button type="button" onClick={handleAlertMaster}>
                {Language[languageStorage][`Cadastrar CPF's`]}
              </button>
            </ContainerAba>

            <Tooltip
              title={
                Language[languageStorage][
                  "Nessa página você tem acesso a visualização, cadastro e edição (ativar, inativar ou remover) de todos os CPF's liberados para cadastro e acesso ao sistema web e/ou mobile"
                ]
              }
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        ) : (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button type="button" onClick={() => setCadastrar(true)}>
                {Language[languageStorage][`Listar CPF's`]}
              </button>
            </ContainerAba>

            <Tooltip
              title={
                Language[languageStorage][
                  "Nessa página você tem acesso a visualização de todos os CPF's liberados para cadastro e acesso ao sistema web e/ou mobile"
                ]
              }
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        )}

        {cadastrar && (
          <ContainerSearch isAdmin={!!cpfUserMaster.length}>
            <>
              <Search
                onSearch={(value: string) => {
                  setSearch(value);
                }}
                nomePlaceHolder={Language[languageStorage].Buscar}
              />

              {!!response.length &&
                empresaPrincipal.length > 0 &&
                !!cpfUserMaster.length && (
                  <AbaMenu>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="dropbtn"
                        onFocus={(): void => {
                          setSetaExportar('AiFillCaretDown');
                        }}
                        onMouseOver={(): void => {
                          setSetaExportar('AiFillCaretDown');
                        }}
                        onBlur={(): void => {
                          setSetaExportar('AiFillCaretLeft');
                        }}
                        onMouseOut={(): void => {
                          setSetaExportar('AiFillCaretLeft');
                        }}
                      >
                        <p style={{ margin: 0, padding: 0 }}>
                          {Language[languageStorage]['Status do(s) Usuário(s)']}
                        </p>
                        {setaExportar === 'AiFillCaretLeft' ? (
                          <AiFillCaretLeft />
                        ) : (
                          <AiFillCaretDown />
                        )}
                      </button>
                      <div
                        className="dropdown-content"
                        onFocus={(): void => {
                          setSetaExportar('AiFillCaretDown');
                        }}
                        onMouseOver={(): void => {
                          setSetaExportar('AiFillCaretDown');
                        }}
                        onBlur={(): void => {
                          setSetaExportar('AiFillCaretLeft');
                        }}
                        onMouseOut={(): void => {
                          setSetaExportar('AiFillCaretLeft');
                        }}
                      >
                        <button
                          type="button"
                          onClick={() => confirmHandleStatus(3)}
                          style={{
                            borderRadius: '0px',
                          }}
                        >
                          {Language[languageStorage]['Ativar usuário(s)']}
                        </button>
                        <button
                          type="button"
                          onClick={() => confirmHandleStatus(1)}
                          style={{
                            borderRadius: '0px',
                          }}
                        >
                          {Language[languageStorage]['Inativar usuário(s)']}
                        </button>
                        <button
                          type="button"
                          onClick={() => confirmHandleStatus(2)}
                          style={{
                            borderRadius: '0px',
                          }}
                        >
                          {Language[languageStorage]['Remover usuário(s)']}
                        </button>
                      </div>
                    </div>
                  </AbaMenu>
                )}
            </>
          </ContainerSearch>
        )}

        <section>
          {!cadastrar && (
            <ConainerCadastroOption>
              <RadioBox
                type="button"
                onClick={() => setRegisterType('individual')}
              >
                {Language[languageStorage]['Cadastro individual']}
              </RadioBox>
              <RadioBox type="button" onClick={() => setRegisterType('lote')}>
                {Language[languageStorage]['Cadastro em lote']}
              </RadioBox>
            </ConainerCadastroOption>
          )}

          {!cadastrar &&
            (registerType === 'individual' ? (
              <AnimationContainer>
                <Form ref={formRefIndividual} onSubmit={handleSubmit}>
                  {/* <p>Empresa</p> */}
                  <p>{Language[languageStorage].Empresa}</p>

                  <SelectCustom
                    name="Empresa"
                    defaultValue={Language[languageStorage].Empresa}
                    optionsDataCompany={empresas}
                    onValue={e => setEmpresaId(parseInt(e.split('-')[0], 10))}
                  />

                  <p>{Language[languageStorage].CPF}</p>
                  <InputOverview
                    name="cpf"
                    placeholder={Language[languageStorage]['CPF do usuário']}
                    mask="cpf"
                  />
                  <Button type="submit" widthProps="100%">
                    {Language[languageStorage].Cadastrar}
                  </Button>
                </Form>
              </AnimationContainer>
            ) : (
              <AnimationContainer>
                <Form ref={formRefEmLote} onSubmit={clickHandleUpload}>
                  <p>{Language[languageStorage].Empresa}</p>

                  <SelectCustom
                    name="Empresa"
                    defaultValue="Empresa"
                    optionsDataCompany={empresas}
                    onValue={e => {
                      setEmpresaIdLote(parseInt(e.split('-')[0], 10));
                    }}
                  />
                  <Content>
                    <Upload onUpload={(e: any) => handleUpload(e)} />
                    {!!uploadedFiles.length && (
                      <FileList
                        files={uploadedFiles}
                        onDelete={(e: string) => handleDelete(e)}
                      />
                    )}
                  </Content>
                  <ContainerButton>
                    <Button type="submit" widthProps="100%">
                      {Language[languageStorage].Salvar}
                    </Button>
                    <Button
                      type="button"
                      widthProps="100%"
                      className="divButtonDownload"
                      onClick={() => {
                        setDownload(arquivoModeloPreCadastroUrlAws);
                        setCount(old => old + 1);
                      }}
                    >
                      <img src={IconFileUploadBtn} alt="iconFileUploadBtn" />
                      {Language[languageStorage]['Baixar Modelo']}
                    </Button>
                    {download && (
                      <iframe
                        title="downloadModeloCsv"
                        src={`${download}?c=${count}`}
                        hidden
                      />
                    )}
                  </ContainerButton>
                </Form>
              </AnimationContainer>
            ))}

          {cadastrar && !response.length && (
            <ContainerSemResultados>
              <h2>
                {
                  Language[languageStorage][
                    `Não existem CPF's pré-cadastrados ainda`
                  ]
                }
                .
              </h2>
            </ContainerSemResultados>
          )}

          {cadastrar && !!response.length && (
            <>
              <ContainerTable>
                <table>
                  <HeaderTable
                    headers={
                      empresaPrincipal.length > 0 ? headers : headersViewer
                    }
                    onSorting={(field: string, order: string) => {
                      setSorting({ field, order });
                    }}
                  />
                  <tbody>
                    {responseData.map(res => (
                      <tr
                        key={`${res.CPF}-${res.CNPJ}-${res.NOME_FILIAL}-${res.ATIVO}`}
                      >
                        <td>{res.CPF ? maskCpf(res.CPF) : '-'}</td>
                        <td>{res.NOME_FILIAL ? res.NOME_FILIAL : '-'}</td>
                        <td>{res.CNPJ ? maskCnpj(res.CNPJ) : '-'}</td>
                        <td>
                          {!res.ATIVO && Language[languageStorage].Inativo}
                          {!!res.ATIVO && Language[languageStorage].Ativo}
                        </td>
                        {empresaPrincipal.length > 0 && (
                          <td>
                            <Checkbox
                              onChange={() => onButtonClick(res)}
                              checked={res.checked}
                              disabled={!cpfUserMaster.length}
                            />
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table id="tableListagemUsuarios" style={{ display: 'none' }}>
                  <HeaderTable
                    headers={
                      empresaPrincipal.length > 0 ? headers : headersViewer
                    }
                    onSorting={(field: string, order: string) => {
                      setSorting({ field, order });
                    }}
                  />
                  <tbody>
                    {responseData.map(res => (
                      <tr
                        key={`${res.CPF}-${res.CNPJ}-${res.NOME_FILIAL}-${res.ATIVO}`}
                      >
                        <td>{res.CPF ? maskCpf(res.CPF) : '-'}</td>
                        <td>{res.NOME_FILIAL ? res.NOME_FILIAL : '-'}</td>
                        <td>{res.CNPJ ? maskCnpj(res.CNPJ) : '-'}</td>
                        <td>
                          {!res.ATIVO && Language[languageStorage].Inativo}
                          {!!res.ATIVO && Language[languageStorage].Ativo}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </ContainerTable>

              <ContainerPagination>
                {response.length > 0 && (
                  <>
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page: number) => setCurrentPage(page)}
                    />

                    <ReactHTMLTableToExcel
                      id="export-excel"
                      className="btn"
                      table="tableListagemUsuarios"
                      filename={`${
                        Language[languageStorage]['listagem-cpfs']
                      }-${moment().format('DD-MM-YYYY')}`}
                      sheet="tablexls"
                      buttonText={Language[languageStorage]['Exportar Excel']}
                    />
                  </>
                )}
              </ContainerPagination>
            </>
          )}
        </section>
      </Container>

      {loading && <Loading />}
    </>
  );
};
