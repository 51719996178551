import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { IoIosArrowForward } from 'react-icons/io';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Pagination from '../../../components/Pagination';
import { ModalUserDetails } from '../../../components/ModalDetalhesUser';
import { ModalHistoricoColaborador } from '../../../components/ModalHistoricoColaborador';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import api from '../../../services/api';
import Search from '../../../components/Search';
import imgAvatar from '../../../assets/IconAvatar.svg';
import { maskCpf, maskTel } from '../../../components/InputOverview/mask';
import ModalConexao from '../../../components/ModalConexao';
import IconFilter from '../../../assets/IconFilter.svg';
import DropDownPagination from '../../../components/DropDownPagination';
import HeaderTable from '../../../components/HeaderTable';
import { ModalFilter, StatusButton } from '../../../components/ModalFilter';
import Language from '../../../language/language.json';

import {
  Container,
  ContainerTitle,
  ContainerItem,
  ContainerSearch,
  ContainerSemResultados,
  ContainerListItens,
  ContainerPagination,
} from './styles';
import { Empresa } from '../../../models/Empresa';
import { ContainerSelect } from '../../RelatoriosV2/DashboardColaboradores/styles';

interface ResponseGet {
  ativo: number;
  avatar: string;
  avatar_url: string;
  avatarURL: string;
  companyId: number;
  coordenador: number;
  cpf: string;
  createdAt: string;
  dataNascimento: string;
  email: string;
  id: number;
  nome: string;
  password: string;
  telefone: string;
  tipo: string;
  updatedAt: string;
}

interface ResponseGetHistoric {
  AMBIENTE_ID: number;
  AMBIENTE_NOME: string;
  ANDAR: string;
  DATA_CRIACAO: string;
  DATA_FINALIZACAO: string;
  FIM: string;
  HORA_FINAL_PRINCIPAL_AMBIENTE: string;
  HORA_INICIAL_PRINCIPAL_AMBIENTE: string;
  HORA_REVISAO_FINAL: string;
  HORA_REVISAO_INICIAL: string;
  ID: number;
  IMAGEM: string;
  INICIO: string;
  OBSERVACAO: string;
  PROCEDIMENTO_NOME: string;
  Revisao: boolean;
  USUARIO: string;
  categoria: string;
  imagem_revisao: string;
  revisao_id_table_revisao: number;
  setor_id: number;
  setor_nome: string;
  escondido: boolean;
}

interface ResponseGetHistoricObs {
  TaskId: number;
  createdAt: string;
  environmentId: number;
  id: number;
  observacao: string;
  updatedAt: string;
  userId: number;
}

const DashboardColaboradores: React.FC = () => {
  const { user, empresaPrincipal, signOut, languageStorage } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [filialId, setFilialId] = useState(-1);
  const [filiais, setFiliais] = useState<Empresa[]>([]);
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [modalDetalhesUser, setModalDetalhesUser] = useState(true);
  const [modalDetalhesAmbiente, setModalDetalhesAmbiente] = useState(true);
  const [dadosModal, setDadosModal] = useState<ResponseGet>();
  const [dadosHistoricoModal, setDadosHistoricoModal] = useState<
    ResponseGetHistoric[]
  >([]);
  const [dadosHistoricoObsModal, setDadosHistoricoObsModal] = useState<
    ResponseGetHistoricObs[]
  >([]);
  const [dadosHistoricoDatasModal, setDadosHistoricoDatasModal] = useState('');
  const [dataFilterModal, setDataFilterModal] = useState<StatusButton[]>([
    {
      status: true,
      name: Language[languageStorage].Colaborador,
    },
    {
      status: false,
      name: Language[languageStorage].CPF,
    },
    {
      status: false,
      name: Language[languageStorage]['E-mail'],
    },
    {
      status: false,
      name: Language[languageStorage].Telefone,
    },
    {
      status: false,
      name: Language[languageStorage]['Intervalo de datas'],
    },
  ]);
  const [search, setSearch] = useState('');
  const [searchDataInicial, setSearchDataInicial] = useState('');
  const [searchDataFinal, setSearchDataFinal] = useState('');
  const [showModalFilter, setShowModalFilter] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);

  const dataInicio = moment().format('YYYY-MM-01');
  const dataFim = moment().format('YYYY-MM-DD');

  const dropDown = [
    { valor: '20', id: '20' },
    { valor: '40', id: '40' },
    { valor: '80', id: '80' },
    { valor: '160', id: '160' },
    { valor: Language[languageStorage].Todos, id: '1' },
  ];

  const headers = [
    { name: Language[languageStorage].Nome, field: 'nome', sortable: false },
    { name: Language[languageStorage].CPF, field: 'cpf', sortable: false },
    {
      name: Language[languageStorage]['E-mail'],
      field: 'email',
      sortable: false,
    },
    {
      name: Language[languageStorage].Telefone,
      field: 'telefone',
      sortable: false,
    },
  ];

  const getEmpresas = useCallback(async () => {
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
    api
      .get(`empresas/${companyId}`)
      .then(resp => {
        setFiliais(resp.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [empresaPrincipal]);

  useEffect(() => {
    setLoading(true);
    getEmpresas();

    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';

    api
      .get(
        `/users/?dataI=${dataInicio}&dataF=${dataFim}&companyId=${companyId}`,
      )
      .then(data => {
        setResponse(data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);

        addToast({
          type: 'error',
          title: Language[languageStorage].Erro,
          description:
            Language[languageStorage][
              'Erro ao carregar dados, por favor atualize a página'
            ],
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const mesAtual = moment().format('YYYY-MM-DD');
    const mesDataInicial = moment(searchDataInicial).format('YYYY-MM-DD');
    const mesDataFinal = moment(searchDataFinal).format('YYYY-MM-DD');

    if (
      searchDataInicial.length === 10 &&
      searchDataFinal.length === 10 &&
      searchDataInicial.substr(0, 1) !== '0' &&
      searchDataFinal.substr(0, 1) !== '0'
    ) {
      if (mesDataInicial <= mesAtual && mesDataFinal <= mesAtual) {
        setLoading(true);
        const companyId =
          empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';

        api
          .get(
            `/users/?dataI=${searchDataInicial}&dataF=${searchDataFinal}&companyId=${companyId}`,
          )
          .then(data => {
            setResponse(data.data);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);

            addToast({
              type: 'error',
              title: Language[languageStorage].Erro,
              description:
                Language[languageStorage][
                  'Erro ao carregar dados, por favor atualize a página'
                ],
            });
          });
      } else {
        addToast({
          type: 'info',
          title: Language[languageStorage]['Filtro inválido'],
          description:
            Language[languageStorage][
              'Intervalo de datas inválidos, favor verificar'
            ],
        });
      }
    }
  }, [
    addToast,
    empresaPrincipal,
    history,
    languageStorage,
    searchDataFinal,
    searchDataInicial,
    signOut,
    user,
  ]);

  useEffect(() => {
    setSearchDataInicial('');
    setSearchDataFinal('');
  }, [search]);

  useEffect(() => {
    setSearch('');
  }, [searchDataInicial, searchDataFinal]);

  const handleMaskDate = useCallback(data => {
    const dataTemp = data.split('-').reverse().join('/');

    return dataTemp;
  }, []);

  const requestHistoricoUsuario = useCallback(
    (dado: ResponseGet) => {
      const mesAtual = moment().format('YYYY-MM-DD');
      const mesDataInicial = moment(searchDataInicial).format('YYYY-MM-DD');
      const mesDataFinal = moment(searchDataFinal).format('YYYY-MM-DD');

      if (
        searchDataInicial.length === 10 &&
        searchDataFinal.length === 10 &&
        searchDataInicial.substr(0, 1) !== '0' &&
        searchDataFinal.substr(0, 1) !== '0'
      ) {
        if (mesDataInicial <= mesAtual && mesDataFinal <= mesAtual) {
          setDadosHistoricoModal([]);

          setLoading(true);
          api
            .get(
              `/dash/user?userId=${dado.id}&dataInicio=${searchDataInicial}&dataFim=${searchDataFinal}`,
            )
            .then(data => {
              if (
                data.data[0] ===
                Language[languageStorage]['Intervalo de datas inválidos']
              ) {
                addToast({
                  type: 'info',
                  title: Language[languageStorage]['Filtro inválido'],
                  description:
                    Language[languageStorage][
                      'Intervalo de datas inválidos, favor verificar'
                    ],
                });
              } else {
                setLoading(false);
                setDadosHistoricoModal(data.data.data);
                setDadosHistoricoObsModal(data.data.obs);
                setDadosHistoricoDatasModal(
                  `${Language[languageStorage].DeHorario} ${handleMaskDate(
                    searchDataInicial,
                  )} ${Language[languageStorage].ateHorario} ${handleMaskDate(
                    searchDataFinal,
                  )}`,
                );
                setModalDetalhesAmbiente(!modalDetalhesAmbiente);
              }
            })
            .catch(() => {
              setLoading(false);
              addToast({
                type: 'error',
                title: Language[languageStorage].Erro,
                description:
                  Language[languageStorage][
                    'Erro ao carregar dados, por favor atualize a página'
                  ],
              });
            });
        } else {
          setLoading(false);
          addToast({
            type: 'info',
            title: Language[languageStorage]['Filtro inválido'],
            description:
              Language[languageStorage][
                'Intervalo de datas inválidos, favor verificar'
              ],
          });
        }
      } else {
        setDadosHistoricoModal([]);

        setLoading(true);
        api
          .get(
            `/dash/user?userId=${dado.id}&dataInicio=${dataInicio}&dataFim=${dataFim}`,
          )
          .then(data => {
            setLoading(false);
            setDadosHistoricoModal(data.data.data);
            setDadosHistoricoObsModal(data.data.obs);
            setDadosHistoricoDatasModal(
              `${Language[languageStorage].DeHorario} ${handleMaskDate(
                dataInicio,
              )} ${Language[languageStorage].ateHorario} ${handleMaskDate(
                dataFim,
              )}`,
            );
            setModalDetalhesAmbiente(!modalDetalhesAmbiente);
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: Language[languageStorage].Erro,
              description:
                Language[languageStorage][
                  'Erro ao carregar dados, por favor atualize a página'
                ],
            });
          });
      }
    },
    [
      searchDataInicial,
      searchDataFinal,
      languageStorage,
      addToast,
      handleMaskDate,
      modalDetalhesAmbiente,
      dataInicio,
      dataFim,
    ],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [search, searchDataInicial, searchDataFinal]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response.length ? response : [];

    if (filialId !== -1) {
      computedResponses = computedResponses.filter(
        i => filialId === i.companyId,
      );
    }

    if (search) {
      computedResponses = computedResponses.filter((res: ResponseGet) => {
        const dataFilter: any = [];

        dataFilterModal.forEach(filtro => {
          if (filtro.status) {
            switch (filtro.name) {
              case Language[languageStorage].Colaborador:
                dataFilter.push(res.nome);
                break;
              case Language[languageStorage].CPF:
                dataFilter.push(res.cpf);
                break;
              case Language[languageStorage]['E-mail']:
                dataFilter.push(res.email);
                break;
              case Language[languageStorage].Telefone:
                dataFilter.push(res.telefone);
                break;
              default:
                break;
            }
          }
        });

        return dataFilter.some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        );
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    filialId,
    search,
    ITEMS_PER_PAGE,
    currentPage,
    dataFilterModal,
    languageStorage,
  ]);

  return (
    <>
      <ModalConexao />

      <ModalFilter
        isOpen={showModalFilter}
        type="dashColaboradores"
        onRequestClose={() => setShowModalFilter(false)}
        onReturnData={e => setDataFilterModal(e)}
      />

      {!!dadosModal && (
        <ModalUserDetails
          isOpen={!modalDetalhesUser}
          dados={dadosModal}
          onRequestClose={() => setModalDetalhesUser(true)}
        />
      )}

      {!!dadosModal &&
        !!dadosHistoricoModal &&
        !!dadosHistoricoObsModal &&
        !!dadosHistoricoDatasModal && (
          <ModalHistoricoColaborador
            isOpen={!modalDetalhesAmbiente}
            dados={dadosModal}
            dadosHistorico={dadosHistoricoModal}
            dadosHistoricoObs={dadosHistoricoObsModal}
            dadosHistoricoDatas={dadosHistoricoDatasModal}
            onRequestClose={() => {
              setModalDetalhesAmbiente(true);
            }}
          />
        )}

      <Container>
        <ContainerTitle>
          <h1>{Language[languageStorage].Colaboradores}</h1>

          <Tooltip
            title={`${Language[languageStorage]['Nessa página você tem acesso a visualização detalhada do histórico de atividades realizadas pelos ASGs. Filtragem a partir dos colaboradores']}.`}
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </ContainerTitle>

        <ContainerSelect>
          <select
            value={filialId}
            onChange={event => {
              const val = parseInt(event.target.value, 10);
              setFilialId(val);
            }}
          >
            <option value={-1}>{Language[languageStorage].Todos}</option>
            {filiais.map(i => (
              <option key={i.ID} value={i.ID}>
                {i.NOME}
              </option>
            ))}
          </select>
        </ContainerSelect>

        <ContainerSearch isFilterDateInterval={dataFilterModal[4].status}>
          {!dataFilterModal[4].status ? (
            <Search
              onSearch={(value: string) => {
                setSearch(value);
              }}
              nomePlaceHolder={Language[languageStorage].Buscar}
            />
          ) : (
            <>
              <Search
                onSearch={(value: string) => {
                  setSearchDataInicial(value);
                }}
                nomePlaceHolder={
                  Language[languageStorage]['Buscar data inicial']
                }
                type="date"
              />

              <Search
                onSearch={(value: string) => {
                  setSearchDataFinal(value);
                }}
                nomePlaceHolder={Language[languageStorage]['Buscar data final']}
                type="date"
              />
            </>
          )}

          <button type="button" onClick={() => setShowModalFilter(true)}>
            <img src={IconFilter} alt="iconFilter" />
          </button>
        </ContainerSearch>

        {response.length === 0 && (
          <ContainerSemResultados>
            <h2>
              {`${Language[languageStorage]['Não existe um histórico de colaboradores para o intervalo de datas atual']}.`}
            </h2>
          </ContainerSemResultados>
        )}

        <ContainerListItens>
          <>
            {responseData.map((res, index) => (
              <ContainerItem
                key={`${res.id}-${res.createdAt}/Colaboradores`}
                isColor={index % 2 !== 0}
              >
                <button
                  type="button"
                  onClick={() => {
                    setModalDetalhesUser(false);
                    setDadosModal(res);
                  }}
                >
                  <img
                    src={res.avatarURL === null ? imgAvatar : res.avatarURL}
                    alt={res.nome}
                  />
                </button>

                <button
                  type="button"
                  onClick={() => {
                    setDadosModal(res);
                    requestHistoricoUsuario(res);
                  }}
                >
                  <ul>
                    <li>
                      <strong>{Language[languageStorage].Nome}</strong>
                      <span>{res.nome}</span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage].CPF}</strong>
                      <span>{maskCpf(res.cpf)}</span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage]['E-mail']}</strong>
                      <span>{res.email}</span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage].Telefone}</strong>
                      <span>{maskTel(res.telefone)}</span>
                    </li>
                  </ul>
                </button>

                <IoIosArrowForward />
              </ContainerItem>
            ))}

            <table id="tableRelatorioColaboradores" style={{ display: 'none' }}>
              <HeaderTable headers={headers} />
              <tbody>
                {responseData.map(res => (
                  <tr key={`${res.id}-${res.createdAt}/Colaboradores`}>
                    <td>{res.nome}</td>
                    <td>{maskCpf(res.cpf)}</td>
                    <td>{res.email}</td>
                    <td>{maskTel(res.telefone)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        </ContainerListItens>

        <ContainerPagination>
          {response.length > 0 && (
            <>
              <div className="divPaginacao">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
                <DropDownPagination
                  onChangeItems={(value: string) => {
                    setCurrentPage(1);
                    if (value === Language[languageStorage].Todos) {
                      setITEMS_PER_PAGE(1);
                    } else {
                      setITEMS_PER_PAGE(Number(value));
                    }
                  }}
                  objetoEnum={dropDown}
                  minWidth={30}
                />
              </div>

              <ReactHTMLTableToExcel
                id="export-excel"
                className="btn"
                table="tableRelatorioColaboradores"
                filename={`${
                  Language[languageStorage]['relatorio-colaboradores'] +
                  moment().format('DD-MM-YYYY')
                }`}
                sheet="tablexls"
                buttonText={Language[languageStorage]['Exportar Excel']}
              />
            </>
          )}
        </ContainerPagination>
      </Container>

      {loading && <Loading />}
    </>
  );
};

export default DashboardColaboradores;
