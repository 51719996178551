import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  max-height: calc(100vh - 90px);
  padding: 30px 40px 20px 40px;
  overflow: auto;

  a {
    background: ${props => props.theme.colors.whitePrimary};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 15px;
    height: 50px;
    max-width: 350px;
    margin: 20px 0;

    @media screen and (max-width: 1400px) {
      margin: 20px 0;
    }

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: ${props => props.theme.colors.bluePrimary};

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 12px;
    }

    transition: background 0.2s;

    &:hover {
      background: ${props => shade(0.1, props.theme.colors.whitePrimary)};
    }
  }
`;

export const ContainerFilter = styled.div`
  margin-bottom: 48px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 12px;
    }
  }

  form {
    div {
      width: 173px;
    }
  }
`;

export const ContainerTitle = styled.div`
  margin-bottom: 20px;

  @media screen and (max-width: 1400px) {
    margin-bottom: 20px;
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: ${props => props.theme.colors.textBlack};
  }

  span {
    margin-left: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      height: 20px;
      color: ${props => props.theme.colors.bluePrimary};
    }
  }
`;
