import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { Loading } from '../../../components/Loading';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import { InputOverview } from '../../../components/InputOverview';
import Button from '../../../components/Button';
import ModalConexao from '../../../components/ModalConexao';
import AlertEmail from '../../../components/AlertEmail';
import CreditsSpan from '../../../components/Credits';
import Language from '../../../language/language.json';

import { Container, Content, AnimationContainer, Background } from './styles';
import { useAuth } from '../../../hooks/auth';
import { TappImage } from '../../../language/interface';

interface SignUpFormData {
  nome: string;
  email: string;
  emailConfirmation: string;
  cpf: string;
  telefone: string;
  password: string;
  dataNascimento: string;
}

const SignUp: React.FC = () => {
  const { languageStorage } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  function formatarCPF(str: string): string {
    const CPF: string = str.replace('.', '').replace('.', '').replace('-', '');
    return CPF;
  }

  function formatarTelefone(str: string): string {
    const telefone: string = str
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '')
      .replace(' ', '');
    return telefone;
  }

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required(
            Language[languageStorage]['Nome obrigatório'],
          ),
          email: Yup.string()
            .required(Language[languageStorage]['E-mail obrigatório'])
            .email(Language[languageStorage]['Digite um email válido']),
          emailConfirmation: Yup.string().oneOf(
            [Yup.ref('email'), null],
            Language[languageStorage]['E-mails precisam ser iguais'],
          ),
          cpf: Yup.string().required(
            Language[languageStorage]['CPF obrigatório'],
          ),
          dataNascimento: Yup.string().required(
            Language[languageStorage]['Data de nascimento obrigatória'],
          ),
          telefone: Yup.string().required(
            Language[languageStorage]['Telefone obrigatório'],
          ),
          password: Yup.string().min(
            6,
            Language[languageStorage]['No mínimo 6 dígitos'],
          ),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            Language[languageStorage]['Senhas precisam ser iguais'],
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { nome, emailConfirmation, dataNascimento, password } = data;
        const cpf = formatarCPF(data.cpf);
        const telefone = formatarTelefone(data.telefone);

        await api
          .post('/users', {
            nome,
            email,
            emailConfirmation,
            password,
            dataNascimento,
            cpf,
            telefone,
          })
          .then(() => {
            setLoading(false);
            addToast({
              type: 'success',
              title: `${Language[languageStorage]['Cadastro realizado']}!`,
              description: `${Language[languageStorage]['Você já pode fazer seu login']}.`,
            });
            history.push('/');
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: `${Language[languageStorage]['Erro ao cadastrar']}!`,
              description: `${Language[languageStorage]['Verifique os dados']}!`,
            });
          });
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: Language[languageStorage]['Erro no Cadastro'],
          description: `${Language[languageStorage]['Ocorreu um erro ao fazer o cadastro, Por favor verifique os campos']}.`,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, email, history, languageStorage],
  );

  return (
    <>
      <ModalConexao />
      <Container>
        <Background>
          <img
            src={TappImage(languageStorage)}
            className="logo"
            alt="ImgLogo"
          />
        </Background>

        <Content>
          <AnimationContainer>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>{Language[languageStorage].Cadastro}</h1>

              <p>{Language[languageStorage]['Nome completo']}</p>
              <InputOverview name="nome" placeholder="João Oliveira" />

              <p>{Language[languageStorage]['E-mail']}</p>
              <InputOverview
                name="email"
                placeholder="joaooliveira@gmail.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <AlertEmail email={email} />

              <p>{Language[languageStorage]['Confirmação de e-mail']}</p>
              <InputOverview
                name="emailConfirmation"
                placeholder="joaooliveira@gmail.com"
              />

              <p>{Language[languageStorage].CPF}</p>
              <InputOverview
                name="cpf"
                placeholder="000.000.000-00"
                mask="cpf"
              />

              <p>{Language[languageStorage]['Data de nascimento']}</p>
              <InputOverview
                name="dataNascimento"
                placeholder="DD/MM/AAAA"
                mask="dateOfBirth"
              />

              <p>{Language[languageStorage].Telefone}</p>
              <InputOverview
                name="telefone"
                placeholder="(00) 0 0000-0000"
                mask="phone"
              />

              <p>{Language[languageStorage].Senha}</p>
              <InputOverview name="password" placeholder="********" />

              <p>{Language[languageStorage]['Confirmar senha']}</p>
              <InputOverview name="confirmPassword" placeholder="********" />

              <article>
                <Button type="submit">
                  {Language[languageStorage].Cadastrar}
                </Button>

                <hr />

                <Link to="/">
                  {Language[languageStorage]['Voltar ao início']}
                </Link>
              </article>
            </Form>
          </AnimationContainer>

          <CreditsSpan />
        </Content>
      </Container>

      {loading && <Loading />}
    </>
  );
};

export default SignUp;
