/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import Modal from 'react-modal';
import jsPDF from 'jspdf';

import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
import { animated } from '@react-spring/web';
import * as XLSX from 'xlsx';

import Select from 'react-select';
import { Tooltip, useMediaQuery, Zoom } from '@material-ui/core';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FaCalendar, FaFont, FaSearch, FaTimes } from 'react-icons/fa';
import { FiInfo } from 'react-icons/fi';
import moment from 'moment';
import { RiFileExcel2Line } from 'react-icons/ri';
import { VscFilePdf } from 'react-icons/vsc';
import { useAuth } from '../../hooks/auth';
import Language from '../../language/language.json';
import { Loading } from '../../components/Loading';
import Button from '../../components/Button';
import { useToast } from '../../hooks/toast';
import Search from '../../components/Search';

import {
  ContainerModal,
  Container,
  ButtonFiltro,
  ContainerTitle,
  SemDados,
  HeaderDate,
} from './styles';
import api from '../../services/api';
import { EmpresasInterface } from '../Cadastros/Periodicidade';
import { AmbienteGet } from '../../models/Ambiente';
import { Categoria } from '../../models/Categoria';
import { Setor } from '../../models/Setor';
import { ResponseGetUsuario } from '../Cadastros/Agendamentos';
import IconFilter from '../../assets/IconFilter.svg';
import { ContainerSearch } from '../Inicio/DashboardHistAgenda/styles';
import { Procedurev2 } from '../../models/Procedures';

interface SelectProps {
  label: string;
  value: number | string;
}
interface SignUpFormData {
  empresas: any;
  ambientes: any;
  setores: any;
  categorias: any;
  colaboradores: any;
  procedimentos: any;
}

export interface CategoriasInterface {
  ID_CATEGORIA: number;
  DESCRICAO: string;
  EMPRESA_ID: number;
}

export interface ProcedimentoInterface {
  ID_PROCEDIMENTO: number;
  NAME_PROCEDIMENTO: string;
  EMPRESA_ID: number;
}

export interface SetoresInterface {
  id: number;
  name: string;
  EMPRESA_ID: number;
}

interface UserProps {
  id: number;
  nome: string;
  email: string;
  dataNascimento: string;
  cpf: string;
  telefone: string;
  companyId: number;
  ativo: number;
  coordenador: number;
  avatar: any;
  tipo: string;
  createdAt: string;
  updatedAt: string;
}
interface EmpresaProps {
  ID: number;
  NOME: string;
  Id_Empresas: number;
  ENDERECO: string;
  CNPJ: string;
  NUMERO: string;
  ATIVO: boolean;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: any;
}

interface AgendaProps {
  ID: number;
  Id_Environments: number;
  Id_Periodicidade: number;
  Id_Users: number;
  Id_EmpresasFilial: number;
  FINALIZADO: any;
  DATA_FINALIZACAO: any;
  ATIVO: boolean;
  revisionPhotos: null | boolean;
  vigIni: string;
  vigFim: string;
  Empresa?: EmpresaProps;
  Environment: {
    id: number;
    name: string;
    categoriaId: number;
    companyId: number;
    setorId: number;
    diasLimpezaId: any;
    qtdLimpezaDia: any;
    andar: string;
    createdAt: string;
    updatedAt: string;
    Revisao: any;
    HORA_INICIAL: any;
    HORA_FINAL: any;
    IMAGEM: any;
    Categoria: {
      id: number;
      name: string;
      companyId: number;
      createdAt: string;
      updatedAt: string;
    };
    Procedures: {
      id: number;
      name: string;
      companyId: number;
      image: any;
      imageUrl: any;
      createdAt: string;
      updatedAt: string;
    }[];
    proceduresOptions: {
      environmentId: number;
      procedureId: number;
      mandatory: boolean;
    }[];
    Setor: {
      id: number;
      name: string;
      companyId: number;
      createdAt: string;
      updatedAt: string;
    };
  };
  Periodicidade: {
    ID: number;
    NOME: string;
    DIAS_SEMANA: string;
    QTD_DIAS: number;
    HORA_BASE_INICIAL: string;
    HORA_BASE_FINAL: string;
    Id_EmpresasFilial: number;
    ATIVO: boolean;
    DATA_CRIACAO: string;
    Revisoes: {
      ID: number;
      Id_Periodicidades: number;
      HORA_INICIAL: string;
      HORA_FINAL: string;
      Id_EmpresasFilial: number;
      ATIVO: boolean;
      DATA_CRIACAO: string;
    }[];
  };
}

interface AgendamentoProps {
  id: number;
  companyId: number;
  agendaId: number;
  userId: number;
  dataAgend: string;
  horaIni: string;
  horaFim: string;
  status: string | null;
  viraNoite: boolean;
  active: boolean;
  justificativa: any;
  startDate: string;
  createdAt: string;
  updatedAt: string;
  Agenda: AgendaProps;
  User: UserProps;
  Empresa: EmpresaProps;
}

interface ProcedimentoProps {
  id: number;
  name: string;
  companyId: number;
  image: any;
  imageUrl: any;
  createdAt: string;
  updatedAt: string;
}

interface DadosGraficos {
  id: number;
  userId: number;
  agendamentoId: number;
  tasksId: number;
  image: string;
  createdAt: string;
  updatedAt: string;
  imagem_pre_limpeza: any;
  revisionId: any;
  ocorrencia: any;
  observacao: string | null;
  User: UserProps;
  Agendamento: AgendamentoProps;
  Procedure: ProcedimentoProps;
  Revisao: any;
}

interface DadosGraficos2 {
  id: number;
  companyId: number;
  agendaId: number;
  userId: number;
  Agenda: AgendaProps;
  User: UserProps;
  Empresa: EmpresaProps;
  dataAgend: string;
  horaIni: string;
  horaFim: string;
  status: string | null;
  viraNoite: boolean;
  active: boolean;
  justificativa: string | null;
  startDate: string | null;
  createdAt: string;
  updatedAt: string;
}

interface DadosGraficosFuncionariosEmAberto {
  id: string;
  value: number | 0;
  companyId: number;
  userId: number;
  Agenda?: AgendaProps[];
  Procedure?: Procedurev2[];
  status?: string | null;
}

export const Auditoria: React.FC = () => {
  const { empresaPrincipal, languageStorage } = useAuth();
  const { addToast } = useToast();
  const isDesktop = useMediaQuery('(min-width:1150px) and (max-width:1500px)');

  const [modalOpen, setModalOpen] = useState(false);
  const [filtroPorData, setFiltroPorData] = useState(false);

  const [loadingColaboradores, setLoadingColaboradores] = useState(false);
  const [loadingAmbientes, setLoadingAmbientes] = useState(false);
  const [loadingDados, setLoadingDados] = useState(false);
  const [loadingDadosGerais, setLoadingDadosGerais] = useState(false);
  const [loadingDados2, setLoadingDados2] = useState(false);
  const [loadingEmpresas, setLoadingEmpresas] = useState(false);

  const [empresas, setEmpresas] = useState<any>();
  const [ambientes, setAmbientes] = useState<any>();
  const [categorias, setCategorias] = useState<any>();
  const [procedimentos, setProcedimentos] = useState<any>();
  const [setores, setSetores] = useState<any>();
  const [colaboradores, setColaboradores] = useState<any>();

  const [ambientesHook, setAmbientesHook] = useState<any>();
  const [categoriasHook, setCategoriasHook] = useState<any>();
  const [setoresHook, setSetoresHook] = useState<any>();
  const [procedimentosHook, setProcedimentosHook] = useState<any>();
  const [colaboradoresHook, setColaboradoresHook] = useState<any>();

  // Form

  const [empresasForm, setEmpresasForm] = useState<any | null>(null);
  const [ambientesForm, setAmbientesForm] = useState<any | null>(null);
  const [categoriasForm, setCategoriasForm] = useState<any | null>(null);
  const [procedimentosForm, setProcedimentosForm] = useState<any | null>(null);
  const [setoresForm, setSetoresForm] = useState<any | null>(null);
  const [colaboradoresForm, setColaboradoresForm] = useState<any | null>(null);

  const [searchDataInicial, setSearchDataInicial] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD'),
  );
  const [searchDataFinal, setSearchDataFinal] = useState(
    moment().format('YYYY-MM-DD'),
  );

  // Graficos

  const [fontSizeGraph, setFontSizeGraph] = useState(11);
  const [primeiroGetDados, setPrimeiroGetDados] = useState(false);
  const [dadosGraficos1, setDadosGraficos1] = useState<DadosGraficos[]>();
  const [dadosGraficos2, setDadosGraficos2] = useState<DadosGraficos2[]>();

  const [dadosAgendamentosStatus, setDadosAgendamentosStatus] = useState<
    any[]
  >();

  const [dadosFuncionariosEmAberto, setDadosFuncionariosEmAberto] = useState<
    DadosGraficosFuncionariosEmAberto[]
  >();

  const [dadosCPNJEmAberto, setDadosCPNJEmAberto] = useState<any[]>();

  const [dadosDemandaMaisTempo, setDadosDemandaMaisTempo] = useState<any[]>();

  const [dadosAtividadeMedia, setDadosAtividadeMedia] = useState<any[]>();

  const [dadosPrevistoXRealizado, setDadosPrevistoXRealizado] = useState<
    any[]
  >();

  useEffect(() => {
    document.querySelectorAll('text').forEach(a => {
      // eslint-disable-next-line no-param-reassign
      a.style.fontSize = `${fontSizeGraph}px`;
    });
  }, [fontSizeGraph]);

  const renderMouth = useCallback(
    (mouth: number): string => {
      switch (mouth) {
        case 1:
          return Language[languageStorage].Calendario.Janeiro;
        case 2:
          return Language[languageStorage].Calendario.Fevereiro;
        case 3:
          return Language[languageStorage].Calendario.Marco;
        case 4:
          return Language[languageStorage].Calendario.Abril;
        case 5:
          return Language[languageStorage].Calendario.Maio;
        case 6:
          return Language[languageStorage].Calendario.Junho;
        case 7:
          return Language[languageStorage].Calendario.Julho;
        case 8:
          return Language[languageStorage].Calendario.Agosto;
        case 9:
          return Language[languageStorage].Calendario.Setembro;
        case 10:
          return Language[languageStorage].Calendario.Outubro;
        case 11:
          return Language[languageStorage].Calendario.Novembro;
        case 12:
          return Language[languageStorage].Calendario.Dezembro;
        default:
          return '';
      }
    },
    [languageStorage],
  );

  const dateRender = useMemo(() => {
    const [yearStart, mouthStart, dayStart] = searchDataInicial.split('-');
    const [yearEnd, mouthEnd, dayEnd] = searchDataFinal.split('-');
    if (searchDataInicial === searchDataFinal)
      return `${dayStart} de ${renderMouth(
        Number(mouthStart),
      )} de ${yearStart}`;
    if (yearStart === yearEnd && mouthStart === mouthEnd) {
      return `${dayStart} a ${dayEnd} de ${renderMouth(
        Number(mouthStart),
      )} de ${yearStart}`;
    }
    if (yearStart === yearEnd && mouthStart !== mouthEnd) {
      return `${dayStart} de ${renderMouth(
        Number(mouthStart),
      )} a ${dayEnd} de ${renderMouth(Number(mouthEnd))} de ${yearStart}`;
    }
    return `${dayStart} de ${renderMouth(
      Number(mouthStart),
    )} de ${yearStart} a ${dayEnd} de ${renderMouth(
      Number(mouthEnd),
    )} de ${yearEnd}`;
  }, [renderMouth, searchDataFinal, searchDataInicial]);

  const resetDadosAgendamentoStatus = useCallback(() => {
    const agedamentosStatus = dadosGraficos1?.reduce(
      (groups: any, items) => ({
        ...groups,
        [items.Agendamento.status || 'A fazer']: [
          ...(groups[items.Agendamento.status || 'A fazer'] || []),
          items,
        ],
      }),
      {},
    );

    const dadosTemp = [
      {
        id: 'A fazer',
        value: agedamentosStatus
          ? agedamentosStatus['A fazer']?.length || 0
          : 0,
      },
      {
        id: 'Andamento',
        value: agedamentosStatus?.Andamento?.length || 0,
      },
      {
        id: 'Completo',
        value: agedamentosStatus?.Completo?.length || 0,
      },
      {
        id: 'Incompleto',
        value: agedamentosStatus?.Incompleto?.length || 0,
      },
    ];
    setDadosAgendamentosStatus(
      dadosTemp.sort((a, b) => b.value - a.value).filter(a => a.value !== 0),
    );
  }, [dadosGraficos1]);

  useEffect(() => {
    if (dadosGraficos1 && dadosGraficos1?.length > 0)
      resetDadosAgendamentoStatus();
  }, [dadosGraficos1, resetDadosAgendamentoStatus]);

  const resetDadosFuncionarios = useCallback(() => {
    const funcionariosEmAberto = dadosGraficos2?.reduce(
      (groups: any, items) => ({
        ...groups,
        [items.User?.nome || 'Sem nome']: [
          ...(groups[items.User?.nome || 'Sem nome'] || []),
          items,
        ],
      }),
      {},
    );
    const dadosTemp: any[] = [];

    Object.keys(funcionariosEmAberto)?.forEach(field => {
      dadosTemp.push({
        id: field,
        value:
          funcionariosEmAberto[field].filter(
            (val: DadosGraficos2) => val.status !== 'Completo',
          ).length || 0,
        companyId: funcionariosEmAberto[field][0]?.companyId,
        userId: funcionariosEmAberto[field][0]?.userId,
      });
    });
    setDadosFuncionariosEmAberto(
      dadosTemp
        .sort((a, b) => b.value - a.value)
        .filter(a => a.value !== 0)
        .slice(0, 10),
    );
  }, [dadosGraficos2]);

  useEffect(() => {
    if (dadosGraficos2 && dadosGraficos2?.length > 0) resetDadosFuncionarios();
  }, [dadosGraficos2, resetDadosFuncionarios]);

  const resetDadosCNPJ = useCallback(() => {
    const cnpjEmAberto = dadosGraficos2?.reduce(
      (groups: any, items) => ({
        ...groups,
        [items.Empresa.NOME]: [...(groups[items.Empresa.NOME] || []), items],
      }),
      {},
    );
    const dadosTemp: any[] = [];

    Object.keys(cnpjEmAberto)?.forEach(field => {
      dadosTemp.push({
        id: field,
        aFazer:
          cnpjEmAberto[field].filter(
            (val: DadosGraficos2) => val.status === null,
          ).length || 0,
        andamento:
          cnpjEmAberto[field].filter(
            (val: DadosGraficos2) => val.status === 'Andamento',
          ).length || 0,
        incompleto:
          cnpjEmAberto[field].filter(
            (val: DadosGraficos2) => val.status === 'Incompleto',
          ).length || 0,
        companyId: cnpjEmAberto[field][0]?.companyId,
      });
    });
    setDadosCPNJEmAberto(dadosTemp);
  }, [dadosGraficos2]);

  useEffect(() => {
    if (dadosGraficos2 && dadosGraficos2?.length > 0) resetDadosCNPJ();
  }, [dadosGraficos2, resetDadosCNPJ]);

  const differenceTime = useCallback((a: string, b: string): number => {
    const toSeconds = (time_str: string): number => {
      const parts = time_str.split(':');
      return (
        parseInt(parts[0], 10) * 3600 +
        parseInt(parts[1], 10) * 60 +
        +parseInt(parts[2], 10)
      );
    };
    const tempDifference = Math.abs(
      toSeconds(`${a}:00`) - toSeconds(`${b}:00`),
    );
    const tempResult = [
      Math.floor(tempDifference / 3600),
      Math.floor((tempDifference % 3600) / 60),
      tempDifference % 60,
    ];
    const result = tempResult
      .map(v => {
        return v < 10 ? `0${v}` : v;
      })
      .join(':');
    const partResult = result.split(':');

    const finalResult =
      parseInt(partResult[0], 10) * 60 + parseInt(partResult[1], 10);
    return finalResult;
  }, []);

  const resetDadosDemandaMaisTempo = useCallback(() => {
    const dadosDemandaMT = dadosGraficos1?.reduce(
      (groups: any, items) => ({
        ...groups,
        [items.Agendamento.Agenda.Environment.name]: [
          ...(groups[items.Agendamento.Agenda.Environment.name] || []),
          items,
        ],
      }),
      {},
    );
    const dadosTemp: any[] = [];
    let demandaTemp: any[] = [];
    Object.keys(dadosDemandaMT)?.forEach(field => {
      demandaTemp = dadosDemandaMT[field].filter(
        (val: DadosGraficos) => val.Agendamento.status === 'Completo',
      );
      dadosTemp.push({
        id: field,
        value: demandaTemp.map((val: DadosGraficos) =>
          differenceTime(
            val.createdAt.slice(11, 16),
            val.updatedAt.slice(11, 16),
          ),
        ),
      });
    });
    setDadosDemandaMaisTempo(
      dadosTemp
        .map((dados: any) => ({
          ...dados,
          value:
            dados.value.length > 0
              ? dados.value.reduce((a: number, b: number) => Math.max(a, b), 0)
              : 0,
        }))
        .sort((a, b) => b.value - a.value)
        .filter(a => a.value !== 0)
        .slice(0, 10),
    );
    setDadosAtividadeMedia(
      dadosTemp
        .map((dados: any) => ({
          ...dados,
          value:
            dados.value.length > 0
              ? Math.round(
                  ((dados.value.reduce((a: number, b: number) => a + b, 0) /
                    dados.value.length) *
                    100) /
                    100,
                ).toFixed(0)
              : 0,
        }))
        .sort((a, b) => b.value - a.value)
        .filter(a => a.value !== 0 && a.value !== '0')
        .slice(0, 10),
    );
  }, [dadosGraficos1, differenceTime]);

  useEffect(() => {
    if (dadosGraficos1 && dadosGraficos1?.length > 0)
      resetDadosDemandaMaisTempo();
  }, [dadosGraficos1, resetDadosDemandaMaisTempo]);

  const resetDadosPrevistoXRealizado = useCallback(() => {
    const previstoXRealizado = dadosGraficos1?.reduce(
      (groups: any, items) => ({
        ...groups,
        [items.User?.nome || 'Sem nome']: [
          ...(groups[items.User?.nome || 'Sem nome'] || []),
          items,
        ],
      }),
      {},
    );
    const dadosTemp: any[] = [];

    Object.keys(previstoXRealizado)?.forEach(field => {
      const dadosTp = previstoXRealizado[field].filter(
        (value: any, index: number, self: any) =>
          index ===
          self.findIndex((t: any) => t.agendamentoId === value.agendamentoId),
      );
      dadosTemp.push({
        id: field,
        previsto: dadosTp.map((val: DadosGraficos) =>
          differenceTime(val.Agendamento.horaIni, val.Agendamento.horaFim),
        ),
        realizado: dadosTp.map((val: DadosGraficos) =>
          differenceTime(
            val.createdAt.slice(11, 16),
            val.updatedAt.slice(11, 16),
          ),
        ),
      });
    });
    let previstoTp;
    let realizadoTp;
    setDadosPrevistoXRealizado(
      dadosTemp
        .map((dados: any) => {
          previstoTp =
            dados.previsto.length > 0
              ? dados.previsto.reduce((a: number, b: number) => a + b, 0) / 60
              : 0;
          realizadoTp =
            dados.realizado.length > 0
              ? dados.realizado.reduce((a: number, b: number) => a + b, 0) / 60
              : 0;
          return {
            ...dados,
            previsto: previstoTp,
            realizado: realizadoTp,
          };
        })
        .sort(
          (a, b) =>
            Math.abs(b.previsto - b.realizado) -
            Math.abs(a.previsto - a.realizado),
        )
        .filter(a => a.previsto !== 0 && a.realizado !== 0)
        .slice(0, 8),
    );
  }, [dadosGraficos1, differenceTime]);

  useEffect(() => {
    if (dadosGraficos1 && dadosGraficos1?.length > 0)
      resetDadosPrevistoXRealizado();
  }, [dadosGraficos1, resetDadosPrevistoXRealizado]);

  const agedamentosStatus = dadosGraficos1?.reduce(
    (groups: any, items) => ({
      ...groups,
      [items.Agendamento.status || 'A fazer']: [
        ...(groups[items.Agendamento.status || 'A fazer'] || []),
        items,
      ],
    }),
    {},
  );
  const filterDadosAgendamentoStatus = (data: SignUpFormData): void => {
    let dadosTemp: any[] = [];
    dadosTemp = [
      agedamentosStatus['A fazer']
        ? {
            id: 'A fazer',
            value: agedamentosStatus['A fazer']?.length || 0,
            Agendamento: agedamentosStatus['A fazer'].map(
              ({ Agendamento }: DadosGraficos) => Agendamento,
            ),
            Procedure: agedamentosStatus['A fazer'].map(
              ({ Procedure }: DadosGraficos) => Procedure,
            ),
          }
        : undefined,
      agedamentosStatus?.Andamento
        ? {
            id: 'Andamento',
            value: agedamentosStatus?.Andamento?.length || 0,
            Agendamento: agedamentosStatus?.Andamento.map(
              ({ Agendamento }: DadosGraficos) => Agendamento,
            ),
            Procedure: agedamentosStatus?.Andamento.map(
              ({ Procedure }: DadosGraficos) => Procedure,
            ),
          }
        : undefined,
      agedamentosStatus?.Completo
        ? {
            id: 'Completo',
            value: agedamentosStatus?.Completo?.length || 0,
            Agendamento: agedamentosStatus?.Completo.map(
              ({ Agendamento }: DadosGraficos) => Agendamento,
            ),
            Procedure: agedamentosStatus?.Completo.map(
              ({ Procedure }: DadosGraficos) => Procedure,
            ),
          }
        : undefined,
      agedamentosStatus?.Incompleto
        ? {
            id: 'Incompleto',
            value: agedamentosStatus?.Incompleto?.length || 0,
            Agendamento: agedamentosStatus?.Incompleto.map(
              ({ Agendamento }: DadosGraficos) => Agendamento,
            ),
            Procedure: agedamentosStatus?.Incompleto.map(
              ({ Procedure }: DadosGraficos) => Procedure,
            ),
          }
        : undefined,
    ].filter(t => t);
    Object.keys(data).forEach(dataElemen => {
      if (dataElemen === 'empresas' && data.empresas?.length > 0)
        dadosTemp = dadosTemp.filter(
          val =>
            val.Agendamento?.filter((v: AgendamentoProps) =>
              data.empresas
                ?.map(({ value }: SelectProps) => value)
                .includes(v.companyId),
            ).length || 0,
        );
      else if (dataElemen === 'colaboradores' && data.colaboradores?.length > 0)
        dadosTemp = dadosTemp.filter(
          val =>
            val.Agendamento?.filter((v: AgendamentoProps) =>
              data.colaboradores
                ?.map(({ value }: SelectProps) => value)
                .includes(v.userId),
            ).length || 0,
        );
      else if (dataElemen === 'ambientes' && data.ambientes?.length > 0) {
        dadosTemp = dadosTemp.map(val => ({
          ...val,
          value:
            val.Agendamento?.filter((v: AgendamentoProps) =>
              data.ambientes
                ?.map(({ value }: SelectProps) => value)
                .includes(v.Agenda.Environment.id),
            ).length || 0,
        }));
      } else if (dataElemen === 'categorias' && data.categorias?.length > 0)
        dadosTemp = dadosTemp.map(val => ({
          ...val,
          value:
            val.Agendamento?.filter((v: AgendamentoProps) =>
              data.categorias
                ?.map(({ value }: SelectProps) => value)
                .includes(v.Agenda.Environment.Categoria.id),
            ).length || 0,
        }));
      else if (dataElemen === 'setores' && data.setores?.length > 0)
        dadosTemp = dadosTemp.map(val => ({
          ...val,
          value:
            val.Agendamento?.filter((v: AgendamentoProps) =>
              data.setores
                ?.map(({ value }: SelectProps) => value)
                .includes(v.Agenda.Environment.Setor.id),
            ).length || 0,
        }));
      else if (dataElemen === 'procedimentos' && data.procedimentos?.length > 0)
        dadosTemp = dadosTemp.map(val => ({
          ...val,
          value:
            val.Agendamento?.filter(() =>
              val.Procedure.map((i: any) => i.id).some((a: any) =>
                data.procedimentos
                  ?.map(({ value }: SelectProps) => value)
                  .includes(a),
              ),
            ).length || 0,
        }));
    });
    if (dadosTemp.length > 0) {
      dadosTemp = dadosTemp
        .sort((a, b) => b.value - a.value)
        .filter(a => a.value !== 0);
    }
    setDadosAgendamentosStatus(dadosTemp);
  };

  const dadosDemandaMT = dadosGraficos1?.reduce(
    (groups: any, items) => ({
      ...groups,
      [items.Agendamento.Agenda.Environment.name]: [
        ...(groups[items.Agendamento.Agenda.Environment.name] || []),
        items,
      ],
    }),
    {},
  );

  const filterDadosDemandaMaisTempo = (data: SignUpFormData): void => {
    let dadosTemp: any[] = [];

    let demandaTemp: any[] = [];

    Object.keys(dadosDemandaMT)?.forEach(field => {
      demandaTemp = dadosDemandaMT[field].filter(
        (val: DadosGraficos) => val.Agendamento.status === 'Completo',
      );
      dadosTemp.push({
        id: field,
        value: demandaTemp.map((val: DadosGraficos) =>
          differenceTime(
            val.createdAt.slice(11, 16),
            val.updatedAt.slice(11, 16),
          ),
        ),
        Agendamento: dadosDemandaMT[field].map(
          ({ Agendamento, createdAt, updatedAt }: DadosGraficos) => ({
            agendamento: Agendamento,
            dataIni: createdAt,
            dataFim: updatedAt,
          }),
        ),
        Procedure: dadosDemandaMT[field].map(
          ({ Procedure }: DadosGraficos) => Procedure,
        ),
      });
    });
    Object.keys(data).forEach(dataElemen => {
      if (dataElemen === 'empresas' && data.empresas?.length > 0)
        dadosTemp = dadosTemp.map(val => ({
          ...val,
          Agendamento: val.Agendamento?.filter((v: any) =>
            data.empresas
              ?.map(({ value }: SelectProps) => value)
              .includes(v.agendamento.companyId),
          ),
        }));
      else if (dataElemen === 'colaboradores' && data.colaboradores?.length > 0)
        dadosTemp = dadosTemp.map(val => ({
          ...val,
          Agendamento: val.Agendamento?.filter((v: any) =>
            data.empresas
              ?.map(({ value }: SelectProps) => value)
              .includes(v.agendamento.userId),
          ),
        }));
      else if (dataElemen === 'ambientes' && data.ambientes?.length > 0) {
        dadosTemp = dadosTemp.map(val => ({
          ...val,
          Agendamento: val.Agendamento?.filter((v: any) =>
            data.ambientes
              ?.map(({ value }: SelectProps) => value)
              .includes(v.agendamento.Agenda.Environment.id),
          ),
        }));
      } else if (dataElemen === 'categorias' && data.categorias?.length > 0)
        dadosTemp = dadosTemp.map(val => ({
          ...val,
          Agendamento: val.Agendamento?.filter((v: any) =>
            data.categorias
              ?.map(({ value }: SelectProps) => value)
              .includes(v.agendamento.Agenda.Environment.Categoria.id),
          ),
        }));
      else if (dataElemen === 'setores' && data.setores?.length > 0)
        dadosTemp = dadosTemp.map(val => ({
          ...val,
          Agendamento: val.Agendamento?.filter((v: any) =>
            data.setores
              ?.map(({ value }: SelectProps) => value)
              .includes(v.agendamento.Agenda.Environment.Setor.id),
          ),
        }));
      else if (dataElemen === 'procedimentos' && data.procedimentos?.length > 0)
        dadosTemp = dadosTemp.map(val => ({
          ...val,
          Agendamento: val.Agendamento?.filter(() =>
            val.Procedure.map((i: any) => i.id).some((a: any) =>
              data.procedimentos
                ?.map(({ value }: SelectProps) => value)
                .includes(a),
            ),
          ),
        }));
    });
    dadosTemp = dadosTemp.map(val => ({
      ...val,
      value: val.Agendamento?.map((agend: any) =>
        differenceTime(
          agend.dataIni.slice(11, 16),
          agend.dataFim.slice(11, 16),
        ),
      ),
    }));
    let atividadeMedia: any[] = [];
    let demandaMT: any[] = [];

    if (dadosTemp.length > 0) {
      demandaMT = dadosTemp
        .map((dados: any) => ({
          ...dados,
          value:
            dados.value.length > 0
              ? dados.value.reduce((a: number, b: number) => Math.max(a, b), 0)
              : 0,
        }))
        .sort((a, b) => b.value - a.value)
        .filter(a => a.value !== 0)
        .slice(0, 10);
      atividadeMedia = dadosTemp
        .map((dados: any) => ({
          ...dados,
          value:
            dados.value.length > 0
              ? Math.round(
                  ((dados.value.reduce((a: number, b: number) => a + b, 0) /
                    dados.value.length) *
                    100) /
                    100,
                ).toFixed(0)
              : 0,
        }))
        .sort((a, b) => b.value - a.value)
        .filter(a => a.value !== 0 && a.value !== '0')
        .slice(0, 10);
    }

    setDadosDemandaMaisTempo(demandaMT);
    setDadosAtividadeMedia(atividadeMedia);
  };
  const funcionariosEmAberto = dadosGraficos2?.reduce(
    (groups: any, items) => ({
      ...groups,
      [items.User?.nome]: [...(groups[items.User?.nome] || []), items],
    }),
    {},
  );
  const filterFuncionariosEmAberto = (data: SignUpFormData): void => {
    let tempDadosFunc: DadosGraficosFuncionariosEmAberto[] = [];
    Object.keys(funcionariosEmAberto)?.forEach(field => {
      tempDadosFunc.push({
        id: field,
        value:
          funcionariosEmAberto[field].filter(
            (val: DadosGraficos2) => val.status !== 'Completo',
          ).length || 0,
        companyId: funcionariosEmAberto[field][0]?.companyId,
        userId: funcionariosEmAberto[field][0]?.userId,
        Agenda: funcionariosEmAberto[
          field
        ].map(({ Agenda, status }: DadosGraficos2) => ({ ...Agenda, status })),
      });
    });
    Object.keys(data).forEach(dataElemen => {
      if (dataElemen === 'empresas' && data.empresas?.length > 0)
        tempDadosFunc = tempDadosFunc.map(val => ({
          ...val,
          value:
            val.Agenda?.filter((agend: any) =>
              data.empresas
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Id_EmpresasFilial),
            ).filter((agend: any) => agend.status !== 'Completo').length || 0,
        }));
      else if (dataElemen === 'colaboradores' && data.colaboradores?.length > 0)
        tempDadosFunc = tempDadosFunc.map(val => ({
          ...val,
          value:
            val.Agenda?.filter((agend: any) =>
              data.empresas
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Id_Users),
            ).filter((agend: any) => agend.status !== 'Completo').length || 0,
        }));
      else if (dataElemen === 'ambientes' && data.ambientes?.length > 0) {
        tempDadosFunc = tempDadosFunc.map(val => ({
          ...val,
          value:
            val.Agenda?.filter(v =>
              data.ambientes
                ?.map(({ value }: SelectProps) => value)
                .includes(v.Environment.id),
            ).filter((agend: any) => agend.status !== 'Completo').length || 0,
        }));
      } else if (dataElemen === 'categorias' && data.categorias?.length > 0)
        tempDadosFunc = tempDadosFunc.map(val => ({
          ...val,
          value:
            val.Agenda?.filter(v =>
              data.categorias
                ?.map(({ value }: SelectProps) => value)
                .includes(v.Environment.Categoria.id),
            ).filter((agend: any) => agend.status !== 'Completo').length || 0,
        }));
      else if (dataElemen === 'setores' && data.setores?.length > 0)
        tempDadosFunc = tempDadosFunc.map(val => ({
          ...val,
          value:
            val.Agenda?.filter(v =>
              data.setores
                ?.map(({ value }: SelectProps) => value)
                .includes(v.Environment.Setor.id),
            ).filter((agend: any) => agend.status !== 'Completo').length || 0,
        }));
      else if (dataElemen === 'procedimentos' && data.procedimentos?.length > 0)
        tempDadosFunc = tempDadosFunc.map(val => ({
          ...val,
          value:
            val.Agenda?.filter((as: any) =>
              as.Environment.Procedures.map((i: any) => i.id).some((a: any) =>
                data.procedimentos
                  ?.map(({ value }: SelectProps) => value)
                  .includes(a),
              ),
            ).filter((agend: any) => agend.status !== 'Completo').length || 0,
        }));
    });

    if (tempDadosFunc.length > 0) {
      tempDadosFunc = tempDadosFunc
        .filter(
          (value, index, self) =>
            index === self.findIndex(t => t.id === value.id),
        )
        .sort((a, b) => b.value - a.value)
        .filter(a => a.value !== 0)
        .slice(0, 10);
    }
    setDadosFuncionariosEmAberto(tempDadosFunc);
  };

  const cnpjEmAberto = dadosGraficos2?.reduce(
    (groups: any, items) => ({
      ...groups,
      [items.Empresa.NOME]: [...(groups[items.Empresa.NOME] || []), items],
    }),
    {},
  );
  const filterCNPJEmAberto = (data: SignUpFormData): void => {
    let tempDadosFunc: any[] = [];
    Object.keys(cnpjEmAberto)?.forEach(field => {
      tempDadosFunc.push({
        id: field,
        aFazer:
          cnpjEmAberto[field].filter(
            (val: DadosGraficos2) => val.status === null,
          ).length || 0,
        andamento:
          cnpjEmAberto[field].filter(
            (val: DadosGraficos2) => val.status === 'Andamento',
          ).length || 0,
        incompleto:
          cnpjEmAberto[field].filter(
            (val: DadosGraficos2) => val.status === 'Incompleto',
          ).length || 0,
        Agenda: cnpjEmAberto[
          field
        ].map(({ Agenda, status }: DadosGraficos2) => ({ ...Agenda, status })),
        Procedure: cnpjEmAberto[field].map(
          ({ Agenda }: DadosGraficos2) => Agenda.Environment.Procedures,
        ),
      });
    });
    Object.keys(data).forEach(dataElemen => {
      if (dataElemen === 'empresas' && data.empresas?.length > 0)
        tempDadosFunc = tempDadosFunc.map(val => ({
          ...val,
          aFazer:
            val.Agenda?.filter((agend: any) =>
              data.empresas
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Id_EmpresasFilial),
            ).filter((agend: any) => agend.status === null).length || 0,
          andamento:
            val.Agenda?.filter((agend: any) =>
              data.empresas
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Id_EmpresasFilial),
            ).filter((agend: any) => agend.status === 'Andamento').length || 0,
          incompleto:
            val.Agenda?.filter((agend: any) =>
              data.empresas
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Id_EmpresasFilial),
            ).filter((agend: any) => agend.status === 'Incompleto').length || 0,
        }));
      else if (dataElemen === 'colaboradores' && data.colaboradores?.length > 0)
        tempDadosFunc = tempDadosFunc.map(val => ({
          ...val,
          aFazer:
            val.Agenda?.filter((agend: any) =>
              data.colaboradores
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Id_Users),
            ).filter((agend: any) => agend.status === null).length || 0,
          andamento:
            val.Agenda?.filter((agend: any) =>
              data.colaboradores
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Id_Users),
            ).filter((agend: any) => agend.status === 'Andamento').length || 0,
          incompleto:
            val.Agenda?.filter((agend: any) =>
              data.colaboradores
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Id_Users),
            ).filter((agend: any) => agend.status === 'Incompleto').length || 0,
        }));
      // Id_Environments
      else if (dataElemen === 'ambientes' && data.ambientes?.length > 0)
        tempDadosFunc = tempDadosFunc.map(val => ({
          ...val,
          aFazer:
            val.Agenda?.filter((agend: any) =>
              data.ambientes
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Id_Environments),
            ).filter((agend: any) => agend.status === null).length || 0,
          andamento:
            val.Agenda?.filter((agend: any) =>
              data.ambientes
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Id_Environments),
            ).filter((agend: any) => agend.status === 'Andamento').length || 0,
          incompleto:
            val.Agenda?.filter((agend: any) =>
              data.ambientes
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Id_Environments),
            ).filter((agend: any) => agend.status === 'Incompleto').length || 0,
        }));
      else if (dataElemen === 'categorias' && data.categorias?.length > 0)
        tempDadosFunc = tempDadosFunc.map(val => ({
          ...val,
          aFazer:
            val.Agenda?.filter((agend: any) =>
              data.categorias
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Environment.categoriaId),
            ).filter((agend: any) => agend.status === null).length || 0,
          andamento:
            val.Agenda?.filter((agend: any) =>
              data.categorias
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Environment.categoriaId),
            ).filter((agend: any) => agend.status === 'Andamento').length || 0,
          incompleto:
            val.Agenda?.filter((agend: any) =>
              data.categorias
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Environment.categoriaId),
            ).filter((agend: any) => agend.status === 'Incompleto').length || 0,
        }));
      else if (dataElemen === 'setores' && data.setores?.length > 0)
        tempDadosFunc = tempDadosFunc.map(val => ({
          ...val,
          aFazer:
            val.Agenda?.filter((agend: any) =>
              data.setores
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Environment.setorId),
            ).filter((agend: any) => agend.status === null).length || 0,
          andamento:
            val.Agenda?.filter((agend: any) =>
              data.setores
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Environment.setorId),
            ).filter((agend: any) => agend.status === 'Andamento').length || 0,
          incompleto:
            val.Agenda?.filter((agend: any) =>
              data.setores
                ?.map(({ value }: SelectProps) => value)
                .includes(agend.Environment.setorId),
            ).filter((agend: any) => agend.status === 'Incompleto').length || 0,
        }));
      else if (dataElemen === 'procedimentos' && data.procedimentos?.length > 0)
        tempDadosFunc = tempDadosFunc.map(val => ({
          ...val,
          aFazer:
            val.Agenda?.filter((as: any) =>
              as.Environment.Procedures.map((i: any) => i.id).some((a: any) =>
                data.procedimentos
                  ?.map(({ value }: SelectProps) => value)
                  .includes(a),
              ),
            ).filter((agend: any) => agend.status === null).length || 0,
          andamento:
            val.Agenda?.filter((as: any) =>
              as.Environment.Procedures.map((i: any) => i.id).some((a: any) =>
                data.procedimentos
                  ?.map(({ value }: SelectProps) => value)
                  .includes(a),
              ),
            ).filter((agend: any) => agend.status === 'Andamento').length || 0,
          incompleto:
            val.Agenda?.filter((as: any) =>
              as.Environment.Procedures.map((i: any) => i.id).some((a: any) =>
                data.procedimentos
                  ?.map(({ value }: SelectProps) => value)
                  .includes(a),
              ),
            ).filter((agend: any) => agend.status === 'Incompleto').length || 0,
        }));
    });
    if (tempDadosFunc.length > 0) {
      tempDadosFunc = tempDadosFunc.filter(
        (value, index, self) =>
          index === self.findIndex(t => t.id === value.id),
      );
      tempDadosFunc = tempDadosFunc.filter(
        a => a.aFazer !== 0 || a.andamento !== 0 || a.incompleto !== 0,
      );
      tempDadosFunc = tempDadosFunc.map(elem => ({
        id: elem.id,
        aFazer: elem.aFazer,
        andamento: elem.andamento,
        incompleto: elem.incompleto,
      }));
    }
    setDadosCPNJEmAberto(tempDadosFunc);
  };
  const previstoXRealizado = dadosGraficos1?.reduce(
    (groups: any, items) => ({
      ...groups,
      [items.User?.nome || 'Sem nome']: [
        ...(groups[items.User?.nome || 'Sem nome'] || []),
        items,
      ],
    }),
    {},
  );
  const filterDadosPrevistoXRealizado = (data: SignUpFormData): void => {
    let dadosTemp: any[] = [];

    Object.keys(previstoXRealizado)?.forEach(field => {
      const dadosTp = previstoXRealizado[field].filter(
        (value: any, index: number, self: any) =>
          index ===
          self.findIndex((t: any) => t.agendamentoId === value.agendamentoId),
      );
      dadosTemp.push({
        id: field,
        previsto: dadosTp.map((val: DadosGraficos) =>
          differenceTime(val.Agendamento.horaIni, val.Agendamento.horaFim),
        ),
        realizado: dadosTp.map((val: DadosGraficos) =>
          differenceTime(
            val.createdAt.slice(11, 16),
            val.updatedAt.slice(11, 16),
          ),
        ),
        companyId: dadosTp[0]?.companyId,
        Agendamento: dadosTp.map(
          ({ Agendamento, createdAt, updatedAt }: DadosGraficos) => ({
            agendamento: Agendamento,
            previsto: {
              dataIni: Agendamento.horaIni,
              dataFim: Agendamento.horaFim,
            },
            realizado: { dataIni: createdAt, dataFim: updatedAt },
          }),
        ),
        Procedure: dadosTp.map(({ Procedure }: DadosGraficos) => Procedure),
      });
    });
    Object.keys(data).forEach(dataElemen => {
      if (dataElemen === 'empresas' && data.empresas?.length > 0)
        dadosTemp = dadosTemp.filter(val =>
          data.empresas
            ?.map(({ value }: SelectProps) => value)
            .includes(val.companyId),
        );
      else if (dataElemen === 'colaboradores' && data.colaboradores?.length > 0)
        dadosTemp = dadosTemp.filter(val =>
          data.colaboradores
            ?.map(({ value }: SelectProps) => value)
            .includes(val.userId),
        );
      else if (dataElemen === 'ambientes' && data.ambientes?.length > 0) {
        dadosTemp = dadosTemp.map(val => ({
          ...val,
          Agendamento: val.Agendamento?.filter((v: any) =>
            data.ambientes
              ?.map(({ value }: SelectProps) => value)
              .includes(v.agendamento.Agenda.Environment.id),
          ),
        }));
      } else if (dataElemen === 'categorias' && data.categorias?.length > 0)
        dadosTemp = dadosTemp.map(val => ({
          ...val,
          Agendamento: val.Agendamento?.filter((v: any) =>
            data.categorias
              ?.map(({ value }: SelectProps) => value)
              .includes(v.agendamento.Agenda.Environment.Categoria.id),
          ),
        }));
      else if (dataElemen === 'setores' && data.setores?.length > 0)
        dadosTemp = dadosTemp.map(val => ({
          ...val,
          Agendamento: val.Agendamento?.filter((v: any) =>
            data.setores
              ?.map(({ value }: SelectProps) => value)
              .includes(v.agendamento.Agenda.Environment.Setor.id),
          ),
        }));
      else if (dataElemen === 'procedimentos' && data.procedimentos?.length > 0)
        dadosTemp = dadosTemp.map(val => ({
          ...val,
          Agendamento: val.Agendamento?.filter(() =>
            val.Procedure.map((i: any) => i.id).some((a: any) =>
              data.procedimentos
                ?.map(({ value }: SelectProps) => value)
                .includes(a),
            ),
          ),
        }));
    });

    dadosTemp = dadosTemp.map(val => ({
      ...val,
      previsto: val.Agendamento?.map((agend: any) =>
        differenceTime(agend.previsto.dataIni, agend.previsto.dataFim),
      ),
      realizado: val.Agendamento?.map((agend: any) =>
        differenceTime(
          agend.realizado.dataIni.slice(11, 16),
          agend.realizado.dataFim.slice(11, 16),
        ),
      ),
    }));

    let demandaMT: any[] = [];

    let previstoTp;
    let realizadoTp;
    if (dadosTemp.length > 0) {
      demandaMT = dadosTemp
        .map((dados: any) => {
          previstoTp =
            dados.previsto.length > 0
              ? dados.previsto.reduce((a: number, b: number) => a + b, 0) / 60
              : 0;
          realizadoTp =
            dados.realizado.length > 0
              ? dados.realizado.reduce((a: number, b: number) => a + b, 0) / 60
              : 0;
          return {
            ...dados,
            previsto: previstoTp,
            realizado: realizadoTp,
          };
        })
        .sort(
          (a, b) =>
            Math.abs(b.previsto - b.realizado) -
            Math.abs(a.previsto - a.realizado),
        )
        .filter(a => a.previsto !== 0 && a.realizado !== 0)
        .slice(0, 8);
    }

    setDadosPrevistoXRealizado(demandaMT);
  };

  const onSubmit = (): void => {
    if (
      !(
        empresasForm?.length > 0 ||
        colaboradoresForm?.length > 0 ||
        ambientesForm?.length > 0 ||
        setoresForm?.length > 0 ||
        categoriasForm?.length > 0 ||
        procedimentosForm?.length > 0
      )
    ) {
      addToast({
        type: 'info',
        title: Language[languageStorage]['Selecione algum filtro'],
        description:
          Language[languageStorage]['Favor selecionar pelo menos um filtro'],
      });
    } else if (
      (dadosGraficos1 && dadosGraficos1?.length > 0) ||
      (dadosGraficos2 && dadosGraficos2?.length > 0)
    ) {
      if (dadosGraficos1 && dadosGraficos1?.length > 0) {
        filterDadosDemandaMaisTempo({
          empresas: empresasForm,
          colaboradores: colaboradoresForm,
          setores: setoresForm,
          ambientes: ambientesForm,
          procedimentos: procedimentosForm,
          categorias: categoriasForm,
        });
        filterDadosAgendamentoStatus({
          empresas: empresasForm,
          colaboradores: colaboradoresForm,
          setores: setoresForm,
          ambientes: ambientesForm,
          procedimentos: procedimentosForm,
          categorias: categoriasForm,
        });
        filterDadosPrevistoXRealizado({
          empresas: empresasForm,
          colaboradores: colaboradoresForm,
          setores: setoresForm,
          ambientes: ambientesForm,
          procedimentos: procedimentosForm,
          categorias: categoriasForm,
        });
      }
      if (dadosGraficos2 && dadosGraficos2?.length > 0) {
        filterFuncionariosEmAberto({
          empresas: empresasForm,
          colaboradores: colaboradoresForm,
          setores: setoresForm,
          ambientes: ambientesForm,
          procedimentos: procedimentosForm,
          categorias: categoriasForm,
        });
        filterCNPJEmAberto({
          empresas: empresasForm,
          colaboradores: colaboradoresForm,
          setores: setoresForm,
          ambientes: ambientesForm,
          procedimentos: procedimentosForm,
          categorias: categoriasForm,
        });
      }
      setModalOpen(false);
    } else {
      addToast({
        type: 'error',
        title: Language[languageStorage]['Sem dados'],
        description: Language[languageStorage]['Não há dados com esse filtro'],
      });
    }
  };

  const getAmbientes = useCallback(() => {
    setLoadingAmbientes(true);
    api
      .get<AmbienteGet[]>(
        `/environments/?id=${empresaPrincipal[0]?.Id_Empresas}`,
      )
      .then(({ data }) => {
        setLoadingAmbientes(false);
        const value = data.map(({ ID, AMBIENTE_NOME: nome, companyId }) => ({
          value: ID,
          label: nome,
          companyId,
        }));
        setAmbientes(value);
      })
      .catch(() => {
        setLoadingAmbientes(false);
      });
  }, [empresaPrincipal]);

  useEffect(() => {
    getAmbientes();
  }, [getAmbientes]);

  const getEmpresas = useCallback(() => {
    setLoadingEmpresas(true);
    api
      .get<EmpresasInterface[]>(`empresas/${empresaPrincipal[0]?.Id_Empresas}`)
      .then(({ data }) => {
        setLoadingEmpresas(false);
        setEmpresas(
          data.map(({ ID, NOME }) => ({
            value: ID,
            label: NOME,
          })),
        );
      })
      .catch(() => {
        setLoadingEmpresas(false);
      });
  }, [empresaPrincipal]);

  useEffect(() => {
    getEmpresas();
  }, [getEmpresas]);

  const getUsuarios = useCallback(() => {
    setLoadingColaboradores(true);
    api
      .get<ResponseGetUsuario[]>(`allUsers/${empresaPrincipal[0]?.Id_Empresas}`)
      .then(({ data }) => {
        setLoadingColaboradores(false);
        setColaboradores(
          data
            .filter(value => value.coordenador === 0)
            .map(({ id, nome, companyId }) => ({
              value: id,
              label: nome,
              companyId,
            })),
        );
      })
      .catch(() => {
        setLoadingColaboradores(false);
      });
  }, [empresaPrincipal]);

  useEffect(() => {
    getUsuarios();
  }, [getUsuarios]);

  const getDados = useCallback(() => {
    setLoadingDadosGerais(true);
    api
      .get(`/environments/form/${empresaPrincipal[0]?.Id_Empresas}`)
      .then(({ data }) => {
        setLoadingDadosGerais(false);
        const cat = data.categorias.map(
          ({
            ID_CATEGORIA: ID,
            DESCRICAO: nome,
            EMPRESA_ID: companyId,
          }: any) => ({
            value: ID,
            label: nome,
            companyId,
          }),
        );
        const proced = data.procedimentos.map(
          ({
            ID_PROCEDIMENTO: ID,
            NAME_PROCEDIMENTO: nome,
            EMPRESA_ID: companyId,
          }: any) => ({
            value: ID,
            label: nome,
            companyId,
          }),
        );
        const setor = data.setores.map(
          ({ id, name: nome, EMPRESA_ID: companyId }: any) => ({
            value: id,
            label: nome,
            companyId,
          }),
        );
        setCategorias(cat);
        setSetores(setor);
        setProcedimentos(proced);
      })
      .catch(() => {
        setLoadingDadosGerais(false);
      });
  }, [empresaPrincipal]);

  useEffect(() => {
    getDados();
  }, [getDados]);

  const getDadosGraficos1 = useCallback(() => {
    setLoadingDados(true);
    api
      .get(
        `/historic/v2/company?companyId=${empresaPrincipal[0]?.Id_Empresas}` +
          `&dataIni=${searchDataInicial}&dataFim=${searchDataFinal}`,
      )
      .then(({ data }) => {
        setDadosGraficos1(data);
        setLoadingDados(false);
      })
      .catch(() => {
        setLoadingDados(false);
      });
  }, [empresaPrincipal, searchDataFinal, searchDataInicial]);

  const getDadosGraficos2 = useCallback(() => {
    setLoadingDados2(true);
    api
      .get(
        `agendas/v2/Agendamentos/company?dataIni=${searchDataInicial}` +
          `&dataFim=${searchDataFinal}&companyId=${empresaPrincipal[0]?.Id_Empresas}`,
      )
      .then(({ data }) => {
        setDadosGraficos2(data);
        setLoadingDados2(false);
      })
      .catch(() => {
        setLoadingDados2(false);
      });
  }, [empresaPrincipal, searchDataFinal, searchDataInicial]);

  const getDadosGraficos = useCallback((): void => {
    getDadosGraficos1();
    getDadosGraficos2();
  }, [getDadosGraficos1, getDadosGraficos2]);

  useEffect(() => {
    if (!primeiroGetDados) {
      getDadosGraficos();
      setPrimeiroGetDados(true);
    }
  }, [getDadosGraficos, primeiroGetDados]);

  const handleChangeEmpresa = (selectOption: any): void => {
    if (!selectOption.length) {
      setAmbientesHook(ambientes);
      setSetoresHook(setores);
      setCategoriasHook(categorias);
      setProcedimentosHook(procedimentos);
      setColaboradoresHook(colaboradores);
    } else {
      setAmbientesHook(
        ambientes?.filter((val: AmbienteGet) =>
          selectOption
            .map(({ value }: SelectProps) => value)
            .includes(val.companyId),
        ),
      );
      setColaboradoresHook(
        colaboradores?.filter((val: ResponseGetUsuario) =>
          selectOption
            .map(({ value }: SelectProps) => value)
            .includes(val.companyId),
        ),
      );
      setCategoriasHook(
        categorias?.filter((val: Categoria) =>
          selectOption
            .map(({ value }: SelectProps) => value)
            .includes(val.companyId),
        ),
      );
      setSetoresHook(
        setores?.filter((val: Setor) =>
          selectOption
            .map(({ value }: SelectProps) => value)
            .includes(val.companyId),
        ),
      );
      setProcedimentosHook(
        procedimentos?.filter((val: Setor) =>
          selectOption
            .map(({ value }: SelectProps) => value)
            .includes(val.companyId),
        ),
      );
    }
  };

  const resetForm = (): void => {
    setEmpresasForm(null);
    setAmbientesForm(null);
    setSetoresForm(null);
    setCategoriasForm(null);
    setProcedimentosForm(null);
    setColaboradoresForm(null);
  };

  const resetFilters = (): void => {
    resetForm();
    setAmbientesHook(ambientes);
    setSetoresHook(setores);
    setCategoriasHook(categorias);
    setProcedimentosHook(procedimentos);
    setColaboradoresHook(colaboradores);
    if (
      (dadosGraficos1 && dadosGraficos1?.length > 0) ||
      (dadosGraficos2 && dadosGraficos2?.length > 0)
    ) {
      if (dadosGraficos1 && dadosGraficos1?.length > 0) {
        resetDadosDemandaMaisTempo();
        resetDadosAgendamentoStatus();
        resetDadosPrevistoXRealizado();
      }
      if (dadosGraficos2 && dadosGraficos2?.length > 0) {
        resetDadosFuncionarios();
        resetDadosCNPJ();
      }
    }
  };

  const verificarDados = (): boolean => {
    if (
      dadosAgendamentosStatus ||
      dadosFuncionariosEmAberto ||
      dadosCPNJEmAberto ||
      dadosDemandaMaisTempo ||
      dadosAtividadeMedia
    )
      return true;
    return false;
  };
  const downloadExcel = (): void => {
    if (verificarDados()) {
      const workbook = XLSX.utils.book_new();
      if (dadosAgendamentosStatus && dadosAgendamentosStatus.length > 0) {
        const wkAgendamentosStatus = XLSX.utils.json_to_sheet(
          dadosAgendamentosStatus.map((val: any) => ({
            STATUS: val.id,
            QUANTIDADE: val.value,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkAgendamentosStatus,
          'Ambientes',
        );
      }
      if (dadosFuncionariosEmAberto && dadosFuncionariosEmAberto.length > 0) {
        const wkFuncionariosEmAberto = XLSX.utils.json_to_sheet(
          dadosFuncionariosEmAberto.map((val: any) => ({
            NOME: val.id,
            QUANTIDADE: val.value,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkFuncionariosEmAberto,
          'Atv em aberto por func',
        );
      }
      if (dadosCPNJEmAberto && dadosCPNJEmAberto.length > 0) {
        const wkCPNJEmAberto = XLSX.utils.json_to_sheet(
          dadosCPNJEmAberto.map((val: any) => ({
            NOME: val.id,
            'A FAZER': val.aFazer,
            ANDAMENTO: val.andamento,
            INCOMPLETO: val.incompleto,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkCPNJEmAberto,
          'Atv em aberto por CNPJ',
        );
      }
      if (dadosDemandaMaisTempo && dadosDemandaMaisTempo.length > 0) {
        const wkDemandaMaisTempo = XLSX.utils.json_to_sheet(
          dadosDemandaMaisTempo.map((val: any) => ({
            ATIVIDADE: val.id,
            TEMPO: `${val.value}${val.value === 1 ? ' minuto' : ' minutos'}`,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkDemandaMaisTempo,
          'Atv que demandam mais tempo',
        );
      }
      if (dadosAtividadeMedia && dadosAtividadeMedia.length > 0) {
        const wkAtividadeMedia = XLSX.utils.json_to_sheet(
          dadosAtividadeMedia.map((val: any) => ({
            ATIVIDADE: val.id,
            TEMPO: `${val.value}${val.value === 1 ? ' minuto' : ' minutos'}`,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkAtividadeMedia,
          'Tempo médio das atv',
        );
      }
      if (dadosPrevistoXRealizado && dadosPrevistoXRealizado.length > 0) {
        const wkDadosPrevistoXRealizado = XLSX.utils.json_to_sheet(
          dadosPrevistoXRealizado.map((val: any) => ({
            NOME: val.id,
            PREVISTO: `${Math.floor(val.previsto ?? 0)}h${
              ((val.previsto ?? 0) * 60) % 60
            }m`,
            REALIZADO: `${Math.floor(val.realizado ?? 0)}h${
              ((val.realizado ?? 0) * 60) % 60
            }m`,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkDadosPrevistoXRealizado,
          'Previsto X Realizado',
        );
      }
      XLSX.writeFile(workbook, 'DataSheet.xlsx');
    } else {
      addToast({
        type: 'error',
        title: 'Sem dados',
        description: 'Não há dados para exportar',
      });
    }
  };

  const handleDownloadPDF = (): void => {
    if (verificarDados()) {
      const doc = new jsPDF(); // eslint-disable-line
      doc.setFontSize(18);
      doc.setTextColor(0, 0, 0);
      doc.setFontType('bold');
      doc.text(Language[languageStorage].Resumo, 103, 20, 'center');
      let posY: number;
      posY = 30;
      if (dadosAgendamentosStatus && dadosAgendamentosStatus.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text(
          Language[languageStorage]['Todas as atividades'],
          103,
          40,
          'center',
        );
        dadosAgendamentosStatus.forEach((res: any, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontSize(14);
          doc.setFontType('normal');
          doc.text(
            `${res.id}: ${res.value} ${
              res.value === 1
                ? Language[languageStorage].atividade
                : Language[languageStorage].atividades
            }`,
            10,
            posY,
            'left',
          );
          if (index === dadosAgendamentosStatus.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (dadosFuncionariosEmAberto && dadosFuncionariosEmAberto.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text(
          Language[languageStorage]['Atividades em aberto por funcionário'],
          103,
          posY,
          'center',
        );
        dadosFuncionariosEmAberto.forEach((res: any, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(
            `${res.id}: ${res.value} ${
              res.value === 1
                ? Language[languageStorage].atividade
                : Language[languageStorage].atividades
            }`,
            10,
            posY,
            'left',
          );
          if (index === dadosFuncionariosEmAberto.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (dadosCPNJEmAberto && dadosCPNJEmAberto.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text(
          Language[languageStorage]['Atividades em aberto por CNPJ'],
          103,
          posY,
          'center',
        );
        dadosCPNJEmAberto.forEach((res: any, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(
            `${res.id}: ${res.aFazer + res.andamento + res.incompleto} ${
              res.aFazer + res.andamento + res.incompleto === 1
                ? Language[languageStorage].atividade
                : Language[languageStorage].atividades
            }`,
            10,
            posY,
            'left',
          );
          if (index === dadosCPNJEmAberto.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (dadosDemandaMaisTempo && dadosDemandaMaisTempo.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text(
          Language[languageStorage]['Atividades que demandam mais tempo'],
          103,
          posY,
          'center',
        );
        dadosDemandaMaisTempo.forEach((res: any, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(
            `${res.id}: ${res.value} ${
              res.value === 1
                ? Language[languageStorage].minuto
                : Language[languageStorage].minutos
            }`,
            10,
            posY,
            'left',
          );
          if (index === dadosDemandaMaisTempo.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (dadosAtividadeMedia && dadosAtividadeMedia.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text(
          Language[languageStorage]['Tempo médio das atividades'],
          103,
          posY,
          'center',
        );
        dadosAtividadeMedia.forEach((res: any, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(
            `${res.id}: ${res.value} ${
              res.value === 1
                ? Language[languageStorage].minuto
                : Language[languageStorage].minutos
            }`,
            10,
            posY,
            'left',
          );
          if (index === dadosAtividadeMedia.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (dadosPrevistoXRealizado && dadosPrevistoXRealizado.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text(
          Language[languageStorage]['Previsto X Realizado'],
          103,
          posY,
          'center',
        );
        dadosPrevistoXRealizado.forEach((res: any, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(
            `${res.id} - ${Language[languageStorage].previsto}: ${Math.floor(
              res.previsto ?? 0,
            )}h${((res.previsto ?? 0) * 60) % 60}m`,
            10,
            posY,
            'left',
          );
          posY += 6;
          doc.text(
            `${res.id} - ${Language[languageStorage].realizado}: ${Math.floor(
              res.realizado ?? 0,
            )}h${((res.realizado ?? 0) * 60) % 60}m`,
            10,
            posY,
            'left',
          );
          if (index === dadosPrevistoXRealizado.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      doc.autoPrint();
      doc.output('dataurlnewwindow');
    } else {
      addToast({
        type: 'error',
        title: Language[languageStorage]['Sem dados'],
        description: Language[languageStorage]['Não há dados para exportar'],
      });
    }
  };

  return (
    <Container>
      <ContainerTitle>
        <header>
          <h1>{Language[languageStorage].Auditoria}</h1>

          <Tooltip
            title={
              Language[languageStorage][
                'Nessa página você tem acesso a visualização dos gráficos para fim de análises estratégicas.'
              ]
            }
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </header>
        <div>
          {(empresasForm?.length > 0 ||
            colaboradoresForm?.length > 0 ||
            ambientesForm?.length > 0 ||
            setoresForm?.length > 0 ||
            categoriasForm?.length > 0 ||
            procedimentosForm?.length > 0) &&
            !modalOpen && (
              <button
                type="button"
                className="danger"
                title="Remover Filtros"
                onClick={() => resetFilters()}
              >
                <FaTimes color="#B0B0B0" />
              </button>
            )}
          <button
            type="button"
            className="danger"
            title="Diminuir fonte"
            onClick={() => setFontSizeGraph(s => s - 1)}
          >
            <FaFont size="15px" color="#B0B0B0" />
          </button>
          <button
            type="button"
            title="Aumentar fonte"
            onClick={() => setFontSizeGraph(s => s + 1)}
          >
            <FaFont size="20px" color="#B0B0B0" />
          </button>
          {!loadingDados && !loadingDados2 && (
            <>
              <button
                type="button"
                className="btnsvg"
                onClick={() => setModalOpen(true)}
              >
                <img src={IconFilter} alt="iconFilter" title="Filtrar" />
              </button>
              <button
                type="button"
                className="btnsvg"
                onClick={() => setFiltroPorData(d => !d)}
              >
                <FaCalendar color="#B0B0B0" />
              </button>
            </>
          )}
        </div>
      </ContainerTitle>
      <div className="container">
        {filtroPorData && (
          <ContainerSearch
            className="container-searcher"
            isFilterDateInterval={filtroPorData}
          >
            <Search
              onSearch={(value: string) => {
                setSearchDataInicial(value);
              }}
              nomePlaceHolder={Language[languageStorage]['Buscar data inicial']}
              type="date"
            />

            <Search
              onSearch={(value: string) => {
                setSearchDataFinal(value);
              }}
              nomePlaceHolder={Language[languageStorage]['Buscar data final']}
              type="date"
            />
            <button type="button" onClick={() => getDadosGraficos()}>
              <FaSearch color="#B0B0B0" />
            </button>
          </ContainerSearch>
        )}
        <HeaderDate>
          <h2>{dateRender}</h2>
          <div>
            <button
              title="Exportar para PDF"
              type="button"
              className="danger"
              onClick={() => handleDownloadPDF()}
            >
              <VscFilePdf size={22} color="#fff" />
            </button>
            <button
              title="Exportar para Excel"
              type="button"
              onClick={() => downloadExcel()}
            >
              <RiFileExcel2Line size={22} color="#fff" />
            </button>
          </div>
        </HeaderDate>
        <div className="row">
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">Ambientes</span>
            {loadingDados && <Loading component />}
            {dadosAgendamentosStatus &&
              dadosAgendamentosStatus.length > 0 &&
              !loadingDados && (
                <ResponsivePie
                  data={dadosAgendamentosStatus}
                  margin={{ top: 40, right: 20, bottom: 40, left: 20 }}
                  innerRadius={0.5}
                  padAngle={0.7}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  colors={{ scheme: 'pastel1' }}
                  enableArcLinkLabels={false}
                  defs={[
                    {
                      id: 'dots',
                      type: 'patternDots',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  legends={
                    !isDesktop
                      ? [
                          {
                            anchor: 'top-left',
                            direction: 'column',
                            justify: false,
                            translateX: 0,
                            translateY: 0,
                            itemWidth: 100,
                            itemHeight: 25,
                            itemsSpacing: 0,
                            symbolSize: 25,
                            itemDirection: 'left-to-right',
                          },
                        ]
                      : undefined
                  }
                />
              )}
            {!dadosAgendamentosStatus?.length && !loadingDados && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>{Language[languageStorage]['Sem dados']}</span>
              </SemDados>
            )}
          </div>
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">
              {
                Language[languageStorage][
                  'Atividades em aberto por funcionário'
                ]
              }
            </span>
            {loadingDados2 && <Loading component />}
            {dadosFuncionariosEmAberto &&
              dadosFuncionariosEmAberto.length > 0 &&
              !loadingDados2 && (
                <ResponsivePie
                  data={dadosFuncionariosEmAberto}
                  margin={{ top: 40, right: 0, bottom: 40, left: 120 }}
                  innerRadius={0.5}
                  padAngle={0.7}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  colors={{ scheme: 'pastel1' }}
                  enableArcLinkLabels={false}
                  defs={[
                    {
                      id: 'dots',
                      type: 'patternDots',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  legends={[
                    {
                      anchor: 'top-left',
                      direction: 'column',
                      justify: false,
                      translateX: -130,
                      translateY: 0,
                      itemWidth: 100,
                      itemHeight: 25,
                      itemsSpacing: 0,
                      symbolSize: 25,
                      itemDirection: 'left-to-right',
                    },
                  ]}
                />
              )}
            {!dadosFuncionariosEmAberto?.length && !loadingDados2 && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>{Language[languageStorage]['Sem dados']}</span>
              </SemDados>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">
              {Language[languageStorage]['Atividades em aberto por CNPJ']}
            </span>
            {loadingDados2 && <Loading component />}
            {dadosCPNJEmAberto &&
              dadosCPNJEmAberto.length > 0 &&
              !loadingDados2 && (
                <ResponsiveBar
                  data={dadosCPNJEmAberto}
                  margin={{ top: 30, right: 130, bottom: 60, left: -10 }}
                  borderWidth={1}
                  keys={['aFazer', 'incompleto', 'andamento']}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  colorBy="indexValue"
                  colors={{ scheme: 'pastel1' }}
                  axisLeft={null}
                  axisBottom={null}
                  defs={[
                    {
                      id: 'dots',
                      type: 'patternDots',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  label={d => (d.value !== 0 ? `${d.value}` : '')}
                  legends={
                    !isDesktop
                      ? [
                          {
                            dataFrom: 'indexes',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 140,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemWidth: 140,
                            itemHeight: 33,
                            itemDirection: 'bottom-to-top',
                            itemOpacity: 0.85,
                            symbolSize: 7,
                            effects: [
                              {
                                on: 'hover',
                                style: {
                                  itemOpacity: 1,
                                },
                              },
                            ],
                          },
                        ]
                      : undefined
                  }
                />
              )}
            {!dadosCPNJEmAberto?.length && !loadingDados2 && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>{Language[languageStorage]['Sem dados']}</span>
              </SemDados>
            )}
          </div>
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">
              {Language[languageStorage]['Atividades que demandam mais tempo']}
            </span>
            {loadingDados && <Loading component />}
            {dadosDemandaMaisTempo &&
              dadosDemandaMaisTempo.length > 0 &&
              !loadingDados && (
                <ResponsivePie
                  data={dadosDemandaMaisTempo}
                  margin={{ top: 40, right: 0, bottom: 40, left: 120 }}
                  innerRadius={0.5}
                  colors={{ scheme: 'pastel1' }}
                  arcLabelsComponent={({ label, style }: any) => (
                    <animated.g
                      transform={style.transform}
                      style={{ pointerEvents: 'none' }}
                    >
                      <text
                        textAnchor="middle"
                        dominantBaseline="central"
                        fill={style.textColor}
                        style={{
                          fontSize: 13,
                          color: '#000',
                          fontWeight: 400,
                        }}
                      >
                        <tspan>{label}</tspan>
                        <tspan x={0} y={11}>
                          min
                        </tspan>
                      </text>
                    </animated.g>
                  )}
                  padAngle={0.7}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  sortByValue
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  enableArcLinkLabels={false}
                  defs={[
                    {
                      id: 'dots',
                      type: 'patternDots',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  legends={
                    !isDesktop
                      ? [
                          {
                            anchor: 'top-left',
                            direction: 'column',
                            justify: false,
                            translateX: -130,
                            translateY: 0,
                            itemWidth: 100,
                            itemHeight: 25,
                            itemsSpacing: 0,
                            symbolSize: 25,
                            itemDirection: 'left-to-right',
                          },
                        ]
                      : undefined
                  }
                />
              )}
            {!dadosDemandaMaisTempo?.length && !loadingDados && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>{Language[languageStorage]['Sem dados']}</span>
              </SemDados>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">Tempo médio das atividades</span>
            {loadingDados && <Loading component />}
            {dadosAtividadeMedia &&
              dadosAtividadeMedia.length > 0 &&
              !loadingDados && (
                <ResponsivePie
                  data={dadosAtividadeMedia}
                  margin={{ top: 40, right: 0, bottom: 40, left: 120 }}
                  innerRadius={0.5}
                  colors={{ scheme: 'pastel1' }}
                  arcLabelsComponent={({ label, style }: any) => (
                    <animated.g
                      transform={style.transform}
                      style={{ pointerEvents: 'none' }}
                    >
                      <text
                        textAnchor="middle"
                        dominantBaseline="central"
                        fill={style.textColor}
                        style={{
                          fontSize: 13,
                          color: '#000',
                          fontWeight: 400,
                        }}
                      >
                        <tspan>{label}</tspan>
                        <tspan x={0} y={11}>
                          min
                        </tspan>
                      </text>
                    </animated.g>
                  )}
                  padAngle={0.7}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  sortByValue
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  enableArcLinkLabels={false}
                  defs={[
                    {
                      id: 'dots',
                      type: 'patternDots',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  legends={
                    !isDesktop
                      ? [
                          {
                            anchor: 'top-left',
                            direction: 'column',
                            justify: false,
                            translateX: -130,
                            translateY: 0,
                            itemWidth: 100,
                            itemHeight: 25,
                            itemsSpacing: 0,
                            symbolSize: 25,
                            itemDirection: 'left-to-right',
                          },
                        ]
                      : undefined
                  }
                />
              )}
            {!dadosAtividadeMedia?.length && !loadingDados && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>{Language[languageStorage]['Sem dados']}</span>
              </SemDados>
            )}
          </div>

          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">
              {Language[languageStorage]['Tempo Previsto x Realizado']}
            </span>
            {loadingDados && <Loading component />}
            {dadosPrevistoXRealizado &&
              dadosPrevistoXRealizado.length > 0 &&
              !loadingDados && (
                <ResponsiveBar
                  data={dadosPrevistoXRealizado}
                  margin={{ top: 30, right: 130, bottom: 60, left: -10 }}
                  borderWidth={2}
                  keys={['previsto', 'realizado']}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  colors={{ scheme: 'pastel1' }}
                  groupMode="grouped"
                  colorBy="indexValue"
                  axisLeft={null}
                  axisBottom={null}
                  borderRadius={5}
                  defs={[
                    {
                      id: 'dots',
                      type: 'patternDots',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  label={
                    d =>
                      d.value !== 0
                        ? `${Math.floor(d.value ?? 0)}h${
                            ((d.value ?? 0) * 60) % 60
                          }m`
                        : ''
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  legends={
                    !isDesktop
                      ? [
                          {
                            dataFrom: 'indexes',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 140,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemWidth: 140,
                            itemHeight: 33,
                            itemDirection: 'bottom-to-top',
                            itemOpacity: 0.85,
                            symbolSize: 7,
                            effects: [
                              {
                                on: 'hover',
                                style: {
                                  itemOpacity: 1,
                                },
                              },
                            ],
                          },
                        ]
                      : undefined
                  }
                />
              )}
            {!dadosPrevistoXRealizado?.length && !loadingDados && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>{Language[languageStorage]['Sem dados']}</span>
              </SemDados>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <ContainerModal>
          {/* {(loadingDadosGerais ||
            loadingEmpresas ||
            loadingAmbientes ||
            loadingColaboradores) && <Loading />}
          {!(
            loadingDadosGerais ||
            loadingEmpresas ||
            loadingAmbientes ||
            loadingColaboradores
          ) && (
            <> */}
          <header>
            <h1>{Language[languageStorage].Filtros}</h1>
            <FaTimes
              size={25}
              color="#b0b0b0"
              onClick={() => {
                setModalOpen(false);
                resetFilters();
              }}
            />
          </header>

          <p>Empresa</p>
          <Select
            isClearable
            isMulti
            name="empresas"
            options={empresas}
            maxMenuHeight={200}
            isLoading={loadingEmpresas}
            // formatOptionLabel={formatOptionLabel}
            styles={{
              clearIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              dropdownIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              menu: styles => ({
                ...styles,
                zIndex: 3,
              }),
            }}
            value={empresasForm}
            onChange={e => {
              setEmpresasForm(e);
              handleChangeEmpresa(e);
            }}
            loadingMessage={() => 'Carregando ...'}
            noOptionsMessage={() => 'Opção não encontrada'}
            placeholder="Selecione"
          />
          <p>{Language[languageStorage].Colaborador}</p>
          <Select
            isClearable
            isMulti
            name="colaboradores"
            options={colaboradoresHook ?? colaboradores}
            maxMenuHeight={200}
            isLoading={loadingColaboradores}
            // formatOptionLabel={formatOptionLabel}
            styles={{
              clearIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              dropdownIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              menu: styles => ({
                ...styles,
                zIndex: 3,
              }),
            }}
            onChange={e => setColaboradoresForm(e)}
            value={colaboradoresForm}
            loadingMessage={() => `${Language[languageStorage].Carregando} ...`}
            noOptionsMessage={() => {
              return Language[languageStorage]['Opção não encontrada'];
            }}
            placeholder={Language[languageStorage].Selecione}
          />

          <p>{Language[languageStorage].Ambientes}</p>
          <Select
            isClearable
            isMulti
            name="ambientes"
            options={ambientesHook ?? ambientes}
            maxMenuHeight={200}
            isLoading={loadingAmbientes}
            // formatOptionLabel={formatOptionLabel}
            styles={{
              clearIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              dropdownIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              menu: styles => ({
                ...styles,
                zIndex: 3,
              }),
            }}
            onChange={e => setAmbientesForm(e)}
            value={ambientesForm}
            loadingMessage={() => {
              return `${Language[languageStorage].Carregando} ...`;
            }}
            noOptionsMessage={() => {
              return Language[languageStorage]['Opção não encontrada'];
            }}
            placeholder={Language[languageStorage].Selecione}
          />

          <p>{Language[languageStorage].Categorias}</p>
          <Select
            isClearable
            isMulti
            name="categorias"
            options={categoriasHook ?? categorias}
            maxMenuHeight={200}
            isLoading={loadingDadosGerais}
            // formatOptionLabel={formatOptionLabel}
            styles={{
              clearIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              dropdownIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              menu: styles => ({
                ...styles,
                zIndex: 3,
              }),
            }}
            onChange={e => setCategoriasForm(e)}
            value={categoriasForm}
            loadingMessage={() => `${Language[languageStorage].Carregando} ...`}
            noOptionsMessage={() => {
              return Language[languageStorage]['Opção não encontrada'];
            }}
            placeholder={Language[languageStorage].Selecione}
          />
          <p>{Language[languageStorage].Setores}</p>
          <Select
            isClearable
            isMulti
            name="setores"
            options={setoresHook ?? setores}
            maxMenuHeight={200}
            isLoading={loadingDadosGerais}
            // formatOptionLabel={formatOptionLabel}
            styles={{
              clearIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              dropdownIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              menu: styles => ({
                ...styles,
                zIndex: 3,
              }),
            }}
            onChange={e => setSetoresForm(e)}
            value={setoresForm}
            loadingMessage={() => `${Language[languageStorage].Carregando} ...`}
            noOptionsMessage={() => {
              return Language[languageStorage]['Opção não encontrada'];
            }}
            placeholder={Language[languageStorage].Selecione}
          />
          <p>{Language[languageStorage].Procedimentos}</p>
          <Select
            isClearable
            isMulti
            name="procedimentos"
            options={procedimentosHook ?? procedimentos}
            maxMenuHeight={200}
            isLoading={loadingDadosGerais}
            // formatOptionLabel={formatOptionLabel}
            styles={{
              clearIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              dropdownIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              menu: styles => ({
                ...styles,
                zIndex: 3,
              }),
            }}
            onChange={e => setProcedimentosForm(e)}
            value={procedimentosForm}
            loadingMessage={() => `${Language[languageStorage].Carregando} ...`}
            noOptionsMessage={() => {
              return Language[languageStorage]['Opção não encontrada'];
            }}
            placeholder={Language[languageStorage].Selecione}
          />
          <br style={{ margin: '30px 0px' }} />

          <Button type="button" onClick={() => onSubmit()} widthProps="46%">
            {Language[languageStorage].Filtrar}
          </Button>
          <ButtonFiltro
            type="button"
            widthProps="50%"
            onClick={() => resetFilters()}
          >
            {Language[languageStorage]['Limpar filtros']}
          </ButtonFiltro>
          {/* </> */}
          {/* )} */}
        </ContainerModal>
      </Modal>
    </Container>
  );
};
