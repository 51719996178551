import React, { useState, useEffect, useCallback } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import moment from 'moment';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import { Calendario, DataRange } from '../../../components/Calendario';
import { Cadastro } from './Cadastro';
import { ModalEditScheduling } from './ModalEditScheduling';
import Search from '../../../components/Search';
import ModalConexao from '../../../components/ModalConexao';

import { Container, Aba, ContainerAba } from './styles';
import { ModalRemoveAgenda } from '../../../components/ModalRemoveAgenda';
import { Agendamentos } from '../../../models/Agendamentos';
import { EditAgenda, ModalEditScheduling2 } from './ModalEditScheduling2';
import Language from '../../../language/language.json';

export interface ResponseGetEmpresas {
  ATIVO: boolean;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  ID: number;
  Id_Empresas: number;
  NOME: string;
  NUMERO: string;
}

export interface ResponseGetUsuario {
  ativo: number;
  avatar: string;
  avatar_url: string;
  companyId: number;
  coordenador: number;
  cpf: string;
  createdAt: string;
  dataNascimento: string;
  email: string;
  id: number;
  nome: string;
  password: string;
  telefone: string;
  tipo: string;
  updatedAt: string;
}

export interface ResponseGetAmbiente {
  AMBIENTE_NOME: string;
  CATEGORIA: string;
  DESC_LIMPEZA: string;
  companyId: number;
  EMPRESA: string;
  ID: number;
  PROCEDIMENTOS: [];
  RISCOS: [];
  SETOR: string;
  ANDAR: string;
}

interface ResponseRevisao {
  idTableRevisao: number[];
  horaInicial: string[];
  horaFinal: string[];
}

export interface ResponseGetPeriodicidade {
  ID: number;
  NOME: string;
  DIAS_SEMANA: string;
  QTD_DIAS: number;
  HORA_BASE_INICIAL: string;
  HORA_BASE_FINAL: string;
  Id_EmpresasFilial: number;
  revisaoHoras: ResponseRevisao;
}

export const AgendamentosPage: React.FC = () => {
  const { empresaPrincipal, languageStorage } = useAuth();
  const { addToast } = useToast();

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingAgenda, setLoadingAgenda] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalEdit2, setShowModalEdit2] = useState(false);
  const [
    dadosAgendamentoEdit,
    setDadosAgendamentoEdit,
  ] = useState<Agendamentos>();
  const [dadosDayEdit, setDadosDayEdit] = useState('');
  const [responseAgendamentos, setResponseAgendamentos] = useState<
    Agendamentos[]
  >([]);
  const [responseEmpresas, setResponseEmpresas] = useState<
    ResponseGetEmpresas[]
  >([]);
  const [responseUsuarios, setResponseUsuarios] = useState<
    ResponseGetUsuario[]
  >([]);
  const [responseAmbientes, setResponseAmbientes] = useState<
    ResponseGetAmbiente[]
  >([]);
  const [responsePeriodicidades, setResponsePeriodicidades] = useState<
    ResponseGetPeriodicidade[]
  >([]);
  const [verificaResponse, setVerificaResponse] = useState(false);
  const [cadastrar, setCadastrar] = useState(false);
  const [isModalRemoveAgenda, setIsModalRemoveAgenda] = useState(false);
  const [dataRange, setDataRange] = useState<DataRange>({
    dateChoose: moment().format('YYYY-MM-DD'),
    dateCurrent: moment().format('YYYY-MM-DD 00:00:00'),
    dayEnd: new Date(
      new Date().getUTCFullYear(),
      new Date().getMonth() + 1,
      0,
    ).getDate(),
    mouth: new Date().getMonth(),
    year: new Date().getUTCFullYear(),
  });
  const [editAgenda, setEditAgenda] = useState<EditAgenda | null>(null);

  const getItens = useCallback(async () => {
    setLoading(true);

    try {
      const companyId =
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
      const endPointEmpresaPrincipal =
        empresaPrincipal.length > 0
          ? `?Id_Empresa=${empresaPrincipal[0].Id_Empresas}`
          : '';

      const dataEmpresas = await api.get(`empresas/${companyId}`);
      const dataUsers = await api.get(`/allUsers/${companyId}`);
      const dataAmbientes = await api.get(`/environments/?id=${companyId}`);
      const dataPeriodicidades = await api.get(
        `/periodicidade/showPeriodicidade${endPointEmpresaPrincipal}`,
      );

      const filiaisAtive: ResponseGetEmpresas[] = [];
      dataEmpresas.data.forEach((filial: ResponseGetEmpresas) => {
        if (filial.ATIVO === true) {
          filiaisAtive.push(filial);
        }
      });
      setResponseEmpresas(filiaisAtive);

      const usersAux: ResponseGetUsuario[] = [];
      dataUsers.data.forEach((user: ResponseGetUsuario) => {
        user.coordenador === 0 && usersAux.push(user);
      });

      setResponseUsuarios([...usersAux]);
      setResponseAmbientes(dataAmbientes.data);
      setResponsePeriodicidades(dataPeriodicidades.data.periodicidade);

      setVerificaResponse(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      addToast({
        type: 'error',
        title: Language[languageStorage].Erro,
        description: `${Language[languageStorage]['Erro ao carregar dados, favor recarregue a página']}.`,
      });
    }
  }, [addToast, empresaPrincipal, languageStorage]);

  const getAgendaData = useCallback(
    async (dateIni: string, dateEnd: string) => {
      setLoadingAgenda(true);

      try {
        const companyId =
          empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';

        const dataAgenda = await api.get(
          `agendas/v2/Agendamentos/company?dataIni=${dateIni}&dataFim=${dateEnd}&companyId=${companyId}`,
        );
        const age: Agendamentos[] = dataAgenda.data;
        setResponseAgendamentos(age.filter(item => item.active));

        setVerificaResponse(true);
        setLoadingAgenda(false);
      } catch (err) {
        console.log(err.message);
        setLoadingAgenda(false);

        addToast({
          type: 'error',
          title: Language[languageStorage].Erro,
          description: `${Language[languageStorage]['Erro ao carregar dados, favor recarregue a página']}.`,
        });
      }
    },
    [addToast, empresaPrincipal, languageStorage],
  );

  useEffect(() => {
    getItens();
  }, [getItens]);

  useEffect(() => {
    getAgendaData(dataRange.dateChoose, dataRange.dateChoose);
  }, [dataRange.dateChoose, getAgendaData]);

  const semanaToDiasSelect = useCallback(
    (val: string) => [
      {
        day: 'Domingo',
        d: languageStorage === 'en-US' ? 'S' : 'D',
        selected: val.includes('Domingo'),
      },
      {
        day: 'Segunda',
        d: languageStorage === 'en-US' ? 'M' : 'S',
        selected: val.includes('Segunda'),
      },
      { day: 'Terça', d: 'T', selected: val.includes('Terça') },
      {
        day: 'Quarta',
        d: languageStorage === 'en-US' ? 'W' : 'Q',
        selected: val.includes('Quarta'),
      },
      {
        day: 'Quinta',
        d: languageStorage === 'en-US' ? 'T' : 'Q',
        selected: val.includes('Quinta'),
      },
      {
        day: 'Sexta',
        d: languageStorage === 'en-US' ? 'F' : 'S',
        selected: val.includes('Sexta'),
      },
      { day: 'Sábado', d: 'S', selected: val.includes('Sábado') },
    ],
    [languageStorage],
  );

  const handlePermitionRegister = useCallback(() => {
    if (
      verificaResponse &&
      !!responseEmpresas.length &&
      !!responseUsuarios.length
    ) {
      setSearch('');
      setCadastrar(true);
    } else {
      addToast({
        type: 'info',
        title: Language[languageStorage]['Cadastros não realizados'],
        description:
          Language[languageStorage][
            'Favor verificar se existe ao menos um usuário ASG e uma filial cadastrada antes de registrar um agendamento'
          ],
      });
    }
  }, [
    addToast,
    languageStorage,
    responseEmpresas.length,
    responseUsuarios.length,
    verificaResponse,
  ]);

  return (
    <>
      <ModalConexao />

      <Container>
        {empresaPrincipal.length > 0 ? (
          <Aba>
            <ContainerAba className="aba" cor={!cadastrar}>
              <button type="button" onClick={() => setCadastrar(false)}>
                {Language[languageStorage]['Listar agendamentos']}
              </button>
            </ContainerAba>
            <ContainerAba className="aba1" cor={cadastrar}>
              <button type="button" onClick={() => handlePermitionRegister()}>
                {Language[languageStorage]['Cadastrar agendamento']}
              </button>
            </ContainerAba>

            <Tooltip
              title={
                Language[languageStorage][
                  'Nessa página você tem acesso a visualização, cadastro e edição de todos os agendamentos para as limpezas e tarefas diárias dos ASGs'
                ]
              }
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        ) : (
          <Aba>
            <ContainerAba className="aba" cor={!cadastrar}>
              <button type="button">
                {Language[languageStorage]['Listar agendamentos']}
              </button>
            </ContainerAba>

            <Tooltip
              title={
                Language[languageStorage][
                  'Nessa página você tem acesso a visualização de todos os agendamentos para as limpezas e tarefas diárias dos ASGs'
                ]
              }
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        )}

        {!cadastrar && (
          <>
            <Search
              onSearch={(value: string) => {
                setSearch(value);
              }}
              nomePlaceHolder={Language[languageStorage].Buscar}
            />

            <Calendario
              onClickCardEdit={(x: Agendamentos, y: string) => {
                const edit: EditAgenda = {
                  active: x.Agenda.ATIVO,
                  agendaId: x.Agenda.ID,
                  ambienteId: x.Agenda.Id_Environments,
                  companyId: x.companyId,
                  diaMesSelected: [],
                  diasDaSemana: semanaToDiasSelect(
                    x.Agenda.Periodicidade.DIAS_SEMANA,
                  ),
                  diasDaSemana2: [],
                  diasSelected: [],
                  horarios: [
                    {
                      hourFim: x.Agenda.Periodicidade.HORA_BASE_FINAL,
                      hourIni: x.Agenda.Periodicidade.HORA_BASE_INICIAL,
                      id: x.Agenda.Periodicidade.ID,
                    },
                    ...x.Agenda.Periodicidade.Revisoes.map(i => ({
                      hourFim: i.HORA_FINAL,
                      hourIni: i.HORA_INICIAL,
                      id: i.ID,
                    })),
                  ],
                  qtdLimpeza: x.Agenda.Periodicidade.QTD_DIAS,
                  type:
                    x.Agenda.Periodicidade.type === '12X'
                      ? '12X'
                      : Language[languageStorage].Semanal,
                  userId: x.userId,
                  periodicidadeId: x.Agenda.Id_Periodicidade,
                  periodicidadeName: x.Agenda.Periodicidade.NOME,
                  photoUni: x.Agenda.revisionPhotos,
                  vigFim: x.Agenda.vigFim,
                  vigIni: x.Agenda.vigIni,
                };
                setEditAgenda(edit);
                setShowModalEdit2(true);
                setDadosDayEdit(y);
              }}
              data={responseAgendamentos}
              dataSearch={search}
              dateRange={dataRange}
              setRange={setDataRange}
              isVisibleRemoveAgenda
              onClickRemoveAgenda={() => {
                setIsModalRemoveAgenda(true);
              }}
            />
          </>
        )}

        {cadastrar && (
          <Cadastro
            dadosEmpresas={responseEmpresas}
            dadosUsuarios={responseUsuarios}
            dadosAmbientes={responseAmbientes}
            dadosPeriodicidades={responsePeriodicidades}
          />
        )}

        {empresaPrincipal.length > 0 && dadosAgendamentoEdit && (
          <ModalEditScheduling
            isOpen={showModalEdit}
            data={responseAgendamentos}
            dadosAgendamento={dadosAgendamentoEdit}
            dadosDay={dadosDayEdit}
            dadosAmbientes={responseAmbientes}
            dadosUsers={responseUsuarios}
            dadosPeriodicidades={responsePeriodicidades}
            onRequestClose={() => setShowModalEdit(false)}
          />
        )}
        {editAgenda !== null && (
          <ModalEditScheduling2
            isOpen={showModalEdit2}
            dadosDay={dadosDayEdit}
            dadoEdit={editAgenda}
            dadosAmbiente={responseAmbientes.filter(
              item => item.companyId === editAgenda.companyId,
            )}
            dadosUsers={responseUsuarios.filter(
              item => item.companyId === editAgenda.companyId,
            )}
            onRequestClose={() => setShowModalEdit2(false)}
          />
        )}

        <ModalRemoveAgenda
          isOpen={isModalRemoveAgenda}
          onRequestClose={() => setIsModalRemoveAgenda(false)}
          dadosUsers={responseUsuarios}
        />

        {(loading || loadingAgenda) && <Loading />}
      </Container>
    </>
  );
};
