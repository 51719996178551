import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 30px 40px 20px 40px;

  @media screen and (max-width: 1152px) {
    padding: 20px;
  }
`;

export const ContainerTable = styled.div`
  margin-top: 30px;
  padding: 0 2px 15px 2px;
  max-width: calc(100vw - 388px);
  max-height: calc(100vh - 401px);
  overflow: auto;
  animation: ${appearFromLeft} 1s;

  @media screen and (max-width: 1400px) {
    max-height: calc(100vh - 397px);
  }

  @media screen and (max-width: 1152px) {
    max-width: 100vw;
    max-height: calc(100vh - 368px);
  }

  table {
    width: 100%;
    background: ${props => props.theme.colors.whitePrimary};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    border-collapse: collapse;

    thead {
      th {
        padding: 20px 30px;
        text-align: left;
        white-space: nowrap;
      }

      th:nth-last-child(2) {
        text-align: center;
      }
    }

    tbody {
      tr {
        border-radius: 20px !important;

        td {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: ${props => props.theme.colors.textBlack};
          padding: 20px 30px;
          white-space: nowrap;

          button {
            border: none;
            background: transparent;
            margin: 0 auto;

            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 26px;
              height: 26px;
              color: ${props => props.theme.colors.bluePrimary};
            }
          }
        }

        &:nth-child(odd) {
          background-color: ${props => props.theme.colors.grayWhite};
        }

        /* &:hover {
          background-color: ${shade(0.1, '#f3f3f3')};
        } */
      }
    }
  }
`;

export const ContainerPagination = styled.div`
  width: 100%;
  padding: 40px 60px 10px 60px;

  @media screen and (max-width: 1152px) {
    padding: 20px 0;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn {
    background: ${props => props.theme.colors.bluePrimary};
    border: none;
    border-radius: 16px;
    padding: 12px 35px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: ${props => props.theme.colors.whitePrimary};

    transition: background 0.2s;

    &:hover {
      background: ${props => shade(0.1, props.theme.colors.bluePrimary)};
    }
  }
`;

export const ContainerSemResultados = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-top: 30px;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: ${props => props.theme.colors.textBlack};
  }
`;

export const ContainerTitle = styled.div`
  margin-bottom: 30px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: ${props => props.theme.colors.textBlack};
  }

  span {
    margin-left: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
      height: 20px;
      color: ${props => props.theme.colors.bluePrimary};
    }
  }
`;
