export interface Categoria {
  id: number;
  name: string;
  companyId: number;
  createdAt: string;
  updatedAt: string;
}

export const EmptyCategoria: Categoria = {
  companyId: -1,
  createdAt: '',
  id: -1,
  name: '',
  updatedAt: '',
};
