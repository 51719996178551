import React, { createContext, useCallback, useState, useContext } from 'react';
import moment from 'moment-timezone';
import api from '../services/api';
import { useToast } from './toast';
import { listLanguage, EnumLanguage } from '../language/interface';

interface Acesso {
  CPF: string;
  EMAIL: string;
  ID: number;
  NivelACesso: number;
  Nome: string;
  Nome_NivelAcesso: string;
  Setor: string;
  SetorID: number;
  idUsuario: number;
}

interface User {
  ativo: number;
  avatar_url: string;
  companyId: number;
  coordenador: number;
  cpf: string;
  dataNascimento: string;
  email: string;
  id: string;
  nome: string;
  telefone: string;
  tipo: string;
}

interface UserMaster {
  ID: number;
  NOME: string;
  CPF: string;
  EMAIL: string;
  DATA_NASCIMENTO: string;
  TELEFONE: string;
  Id_Empresas: number;
  ATIVO: boolean;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
}

interface UserEmpresaPrincipal {
  ATIVO: boolean;
  BAIRRO: string;
  CIDADE: string;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  ID: number;
  Id_Empresas: number;
  NOME: string;
  NUMERO: string;
}

interface AuthState {
  token: string;
  user: User;
  cpfUserMaster: UserMaster[];
  empresaPrincipal: UserEmpresaPrincipal[];
  nivelAcesso: Acesso[];
  dataHoraToken: string;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  dataHoraToken: string;
  user: User;
  cpfUserMaster: UserMaster[];
  empresaPrincipal: UserEmpresaPrincipal[];
  nivelAcesso: Acesso[];
  languageStorage: EnumLanguage;
  signIn(credentials: SignInCredentials): Promise<boolean>;
  signOut(): void;
  updateUser(user: User): void;
  updateLanguage(idiom: string): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const getIdiom = useCallback((lang: string): EnumLanguage => {
    const idiom = listLanguage.find(item => item.value === lang);
    if (idiom) {
      return idiom.value;
    }
    return 'pt-BR';
  }, []);

  const { addToast } = useToast();
  const [languageStorage, setLanguageStorage] = useState<EnumLanguage>(
    getIdiom(localStorage.getItem('@Tapp:language') || 'pt-BR'),
  );
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Tapp:token');
    const dataHoraToken = localStorage.getItem('@Tapp:dataHoraToken');
    const user = localStorage.getItem('@Tapp:user');
    const cpfUserMaster = localStorage.getItem('@Tapp:cpfUserMaster');
    const empresaPrincipal = localStorage.getItem('@Tapp:empresaPrincipal');
    const nivelAcesso = localStorage.getItem('@Tapp:nivelAcesso');

    if (
      token &&
      dataHoraToken &&
      user &&
      cpfUserMaster &&
      empresaPrincipal &&
      nivelAcesso
    ) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return {
        token,
        dataHoraToken: JSON.parse(dataHoraToken),
        user: JSON.parse(user),
        cpfUserMaster: JSON.parse(cpfUserMaster),
        empresaPrincipal: JSON.parse(empresaPrincipal),
        nivelAcesso: JSON.parse(nivelAcesso),
      };
    }

    return {} as AuthState;
  });

  const signOut = useCallback(() => {
    const token = localStorage.removeItem('@Tapp:token'); // eslint-disable-line
    const dataHoraToken = localStorage.removeItem('@Tapp:dataHoraToken');// eslint-disable-line
    const user = localStorage.removeItem('@Tapp:user');// eslint-disable-line
    const cpfUserMaster = localStorage.removeItem('@Tapp:cpfUserMaster');// eslint-disable-line
    const empresaPrincipal = localStorage.removeItem('@Tapp:empresaPrincipal');// eslint-disable-line
    const nivelAcesso = localStorage.removeItem('@Tapp:nivelAcesso');// eslint-disable-line

    setData({} as AuthState);
  }, []);

  const signIn = useCallback(
    async ({ email, password }): Promise<boolean> => {
      const m = moment.tz('America/Fortaleza');
      const dataAtual = m.utc().format();

      const response = await api.post('sessions', {
        email,
        password,
      });

      const {
        token,
        dataHoraToken,
        user,
        cpfUserMaster,
        empresaPrincipal,
        nivelAcesso,
      } = response.data;

      localStorage.setItem('@Tapp:token', token);
      localStorage.setItem('@Tapp:dataHoraToken', JSON.stringify(dataAtual));
      localStorage.setItem('@Tapp:user', JSON.stringify(user));
      localStorage.setItem(
        '@Tapp:cpfUserMaster',
        JSON.stringify(cpfUserMaster),
      );
      localStorage.setItem(
        '@Tapp:empresaPrincipal',
        JSON.stringify(empresaPrincipal),
      );
      localStorage.setItem('@Tapp:nivelAcesso', JSON.stringify(nivelAcesso));

      api.defaults.headers.authorization = `Bearer ${token}`;

      if (response.data.user.coordenador === 0) {
        signOut();

        addToast({
          type: 'info',
          title: 'Acesso bloqueado!',
          description:
            'Caso queira alterar suas permições, entre em contato com seu coordenador ou o administrador do sistema.',
        });
        return false;
      }

      setData({
        token,
        dataHoraToken,
        user,
        cpfUserMaster,
        empresaPrincipal,
        nivelAcesso,
      });
      return true;
    },
    [addToast, signOut],
  );

  const updateUser = useCallback(
    async (user: User) => {
      localStorage.setItem('@Tapp:user', JSON.stringify(user));

      setData({
        token: data.token,
        dataHoraToken: data.dataHoraToken,
        user,
        cpfUserMaster: data.cpfUserMaster,
        empresaPrincipal: data.empresaPrincipal,
        nivelAcesso: data.nivelAcesso,
      } as AuthState);
    },
    [
      data.token,
      data.dataHoraToken,
      data.cpfUserMaster,
      data.empresaPrincipal,
      data.nivelAcesso,
    ],
  );

  const updateLanguage = useCallback(
    (idiom: string) => {
      const lang = getIdiom(idiom);
      localStorage.setItem('@Tapp:language', lang);
      setLanguageStorage(lang);
    },
    [getIdiom],
  );

  return (
    <AuthContext.Provider
      value={{
        dataHoraToken: data.dataHoraToken,
        user: data.user,
        cpfUserMaster: data.cpfUserMaster,
        empresaPrincipal: data.empresaPrincipal,
        nivelAcesso: data.nivelAcesso,
        languageStorage,
        signIn,
        signOut,
        updateUser,
        updateLanguage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
