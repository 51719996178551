import React, {
  FormEvent,
  useState,
  useMemo,
  useEffect,
  useContext,
} from 'react';
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import api from '../../../../services/api';
import { Loading } from '../../../../components/Loading';
import { useToast } from '../../../../hooks/toast';
import { maskCpf } from '../../../../components/InputOverview/mask';
import Button from '../../../../components/Button';
import { SelectInterface } from '../../../../models/SelectInterface';
import Language from '../../../../language/language.json';
import { Agendamentos } from '../../../../models/Agendamentos';
import { useAuth } from '../../../../hooks/auth';

import {
  ResponseGetAmbiente,
  ResponseGetPeriodicidade,
  ResponseGetUsuario,
} from '../index';

import { Container } from './styles';

Modal.setAppElement('#root');

interface ModalProps {
  isOpen: boolean;
  data: Agendamentos[];
  dadosAgendamento: Agendamentos;
  dadosDay: string;
  dadosAmbientes: ResponseGetAmbiente[];
  dadosPeriodicidades: ResponseGetPeriodicidade[];
  dadosUsers: ResponseGetUsuario[];
  onRequestClose: () => void;
}

export function ModalEditScheduling({
  isOpen,
  data,
  dadosAgendamento,
  dadosDay,
  dadosAmbientes,
  dadosPeriodicidades,
  dadosUsers,
  onRequestClose,
}: ModalProps): JSX.Element {
  const { languageStorage } = useAuth();
  const { addToast } = useToast();
  const { colors } = useContext(ThemeContext);
  const { go } = useHistory();
  const [loading, setLoading] = useState(false);
  const [companyId, setCompanyId] = useState<number>(
    dadosAgendamento.Agenda.Id_EmpresasFilial,
  );
  const [environmentesDataSelect, setEnvironmentesDataSelect] = useState<
    SelectInterface[]
  >([]);
  const [usersDataSelect, setUsersDataSelect] = useState<SelectInterface[]>([]);
  const [frequencyDataSelect, setFrequencyDataSelect] = useState<
    SelectInterface[]
  >([]);
  const [selectEnvironments, setSelectEnvironments] = useState<
    SelectInterface[]
  >([]);
  const [selectUsers, setSelectUsers] = useState<SelectInterface[]>([]);
  const [selectFrequencys, setSelectFrequencys] = useState<SelectInterface[]>(
    [],
  );
  const [colorStatus, setColorStatus] = useState<boolean[]>([]);
  const [colorIsPhotoRevisao, setColorIsPhotoRevisao] = useState<boolean[]>([]);

  useEffect(() => {
    setCompanyId(dadosAgendamento.Agenda.Id_EmpresasFilial);
  }, [dadosAgendamento]);

  const dataFilterAgenda = useMemo(() => {
    const filterAgenda = data.filter(
      dataTemp =>
        dataTemp.userId === dadosAgendamento.userId &&
        dataTemp.Agenda.Id_Environments ===
          dadosAgendamento.Agenda.Id_Environments &&
        dataTemp.Agenda.Id_Periodicidade ===
          dadosAgendamento.Agenda.Id_Periodicidade &&
        dataTemp.dataAgend === dadosDay,
    );

    const selectEnvironmentsAux: SelectInterface[] = [];
    const selectFrequencysAux: SelectInterface[] = [];
    const selectUsersAux: SelectInterface[] = [];
    const selectPhotoRevision: boolean[] = [];

    filterAgenda.forEach(element => {
      selectEnvironmentsAux.push({
        value: element.Agenda.Id_Environments.toString(),
        label: element.Agenda.Environment.name,
      });

      selectFrequencysAux.push({
        value: element.Agenda.Id_Periodicidade.toString(),
        label: element.Agenda.Periodicidade.NOME,
      });

      selectUsersAux.push({
        value: element.userId.toString(),
        label: element.User.nome,
      });

      selectPhotoRevision.push(element.Agenda.revisionPhotos);
    });

    setSelectEnvironments([...selectEnvironmentsAux]);
    setSelectFrequencys([...selectFrequencysAux]);
    setSelectUsers([...selectUsersAux]);
    setColorIsPhotoRevisao(selectPhotoRevision);

    const computedResponses: Agendamentos[] = filterAgenda;

    return computedResponses;
  }, [
    dadosAgendamento.Agenda.Id_Environments,
    dadosAgendamento.Agenda.Id_Periodicidade,
    dadosAgendamento.userId,
    dadosDay,
    data,
  ]);

  useEffect(() => {
    const environmentesDataSelectTemp: SelectInterface[] = [];
    const frequencyDataSelectTemp: SelectInterface[] = [];
    const userDataSelectTemp: SelectInterface[] = [];

    dadosAmbientes
      .filter(ambiente => ambiente.companyId === companyId)
      .forEach(dado => {
        environmentesDataSelectTemp.push({
          value: dado.ID.toString(),
          label: dado.AMBIENTE_NOME,
        });
      });

    dadosPeriodicidades
      .filter(periodicidade => periodicidade.Id_EmpresasFilial === companyId)
      .forEach(dado => {
        frequencyDataSelectTemp.push({
          value: dado.ID.toString(),
          label: dado.NOME,
        });
      });

    dadosUsers
      .filter(user => user.companyId === companyId)
      .forEach(dado => {
        userDataSelectTemp.push({
          value: dado.id.toString(),
          label: dado.nome,
        });
      });

    setEnvironmentesDataSelect(environmentesDataSelectTemp);
    setFrequencyDataSelect([...frequencyDataSelectTemp]);
    setUsersDataSelect(userDataSelectTemp);

    const colorStatusTemp: boolean[] = [];
    dataFilterAgenda.forEach(agendamento => {
      agendamento.Agenda.ATIVO
        ? colorStatusTemp.push(true)
        : colorStatusTemp.push(false);
    });

    setColorStatus([...colorStatusTemp]);
  }, [
    companyId,
    dadosAmbientes,
    dadosPeriodicidades,
    dadosUsers,
    dataFilterAgenda,
  ]);

  async function handleCreateNewSectorFunction(
    event: FormEvent,
  ): Promise<void> {
    event.preventDefault();

    setLoading(true);

    dataFilterAgenda.forEach(async (element, index) => {
      await api
        .put('/agendas/updateAgenda', {
          ID: element.agendaId,
          Id_Environments: Number(selectEnvironments[index].value),
          Id_Periodicidade: Number(selectFrequencys[index].value),
          Id_Users: Number(selectUsers[index].value),
          ATIVO: colorStatus[index],
          revisionPhotos: colorIsPhotoRevisao[index],
        })
        .then(() => {
          if (dataFilterAgenda.length - 1 === index) {
            setLoading(false);

            addToast({
              title: `${Language[languageStorage].Sucesso}!`,
              description: `${Language[languageStorage]['Os agendamentos foram editados com sucesso']}.`,
              type: 'success',
            });
          }

          setTimeout(() => {
            go(0);
          }, 1000);
        })
        .catch(err => {
          setLoading(false);
          // console.log(err.message);
          // console.log(err.response);

          if (err.response.data.errors) {
            addToast({
              title: `${Language[languageStorage]['Erro ao editar agendamentos']}!`,
              description: `${err.response.data.errors}.`,
              type: 'error',
            });
          } else {
            addToast({
              title: `${Language[languageStorage]['Erro ao editar agendamentos']}!`,
              description: `${Language[languageStorage]['Ocorreu um erro, por favor tente novamente']}.`,
              type: 'error',
            });
          }

          return 0;
        });
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={() => onRequestClose()}
        className="react-modal-close"
      >
        <AiOutlineClose size={22} />
      </button>

      <Container onSubmit={handleCreateNewSectorFunction}>
        <header>
          <h1>{dadosDay.split('-').reverse().join('/')}</h1>
        </header>

        <section>
          <table>
            <thead>
              <tr>
                <th>{Language[languageStorage].Nome}</th>
                <th>{Language[languageStorage].CPF}</th>
                <th>{Language[languageStorage].Ambiente}</th>
                <th>{Language[languageStorage].Periodicidade}</th>
                <th>{Language[languageStorage].Status}</th>
                <th>{Language[languageStorage]['Revisão com foto única']}</th>
              </tr>
            </thead>

            <tbody>
              {dataFilterAgenda.map((agendamento, index) => (
                <tr key={agendamento.agendaId} style={{ cursor: 'default' }}>
                  <td style={{ width: 280 }}>
                    {/* {agendamento.nomeUser} */}
                    <Select
                      name="selectUsers"
                      menuPortalTarget={document.querySelector('body')}
                      options={usersDataSelect}
                      placeholder={`${Language[languageStorage].Usuário}...`}
                      classNamePrefix="select"
                      value={selectUsers[index]}
                      onChange={e => {
                        const selectUsersTemp = [...selectUsers];
                        if (e) selectUsersTemp[index] = e;
                        setSelectUsers([...selectUsersTemp]);
                      }}
                    />
                  </td>
                  <td>{maskCpf(agendamento.User.cpf)}</td>
                  <td style={{ width: 280 }}>
                    <Select
                      name="selectEnvironments"
                      menuPortalTarget={document.querySelector('body')}
                      options={environmentesDataSelect}
                      placeholder={`${Language[languageStorage].Ambiente}...`}
                      classNamePrefix="select"
                      value={selectEnvironments[index]}
                      onChange={e => {
                        const selectEnvironmentsTemp = [...selectEnvironments];
                        if (e) selectEnvironmentsTemp[index] = e;
                        setSelectEnvironments([...selectEnvironmentsTemp]);
                      }}
                    />
                  </td>
                  <td style={{ width: 280 }}>
                    <Select
                      name="selectFrequencys"
                      menuPortalTarget={document.querySelector('body')}
                      options={frequencyDataSelect}
                      placeholder={`${Language[languageStorage].Periodicidade}...`}
                      classNamePrefix="select"
                      value={selectFrequencys[index]}
                      onChange={e => {
                        const selectFrequencyTemp = [...selectFrequencys];
                        if (e) selectFrequencyTemp[index] = e;
                        setSelectFrequencys([...selectFrequencyTemp]);
                      }}
                    />
                  </td>
                  <td>
                    <aside>
                      <button
                        type="button"
                        onClick={() => {
                          const colorStatusAux = [...colorStatus];
                          colorStatusAux[index] = !colorStatusAux[index];
                          setColorStatus([...colorStatusAux]);
                        }}
                      >
                        <BsCheckCircle
                          color={colorStatus[index] ? colors.greenPrimary : ''}
                        />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          const colorStatusAux = [...colorStatus];
                          colorStatusAux[index] = !colorStatusAux[index];
                          setColorStatus([...colorStatusAux]);
                        }}
                      >
                        <BsXCircle
                          color={!colorStatus[index] ? colors.redPrimary : ''}
                        />
                      </button>
                    </aside>
                  </td>
                  <td>
                    <aside style={{ width: 65 }}>
                      <button
                        type="button"
                        onClick={() => {
                          const colorIsPhotoRevisaoAux = [
                            ...colorIsPhotoRevisao,
                          ];
                          colorIsPhotoRevisaoAux[
                            index
                          ] = !colorIsPhotoRevisaoAux[index];
                          setColorIsPhotoRevisao([...colorIsPhotoRevisaoAux]);
                        }}
                      >
                        <BsCheckCircle
                          color={
                            colorIsPhotoRevisao[index]
                              ? colors.greenPrimary
                              : ''
                          }
                        />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          const colorIsPhotoRevisaoAux = [
                            ...colorIsPhotoRevisao,
                          ];
                          colorIsPhotoRevisaoAux[
                            index
                          ] = !colorIsPhotoRevisaoAux[index];
                          setColorIsPhotoRevisao([...colorIsPhotoRevisaoAux]);
                        }}
                      >
                        <BsXCircle
                          color={
                            !colorIsPhotoRevisao[index] ? colors.redPrimary : ''
                          }
                        />
                      </button>
                    </aside>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <Button type="submit" widthProps="80%">
          {Language[languageStorage].Salvar}
        </Button>
      </Container>

      {loading && <Loading />}
    </Modal>
  );
}
