import axios from 'axios';

export const baseUrlProducaoAux = 'https://api.appgestaoqrcode.com/';
// export const baseUrlAux = 'http://159.65.218.57';
// export const baseUrlNgrokAux =
// 'https://cec8-2804-d4b-94e2-4700-14b6-864e-da3-6683.ngrok.io';

const api = axios.create({
  baseURL: baseUrlProducaoAux, // API de produção
  // baseURL: baseUrlAux, // API de desenvolvimento
  // baseURL: baseUrlNgrokAux, // API de teste
});

export default api;
