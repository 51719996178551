import React, { useState, useRef, useCallback, useContext } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { FiXCircle } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { ThemeContext } from 'styled-components';

import Language from '../../../../language/language.json';
import { useAuth } from '../../../../hooks/auth';
import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';
import api from '../../../../services/api';
import { SelectCustom } from '../../../../components/SelectCustom';
import { Loading } from '../../../../components/Loading';
import Button from '../../../../components/Button';
import HeaderTable from '../../../../components/HeaderTable';
import {
  AmbienteEPeriodicidade,
  ModalAmbientePeriodicidade,
} from '../ModalAmbientePeriodicidade';
import {
  ResponseGetEmpresas,
  ResponseGetAmbiente,
  ResponseGetUsuario,
  ResponseGetPeriodicidade,
} from '../index';

import { AnimationContainer, ContainerTable } from './styles';

interface SignUpFormData {
  empresa: string;
  empresaLote: string;
  usuarioAsg: string;
  vigIni: string;
  vigFim: string;
}

export interface AgendaPost {
  // ID: number;
  Id_Environments: number;
  Id_Periodicidade: number;
  Id_Users: number;
  Id_EmpresasFilial: number;
  // FINALIZADO: boolean;
  // DATA_FINALIZACAO: Date;
  // ATIVO: boolean;
  revisionPhotos: boolean;
  vigIni: string;
  vigFim: string;
}

interface PostAgenda {
  Id_EmpresasFilial: number;
  Id_Environments: number;
  Id_Users: number;
  revisionPhotos: boolean;
  vigIni: string;
  vigFim: string;
  Periodicidade: {
    nome: string;
    hora_base_inicial: string;
    hora_base_final: string;
    Id_EmpresasFilial: number;
    type: 'sem' | '12X';
    DIAS_SEMANA: string;
    Revisoes: {
      HORA_INICIAL: string;
      HORA_FINAL: string;
      Id_EmpresasFilial: number;
    }[];
  };
}

export interface ResponsePost {
  Id_Users: number[] | number;
  Id_Environments: number[];
  Id_Periodicidade: number[];
  Id_EmpresasFilial: number;
}

interface CadastroProps {
  dadosEmpresas: ResponseGetEmpresas[];
  dadosUsuarios: ResponseGetUsuario[];
  dadosAmbientes: ResponseGetAmbiente[];
  dadosPeriodicidades: ResponseGetPeriodicidade[];
}

export const Cadastro: React.FC<CadastroProps> = ({
  dadosEmpresas,
  dadosUsuarios,
  dadosAmbientes,
}) => {
  const { languageStorage } = useAuth();
  const { addToast } = useToast();
  const { go } = useHistory();
  const { colors } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const formRefIndividual = useRef<FormHandles>(null);

  const [companyId, setCompanyId] = useState<number>(dadosEmpresas[0].ID);
  const [userId, setUserId] = useState<number>(dadosUsuarios[0].id);
  const [showModal, setShowModal] = useState(false);
  const [itensAmbPer, setItensAmbPer] = useState<AmbienteEPeriodicidade[]>([]);

  const headersAP = [
    {
      name: Language[languageStorage].Ambiente,
      field: 'ambiente',
      sortable: true,
    },
    { name: Language[languageStorage].Tipo, field: 'type', sortable: true },
    { name: Language[languageStorage].Semana, field: 'semana', sortable: true },
    {
      name: Language[languageStorage].Horarios,
      field: 'horario',
      sortable: false,
    },
    {
      name: Language[languageStorage].Vigencia,
      field: 'vigencia',
      sortable: false,
    },
    {
      name: Language[languageStorage].Remover,
      field: 'remove',
      sortable: false,
    },
  ];

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRefIndividual.current?.setErrors({});
        const schema = Yup.object().shape({
          empresa: Yup.string().required(
            Language[languageStorage]['Empresa é obrigatória'],
          ),
          usuarioAsg: Yup.string().required(
            Language[languageStorage]['Colaborador é obrigatório'],
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (itensAmbPer.length <= 0) {
          setLoading(false);
          addToast({
            type: 'error',
            title: Language[languageStorage].Erro,
            description: `${Language[languageStorage]['A data inicial está menor que a data atual']}.`,
          });
          return;
        }

        const newAgendas: PostAgenda[] = [];
        itensAmbPer.forEach(item => {
          newAgendas.push({
            Id_EmpresasFilial: companyId,
            Id_Environments: item.ambienteId,
            Id_Users: userId,
            Periodicidade: {
              hora_base_final: item.horaPrinFim,
              hora_base_inicial: item.horaPrincIni,
              Id_EmpresasFilial: companyId,
              nome: `${Language[languageStorage].Agenda.toUpperCase()}_${
                item.ambienteId
              }_${userId}_${moment().format('YYYYMMDD')}`,
              type: item.type === '12X' ? '12X' : 'sem',
              DIAS_SEMANA: item.dataSemana,
              Revisoes: item.revisoes.map(it => ({
                HORA_FINAL: it.horaFim,
                HORA_INICIAL: it.horaIni,
                Id_EmpresasFilial: companyId,
              })),
            },
            revisionPhotos: item.photoUnic,
            vigFim: item.vigFim,
            vigIni: item.vigIni,
          });
        });
        // console.log(newAgendas);

        api
          .post('/agendas/v2/saveAgenda', newAgendas)
          .then(() => {
            // console.log(resp.data);
            setLoading(false);
            addToast({
              type: 'success',
              title: Language[languageStorage].Sucesso,
              description:
                Language[languageStorage][
                  'O agendamento foi cadastrado com sucesso e já pode ser visualizado no calendário'
                ],
            });

            setTimeout(() => {
              go(0);
            }, 1000);
          })
          .catch(err => {
            console.log(err.message);
            // console.log(err.response);
            if (err.response.status === 400) {
              addToast({
                type: 'error',
                title: Language[languageStorage].Erro,
                description: `${err.response.data.message}`,
              });
            } else {
              addToast({
                type: 'error',
                title: Language[languageStorage].Erro,
                description:
                  Language[languageStorage][
                    'Ocorreu um erro ao cadastrar o agendamento, tente novamente'
                  ],
              });
            }

            setLoading(false);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRefIndividual.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: Language[languageStorage]['Campos em branco'],
          description:
            Language[languageStorage][
              'Existem campos obrigatórios vazios, favor preencher'
            ],
        });
      }
    },
    [addToast, companyId, go, itensAmbPer, languageStorage, userId],
  );

  return (
    <section>
      <ModalAmbientePeriodicidade
        onClose={() => setShowModal(false)}
        visible={showModal}
        dadosAmbiente={dadosAmbientes.filter(i => i.companyId === companyId)}
        getNewObj={e => {
          setItensAmbPer([...itensAmbPer, e]);
        }}
      />
      <AnimationContainer>
        <Form ref={formRefIndividual} onSubmit={handleSubmit}>
          <p>{Language[languageStorage].Empresa}</p>
          <SelectCustom
            key="1"
            name="empresa"
            defaultValue={Language[languageStorage].Empresas}
            optionsDataCompany={dadosEmpresas}
            onValue={e => {
              setCompanyId(parseInt(e.split('-')[0], 10));
              setUserId(
                dadosUsuarios.filter(
                  usuario =>
                    usuario.companyId === parseInt(e.split('-')[0], 10),
                ).length
                  ? dadosUsuarios.filter(
                      usuario =>
                        usuario.companyId === parseInt(e.split('-')[0], 10),
                    )[0].id
                  : dadosUsuarios[0].id,
              );
              setItensAmbPer([]);
            }}
          />
          <p>{Language[languageStorage].Colaborador}</p>
          <SelectCustom
            name="usuarioAsg"
            defaultValue={Language[languageStorage].Colaborador}
            type="users"
            optionsDataUsers={dadosUsuarios.filter(
              usuario => usuario.companyId === companyId,
            )}
            onValue={e => setUserId(parseInt(e.split('-')[0], 10))}
          />

          <p>{Language[languageStorage]['Ambientes e periocidades']}</p>
          <Button type="button" onClick={() => setShowModal(true)}>
            {Language[languageStorage]['Criar novo item']}
          </Button>
          {itensAmbPer.length > 0 && (
            <ContainerTable>
              <table>
                <HeaderTable headers={headersAP} />
                <tbody>
                  {itensAmbPer.map((item, index) => (
                    <tr key={`${item.ambienteId}-${index.toString()}`}>
                      <td>{item.nomeAmbiente}</td>
                      <td>{item.type}</td>
                      <td>
                        {item.dataSemana
                          .replace(
                            'Domingo',
                            Language[languageStorage].Calendario.Domingo,
                          )
                          .replace(
                            'Segunda',
                            Language[languageStorage].Calendario.Segunda,
                          )
                          .replace(
                            'Terça',
                            Language[languageStorage].Calendario.Terca,
                          )
                          .replace(
                            'Quarta',
                            Language[languageStorage].Calendario.Quarta,
                          )
                          .replace(
                            'Quinta',
                            Language[languageStorage].Calendario.Quinta,
                          )
                          .replace(
                            'Sexta',
                            Language[languageStorage].Calendario.Sexta,
                          )
                          .replace(
                            'Sábado',
                            Language[languageStorage].Calendario.Sabado,
                          )}
                      </td>
                      <td>
                        <span>
                          {Language[languageStorage].Principal}:{' '}
                          {item.horaPrincIni}{' '}
                          {Language[languageStorage].ateHorario}{' '}
                          {item.horaPrinFim}
                        </span>
                        {item.revisoes.length > 0 && (
                          <span>
                            <br />
                            Rev.:{' '}
                            {item.revisoes
                              .map(
                                i =>
                                  `${i.horaIni} ${Language[languageStorage].ateHorario} ${i.horaFim}`,
                              )
                              .join(' , ')}
                          </span>
                        )}
                      </td>
                      <td>
                        {moment(item.vigIni).format('DD/MM/YYYY')}{' '}
                        {Language[languageStorage].ateHorario}{' '}
                        {moment(item.vigFim).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="option"
                          onClick={() => {
                            Swal.fire({
                              title: `${Language[languageStorage]['Tem certeza que deseja remover']}?`,
                              icon: 'warning',
                              showCancelButton: true,
                              cancelButtonText:
                                Language[languageStorage]['Não'],
                              confirmButtonColor: colors.greenPrimary,
                              cancelButtonColor: colors.redPrimary,
                              confirmButtonText: Language[languageStorage].Sim,
                            }).then(result => {
                              if (result.isConfirmed) {
                                setItensAmbPer(
                                  itensAmbPer.filter((_, i) => i !== index),
                                );
                              }
                            });
                          }}
                        >
                          <FiXCircle color={colors.bluePrimary} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ContainerTable>
          )}
          <Button type="submit" widthProps="100%">
            {Language[languageStorage].Salvar}
          </Button>
        </Form>
      </AnimationContainer>

      {loading && <Loading />}
    </section>
  );
};
