import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';

import {
  AiFillLike,
  AiFillDislike,
  AiOutlineQuestionCircle,
} from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { ThemeContext } from 'styled-components';
import Pagination from '../../../components/Pagination';
import { response } from './messages';
import { Mensage } from './Interface';
import Language from '../../../language/language.json';
import { useAuth } from '../../../hooks/auth';

import { Container, ContainerCard, ContainerPagination } from './styles';

export const FAQ: React.FC = () => {
  const { languageStorage } = useAuth();
  const { colors } = useContext(ThemeContext);
  const [statusButtonCard, setStatusButtonCard] = useState<boolean[]>([]);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE] = useState(20);

  const responseData = useMemo(() => {
    let computedResponses: Mensage[] = [];
    computedResponses = response(languageStorage);

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [ITEMS_PER_PAGE, currentPage, languageStorage]);

  useEffect(() => {
    const statusTemp: boolean[] = [];
    responseData.forEach(() => {
      statusTemp.push(true);
    });

    setStatusButtonCard([...statusTemp]);
  }, [responseData]);

  const descriptionFormat1 = useCallback((dataCard: Mensage) => {
    const cardSplit = dataCard.description.split('LINK');

    return cardSplit[0];
  }, []);

  const descriptionLink = useCallback((dataCard: Mensage) => {
    return dataCard.linkGooglePlayStore;
  }, []);

  const descriptionFormat2 = useCallback((dataCard: Mensage) => {
    const cardSplit = dataCard.description.split('LINK');

    return cardSplit[1];
  }, []);

  return (
    <Container>
      <header>
        <h1>FAQ</h1>

        <Tooltip
          title={`${Language[languageStorage]['Nessa página você pode tirar suas dúvidas com várias perguntas e respostas sobre a utilização geral do sistema web do TAPP']}.`}
          arrow
          TransitionComponent={Zoom}
        >
          <span>
            <AiOutlineQuestionCircle />
          </span>
        </Tooltip>
      </header>

      <section>
        {responseData.map((card, index) => (
          <ContainerCard key={card.id}>
            <h2>{card.title}</h2>

            {card.linkGooglePlayStore ? (
              <p>
                {descriptionFormat1(card)}
                {descriptionLink(card)}
                {descriptionFormat2(card)}
              </p>
            ) : (
              <p>{card.description}</p>
            )}

            <footer>
              {`${Language[languageStorage]['Este FAQ foi útil']}?`}
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonCard];

                  statusTemp[index] = true;

                  setStatusButtonCard([...statusTemp]);
                }}
              >
                <AiFillLike
                  color={statusButtonCard[index] ? colors.greenPrimary : ''}
                />
              </button>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonCard];

                  statusTemp[index] = false;

                  setStatusButtonCard([...statusTemp]);
                }}
              >
                <AiFillDislike
                  color={!statusButtonCard[index] ? colors.redPrimary : ''}
                />
              </button>
            </footer>
          </ContainerCard>
        ))}
      </section>

      <ContainerPagination>
        {response.length > 0 && (
          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        )}
      </ContainerPagination>
    </Container>
  );
};
