/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import jsPDF from 'jspdf';
import moment from 'moment';
import { uuid } from 'uuidv4';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Zoom from 'react-img-zoom';

import { AiOutlineException } from 'react-icons/ai';
import { VscFilePdf } from 'react-icons/vsc';
import { RiFileExcel2Line } from 'react-icons/ri';
import { FiAlertCircle, FiAlertTriangle } from 'react-icons/fi';

import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { Loading } from '../Loading';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import Button from '../Button';
import HeaderTable from '../HeaderTable';
import Search from '../Search';
import { maskCpf } from '../InputOverview/mask';
import Language from '../../language/language.json';

import {
  ContainerModal,
  ContainerHeader,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
  ContainerMap,
  ContainerMapDetails,
  ContainerButtonDetalhes,
  ContainerButtonsPdfExcel,
  ContainerSearch,
  ContainerTableMacon,
  IconeAtrasado,
  ButtonDetalhes,
  ContainerListCards,
  ContainerMapObservacao,
  ZoomImg,
  ButtonImgNull,
  ButtonImg,
  HistoricoVazio,
} from './styles';

Modal.setAppElement('#root');

interface ResponseGet {
  ativo: number;
  avatar: string;
  avatar_url: string;
  companyId: number;
  coordenador: number;
  cpf: string;
  createdAt: string;
  dataNascimento: string;
  email: string;
  id: number;
  nome: string;
  password: string;
  telefone: string;
  tipo: string;
  updatedAt: string;
}

interface ResponseGetHistoric {
  AMBIENTE_ID: number;
  AMBIENTE_NOME: string;
  ANDAR: string;
  DATA_CRIACAO: string;
  DATA_FINALIZACAO: string;
  FIM: string;
  HORA_FINAL_PRINCIPAL_AMBIENTE: string;
  HORA_INICIAL_PRINCIPAL_AMBIENTE: string;
  HORA_REVISAO_FINAL: string;
  HORA_REVISAO_INICIAL: string;
  ID: number;
  IMAGEM: string;
  INICIO: string;
  OBSERVACAO: string;
  PROCEDIMENTO_NOME: string;
  Revisao: boolean;
  USUARIO: string;
  categoria: string;
  imagem_revisao: string;
  revisao_id_table_revisao: number;
  setor_id: number;
  setor_nome: string;
  escondido: boolean;
}

interface ResponseGetHistoricObs {
  TaskId: number;
  createdAt: string;
  environmentId: number;
  id: number;
  observacao: string;
  updatedAt: string;
  userId: number;
}

interface ModalDetalhesAmbientes {
  isOpen: boolean;
  dados: ResponseGet;
  dadosHistorico: ResponseGetHistoric[];
  dadosHistoricoObs: ResponseGetHistoricObs[];
  dadosHistoricoDatas: string;
  onRequestClose: () => void;
}

interface ResponseGetTasks {
  companyId: number;
  id: number;
  name: string;
  empresa: number;
}

export const ModalHistoricoColaborador: React.FC<ModalDetalhesAmbientes> = ({
  isOpen,
  dados,
  dadosHistorico,
  dadosHistoricoObs,
  dadosHistoricoDatas,
  onRequestClose,
}) => {
  const { empresaPrincipal, languageStorage } = useAuth();
  const [search, setSearch] = useState('');
  const [searchDetails, setSearchDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const [responseTasks, setResponseTasks] = useState<ResponseGetTasks[]>([]);
  const [
    dataHistoryButtom,
    setDataHistoryButtom,
  ] = useState<ResponseGetHistoric>();

  const [dadosHistoricoExcelPdf, setDadosHistoricoExcelPdf] = useState<
    ResponseGetHistoric[]
  >([]);
  const [detalhesHistoricoExcelPdf, setDetalhesHistoricoExcelPdf] = useState<
    ResponseGetHistoric[]
  >([]);
  const [newDetalhesHistorico, setNewDetalhesHistorico] = useState<
    ResponseGetHistoric[]
  >([]);
  const [exibirDetalhes, setExibirDetalhes] = useState(false);
  const [dadosCopia, setDadosCopia] = useState<ResponseGetHistoric[]>([]);
  const [zoomImg, setZoomImg] = useState('');
  const [img, setImg] = useState<string | ResponseGetHistoric[]>();

  const headers = [
    { name: Language[languageStorage].Colaborador, field: 'nomeColaborador' },
    { name: Language[languageStorage].CPF, field: 'cpf' },
    { name: Language[languageStorage]['E-mail'], field: 'email' },
    { name: Language[languageStorage].Telefone, field: 'telefone' },
    { name: Language[languageStorage].Ambiente, field: 'nomeAmbiente' },
    {
      name: Language[languageStorage]['Horário base (agendado)'],
      field: 'horarioMarcadoPrimeiraLimpeza',
    },
    {
      name: Language[languageStorage]['Data e hora inicial (realizado)'],
      field: 'dataInicial',
    },
    {
      name: Language[languageStorage]['Data e hora final (realizado)'],
      field: 'dataFinal',
    },
    { name: Language[languageStorage].Observacao, field: 'observacao' },
  ];

  const headersRevisions = [
    { name: Language[languageStorage].Colaborador, field: 'nomeColaborador' },
    { name: Language[languageStorage].CPF, field: 'cpf' },
    { name: Language[languageStorage]['E-mail'], field: 'email' },
    { name: Language[languageStorage].Telefone, field: 'telefone' },
    { name: Language[languageStorage].Ambiente, field: 'nomeAmbiente' },
    {
      name: Language[languageStorage]['Horário base (agendado)'],
      field: 'horarioMarcadoPrimeiraLimpeza',
    },
    {
      name: Language[languageStorage]['Horário da revisão (agendado)'],
      field: 'horarioMarcadoRevisao',
    },
    {
      name: Language[languageStorage]['Data e hora inicial (realizado)'],
      field: 'dataInicial',
    },
    {
      name: Language[languageStorage]['Data e hora final (realizado)'],
      field: 'dataFinal',
    },
    { name: Language[languageStorage].Procedimento, field: 'procedimento' },
    { name: Language[languageStorage].Observacao, field: 'observacao' },
    {
      name: Language[languageStorage]['Horários das revisões (realizado)'],
      field: 'horarioRealizadoRevisao',
    },
    { name: Language[languageStorage].Foto, field: 'foto' },
  ];

  useEffect(() => {
    const dadosHistoricoInitAux: string[] = [];
    const dadosHistoricoFinalAux: string[] = [];
    const dadosHistoricoAux: ResponseGetHistoric[] = [];

    dadosHistorico.forEach(element => {
      if (
        !dadosHistoricoInitAux.find(initAux => initAux === element.INICIO) &&
        !dadosHistoricoFinalAux.find(finalAux => finalAux === element.FIM)
      ) {
        dadosHistoricoInitAux.push(element.INICIO);
        dadosHistoricoFinalAux.push(element.FIM);
        dadosHistoricoAux.push({ ...element, escondido: false });
      }
    });

    setDadosCopia(dadosHistoricoAux);
  }, [dadosHistorico]);

  useEffect(() => {
    setLoading(true);

    api
      .get(
        `procedures/?id=${
          empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
        }`,
      )
      .then(res => {
        setLoading(false);
        setResponseTasks(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [empresaPrincipal]);

  const findTaskNode = useCallback(
    (dataHistory: ResponseGetHistoric): string => {
      for (let j = 0; j < dadosHistoricoObs.length; j += 1) {
        const dateDataHistory = moment(dataHistory.INICIO).format(
          'DD/MM/YYYY HH:mm:ss',
        );

        const dateNote = moment(dadosHistoricoObs[j].createdAt).format(
          'DD/MM/YYYY HH:mm:ss',
        );

        if (
          dataHistory.AMBIENTE_ID === dadosHistoricoObs[j].environmentId &&
          dateDataHistory === dateNote
        ) {
          const findTaskNodeId = responseTasks.find(
            task => task.id === dadosHistoricoObs[j].TaskId,
          );
          return findTaskNodeId ? findTaskNodeId.name : '-';
        }
      }
      return '-';
    },
    [dadosHistoricoObs, responseTasks],
  );

  const findNoteDate = useCallback(
    (dataHistory: ResponseGetHistoric): boolean => {
      const dataFilterNode = dadosHistorico.filter(
        element =>
          element.INICIO === dataHistory.INICIO &&
          element.FIM === dataHistory.FIM,
      );

      let status = false;

      dadosHistoricoObs.forEach(dataObs => {
        const dateNote = moment(dataObs.createdAt).format();

        if (
          dataFilterNode.find(dataNode => {
            return (
              moment(dataNode.INICIO).format() === dateNote &&
              dataNode.AMBIENTE_ID === dataObs.environmentId
            );
          })
        ) {
          status = true;
        }
      });
      return status;
    },
    [dadosHistorico, dadosHistoricoObs],
  );

  const findNoteDateUnic = useCallback(
    (dataHistory): string => {
      for (let j = 0; j < dadosHistoricoObs.length; j += 1) {
        const dateDataInitHistory = moment(dataHistory.INICIO).format(
          'DD/MM/YYYY HH:mm:ss',
        );
        const dateDataFinalHistory = moment(dataHistory.FIM).format(
          'DD/MM/YYYY HH:mm:ss',
        );

        const dateInitNote = moment(dadosHistoricoObs[j].createdAt).format(
          'DD/MM/YYYY HH:mm:ss',
        );
        const dateFinalNote = moment(dadosHistoricoObs[j].updatedAt).format(
          'DD/MM/YYYY HH:mm:ss',
        );

        if (
          dataHistory.AMBIENTE_ID === dadosHistoricoObs[j].environmentId &&
          dateDataInitHistory === dateInitNote &&
          dateDataFinalHistory === dateFinalNote
        ) {
          return dadosHistoricoObs[j].observacao;
        }
      }
      return '-';
    },
    [dadosHistoricoObs],
  );

  const findNoteDateUnicRevision = useCallback(
    (dataHistory: ResponseGetHistoric): string => {
      for (let j = 0; j < dadosHistoricoObs.length; j += 1) {
        const dateDataHistory = moment(dataHistory.DATA_CRIACAO).format(
          'DD/MM/YYYY HH:mm:ss',
        );
        const dateNote = moment(dadosHistoricoObs[j].createdAt).format(
          'DD/MM/YYYY HH:mm:ss',
        );

        if (
          dataHistory.AMBIENTE_ID === dadosHistoricoObs[j].environmentId &&
          dateDataHistory === dateNote
        ) {
          return dadosHistoricoObs[j].observacao;
        }
      }
      return '-';
    },
    [dadosHistoricoObs],
  );

  const onButtonClick = useCallback(
    dadoParam => {
      const newResponseData = dadosCopia.map(dado => {
        return JSON.stringify(dado) === JSON.stringify(dadoParam)
          ? { ...dado, escondido: !dado.escondido }
          : dado;
      });
      setDadosCopia(newResponseData);
    },
    [dadosCopia],
  );

  const handleButtonDate = useCallback(
    (dado: ResponseGetHistoric) => {
      const dataFilterDetails = dadosHistorico.filter(
        element => element.INICIO === dado.INICIO && element.FIM === dado.FIM,
      );

      const dadosHistoricoIdRevisionAux: number[] = [];
      const dadosFilterHistorico: ResponseGetHistoric[] = [];

      dataFilterDetails.forEach(element => {
        if (dadosHistoricoIdRevisionAux.indexOf(element.ID) === -1) {
          dadosHistoricoIdRevisionAux.push(element.ID);
          dadosFilterHistorico.push(element);
        }
      });

      setDataHistoryButtom(dado);
      setNewDetalhesHistorico(dadosFilterHistorico);
    },
    [dadosHistorico],
  );

  const filterScheduledReviews = useCallback(() => {
    const dataFilterRevision = dadosHistorico.filter(
      element =>
        element.INICIO === dataHistoryButtom?.INICIO &&
        element.FIM === dataHistoryButtom?.FIM,
    );

    const filterDateInitRevision: string[] = [];
    const filterDateFinalRevision: string[] = [];
    const filterDateRevisions: string[] = [];

    dataFilterRevision.forEach(element => {
      if (
        filterDateInitRevision.indexOf(element.HORA_REVISAO_INICIAL) === -1 &&
        filterDateFinalRevision.indexOf(element.HORA_REVISAO_FINAL) === -1
      ) {
        filterDateInitRevision.push(element.HORA_REVISAO_INICIAL);
        filterDateFinalRevision.push(element.HORA_REVISAO_FINAL);
        filterDateRevisions.push(
          `${element.HORA_REVISAO_INICIAL} - ${element.HORA_REVISAO_FINAL}`,
        );
      }
    });

    return filterDateRevisions.length ? filterDateRevisions.join(', ') : '-';
  }, [dadosHistorico, dataHistoryButtom]);

  const filterPhotosReviews = useCallback(() => {
    const dataFilterRevision = dadosHistorico.filter(
      element =>
        element.DATA_CRIACAO &&
        element.DATA_FINALIZACAO &&
        element.INICIO === dataHistoryButtom?.INICIO &&
        element.FIM === dataHistoryButtom?.FIM &&
        moment(element.INICIO).format('DD/MM/YYYY') ===
          moment(element.DATA_CRIACAO).format('DD/MM/YYYY'),
    );

    const filterDateIdRevision: number[] = [];
    const filterDateObjectRevision: ResponseGetHistoric[] = [];

    dataFilterRevision.forEach(element => {
      if (
        filterDateIdRevision.indexOf(element.revisao_id_table_revisao) === -1
      ) {
        filterDateIdRevision.push(element.revisao_id_table_revisao);
        filterDateObjectRevision.push(element);
      }
    });

    return filterDateObjectRevision;
  }, [dadosHistorico, dataHistoryButtom]);

  const filterSchedulesReviewsDone = useCallback(() => {
    const filterReviews: string[] = [];
    const filterReviewsDateInit: string[] = [];
    const filterReviewsDateFinal: string[] = [];

    filterPhotosReviews().forEach(element => {
      if (
        filterReviewsDateInit.indexOf(element.DATA_CRIACAO) === -1 &&
        filterReviewsDateFinal.indexOf(element.DATA_FINALIZACAO) === -1
      ) {
        filterReviewsDateInit.push(element.DATA_CRIACAO);
        filterReviewsDateFinal.push(element.DATA_FINALIZACAO);

        filterReviews.push(
          `${moment(element.DATA_CRIACAO).format('HH:mm:ss')} - ${moment(
            element.DATA_FINALIZACAO,
          ).format('HH:mm:ss')}`,
        );
      }
    });

    return filterReviews.length ? filterReviews.join(', ') : '-';
  }, [filterPhotosReviews]);

  const handleDownloadPDF = useCallback(
    (dadosHistoricoParam: ResponseGetHistoric[], type: string) => {
      const doc = new jsPDF(); // eslint-disable-line
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      doc.setFontType('bold');
      doc.text(
        Language[languageStorage]['Histórico do Colaborador'],
        103,
        20,
        'center',
      );
      doc.setFontSize(14);
      // tamanho 207 tela a4
      let posY: number;
      posY = 30;

      doc.text(`${dados.nome}`, 103, posY, 'center');

      // inicio bloco de dados pessoais
      dadosHistoricoParam.forEach((res, index) => {
        index === 0 ? (posY += 28) : (posY += 20);

        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text(`${Language[languageStorage].Ambiente}:`, 10, posY, 'left');
        let quebraLinha = doc.splitTextToSize(`${res.AMBIENTE_NOME}`, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 33, posY, { align: 'left', maxWidth: 175 });

        posY += 6;
        doc.setFontType('bold');
        doc.text(
          `${Language[languageStorage]['Horário base (agendado)']}:`,
          10,
          posY,
          'left',
        );
        quebraLinha = doc.splitTextToSize(
          res.HORA_INICIAL_PRINCIPAL_AMBIENTE &&
            res.HORA_FINAL_PRINCIPAL_AMBIENTE
            ? `${res.HORA_INICIAL_PRINCIPAL_AMBIENTE} - ${res.HORA_FINAL_PRINCIPAL_AMBIENTE}`
            : '-',
          70,
        );
        doc.setFontType('normal');
        doc.text(quebraLinha, 63, posY, { align: 'left', maxWidth: 175 });

        const filterScheduledReviewsAux = filterScheduledReviews();
        const filterScheduledReviewsAuxLength = filterScheduledReviewsAux.split(
          '-',
        ).length;

        posY += 6;
        doc.setFontType('bold');
        doc.text(
          `${Language[languageStorage]['Horário da revisão (agendado)']}:`,
          10,
          posY,
          'left',
        );
        quebraLinha = doc.splitTextToSize(`${filterScheduledReviews()}`, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 74, posY, { align: 'left', maxWidth: 130 });
        let posYAux;

        if (Math.ceil(filterScheduledReviewsAuxLength / 9) > 1) {
          posYAux = (filterScheduledReviewsAuxLength / 9) * 11;
        } else {
          posYAux = 6;
        }

        posY += posYAux;
        doc.setFontType('bold');
        doc.text(
          `${Language[languageStorage]['Data e hora inicial (realizado)']}:`,
          10,
          posY,
          'left',
        );
        quebraLinha = doc.splitTextToSize(
          `${moment(new Date(res.INICIO)).format('DD/MM/YYYY HH:mm:ss')}`,
          70,
        );
        doc.setFontType('normal');
        doc.text(quebraLinha, 73, posY, { align: 'left', maxWidth: 175 });

        posY += 6;
        doc.setFontType('bold');
        doc.text(
          `${Language[languageStorage]['Data e hora final (realizado)']}:`,
          10,
          posY,
          'left',
        );
        quebraLinha = doc.splitTextToSize(
          `${moment(new Date(res.FIM)).format('DD/MM/YYYY HH:mm:ss')}`,
          70,
        );
        doc.setFontType('normal');
        doc.text(quebraLinha, 70, posY, { align: 'left', maxWidth: 175 });

        if (type === 'detalhes') {
          posY += 6;
          doc.setFontType('bold');
          doc.text(
            `${Language[languageStorage].Procedimento}:`,
            10,
            posY,
            'left',
          );
          quebraLinha = doc.splitTextToSize(
            `${
              res.PROCEDIMENTO_NOME === null ||
              res.PROCEDIMENTO_NOME === undefined ||
              res.PROCEDIMENTO_NOME === ''
                ? '-'
                : res.PROCEDIMENTO_NOME
            }`,
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 41, posY, { align: 'left', maxWidth: 175 });
        }

        posY += 6;
        doc.setFontType('bold');
        doc.text(`${Language[languageStorage].Observacao}:`, 10, posY, 'left');
        quebraLinha = doc.splitTextToSize(findNoteDateUnic(res), 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 39, posY, { align: 'left', maxWidth: 175 });

        posY += 6;
        doc.setFontType('bold');
        doc.text(
          `${Language[languageStorage]['Horários das revisões (realizado)']}:`,
          10,
          posY,
          'left',
        );
        quebraLinha = doc.splitTextToSize(
          `${filterSchedulesReviewsDone()}`,
          70,
        );
        doc.setFontType('normal');
        doc.text(quebraLinha, 78, posY, { align: 'left', maxWidth: 120 });

        if (posY > 207) {
          doc.addPage();
          posY = 0;
        }
      });

      doc.autoPrint();
      doc.output('dataurlnewwindow');
    },
    [
      dados.nome,
      filterScheduledReviews,
      filterSchedulesReviewsDone,
      findNoteDateUnic,
      languageStorage,
    ],
  );

  const responseData = useMemo(() => {
    let computedResponses: ResponseGetHistoric[] = [];
    let computedResponsesExcelPdf: ResponseGetHistoric[] = [];
    computedResponses = dadosCopia;
    computedResponsesExcelPdf = dadosCopia;

    if (search) {
      computedResponses = computedResponses.filter(
        (res: ResponseGetHistoric) => {
          const dataInicio = moment(new Date(res.INICIO)).format(
            'DD/MM/YYYY HH:mm:ss',
          );
          const dataFim = moment(new Date(res.FIM)).format(
            'DD/MM/YYYY HH:mm:ss',
          );

          return [res.AMBIENTE_NOME, dataInicio, dataFim].some(
            (item: any) =>
              item &&
              item
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase()),
          );
        },
      );

      computedResponsesExcelPdf = computedResponsesExcelPdf.filter(
        (res: ResponseGetHistoric) => {
          const dataInicio = moment(new Date(res.INICIO)).format(
            'DD/MM/YYYY HH:mm:ss',
          );
          const dataFim = moment(new Date(res.FIM)).format(
            'DD/MM/YYYY HH:mm:ss',
          );

          return [res.AMBIENTE_NOME, dataInicio, dataFim].some(
            (item: any) =>
              item &&
              item
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase()),
          );
        },
      );
    }

    setDadosHistoricoExcelPdf(computedResponsesExcelPdf);
    return computedResponses;
  }, [dadosCopia, search]);

  const responseDataDetalhes = useMemo(() => {
    let computedResponses: ResponseGetHistoric[] = newDetalhesHistorico;
    const computedResponsesExcelPdf: ResponseGetHistoric[] = newDetalhesHistorico;

    if (searchDetails) {
      computedResponses = computedResponses.filter((res: ResponseGetHistoric) =>
        res.PROCEDIMENTO_NOME.toString()
          .toLowerCase()
          .includes(searchDetails.toLowerCase()),
      );
    }
    setDetalhesHistoricoExcelPdf(computedResponsesExcelPdf);
    return computedResponses;
  }, [newDetalhesHistorico, searchDetails]);

  const handleZoomImg = useCallback((imgString: string) => {
    setZoomImg('principal');
    setImg(imgString);
  }, []);

  const handleZoomImgRevisions = useCallback(
    (imgObjects: ResponseGetHistoric[]) => {
      setZoomImg('revisoes');
      setImg(imgObjects);
    },
    [],
  );

  const checkOutOfHours = useCallback(
    (
      horarioMarcadoInit,
      horarioRealizadoInit,
      horarioMarcadoFinal,
      horarioRealizadoFinal,
    ) => {
      if (
        horarioRealizadoInit < horarioMarcadoInit ||
        horarioRealizadoInit > horarioMarcadoFinal ||
        horarioRealizadoFinal < horarioMarcadoInit ||
        horarioRealizadoFinal > horarioMarcadoFinal
      ) {
        return true;
      }

      return false;
    },
    [],
  );

  const onClose = useCallback(() => {
    setSearch('');
    setSearchDetails('');
    setZoomImg('');
    setNewDetalhesHistorico([]);
    setExibirDetalhes(false);
    onRequestClose();
  }, [onRequestClose]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ContainerModal>
        {!exibirDetalhes && (
          <>
            <ContainerHeaderDados>
              {Language[languageStorage]['Histórico do Colaborador']}
            </ContainerHeaderDados>

            <ContainerSearch>
              <Search
                onSearch={(value: string) => {
                  setSearch(value);
                }}
                nomePlaceHolder={Language[languageStorage].Buscar}
              />

              <strong>{dadosHistoricoDatas}</strong>
            </ContainerSearch>
          </>
        )}
        {exibirDetalhes && zoomImg === '' && (
          <>
            <ContainerHeaderDados>
              {Language[languageStorage]['Detalhes do Histórico']}
            </ContainerHeaderDados>

            <ContainerSearch>
              <Search
                onSearch={(value: string) => {
                  setSearchDetails(value);
                }}
                nomePlaceHolder={
                  Language[languageStorage]['Buscar procedimento']
                }
              />

              <strong>{dadosHistoricoDatas}</strong>
            </ContainerSearch>
          </>
        )}

        {!exibirDetalhes && (
          <ContainerHeader>
            {dadosHistorico.length !== 0 ? (
              <ContainerListCards>
                {responseData.map(res => (
                  <ContainerMap key={`${res.ID}-${res.AMBIENTE_ID}`}>
                    {res.escondido === false && (
                      <>
                        <ul>
                          <li>
                            <strong>
                              {Language[languageStorage].Ambiente}
                            </strong>
                            <span>
                              {res.AMBIENTE_NOME === null
                                ? '-'
                                : res.AMBIENTE_NOME}
                            </span>
                          </li>
                          <li>
                            <strong>
                              {
                                Language[languageStorage][
                                  'Data e hora inicial (realizado)'
                                ]
                              }
                            </strong>
                            <span>
                              {res.INICIO === null
                                ? '-'
                                : moment(res.INICIO).format(
                                    'DD/MM/YYYY HH:mm:ss',
                                  )}
                            </span>
                          </li>
                          <li>
                            <strong>
                              {
                                Language[languageStorage][
                                  'Data e hora final (realizado)'
                                ]
                              }
                            </strong>
                            <span>
                              {res.FIM === null
                                ? '-'
                                : moment(res.FIM).format('DD/MM/YYYY HH:mm:ss')}
                            </span>
                          </li>
                        </ul>

                        <article>
                          <IconeAtrasado>
                            {findNoteDate(res) && (
                              <FiAlertCircle
                                onClick={() => onButtonClick(res)}
                              />
                            )}
                          </IconeAtrasado>

                          <ButtonDetalhes
                            type="button"
                            onClick={() => [
                              handleButtonDate(res),
                              setExibirDetalhes(true),
                            ]}
                          >
                            <IoIosArrowForward />
                          </ButtonDetalhes>
                        </article>
                      </>
                    )}

                    {res.escondido === true && (
                      <ContainerMapObservacao>
                        <ul>
                          <li>
                            <strong>
                              {
                                Language[languageStorage][
                                  'Tarefa não realizada'
                                ]
                              }
                            </strong>
                            <span>{findTaskNode(res)}</span>
                          </li>
                          <li>
                            <strong>
                              {Language[languageStorage].Observacao}
                            </strong>
                            <span>{findNoteDateUnic(res)}</span>
                          </li>
                        </ul>

                        <ButtonDetalhes
                          type="button"
                          onClick={() => onButtonClick(res)}
                        >
                          <IoIosArrowBack />
                        </ButtonDetalhes>
                      </ContainerMapObservacao>
                    )}
                  </ContainerMap>
                ))}
              </ContainerListCards>
            ) : (
              <HistoricoVazio>
                <p>
                  {
                    Language[languageStorage][
                      'Dados não encontrados para o colaborador atual'
                    ]
                  }
                </p>
              </HistoricoVazio>
            )}
          </ContainerHeader>
        )}

        {exibirDetalhes && zoomImg === '' && (
          <ContainerHeader>
            <ContainerListCards>
              {responseDataDetalhes.map(res => (
                <ContainerMapDetails key={uuid()}>
                  {res.IMAGEM === null ||
                  res.IMAGEM.split('/').pop() === 'null' ? (
                    <ButtonImgNull type="button">
                      <AiOutlineException size={80} />
                    </ButtonImgNull>
                  ) : (
                    <ButtonImg
                      type="button"
                      onClick={() => handleZoomImg(res.IMAGEM)}
                    >
                      <img
                        src={res.IMAGEM}
                        alt={
                          res.AMBIENTE_NOME === null ? '-' : res.AMBIENTE_NOME
                        }
                      />
                    </ButtonImg>
                  )}

                  <ul>
                    <li>
                      <strong>{Language[languageStorage].Ambiente}: </strong>
                      <span>
                        {res.AMBIENTE_NOME === null ? '-' : res.AMBIENTE_NOME}
                      </span>
                    </li>
                    <li>
                      <strong>
                        {Language[languageStorage]['Horário base (agendado)']}:{' '}
                      </strong>
                      <span>
                        {res.HORA_INICIAL_PRINCIPAL_AMBIENTE &&
                        res.HORA_FINAL_PRINCIPAL_AMBIENTE
                          ? `${res.HORA_INICIAL_PRINCIPAL_AMBIENTE} - ${res.HORA_FINAL_PRINCIPAL_AMBIENTE}`
                          : '-'}
                      </span>
                    </li>
                    <li>
                      <strong>
                        {
                          Language[languageStorage][
                            'Horário da revisão (agendado)'
                          ]
                        }
                        :{' '}
                      </strong>
                      <span>
                        {filterScheduledReviews()
                          ? filterScheduledReviews()
                          : '-'}
                      </span>
                    </li>
                    <li>
                      <strong>
                        {
                          Language[languageStorage][
                            'Data e hora inicial (realizado)'
                          ]
                        }
                        :{' '}
                      </strong>
                      <span>
                        {res.INICIO === null
                          ? '-'
                          : moment(new Date(res.INICIO)).format(
                              'DD/MM/YYYY HH:mm:ss',
                            )}
                      </span>
                    </li>
                    <li>
                      <strong>
                        {
                          Language[languageStorage][
                            'Data e hora final (realizado)'
                          ]
                        }
                        :{' '}
                      </strong>
                      <span>
                        {res.FIM === null
                          ? '-'
                          : moment(new Date(res.FIM)).format(
                              'DD/MM/YYYY HH:mm:ss',
                            )}
                      </span>
                    </li>
                    <li>
                      <strong>
                        {Language[languageStorage].Procedimento}:{' '}
                      </strong>
                      <span>
                        {res.PROCEDIMENTO_NOME === null ||
                        res.PROCEDIMENTO_NOME.trim() === ''
                          ? '-'
                          : res.PROCEDIMENTO_NOME}
                      </span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage].Observacao}: </strong>
                      <span>{findNoteDateUnic(res)}</span>
                    </li>
                    <li>
                      <strong>
                        {Language[languageStorage]['Revisões (realizado)']}:{' '}
                      </strong>
                      <Link
                        to="#"
                        onClick={() => {
                          handleZoomImgRevisions(filterPhotosReviews());
                        }}
                      >
                        {Language[languageStorage]['Mostrar revisões']}
                      </Link>
                    </li>
                  </ul>
                </ContainerMapDetails>
              ))}
            </ContainerListCards>
          </ContainerHeader>
        )}

        {zoomImg === 'principal' && (
          <ZoomImg>
            <Zoom img={img} zoomScale={1} width="100%" height={380} />
          </ZoomImg>
        )}
        {zoomImg === 'revisoes' && (
          <ZoomImg>
            {img &&
              typeof img === 'object' &&
              img.map(imgRevision => (
                <section key={imgRevision.imagem_revisao}>
                  <article>
                    {`${filterScheduledReviews()} | ${moment(
                      imgRevision.DATA_CRIACAO,
                    ).format('DD/MM/YYYY HH:mm:ss')} - ${moment(
                      imgRevision.DATA_FINALIZACAO,
                    ).format('DD/MM/YYYY HH:mm:ss')}`}

                    {checkOutOfHours(
                      imgRevision.HORA_REVISAO_INICIAL,
                      moment(imgRevision.DATA_CRIACAO).format('HH:mm'),
                      imgRevision.HORA_REVISAO_FINAL,
                      moment(imgRevision.DATA_FINALIZACAO).format('HH:mm'),
                    ) && (
                      <IconeAtrasado>
                        <FiAlertTriangle style={{ cursor: 'default' }} />
                      </IconeAtrasado>
                    )}
                  </article>

                  <span>
                    {`${
                      Language[languageStorage].Observacao
                    }: ${findNoteDateUnicRevision(imgRevision)}`}
                  </span>

                  <Zoom
                    img={`https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${imgRevision.imagem_revisao}`}
                    zoomScale={1}
                    width="100%"
                    height={380}
                  />
                </section>
              ))}

            {img && !img.length && (
              <h1>
                {Language[languageStorage]['Histórico de revisões vazio']}
              </h1>
            )}
          </ZoomImg>
        )}

        {!exibirDetalhes && (
          <>
            <ContainerButtonAtualizar>
              <Button
                type="button"
                onClick={() => {
                  setSearch('');
                  setSearchDetails('');
                  setZoomImg('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  onRequestClose();
                }}
              >
                {Language[languageStorage].Ok}
              </Button>
            </ContainerButtonAtualizar>

            <ContainerButtonsPdfExcel>
              <button
                type="button"
                id="pdf"
                onClick={() => handleDownloadPDF(responseData, 'dados')}
              >
                <VscFilePdf size={22} />
              </button>

              <ReactHTMLTableToExcel
                type="button"
                id="excel"
                table="tableListagemDadosColaborador"
                filename={`${
                  Language[languageStorage]['relatorio-colaboradores']
                }-${moment().format('DD-MM-YYYY')}`}
                sheet="tablexls"
                buttonText={<RiFileExcel2Line size={22} />}
              />
            </ContainerButtonsPdfExcel>
          </>
        )}
        {exibirDetalhes && zoomImg === '' && (
          <>
            <ContainerButtonDetalhes>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setSearch('');
                  setSearchDetails('');
                  setZoomImg('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  onRequestClose();
                }}
              >
                {Language[languageStorage].Ok}
              </Button>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setExibirDetalhes(false);
                  setSearch('');
                  setSearchDetails('');
                  setZoomImg('');
                }}
              >
                {Language[languageStorage].Voltar}
              </Button>
            </ContainerButtonDetalhes>

            <ContainerButtonsPdfExcel>
              <button
                type="button"
                id="pdf"
                onClick={() => {
                  handleDownloadPDF(responseDataDetalhes, 'detalhes');
                }}
              >
                <VscFilePdf size={22} />
              </button>

              <ReactHTMLTableToExcel
                type="button"
                id="excel"
                table="tableListagemDetalhesColaborador"
                filename={`${
                  Language[languageStorage]['relatorio-colaboradores']
                }-${moment().format('DD-MM-YYYY')}`}
                sheet="tablexls"
                buttonText={<RiFileExcel2Line size={22} />}
              />
            </ContainerButtonsPdfExcel>
          </>
        )}

        {zoomImg !== '' && (
          <>
            <ContainerButtonDetalhes>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setSearch('');
                  setSearchDetails('');
                  setZoomImg('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  onRequestClose();
                }}
              >
                {Language[languageStorage].Ok}
              </Button>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setZoomImg('');
                }}
              >
                {Language[languageStorage].Voltar}
              </Button>
            </ContainerButtonDetalhes>

            {zoomImg === 'revisoes' && (
              <ContainerButtonsPdfExcel>
                <ReactHTMLTableToExcel
                  type="button"
                  id="excel"
                  table="tableListagemDetalhesRevisoesColaborador"
                  filename={`${
                    Language[languageStorage]['relatorio-revisoes-colaborador']
                  }-${moment().format('DD-MM-YYYY')}`}
                  sheet="tablexls"
                  buttonText={<RiFileExcel2Line size={22} />}
                />
              </ContainerButtonsPdfExcel>
            )}
          </>
        )}
      </ContainerModal>

      <ContainerTableMacon>
        <table id="tableListagemDadosColaborador">
          <HeaderTable headers={headers} />
          <tbody>
            {dadosHistoricoExcelPdf.map(res => (
              <tr key={uuid()}>
                <td>{dados.nome === null ? '-' : dados.nome}</td>
                <td>{dados.cpf === null ? '-' : maskCpf(dados.cpf)}</td>
                <td>{dados.email === null ? '-' : dados.email}</td>
                <td>{dados.telefone === null ? '-' : dados.telefone}</td>
                <td>{res.AMBIENTE_NOME === null ? '-' : res.AMBIENTE_NOME}</td>
                <td>
                  {res.HORA_INICIAL_PRINCIPAL_AMBIENTE === null &&
                  res.HORA_FINAL_PRINCIPAL_AMBIENTE === null
                    ? '-'
                    : `${res.HORA_INICIAL_PRINCIPAL_AMBIENTE} - ${res.HORA_FINAL_PRINCIPAL_AMBIENTE}`}
                </td>
                <td>
                  {res.INICIO === null
                    ? '-'
                    : moment(new Date(res.INICIO)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                </td>
                <td>
                  {res.FIM === null
                    ? '-'
                    : moment(new Date(res.FIM)).format('DD/MM/YYYY HH:mm:ss')}
                </td>
                <td>{findNoteDateUnic(res)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTableMacon>

      <ContainerTableMacon>
        <table id="tableListagemDetalhesColaborador">
          <HeaderTable headers={headersRevisions} />
          <tbody>
            {detalhesHistoricoExcelPdf.map(res => (
              <tr key={uuid()}>
                <td>{dados.nome === null ? '-' : dados.nome}</td>
                <td>{dados.cpf === null ? '-' : maskCpf(dados.cpf)}</td>
                <td>{dados.email === null ? '-' : dados.email}</td>
                <td>{dados.telefone === null ? '-' : dados.telefone}</td>
                <td>{res.AMBIENTE_NOME === null ? '-' : res.AMBIENTE_NOME}</td>
                <td>
                  {res.HORA_INICIAL_PRINCIPAL_AMBIENTE === null &&
                  res.HORA_FINAL_PRINCIPAL_AMBIENTE === null
                    ? '-'
                    : `${res.HORA_INICIAL_PRINCIPAL_AMBIENTE} - ${res.HORA_FINAL_PRINCIPAL_AMBIENTE}`}
                </td>
                <td>{filterScheduledReviews()}</td>
                <td>
                  {res.INICIO === null
                    ? '-'
                    : moment(new Date(res.INICIO)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                </td>
                <td>
                  {res.FIM === null
                    ? '-'
                    : moment(new Date(res.FIM)).format('DD/MM/YYYY HH:mm:ss')}
                </td>
                <td>
                  {res.PROCEDIMENTO_NOME === null ||
                  res.PROCEDIMENTO_NOME.trim() === ''
                    ? '-'
                    : res.PROCEDIMENTO_NOME}
                </td>
                <td>{findNoteDateUnic(res)}</td>
                <td>{filterSchedulesReviewsDone()}</td>
                <td>
                  {res.IMAGEM === null ||
                  res.IMAGEM.split('/').pop() === 'null' ? (
                    Language[languageStorage]['Sem Foto']
                  ) : (
                    <a href={res.IMAGEM === null ? '-' : res.IMAGEM}>
                      {Language[languageStorage]['Ir para Foto']}
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTableMacon>

      <ContainerTableMacon>
        <table id="tableListagemDetalhesRevisoesColaborador">
          <HeaderTable headers={headersRevisions} />
          <tbody>
            {newDetalhesHistorico.map(res => (
              <tr key={uuid()}>
                <td>{dados.nome === null ? '-' : dados.nome}</td>
                <td>{dados.cpf === null ? '-' : maskCpf(dados.cpf)}</td>
                <td>{dados.email === null ? '-' : dados.email}</td>
                <td>{dados.telefone === null ? '-' : dados.telefone}</td>
                <td>{res.AMBIENTE_NOME === null ? '-' : res.AMBIENTE_NOME}</td>
                <td>
                  {res.HORA_INICIAL_PRINCIPAL_AMBIENTE === null &&
                  res.HORA_FINAL_PRINCIPAL_AMBIENTE === null
                    ? '-'
                    : `${res.HORA_INICIAL_PRINCIPAL_AMBIENTE} - ${res.HORA_FINAL_PRINCIPAL_AMBIENTE}`}
                </td>
                <td>{filterScheduledReviews()}</td>
                <td>
                  {res.INICIO === null
                    ? '-'
                    : moment(new Date(res.INICIO)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                </td>
                <td>
                  {res.FIM === null
                    ? '-'
                    : moment(new Date(res.FIM)).format('DD/MM/YYYY HH:mm:ss')}
                </td>
                <td>
                  {res.PROCEDIMENTO_NOME === null ||
                  res.PROCEDIMENTO_NOME.trim() === ''
                    ? '-'
                    : res.PROCEDIMENTO_NOME}
                </td>
                <td>{findNoteDateUnic(res)}</td>

                <td>{filterSchedulesReviewsDone()}</td>
                <td>
                  {res.imagem_revisao === null ||
                  res.imagem_revisao.split('/').pop() === 'null' ? (
                    Language[languageStorage]['Sem Foto']
                  ) : (
                    <a
                      href={
                        res.imagem_revisao === null
                          ? '-'
                          : `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${res.imagem_revisao}`
                      }
                    >
                      {Language[languageStorage]['Ir para Foto']}
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTableMacon>

      {loading && <Loading />}
    </Modal>
  );
};
