import React, { useCallback, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FiWifiOff } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import Button from '../Button';
import Language from '../../language/language.json';
import { useAuth } from '../../hooks/auth';

import {
  ContainerHeader,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
} from './styles';

Modal.setAppElement('#root');

const ModalConexao: React.FC = () => {
  const { languageStorage } = useAuth();
  const [conexao, setConexao] = useState(navigator.onLine);
  const history = useHistory();

  const signOut = useCallback(async () => {
    localStorage.removeItem('@Tapp:token');
    localStorage.removeItem('@Tapp:user');
    localStorage.removeItem('@Tapp:cpfUserMaster');
    localStorage.removeItem('@Tapp:empresaPrincipal');
    localStorage.removeItem('@Tapp:nivelAcesso');
    localStorage.removeItem('@Tapp:dataHoraToken');

    history.go(0);
  }, [history]);

  const getData = useCallback(async () => {
    let tokenExpirado = false;
    const value = localStorage.getItem('@Tapp:dataHoraToken');

    if (value) {
      const valueFormat = value.replace(/"/g, '');
      const dataTokenMaisUmDia = new Date(valueFormat).setDate(
        new Date(valueFormat).getDate() + 1,
      );
      const dataAtual = new Date().getTime();
      tokenExpirado = dataAtual > dataTokenMaisUmDia;
    }

    if (tokenExpirado) signOut();
  }, [signOut]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Modal
      isOpen={!conexao}
      onRequestClose={() => setConexao(true)}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <>
        <button
          type="button"
          className="react-modal-close"
          onClick={() => setConexao(true)}
        >
          <AiOutlineClose />
        </button>

        <ContainerHeader>
          <FiWifiOff />
        </ContainerHeader>

        <ContainerHeaderDados>
          <p>{Language[languageStorage]['Sem Conexão']}</p>
          <p>
            {`${Language[languageStorage]['Por favor, se conecte para ter acesso ao sistema']}.`}
          </p>
        </ContainerHeaderDados>

        <ContainerButtonAtualizar>
          <Button type="button" onClick={() => history.go(0)}>
            {Language[languageStorage].Recarregar}
          </Button>
        </ContainerButtonAtualizar>
      </>
    </Modal>
  );
};

export default ModalConexao;
