import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

interface ContentProps {
  typeProps: 'leitura' | 'escrita';
}

export const Content = styled.div<ContentProps>`
  background: ${props => props.theme.colors.whitePrimary};
  border-radius: 16px;
  max-height: ${props =>
    props.typeProps === 'escrita'
      ? 'calc(100vh - 323px)'
      : 'calc(100vh - 406px)'};

  overflow: auto;
  animation: ${appearFromLeft} 1s;
`;

export const HeaderMonth = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    margin: 16px 0;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    h2 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: ${props => props.theme.colors.textBlack};
      margin-right: 45px;
    }

    button {
      background: transparent;
      border: none;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: ${props => props.theme.colors.bluePrimary};
        width: 26px;
        height: 26px;

        transition: color 0.2s;

        &:hover {
          color: ${props => shade(0.2, props.theme.colors.bluePrimary)};
        }
      }

      & + button {
        margin-left: 30px !important;
      }
    }
  }

  button.removeModal {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${props => props.theme.colors.bluePrimary};
    color: ${props => props.theme.colors.whitePrimary};
    border-radius: 16px;
    height: 50px;
    width: 345px;
    border: none;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    transition: 0.2s;

    &:hover {
      background-color: ${props => shade(0.2, props.theme.colors.bluePrimary)};
    }
  }
`;

export const HeaderWeek = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 16px 16px 0 0;
  padding: 22px; //42px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 250%;

  @media screen and (max-width: 1152px) {
    padding: 22px;
  }
`;

interface ButtonWeekProps {
  isChecked: boolean;
}

export const ButtonWeek = styled.button<ButtonWeekProps>`
  background: ${props =>
    props.isChecked ? props.theme.colors.bluePrimary : ''};
  border: none;
  border-radius: 50%;
  width: 64px;
  height: 64px;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;

  p {
    font-size: 24px;
    line-height: 36px;
    color: ${props =>
      props.isChecked
        ? props.theme.colors.whitePrimary
        : props.theme.colors.grayBlack};
  }

  @media screen and (max-width: 1152px) {
    width: 56px;
    height: 56px;

    p {
      font-size: 18px;
    }
  }

  transition: background 0.2s;

  &:hover {
    background: ${props => props.theme.colors.bluePrimary};

    p {
      color: ${props => props.theme.colors.whitePrimary};
    }
  }
`;

export const BodyMonth = styled.section`
  padding: 16px 0 0 0; //26px 37px;

  @media screen and (max-width: 1152px) {
    padding: 16px 0 0 0;
  }

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  aside {
    div {
      width: 75px; //100px;
      height: 160px; //200px;

      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      article {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        color: ${props => props.theme.colors.bluePrimary};
        width: 20px; //24px;
        height: 20px; //24px;
        margin-right: 10px; //14px;
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px; //24px;
        line-height: 36px;
        color: ${props => props.theme.colors.grayBlack};
      }

      @media screen and (max-width: 1152px) {
        width: 65px;

        svg {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }
`;

interface ContainerLineProps {
  contTime: number;
}

export const ContainerLine = styled.div<ContainerLineProps>`
  height: ${props => `calc(${props.contTime} * 160px)`};
  background: ${props => props.theme.colors.grayBlack};
  width: 0.8px;
`;

export const ContainerNotion = styled.div`
  width: 100vw;
  max-width: calc(100vw - 480px);

  @media screen and (max-width: 1152px) {
    max-width: 100vw;
  }

  section {
    border-top: 0.1px solid ${props => props.theme.colors.grayBlack};
    padding: 0 18px; //0 38px;
    height: 160px;
    overflow: auto;

    display: flex;

    @media screen and (max-width: 1152px) {
      padding: 0 18px;
    }
  }
`;

interface ButtonCardProps {
  backgroundProps: string;
  typeProps: 'leitura' | 'escrita';
}

export const ButtonCard = styled.button<ButtonCardProps>`
  border: none;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${props => (props.typeProps === 'escrita' ? 'pointer' : 'default')};

  ul {
    width: max-content;
    padding: 13px 26px; //23px 33px;
    background: ${props =>
      props.backgroundProps === props.theme.colors.orangePrimary
        ? props.backgroundProps
        : props.theme.colors.bluePrimary};
    border-radius: 16px;
    position: relative;
    transition: background 0.2s;

    @media screen and (max-width: 1152px) {
      padding: 13px 26px;
    }

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    li {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px; //18px;
      line-height: 22px; //27px;
      color: ${props => props.theme.colors.whitePrimary};

      @media screen and (max-width: 1152px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    img {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 12px;
      right: 15px;
    }
  }

  & + button {
    margin-left: 20px;
  }

  &:hover {
    ul {
      background: ${props => shade(0.1, props.backgroundProps)};
    }
  }
`;

export const ContainerSemResultados = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-top: 30px;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #363636;
  }
`;
