import React from 'react';
import { FiInfo } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';
import Language from '../../language/language.json';
import { Card } from './styles';

export const CardEmpty: React.FC = () => {
  const { languageStorage } = useAuth();
  return (
    <Card>
      <div className="line" />
      <div className="info">
        <FiInfo size={40} />
        <p>{Language[languageStorage]['Não há registro']}</p>
      </div>
    </Card>
  );
};
