import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { FiUser } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';
import moment from 'moment';

import { useAuth } from '../../hooks/auth';
import Language from '../../language/language.json';
import api from '../../services/api';
import { Loading } from '../Loading';
import { Agendamentos } from '../../models/Agendamentos';
import { HistoryTaskAgendamento } from '../../models/Historic';

import { ContainerHeader, ContainerHeaderDados } from './styles';

interface HorariosAgenda {
  horaInit: string;
  horaFinal: string;
}

interface HorariosColab {
  horaInit: string;
  horaFinal: string;
  observacao: string;
  isFazendo: boolean;
  isStart: boolean;
}

interface DateHorariosColab {
  date: string;
  horarios: HorariosColab[];
}

interface Props {
  visible: boolean;
  onClose(): void;
  agenda: Agendamentos;
  date: string;
}

Modal.setAppElement('#root');

export const ModalAgendaDash: React.FC<Props> = ({
  agenda,
  onClose,
  visible,
  date,
}) => {
  const { languageStorage } = useAuth();
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState<DateHorariosColab[]>([]);

  useEffect(() => {
    setLoading(true);
    const date2 = moment(date).add('day', 1).format('YYYY-MM-DD');
    // console.log('DATES', date, date2);
    api
      .get(
        `/historic/v2/agenda?agendaId=${agenda.Agenda.ID}&dataIni=${date}&dataFim=${date2}`,
      )
      .then(response => {
        const histAll: HistoryTaskAgendamento[] = response.data;
        // console.log(
        //   `/historic/v2/agenda?agendaId=${agenda.Agenda.ID}&dataIni=${date}&dataFim=${date2}`,
        // );
        // console.log(response.data);

        const horariosDate: DateHorariosColab[] = [];
        histAll.forEach(item => {
          // if(user.id === item.userId){
          const dateItem = item.createdAt.slice(0, 10);
          // console.log(dateItem);
          const findIndex = horariosDate.findIndex(i => i.date === dateItem);
          if (findIndex !== -1) {
            const findCreated = horariosDate[findIndex].horarios.find(
              i => i.horaInit === item.createdAt.slice(11, 16),
            );
            if (!findCreated) {
              horariosDate[findIndex].horarios.push({
                horaFinal: item.updatedAt.split('T')[1].slice(0, 5),
                horaInit: item.createdAt.slice(11, 16),
                isFazendo: false,
                isStart: false,
                observacao: item.observacao,
              });
            }
          } else {
            const newObj: DateHorariosColab = {
              date: item.createdAt.slice(0, 10),
              horarios: [
                {
                  horaFinal: item.updatedAt.split('T')[1].slice(0, 5),
                  horaInit: item.createdAt.slice(11, 16),
                  isFazendo: item.Agendamento.status === 'Andamento',
                  isStart: false,
                  observacao: item.observacao,
                },
              ],
            };
            horariosDate.push(newObj);
          }
          // }
        });

        setHistory(horariosDate);
        setLoading(false);
      })
      .catch(error => {
        // console.log("Dados não encontrados.")
        console.log(error.message);
        setLoading(false);
      });
  }, [agenda.Agenda.ID, agenda.Agenda.Id_Environments, agenda.userId, date]);

  const horAgenda: HorariosAgenda[] = useMemo(() => {
    // console.log(agenda.dataAgend, date, agenda.dataAgend === date);
    const temAgenda = agenda.dataAgend === date;
    const horariosAgenda: HorariosAgenda[] = [];

    if (temAgenda) {
      horariosAgenda.push({
        horaFinal: agenda.Agenda.Periodicidade.HORA_BASE_FINAL,
        horaInit: agenda.Agenda.Periodicidade.HORA_BASE_INICIAL,
      });
      agenda.Agenda.Periodicidade.Revisoes.forEach(itemRev => {
        if (itemRev) {
          horariosAgenda.push({
            horaInit: itemRev.HORA_INICIAL,
            horaFinal: itemRev.HORA_FINAL,
          });
        }
      });
      return horariosAgenda;
    }
    return [];
  }, [date, agenda]);

  const horColab: HorariosColab[] = useMemo(() => {
    // console.log('DATE SELECT', date);
    const horariosOfColab: HorariosColab[] = [];

    const ambAll = history.filter(item => date === item.date);
    ambAll.forEach(item => {
      item.horarios.forEach(i => {
        horariosOfColab.push(i);
      });
    });

    horariosOfColab.sort((a, b) => {
      const valueInitA = Number(a.horaInit.split(':').join(''));
      const valueInitB = Number(b.horaInit.split(':').join(''));
      if (valueInitA < valueInitB) return -1;
      if (valueInitA > valueInitB) return 1;

      const valueFimA = Number(a.horaFinal.split(':').join(''));
      const valueFimB = Number(b.horaFinal.split(':').join(''));
      if (valueFimA < valueFimB) return -1;
      if (valueFimA > valueFimB) return 1;
      return 0;
    });

    if (horAgenda.length > horariosOfColab.length) {
      const val = horAgenda.length - horariosOfColab.length;
      for (let i = 0; i < val; i += 1) {
        horariosOfColab.push({
          horaFinal: '',
          horaInit: '',
          isFazendo: false,
          isStart: true,
          observacao: '',
        });
      }
    }
    return horariosOfColab;
  }, [date, history, horAgenda.length]);

  const renderItem = useCallback(
    (horaColaborador: HorariosColab) => {
      if (!horaColaborador) {
        return <p />;
      }
      if (horaColaborador.isStart) {
        return <span>{Language[languageStorage]['A iniciar']}</span>;
      }
      if (horaColaborador.isFazendo) {
        return <span>{Language[languageStorage]['Em andamento']}</span>;
      }
      if (horaColaborador.observacao !== '') {
        return (
          <span>
            {horaColaborador.horaInit} - {horaColaborador.horaFinal}
          </span>
        );
      }

      return (
        <span>
          {horaColaborador.horaInit} - {horaColaborador.horaFinal}
        </span>
      );
    },
    [languageStorage],
  );

  const renderHorAgenda = useCallback(
    (horaAgenda: HorariosAgenda) => {
      if (!horaAgenda) {
        return <span>{Language[languageStorage]['Fez a mais']}</span>;
      }
      return (
        <span>
          {horaAgenda.horaInit} - {horaAgenda.horaFinal}
        </span>
      );
    },
    [languageStorage],
  );

  return (
    <Modal
      isOpen={visible}
      onRequestClose={onClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <>
        <button type="button" className="react-modal-close" onClick={onClose}>
          <AiOutlineClose />
        </button>

        <ContainerHeader>
          <h1>{Language[languageStorage].Agenda}</h1>
        </ContainerHeader>

        <ContainerHeaderDados>
          <div className="info">
            <div className="agenda">
              <p>
                {Language[languageStorage].Ambiente}:{' '}
                {agenda.Agenda.Environment.name}
              </p>
              <span>
                {Language[languageStorage].Setor}:{' '}
                {agenda.Agenda.Environment.Setor.name}
              </span>

              <span>
                {Language[languageStorage].Categoria}:{' '}
                {agenda.Agenda.Environment.Categoria.name}
              </span>
              <span>{agenda.Agenda.Periodicidade.DIAS_SEMANA}</span>
            </div>
            <div className="user">
              {agenda.User.avatar ? (
                <img
                  src={`https://app-qrcode-avatar.s3.amazonaws.com/${agenda.User.avatar}`}
                  alt={Language[languageStorage].Foto}
                />
              ) : (
                <FiUser />
              )}
              <span style={{ textAlign: 'center' }}>{agenda.User.nome}</span>
            </div>
          </div>
          <div className="dados">
            <div className="title">
              <p>{Language[languageStorage].Horario}</p>
              <p>{Language[languageStorage].Realizado}</p>
            </div>
            <div className="table">
              {horColab.map((rev, index) => (
                <div key={index.toString() + rev.toString()}>
                  {renderHorAgenda(horAgenda[index])}
                  {renderItem(rev)}
                </div>
              ))}
            </div>
          </div>
          {loading && <Loading />}
        </ContainerHeaderDados>
      </>
    </Modal>
  );
};
