import * as Yup from 'yup';
import { EnumLanguage } from '../../../../language/interface';
import Language from '../../../../language/language.json';

export function YupValidations(
  quantLimpezas: number,
  idiom: EnumLanguage,
): any {
  let schema;

  if (Number(quantLimpezas) !== 0) {
    schema = Yup.object().shape({
      ambiente: Yup.string().required(
        `${Language[idiom]['Nome do ambiente é obrigatório']}!`,
      ),
      quantLimpezas: Yup.string().required(
        `${Language[idiom]['Quantidade de limpezas é obrigatória']}!`,
      ),
      vigIni: Yup.string().required(
        `${Language[idiom]['Data inicial de vigência é obrigatória']}!`,
      ),
      vigFim: Yup.string().required(
        `${Language[idiom]['Data final de vigência é obrigatória']}!`,
      ),
      inicio1:
        Number(quantLimpezas) >= 1
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim1:
        Number(quantLimpezas) >= 1
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio2:
        Number(quantLimpezas) >= 2
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim2:
        Number(quantLimpezas) >= 2
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio3:
        Number(quantLimpezas) >= 3
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim3:
        Number(quantLimpezas) >= 3
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio4:
        Number(quantLimpezas) >= 4
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim4:
        Number(quantLimpezas) >= 4
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio5:
        Number(quantLimpezas) >= 5
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim5:
        Number(quantLimpezas) >= 5
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio6:
        Number(quantLimpezas) >= 6
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim6:
        Number(quantLimpezas) >= 6
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio7:
        Number(quantLimpezas) >= 7
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim7:
        Number(quantLimpezas) >= 7
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio8:
        Number(quantLimpezas) >= 8
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim8:
        Number(quantLimpezas) >= 8
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio9:
        Number(quantLimpezas) >= 9
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim9:
        Number(quantLimpezas) >= 9
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio10:
        Number(quantLimpezas) >= 10
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim10:
        Number(quantLimpezas) >= 10
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio11:
        Number(quantLimpezas) >= 11
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim11:
        Number(quantLimpezas) >= 11
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio12:
        Number(quantLimpezas) >= 12
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim12:
        Number(quantLimpezas) >= 12
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio13:
        Number(quantLimpezas) >= 13
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim13:
        Number(quantLimpezas) >= 13
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio14:
        Number(quantLimpezas) >= 14
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim14:
        Number(quantLimpezas) >= 14
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio15:
        Number(quantLimpezas) >= 15
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim15:
        Number(quantLimpezas) >= 15
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio16:
        Number(quantLimpezas) >= 16
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim16:
        Number(quantLimpezas) >= 16
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio17:
        Number(quantLimpezas) >= 17
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim17:
        Number(quantLimpezas) >= 17
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio18:
        Number(quantLimpezas) >= 18
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim18:
        Number(quantLimpezas) >= 18
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio19:
        Number(quantLimpezas) >= 19
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim19:
        Number(quantLimpezas) >= 19
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
      inicio20:
        Number(quantLimpezas) >= 20
          ? Yup.string().required(`${Language[idiom]['Início é obrigatório']}!`)
          : Yup.string().notRequired(),
      fim20:
        Number(quantLimpezas) >= 20
          ? Yup.string().required(`${Language[idiom]['Fim é obrigatório']}!`)
          : Yup.string().notRequired(),
    });
  } else {
    schema = Yup.object().shape({
      ambiente: Yup.string().required(
        `${Language[idiom]['Nome do ambiente é obrigatório']}!`,
      ),
      quantLimpezas: Yup.string().required(
        `${Language[idiom]['Quantidade de limpezas é obrigatória']}!`,
      ),
      vigIni: Yup.string().required(
        `${Language[idiom]['Data inicial de vigência é obrigatória']}!`,
      ),
      vigFim: Yup.string().required(
        `${Language[idiom]['Data final de vigência é obrigatória']}!`,
      ),
    });
  }

  return schema;
}
