import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { IoIosArrowForward } from 'react-icons/io';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ModalHistoricoAmbiente } from '../../../components/ModalHistoricoAmbiente';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import api from '../../../services/api';
import Search from '../../../components/Search';
import ModalConexao from '../../../components/ModalConexao';
import { ModalFilter, StatusButton } from '../../../components/ModalFilter';
import IconFilter from '../../../assets/IconFilter.svg';
import Pagination from '../../../components/Pagination';
import HeaderTable from '../../../components/HeaderTable';
import DropDownPagination from '../../../components/DropDownPagination';
import Language from '../../../language/language.json';

import {
  Container,
  ContainerTitle,
  ContainerItem,
  ContainerSearch,
  ContainerSemResultados,
  ContainerListItens,
  ContainerPagination,
} from './styles';
import { Empresa } from '../../../models/Empresa';
import { ContainerSelect } from '../../RelatoriosV2/DashboardColaboradores/styles';

interface ResponseGet {
  AMBIENTE_NOME: string;
  ANDAR: string;
  CATEGORIA: string;
  DESC_LIMPEZA: string;
  EMPRESA: string;
  HORA_FINAL_PRINCIPAL_AMBIENTE: string;
  HORA_INICIAL_PRINCIPAL_AMBIENTE: string;
  HORA_REVISAO_FINAL: string;
  HORA_REVISAO_INICIAL: string;
  ID: number;
  ID_EMPRESA_MAE: number;
  PROCEDIMENTOS: string[];
  QTD: number;
  REVISAO_FINAL: string[];
  REVISAO_ID: number;
  REVISAO_INICIAL: string[];
  RISCOS: string[];
  SETOR: string;
  ambienteCompanyId: number;
  revisao_id_table_revisao: number;
  createdAt: string;
  revisao: boolean;
  DATA_CRIACAO_HISTORICO: string;
  DATA_CRIACAO_REVISAO: string;
  nome_users: string;
  observacao: string;
  revisao_observacao: string;
  userId: number;
  userIdRevisao: number;
  OBSERVACAO: string;
  DATA_FINALIZACAO_HISTORICO: string;
}

interface ResponseGetHistoric {
  DATA_CRIACAO: string;
  DATA_FINALIZACAO: string;
  imagem_revisao: string;
  HORA_FINAL_PRINCIPAL_AMBIENTE: string;
  HORA_INICIAL_PRINCIPAL_AMBIENTE: string;
  HORA_REVISAO_FINAL: string;
  HORA_REVISAO_INICIAL: string;
  Revisao: boolean;
  ambiente_nome: string;
  andar: string;
  avatar: string;
  categoria_id: number;
  categoria_nome: string;
  cpf: string;
  data_fim: string;
  data_inicio: string;
  email_usuario: string;
  id_historico: number;
  id_usuario: number;
  imagem: string;
  nome_usuario: string;
  procedimento: string;
  setor_id: number;
  setor_nome: string;
  telefone: string;
  revisao_id_table_revisao: number;
  escondido: boolean;
  DATA_FINALIZACAO_HISTORICO: string;
}

interface ResponseGetHistoricObs {
  createdAt: string;
  environmentId: number;
  id: number;
  observacao: string;
  updatedAt: string;
  userId: number;
}

interface ResponseGetExcel {
  AMBIENTE_NOME: string;
  ANDAR: string;
  CATEGORIA: string;
  DATA_CRIACAO_HISTORICO: string;
  DATA_CRIACAO_REVISAO: string;
  DATA_FINALIZACAO_HISTORICO: string;
  DATA_FINALIZACAO_REVISAO: string;
  EMPRESA: string;
  HISTORICO_ID: number;
  HORA_FINAL_PRINCIPAL_AMBIENTE: string;
  HORA_INICIAL_PRINCIPAL_AMBIENTE: string;
  HORA_REVISAO_FINAL: string;
  HORA_REVISAO_INICIAL: string;
  ID: number;
  QTD: number;
  REVISAO_ID: number;
  SETOR: string;
  companyId: number;
  empresaMae_id: number;
  name_procedimento: string;
  nome_users: string;
  userId: number;
  userIdRevisao: number;
  dataMarcada: string;
}

interface ResponseGetExcelObservacao {
  TaskId: number;
  createdAt: string;
  data_criacao_obs: string;
  data_update_obs: string;
  environmentId: number;
  id: number;
  nome_procedimento: string;
  observacao: string;
  updatedAt: string;
  userId: number;
}

interface ResponseGetAgendamento {
  horaRevFinal: string[];
  horaRevInicial: string[];
  revisoes: number[];
}

interface ResponseGetProcedimentos {
  idProc: number[];
  procedimentoNome: string[];
}

export interface ResponseAgenda {
  agendaAtiva: boolean;
  agendamento: ResponseGetAgendamento;
  andar: string;
  avatar: string;
  // avatar_url: string;
  cpfUser: string;
  diasSemana: string;
  horaBaseFinal: string;
  horaBaseInicial: string;
  idAgenda: number;
  idAmbientes: number;
  idAmbienteEmAberto: boolean;
  idCategoria: number;
  idEmpresaFilial: number;
  idEmpresaPrincipal: number;
  idPeriodicidade: number;
  idSetor: number;
  idUsers: number;
  nomeAmbiente: string;
  nomeCategoria: string;
  nomePeriodicidade: string;
  nomeSetor: string;
  nomeUser: string;
  procedimentos: ResponseGetProcedimentos;
  qtdLimpezas: number;
  // isRevisao?: boolean;
}

interface AgendaMaisEmpresa extends ResponseAgenda {
  nomeEmpresa: string;
}

const DashboardAmbientes: React.FC = () => {
  const { user, empresaPrincipal, languageStorage } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [filialId, setFilialId] = useState(-1);
  const [filiais, setFiliais] = useState<Empresa[]>([]);
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [responseExcel, setResponseExcel] = useState<ResponseGetExcel[]>([]);
  const [responseExcelObservation, setResponseExcelObservation] = useState<
    ResponseGetExcelObservacao[]
  >([]);
  const [modalDetalhesAmbiente, setModalDetalhesAmbiente] = useState(true);
  const [dadosModal, setDadosModal] = useState<ResponseGet>();
  const [dadosHistoricoModal, setDadosHistoricoModal] = useState<
    ResponseGetHistoric[]
  >([]);
  const [dadosHistoricoObsModal, setDadosHistoricoObsModal] = useState<
    ResponseGetHistoricObs[]
  >([]);
  const [dadosHistoricoDatasModal, setDadosHistoricoDatasModal] = useState('');
  const [dataFilterModal, setDataFilterModal] = useState<StatusButton[]>([
    {
      status: true,
      name: Language[languageStorage].Ambiente,
    },
    {
      status: false,
      name: Language[languageStorage].Categoria,
    },
    {
      status: false,
      name: Language[languageStorage].Setor,
    },
    {
      status: false,
      name: Language[languageStorage].Andar,
    },
    {
      status: false,
      name: Language[languageStorage]['Intervalo de datas'],
    },
  ]);

  const [search, setSearch] = useState('');
  const [searchDataInicial, setSearchDataInicial] = useState('');
  const [searchDataFinal, setSearchDataFinal] = useState('');
  const [showModalFilter, setShowModalFilter] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);

  const dataInicio = moment().format('YYYY-MM-01');
  const dataFim = moment().format('YYYY-MM-DD');
  const [dataInicioModal, setDataInicioModal] = useState('');
  const [dataFinalModal, setDataFinalModal] = useState('');

  const dropDown = [
    { valor: '20', id: '20' },
    { valor: '40', id: '40' },
    { valor: '80', id: '80' },
    { valor: '160', id: '160' },
    { valor: Language[languageStorage].Todos, id: '1' },
  ];

  const headers = [
    { name: 'ID', field: 'REVISAO_ID', sortable: false },
    {
      name: Language[languageStorage].Empresa,
      field: 'EMPRESA',
      sortable: false,
    },
    {
      name: Language[languageStorage].Ambiente,
      field: 'AMBIENTE_NOME',
      sortable: false,
    },
    {
      name: Language[languageStorage].Categoria,
      field: 'CATEGORIA',
      sortable: false,
    },
    { name: Language[languageStorage].Setor, field: 'SETOR', sortable: false },
    { name: Language[languageStorage].Andar, field: 'ANDAR', sortable: false },
    {
      name: Language[languageStorage].Usuário,
      field: 'nome_users',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Tipo da Limpeza'],
      field: 'type_limpeza',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Dia da Limpeza (Marcado)'],
      field: 'DIA_PRINCIPAL_AMBIENTE',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Horário inicial da Limpeza (Marcado)'],
      field: 'HORARIO_INICIAL_LIMPEZA_AMBIENTE',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Horário final da Limpeza (Marcado)'],
      field: 'HORARIO_FINAL_LIMPEZA_AMBIENTE',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Tempo médio da Limpeza (Marcado)'],
      field: 'tempo_limpeza_marcado',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Horário inicial da Limpeza (Realizado)'],
      field: 'DATA_CRIACAO_HISTORICO',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Horário final da Limpeza (Realizado)'],
      field: 'DATA_FINAL_CRIACAO_HISTORICO',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Tempo da Limpeza'],
      field: 'tempo_limpeza',
      sortable: false,
    },
    {
      name: Language[languageStorage]['Tempo resultante'],
      field: 'tempo_resultante',
      sortable: false,
    },
    {
      name: Language[languageStorage].Observacao,
      field: 'OBSERVACAO',
      sortable: false,
    },
    {
      name: Language[languageStorage].Status,
      field: 'STATUS',
      sortable: false,
    },
  ];

  const getIsAgendaToday = (
    daySeman: number,
    diasAgendaString: string,
  ): boolean => {
    function getNumberDay(dayString: string): number {
      switch (dayString) {
        case 'Domingo':
          return 0;
        case 'Segunda':
          return 1;
        case 'Terça':
          return 2;
        case 'Quarta':
          return 3;
        case 'Quinta':
          return 4;
        case 'Sexta':
          return 5;
        case 'Sábado':
          return 6;
        default:
          return 1;
      }
    }
    const daysAgenda = diasAgendaString
      .split(' - ')
      .map(item => getNumberDay(item));
    return !!daysAgenda.find(item => item === daySeman);
  };

  const getEmpresas = useCallback(async () => {
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
    api
      .get(`empresas/${companyId}`)
      .then(resp => {
        setFiliais(resp.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [empresaPrincipal]);

  useEffect(() => {
    getEmpresas();
  }, [getEmpresas]);

  useEffect(() => {
    setLoading(true);

    api
      .get(
        `environments/dashboard?dataI=${dataInicio}&dataF=${dataFim}&idEmpresa=${
          empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
        }`,
      )
      .then(async data => {
        const responseAgenda = await api.get(
          `/agendas/showAgenda?Id_Empresa=${
            empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
          }`,
        );
        const agendaResp: ResponseAgenda[] = responseAgenda.data.agendamento;

        setResponse(data.data.historico);

        const excelAll: ResponseGetExcel[] = data.data.excel;

        const filterResponseExcel: ResponseGetExcel[] = [
          ...new Map(
            excelAll.map((item: ResponseGetExcel) => [item.ID, item]),
          ).values(),
        ];

        filterResponseExcel.sort((a, b) => {
          if (a.DATA_CRIACAO_HISTORICO < b.DATA_CRIACAO_HISTORICO) {
            return 1;
          }
          if (a.DATA_CRIACAO_HISTORICO > b.DATA_CRIACAO_HISTORICO) {
            return -1;
          }
          return 0;
        });

        const agendas: AgendaMaisEmpresa[] = [];
        agendaResp.forEach(item => {
          const nameEmpresa = filterResponseExcel.find(
            itemEx => itemEx.ID === item.idAmbientes,
          );
          if (nameEmpresa) {
            agendas.push({
              ...item,
              nomeEmpresa: nameEmpresa.EMPRESA,
            });
          }
        });

        const datesHistExcel = filterResponseExcel.map(
          item => item.DATA_CRIACAO_HISTORICO,
        );
        const datesDif: string[] = [
          ...new Map(
            datesHistExcel.map(item => [item.slice(0, 10), item.slice(0, 10)]),
          ).values(),
        ];

        // console.log(
        //   datesDif.map(item => new Date(`${item} 12:00:00`).getDay()),
        // );

        const excelAllResp: ResponseGetExcel[] = [];

        datesDif.forEach(dayFormat => {
          const daySemanItem = new Date(`${dayFormat} 12:00:00`).getDay();
          const filterExcel = filterResponseExcel.filter(
            item => item.DATA_CRIACAO_HISTORICO.slice(0, 10) === dayFormat,
          );
          const filterAgenda = agendas.filter(item =>
            getIsAgendaToday(daySemanItem, item.diasSemana),
          );
          // excelAllResp.push(...filterExcel);
          excelAllResp.push(
            ...filterExcel.map(item => {
              return { ...item, dataMarcada: dayFormat };
            }),
          );
          const filterAg = filterAgenda.filter(itemAg => {
            return !filterExcel.find(
              itemEx =>
                itemEx.ID === itemAg.idAmbientes &&
                itemEx.userId === itemAg.idUsers,
            );
          });
          filterAg.forEach(item => {
            excelAllResp.push({
              AMBIENTE_NOME: item.nomeAmbiente,
              ANDAR: item.andar,
              CATEGORIA: item.nomeCategoria,
              DATA_CRIACAO_HISTORICO: '',
              DATA_CRIACAO_REVISAO: '',
              DATA_FINALIZACAO_HISTORICO: '',
              DATA_FINALIZACAO_REVISAO: '',
              EMPRESA: item.nomeEmpresa,
              HISTORICO_ID: -1,
              HORA_FINAL_PRINCIPAL_AMBIENTE: item.horaBaseFinal,
              HORA_INICIAL_PRINCIPAL_AMBIENTE: item.horaBaseInicial,
              HORA_REVISAO_FINAL: '',
              HORA_REVISAO_INICIAL: '',
              ID: item.idAmbientes,
              QTD: item.qtdLimpezas,
              REVISAO_ID: item.agendamento.revisoes[0],
              SETOR: item.nomeSetor,
              companyId: item.idEmpresaFilial,
              empresaMae_id: item.idEmpresaPrincipal,
              nome_users: item.nomeUser,
              name_procedimento: item.nomePeriodicidade,
              userId: item.idUsers,
              userIdRevisao: -1,
              dataMarcada: dayFormat,
            });
          });
        });

        // const respExcel: ResponseGetExcel[] = [];
        // filterResponseExcel.forEach(item => {})

        setResponseExcel(excelAllResp);
        setResponseExcelObservation(data.data.excelObservacao);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);

        addToast({
          type: 'error',
          title: Language[languageStorage].Erro,
          description:
            Language[languageStorage][
              'Erro ao carregar dados, por favor atualize a página'
            ],
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const mesAtual = moment().format('YYYY-MM-DD');
    const mesDataInicial = moment(searchDataInicial).format('YYYY-MM-DD');
    const mesDataFinal = moment(searchDataFinal).format('YYYY-MM-DD');

    if (
      searchDataInicial.length === 10 &&
      searchDataFinal.length === 10 &&
      searchDataInicial.substr(0, 1) !== '0' &&
      searchDataFinal.substr(0, 1) !== '0'
    ) {
      if (mesDataInicial <= mesAtual && mesDataFinal <= mesAtual) {
        setLoading(true);

        api
          .get(
            `/environments/dashboard?dataI=${searchDataInicial}&dataF=${searchDataFinal}&idEmpresa=${
              empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
            }`,
          )
          .then(data => {
            setResponse(data.data.historico);
            const filterResponseExcel: any = [
              ...new Map(
                data.data.excel.map((item: ResponseGetExcel) => [
                  item.ID,
                  item,
                ]),
              ).values(),
            ];
            setResponseExcel(filterResponseExcel);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);

            addToast({
              type: 'error',
              title: Language[languageStorage].Erro,
              description:
                Language[languageStorage][
                  'Erro ao carregar dados, por favor atualize a página'
                ],
            });
          });
      } else {
        addToast({
          type: 'info',
          title: Language[languageStorage]['Filtro inválido'],
          description:
            Language[languageStorage][
              'Intervalo de datas inválidos, favor verificar'
            ],
        });
      }
    }
  }, [
    addToast,
    dataFim,
    dataInicio,
    empresaPrincipal,
    history,
    languageStorage,
    searchDataFinal,
    searchDataInicial,
    user,
  ]);

  useEffect(() => {
    setSearchDataInicial('');
    setSearchDataFinal('');
  }, [search]);

  useEffect(() => {
    setSearch('');
  }, [searchDataInicial, searchDataFinal]);

  const handleMaskDate = useCallback(data => {
    const dataTemp = data.split('-').reverse().join('/');

    return dataTemp;
  }, []);

  const requestHistoricoAmbiente = useCallback(
    (dado: ResponseGet) => {
      const mesAtual = moment().format('YYYY-MM-DD');
      const mesDataInicial = moment(searchDataInicial).format('YYYY-MM-DD');
      const mesDataFinal = moment(searchDataFinal).format('YYYY-MM-DD');

      if (
        searchDataInicial.length === 10 &&
        searchDataFinal.length === 10 &&
        searchDataInicial.substr(0, 1) !== '0' &&
        searchDataFinal.substr(0, 1) !== '0'
      ) {
        if (mesDataInicial <= mesAtual && mesDataFinal <= mesAtual) {
          setDadosHistoricoModal([]);

          setLoading(true);
          api
            .get(
              `/info/all/environments?environmentId=${dado.ID}&dataInicio=${searchDataInicial}&dataFim=${searchDataFinal}`,
            )
            .then(data => {
              setLoading(false);
              setDadosHistoricoModal(data.data.data);
              setDadosHistoricoObsModal(data.data.obs);
              setDadosHistoricoDatasModal(
                `${Language[languageStorage].DeHorario} ${handleMaskDate(
                  searchDataInicial,
                )} ${Language[languageStorage].ateHorario} ${handleMaskDate(
                  searchDataFinal,
                )}`,
              );
              setModalDetalhesAmbiente(!modalDetalhesAmbiente);
              setDataInicioModal(searchDataInicial);
              setDataFinalModal(searchDataFinal);
            })
            .catch(() => {
              setLoading(false);
              addToast({
                type: 'error',
                title: Language[languageStorage].Erro,
                description:
                  Language[languageStorage][
                    'Erro ao carregar dados, por favor atualize a página'
                  ],
              });
            });
        } else {
          setLoading(false);
          addToast({
            type: 'info',
            title: Language[languageStorage]['Filtro inválido'],
            description:
              Language[languageStorage][
                'Intervalo de datas inválidos, favor verificar'
              ],
          });
        }
      } else {
        setDadosHistoricoModal([]);
        setLoading(true);
        api
          .get(
            `/info/all/environments?environmentId=${dado.ID}&dataInicio=${dataInicio}&dataFim=${dataFim}`,
          )
          .then(data => {
            setLoading(false);
            setDadosHistoricoModal(data.data.data);
            setDadosHistoricoObsModal(data.data.obs);
            setDadosHistoricoDatasModal(
              `${Language[languageStorage].DeHorario} ${handleMaskDate(
                dataInicio,
              )} ${Language[languageStorage].ateHorario} ${handleMaskDate(
                dataFim,
              )}`,
            );
            setModalDetalhesAmbiente(!modalDetalhesAmbiente);
            setDataInicioModal(dataInicio);
            setDataFinalModal(dataFim);
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: Language[languageStorage].Erro,
              description:
                Language[languageStorage][
                  'Erro ao carregar dados, por favor atualize a página'
                ],
            });
          });
      }
    },
    [
      searchDataInicial,
      searchDataFinal,
      handleMaskDate,
      modalDetalhesAmbiente,
      addToast,
      languageStorage,
      dataInicio,
      dataFim,
    ],
  );

  const tempoLimpeza = useCallback((dado: string, dado2: string): string => {
    if (dado && dado2) {
      const diff = moment(new Date(dado), 'DD/MM/YYYY HH:mm:ss').diff(
        moment(new Date(dado2), 'DD/MM/YYYY HH:mm:ss'),
      );

      const horas =
        moment.duration(diff).hours().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).hours().toString().replace('-', '')}`
          : moment.duration(diff).hours().toString().replace('-', '');
      const minutos =
        moment.duration(diff).minutes().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).minutes().toString().replace('-', '')}`
          : moment.duration(diff).minutes().toString().replace('-', '');
      const segundos =
        moment.duration(diff).seconds().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).seconds().toString().replace('-', '')}`
          : moment.duration(diff).seconds().toString().replace('-', '');
      const horario = `${horas}:${minutos}:${segundos}`;
      return horario;
    }
    return ' - ';
  }, []);

  const timeResult = useCallback((timeHMS: string, timeHMS2: string) => {
    function segToHMS(segs: number): string {
      const hours = Math.trunc(segs / 3600);
      const minute = Math.trunc((segs - 3600 * hours) / 60);
      const seconds = segs - (3600 * hours + 60 * minute);
      // console.log(data, data2, hours, minute, seconds);
      return `${hours < 10 ? `0${hours}` : hours}:${
        minute < 10 ? `0${minute}` : minute
      }:${seconds < 10 ? `0${seconds}` : seconds}`;
    }
    if (timeHMS !== ' - ' && timeHMS2 !== ' - ') {
      const [hour1, min1, seg1] = timeHMS.split(':');
      const [hour2, min2, seg2] = timeHMS2.split(':');
      const time1 = Number(seg1) + 60 * (Number(min1) + 60 * Number(hour1));
      const time2 = Number(seg2) + 60 * (Number(min2) + 60 * Number(hour2));
      const diff = time1 - time2;
      if (diff < 0) {
        const diff2 = time2 - time1;
        return `- ${segToHMS(diff2)}`;
      }
      return `+ ${segToHMS(diff)}`;
    }
    return ' - ';
  }, []);

  const handleFindObservation = useCallback(
    (dado: ResponseGetExcel): string => {
      const findObservation = responseExcelObservation.find(
        item =>
          item.environmentId === dado.ID &&
          item.userId === dado.userId &&
          (dado.DATA_CRIACAO_REVISAO
            ? item.data_criacao_obs === dado.DATA_CRIACAO_REVISAO
            : item.data_criacao_obs === dado.DATA_CRIACAO_HISTORICO),
      );

      return findObservation ? findObservation.observacao : '-';
    },
    [responseExcelObservation],
  );

  const handleFindStatus = useCallback(
    (dado: ResponseGetExcel): string => {
      if (dado.DATA_CRIACAO_HISTORICO) {
        const findObservation = responseExcelObservation.find(
          item =>
            item.environmentId === dado.ID &&
            item.userId === dado.userId &&
            (dado.DATA_CRIACAO_REVISAO
              ? item.data_criacao_obs === dado.DATA_CRIACAO_REVISAO
              : item.data_criacao_obs === dado.DATA_CRIACAO_HISTORICO),
        );

        return findObservation
          ? Language[languageStorage].Pendente
          : Language[languageStorage].Finalizado;
      }
      return Language[languageStorage]['A iniciar'];
    },
    [languageStorage, responseExcelObservation],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [search, searchDataInicial, searchDataFinal]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response;

    if (filialId !== -1) {
      computedResponses = computedResponses.filter(
        i => filialId === i.ambienteCompanyId,
      );
    }

    if (search) {
      computedResponses = computedResponses.filter((res: ResponseGet) => {
        const dataFilter: any = [];

        dataFilterModal.forEach(filtro => {
          if (filtro.status) {
            switch (filtro.name) {
              case Language[languageStorage].Ambiente:
                dataFilter.push(res.AMBIENTE_NOME);
                break;
              case Language[languageStorage].Categoria:
                dataFilter.push(res.CATEGORIA);
                break;
              case Language[languageStorage].Setor:
                dataFilter.push(res.SETOR);
                break;
              case Language[languageStorage].Andar:
                dataFilter.push(res.ANDAR);
                break;
              default:
                break;
            }
          }
        });

        return dataFilter.some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        );
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    filialId,
    search,
    ITEMS_PER_PAGE,
    currentPage,
    dataFilterModal,
    languageStorage,
  ]);

  function DataDaLimpezaRealizado(
    dataRevisao: string,
    dataHistorico: string,
  ): string {
    if (dataRevisao) {
      return moment(dataRevisao).format('DD/MM/YYYY');
    }
    if (dataHistorico) {
      return moment(dataHistorico).format('DD/MM/YYYY');
    }
    return ' - ';
  }

  function HorarioDaLimpezaRealizado(
    horaRevisao: string,
    horaHistorico: string,
  ): string {
    if (horaRevisao) {
      return moment(horaRevisao).format('HH:mm:ss');
    }
    if (horaHistorico) {
      return moment(horaHistorico).format('HH:mm:ss');
    }
    return ' - ';
  }

  return (
    <>
      <ModalConexao />

      <ModalFilter
        isOpen={showModalFilter}
        type="dashAmbientes"
        onRequestClose={() => setShowModalFilter(false)}
        onReturnData={e => setDataFilterModal(e)}
      />

      {!!dadosModal &&
        !!dadosHistoricoModal &&
        !!dadosHistoricoObsModal &&
        !!dataInicioModal &&
        !!dataFinalModal && (
          <ModalHistoricoAmbiente
            isOpen={!modalDetalhesAmbiente}
            dados={dadosModal}
            dadosHistorico={dadosHistoricoModal}
            dadosHistoricoObs={dadosHistoricoObsModal}
            dadosHistoricoDatas={dadosHistoricoDatasModal}
            dadosDataInicio={dataInicioModal}
            dadosDataFinal={dataFinalModal}
            onRequestClose={() => setModalDetalhesAmbiente(true)}
          />
        )}

      <Container>
        <ContainerTitle>
          <h1>{Language[languageStorage].Ambientes}</h1>

          <Tooltip
            title={`${Language[languageStorage]['Nessa página você tem acesso a visualização detalhada do histórico de atividades realizadas pelos ASGs. Filtragem a partir dos ambientes']}.`}
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </ContainerTitle>

        <ContainerSelect>
          <select
            value={filialId}
            onChange={event => {
              const val = parseInt(event.target.value, 10);
              setFilialId(val);
            }}
          >
            <option value={-1}>{Language[languageStorage].Todos}</option>
            {filiais.map(i => (
              <option key={i.ID} value={i.ID}>
                {i.NOME}
              </option>
            ))}
          </select>
        </ContainerSelect>

        <ContainerSearch isFilterDateInterval={dataFilterModal[4].status}>
          {!dataFilterModal[4].status ? (
            <Search
              onSearch={(value: string) => {
                setSearch(value);
              }}
              nomePlaceHolder={Language[languageStorage].Buscar}
            />
          ) : (
            <>
              <Search
                onSearch={(value: string) => {
                  setSearchDataInicial(value);
                }}
                nomePlaceHolder={
                  Language[languageStorage]['Buscar data inicial']
                }
                type="date"
              />

              <Search
                onSearch={(value: string) => {
                  setSearchDataFinal(value);
                }}
                nomePlaceHolder={Language[languageStorage]['Buscar data final']}
                type="date"
              />
            </>
          )}

          <button type="button" onClick={() => setShowModalFilter(true)}>
            <img src={IconFilter} alt="iconFilter" />
          </button>
        </ContainerSearch>

        {response.length === 0 && (
          <ContainerSemResultados>
            <h2>
              {
                Language[languageStorage][
                  'Não existe um histórico de ambientes para o intervalo de datas atual'
                ]
              }
            </h2>
          </ContainerSemResultados>
        )}

        <ContainerListItens>
          <>
            {responseData.map((res, index) => (
              <ContainerItem
                key={`${res.ID}-${res.AMBIENTE_NOME}`}
                isColor={index % 2 !== 0}
              >
                <button
                  type="button"
                  onClick={() => {
                    setDadosModal(res);
                    requestHistoricoAmbiente(res);
                  }}
                >
                  <ul>
                    <li>
                      <strong>{Language[languageStorage].Ambiente}</strong>
                      <span>{res.AMBIENTE_NOME}</span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage].Categoria}</strong>
                      <span>{res.CATEGORIA}</span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage].Setor}</strong>
                      <span>{res.SETOR}</span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage].Andar}</strong>
                      <span>{res.ANDAR}</span>
                    </li>
                  </ul>
                </button>

                <IoIosArrowForward />
              </ContainerItem>
            ))}

            <table id="tableListagemAmbientes" style={{ display: 'none' }}>
              <HeaderTable headers={headers} />
              <tbody>
                {responseExcel.map(res => (
                  <tr key={`${res.ID}-${res.HISTORICO_ID}-${res.REVISAO_ID}`}>
                    <td>{res.ID}</td>
                    <td>{res.EMPRESA}</td>
                    <td>{res.AMBIENTE_NOME}</td>
                    <td>{res.CATEGORIA}</td>
                    <td>{res.SETOR}</td>
                    <td>{res.ANDAR}</td>
                    <td>{res.nome_users}</td>
                    <td>
                      {res.DATA_CRIACAO_REVISAO
                        ? Language[languageStorage]['Revisão']
                        : Language[languageStorage].Principal}
                    </td>
                    <td>
                      {moment(
                        res.DATA_CRIACAO_REVISAO
                          ? res.DATA_CRIACAO_REVISAO
                          : res.DATA_CRIACAO_HISTORICO,
                      ).format('DD/MM/YYYY')}
                    </td>
                    <td>
                      {res.DATA_CRIACAO_REVISAO
                        ? res.HORA_REVISAO_INICIAL
                        : res.HORA_INICIAL_PRINCIPAL_AMBIENTE}
                    </td>
                    <td>
                      {res.DATA_CRIACAO_REVISAO
                        ? res.HORA_REVISAO_FINAL
                        : res.HORA_FINAL_PRINCIPAL_AMBIENTE}
                    </td>
                    <td>
                      {tempoLimpeza(
                        `2022-01-01 ${
                          res.DATA_CRIACAO_REVISAO
                            ? res.HORA_REVISAO_INICIAL
                            : res.HORA_INICIAL_PRINCIPAL_AMBIENTE
                        }`,
                        `2022-01-01 ${
                          res.DATA_CRIACAO_REVISAO
                            ? res.HORA_REVISAO_FINAL
                            : res.HORA_FINAL_PRINCIPAL_AMBIENTE
                        }`,
                      )}
                    </td>
                    <td>
                      {HorarioDaLimpezaRealizado(
                        res.DATA_CRIACAO_REVISAO,
                        res.DATA_CRIACAO_HISTORICO,
                      )}
                    </td>
                    <td>
                      {HorarioDaLimpezaRealizado(
                        res.DATA_FINALIZACAO_REVISAO,
                        res.DATA_FINALIZACAO_HISTORICO,
                      )}
                    </td>
                    <td>
                      {tempoLimpeza(
                        res.DATA_CRIACAO_REVISAO
                          ? res.DATA_CRIACAO_REVISAO
                          : res.DATA_CRIACAO_HISTORICO,
                        res.DATA_FINALIZACAO_REVISAO
                          ? res.DATA_FINALIZACAO_REVISAO
                          : res.DATA_FINALIZACAO_HISTORICO,
                      )}
                    </td>
                    <td>
                      {timeResult(
                        tempoLimpeza(
                          `2022-01-01 ${
                            res.DATA_CRIACAO_REVISAO
                              ? res.HORA_REVISAO_INICIAL
                              : res.HORA_INICIAL_PRINCIPAL_AMBIENTE
                          }`,
                          `2022-01-01 ${
                            res.DATA_CRIACAO_REVISAO
                              ? res.HORA_REVISAO_FINAL
                              : res.HORA_FINAL_PRINCIPAL_AMBIENTE
                          }`,
                        ),
                        tempoLimpeza(
                          res.DATA_CRIACAO_REVISAO
                            ? res.DATA_CRIACAO_REVISAO
                            : res.DATA_CRIACAO_HISTORICO,
                          res.DATA_FINALIZACAO_REVISAO
                            ? res.DATA_FINALIZACAO_REVISAO
                            : res.DATA_FINALIZACAO_HISTORICO,
                        ),
                      )}
                    </td>
                    <td>{handleFindObservation(res)}</td>
                    <td>{handleFindStatus(res)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        </ContainerListItens>

        <ContainerPagination>
          {response.length > 0 && (
            <>
              <div className="divPaginacao">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
                <DropDownPagination
                  onChangeItems={(value: string) => {
                    setCurrentPage(1);
                    if (value === Language[languageStorage].Todos) {
                      setITEMS_PER_PAGE(1);
                    } else {
                      setITEMS_PER_PAGE(Number(value));
                    }
                  }}
                  objetoEnum={dropDown}
                  minWidth={30}
                />
              </div>

              <ReactHTMLTableToExcel
                id="export-excel"
                className="btn"
                table="tableRelatorioAmbientes"
                filename={`${
                  Language[languageStorage]['relatorio-ambientes'] +
                  moment().format('DD-MM-YYYY')
                }`}
                sheet="tablexls"
                buttonText={Language[languageStorage]['Exportar Excel']}
              />
            </>
          )}
        </ContainerPagination>
      </Container>

      {loading && <Loading />}
    </>
  );
};

export default DashboardAmbientes;
