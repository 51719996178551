import React, { InputHTMLAttributes } from 'react';
import { Container } from './styles';
import { listLanguage } from '../../language/interface';

interface InputProps extends InputHTMLAttributes<HTMLSelectElement> {
  defalt: (arg: string) => void;
}

const SelectLanguage: React.FC<InputProps> = ({ defalt, ...rest }) => {
  return (
    <Container>
      <select
        onChange={e => {
          defalt(e.target.value);
        }}
        {...rest}
      >
        {listLanguage.map(item => (
          <option key={item.id} value={item.value} label={item.name} />
        ))}
      </select>
    </Container>
  );
};

export default SelectLanguage;
