/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { uuid } from 'uuidv4';
import jsPDF from 'jspdf';
import moment from 'moment';
import { AiOutlineException } from 'react-icons/ai';
import { VscFilePdf } from 'react-icons/vsc';
import { RiFileExcel2Line } from 'react-icons/ri';
import { FiAlertCircle } from 'react-icons/fi';
import { IoIosArrowForward } from 'react-icons/io';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Zoom from 'react-img-zoom';
import { Link } from 'react-router-dom';
import HeaderTable from '../HeaderTable';
import Search from '../Search';
import Button from '../Button';
import { maskCpf, maskTel } from '../InputOverview/mask';
import Language from '../../language/language.json';

import {
  ContainerModal,
  ContainerHeader,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
  ContainerMap,
  ContainerMapDetails,
  ContainerButtonsPdfExcel,
  ContainerButtonDetalhes,
  ContainerTableMacon,
  ContainerSearch,
  ContainerListCards,
  ButtonDetalhes,
  IconeAtrasado,
  HistoricoVazio,
  ZoomImg,
  ButtonImgNull,
  ButtonImg,
} from './styles';
import { HistoryProcedimentoSlim } from '../../models/Historic';
import { HistoryUserDate } from '../../pages/RelatoriosV2/DashboardAmbientes';
import { useAuth } from '../../hooks/auth';

Modal.setAppElement('#root');

interface ResponseGetHistoric {
  horaInicialAmbiente: string;
  horaFinalAmbiente: string;
  revisoes: {
    horaIni: string;
    horaEnd: string;
  }[];
  data_fim: string;
  data_inicio: string;
  procedAll: string[];
  procedimento: string;
  observacao: string;
  imagem: string;
  isRevision: boolean;
  User: {
    name: string;
    telefone: string;
    email: string;
    id: number;
    cpf: string;
  };
  escondido?: boolean;
}

export interface HistAmbienteAgendamento {
  nameAmbiente: string;
  idAmbiente: number;
  categoria: string;
  setor: string;
  andar: string;
  horaIni: string;
  horaFim: string;
  horaRevisoes: {
    horaIni: string;
    horaEnd: string;
  }[];
  procedimentos: string[];
  companyId: number;
  history: HistoryProcedimentoSlim[];
}

interface ModalDetalhesAmbientes {
  isOpen: boolean;
  dados: HistAmbienteAgendamento;
  dadosHistorico: HistoryUserDate[];
  dadosHistoricoDatas: string;
  onRequestClose: () => void;
}

export const ModalHistoricoAmbienteV2: React.FC<ModalDetalhesAmbientes> = ({
  isOpen,
  dados,
  dadosHistorico,
  dadosHistoricoDatas,
  onRequestClose,
}) => {
  const { languageStorage } = useAuth();
  const [search, setSearch] = useState('');
  const [searchDatas, setSearchDatas] = useState('');
  const [searchProcedimento, setSearchProcedimento] = useState('');

  const [userToHistory, setUserToHistory] = useState<ResponseGetHistoric[]>([]);
  const [dateCreated, setDateCreated] = useState('');
  const [dadosHistoricoExcelPdf, setDadosHistoricoExcelPdf] = useState<
    ResponseGetHistoric[]
  >([]);
  const [detalhesHistoricoExcelPdf, setDetalhesHistoricoExcelPdf] = useState<
    ResponseGetHistoric[]
  >([]);
  const [
    procedimentosHistoricoExcelPdf,
    setProcedimentosHistoricoExcelPdf,
  ] = useState<ResponseGetHistoric[]>([]);
  const [userResponseData, setUserResponseData] = useState<number>();
  const [exibirDetalhes, setExibirDetalhes] = useState(false);
  const [exibirDetalhesData, setExibirDetalhesData] = useState(false);
  const [newDetalhesHistorico, setNewDetalhesHistorico] = useState<
    ResponseGetHistoric[]
  >();
  const [zoomImg, setZoomImg] = useState('');
  const [img, setImg] = useState<string | ResponseGetHistoric[]>();

  const headers = [
    { name: Language[languageStorage].Ambiente, field: 'ambiente' },
    { name: Language[languageStorage].Colaborador, field: 'colaborador' },
    { name: Language[languageStorage].CPF, field: 'cpf' },
    { name: Language[languageStorage]['E-mail'], field: 'email' },
    { name: Language[languageStorage].Telefone, field: 'telefone' },
    {
      name: Language[languageStorage]['Horário base (agendado)'],
      field: 'horarioPrimeiraLimpeza',
    },
  ];

  const headersHorarios = [
    { name: Language[languageStorage].Ambiente, field: 'ambiente' },
    { name: Language[languageStorage].Colaborador, field: 'colaborador' },
    { name: Language[languageStorage].CPF, field: 'cpf' },
    { name: Language[languageStorage]['E-mail'], field: 'email' },
    { name: Language[languageStorage].Telefone, field: 'telefone' },
    {
      name: Language[languageStorage]['Horário base (agendado)'],
      field: 'horarioPrimeiraLimpeza',
    },
    {
      name: Language[languageStorage]['Data e hora inicial (realizado)'],
      field: 'dataInicial',
    },
    {
      name: Language[languageStorage]['Data e hora final (realizado)'],
      field: 'dataFinal',
    },
    { name: Language[languageStorage].Observacao, field: 'observacao' },
  ];

  const headersRevisions = [
    { name: Language[languageStorage].Ambiente, field: 'ambiente' },
    { name: Language[languageStorage].Colaborador, field: 'colaborador' },
    { name: Language[languageStorage].CPF, field: 'cpf' },
    { name: Language[languageStorage]['E-mail'], field: 'email' },
    { name: Language[languageStorage].Telefone, field: 'telefone' },
    {
      name: Language[languageStorage]['Horário base (agendado)'],
      field: 'horarioPrimeiraLimpeza',
    },
    {
      name: Language[languageStorage]['Horário da revisão (agendado)'],
      field: 'horarioRevisao',
    },
    {
      name: Language[languageStorage]['Data e hora inicial (realizado)'],
      field: 'dataInicial',
    },
    {
      name: Language[languageStorage]['Data e hora final (realizado)'],
      field: 'dataFinal',
    },
    { name: Language[languageStorage].Procedimento, field: 'procedimento' },
    { name: Language[languageStorage].Observacao, field: 'observacao' },
    {
      name: Language[languageStorage]['Horários das revisões (realizado)'],
      field: 'horarioRealizadoRevisao',
    },
    { name: Language[languageStorage].Foto, field: 'foto' },
  ];

  useEffect(() => {
    const dadosHistoricoData: ResponseGetHistoric[] = [];

    dadosHistorico.forEach(element => {
      element.historys.forEach(item => {
        item.historys.forEach(i => {
          dadosHistoricoData.push({
            data_fim: i.updatedAt,
            data_inicio: i.createdAt,
            horaFinalAmbiente: dados.horaFim,
            horaInicialAmbiente: dados.horaIni,
            observacao: i.observacao,
            procedimento: i.nameProcedimento,
            procedAll: dados.procedimentos,
            revisoes: dados.horaRevisoes,
            User: {
              cpf: element.User.cpf,
              email: element.User.email,
              id: element.User.id,
              name: element.User.nome,
              telefone: element.User.telefone,
            },
            imagem: i.imageUrl,
            isRevision: i.isRevisao,
          });
        });
      });
    });

    setUserToHistory(dadosHistoricoData);
  }, [
    dados.horaFim,
    dados.horaIni,
    dados.horaRevisoes,
    dados.procedimentos,
    dadosHistorico,
  ]);

  const onButtonClick = useCallback(
    dadoParam => {
      const newResponseData =
        newDetalhesHistorico &&
        newDetalhesHistorico.map(dado => {
          return JSON.stringify(dado) === JSON.stringify(dadoParam)
            ? { ...dado, escondido: !dado.escondido }
            : dado;
        });
      setNewDetalhesHistorico(newResponseData);
    },
    [newDetalhesHistorico],
  );

  // diferença de duas datas que entrega esse formato string HH:mm:ss
  const getDiffData = useCallback((data: string, data2: string): string => {
    const difTime =
      (new Date(data).getTime() - new Date(data2).getTime()) / 1000;
    const hours = Math.trunc(difTime / 3600);
    const minute = Math.trunc((difTime - 3600 * hours) / 60);
    const seconds = difTime - (3600 * hours + 60 * minute);
    // console.log(data, data2, hours, minute, seconds);
    return `${hours < 10 ? `0${hours}` : hours}:${
      minute < 10 ? `0${minute}` : minute
    }:${seconds < 10 ? `0${seconds}` : seconds}`;
  }, []);

  // diferença de tempo entre dois objetos de data inicial e final - Retorno: HH:mm:ss
  const getDiffTimes = useCallback(
    (
      obj1: { timeInit: string; timeEnd: string },
      obj2: { timeInit: string; timeEnd: string },
    ): string => {
      const difTimeIni =
        (new Date(obj1.timeInit).getTime() - new Date(obj1.timeEnd).getTime()) /
        1000;
      const difTimeEnd =
        (new Date(obj2.timeInit).getTime() - new Date(obj2.timeEnd).getTime()) /
        1000;

      if (difTimeEnd > difTimeIni) {
        const difTime = difTimeEnd - difTimeIni;

        const hours = Math.trunc(difTime / 3600);
        const minute = Math.trunc((difTime - 3600 * hours) / 60);
        const seconds = difTime - (3600 * hours + 60 * minute);

        return `${hours < 10 ? `0${hours}` : hours}:${
          minute < 10 ? `0${minute}` : minute
        }:${seconds < 10 ? `0${seconds}` : seconds}`;
      }
      const difTime = difTimeIni - difTimeEnd;

      const hours = Math.trunc(difTime / 3600);
      const minute = Math.trunc((difTime - 3600 * hours) / 60);
      const seconds = difTime - (3600 * hours + 60 * minute);

      return `- ${hours < 10 ? `0${hours}` : hours}:${
        minute < 10 ? `0${minute}` : minute
      }:${seconds < 10 ? `0${seconds}` : seconds}`;
    },
    [],
  );

  const handleDownloadPDF = useCallback(
    (dadosHistoricoParam: ResponseGetHistoric[], type: string) => {
    const doc = new jsPDF(); // eslint-disable-line
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      doc.setFontType('bold');
      doc.text(
        Language[languageStorage]['Histórico do Ambiente'],
        103,
        20,
        'center',
      );
      doc.setFontSize(14);
      // tamanho 207 tela a4
      let posY: number;
      posY = 30;

      doc.text(`${dados.nameAmbiente}`, 103, posY, {
        align: 'center',
        maxWidth: 175,
      });

      // inicio bloco de dados pessoais
      dadosHistoricoParam.forEach((res, index) => {
        index === 0 ? (posY += 28) : (posY += 20);

        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text(`${Language[languageStorage].Colaborador}:`, 10, posY, 'left');
        let quebraLinha = doc.splitTextToSize(`${res.User.name}`, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 39, posY, { align: 'left', maxWidth: 175 });

        posY += 6;
        doc.setFontType('bold');
        doc.text(`${Language[languageStorage].CPF}:`, 10, posY, 'left');
        quebraLinha = doc.splitTextToSize(`${maskCpf(res.User.cpf)}`, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 22, posY, { align: 'left', maxWidth: 175 });

        posY += 6;
        doc.setFontType('bold');
        doc.text(`${Language[languageStorage]['E-mail']}:`, 10, posY, 'left');
        quebraLinha = doc.splitTextToSize(`${res.User.email}`, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 26, posY, { align: 'left', maxWidth: 175 });

        posY += 6;
        doc.setFontType('bold');
        doc.text(`${Language[languageStorage].Telefone}:`, 10, posY, 'left');
        quebraLinha = doc.splitTextToSize(`${maskTel(res.User.telefone)}`, 70);
        doc.setFontType('normal');
        doc.text(quebraLinha, 30, posY, { align: 'left', maxWidth: 175 });

        posY += 6;
        doc.setFontType('bold');
        doc.text(
          `${Language[languageStorage]['Horário base (agendado)']}:`,
          10,
          posY,
          'left',
        );
        quebraLinha = doc.splitTextToSize(
          res.horaInicialAmbiente && res.horaFinalAmbiente
            ? `${res.horaInicialAmbiente} a ${res.horaFinalAmbiente}`
            : '-',
          70,
        );
        doc.setFontType('normal');
        doc.text(quebraLinha, 63, posY, { align: 'left', maxWidth: 175 });

        if (type === 'dadosHorarios' || type === 'detalhes') {
          const filterScheduledReviewsAux = res.revisoes
            .map(i => `${i.horaIni} - ${i.horaEnd}`)
            .join(', ');
          const filterScheduledReviewsAuxLength = filterScheduledReviewsAux.split(
            ',',
          ).length;

          posY += 6;
          doc.setFontType('bold');
          doc.text(
            `${Language[languageStorage]['Horário da revisão (agendado)']}:`,
            10,
            posY,
            'left',
          );
          quebraLinha = doc.splitTextToSize(
            res.revisoes.map(i => `${i.horaIni} - ${i.horaEnd}`).join(', '),
            70,
          );
          doc.setFontType('normal');
          // console.log(quebraLinha);
          doc.text(quebraLinha, 74, posY, { align: 'left', maxWidth: 130 });
          let posYAux;

          if (Math.ceil(filterScheduledReviewsAuxLength / 9) > 1) {
            posYAux = (filterScheduledReviewsAuxLength / 9) * 11;
          } else {
            posYAux = 6;
          }

          posY += posYAux;
          doc.setFontType('bold');
          doc.text(
            `${Language[languageStorage]['Data e hora inicial (realizado)']}:`,
            10,
            posY,
            'left',
          );
          quebraLinha = doc.splitTextToSize(
            `${moment(new Date(res.data_inicio)).format(
              'DD/MM/YYYY HH:mm:ss',
            )}`,
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 73, posY, { align: 'left', maxWidth: 175 });

          posY += 6;
          doc.setFontType('bold');
          doc.text(
            `${Language[languageStorage]['Data e hora final (realizado)']}:`,
            10,
            posY,
            'left',
          );
          quebraLinha = doc.splitTextToSize(
            `${moment(new Date(res.data_fim)).format('DD/MM/YYYY HH:mm:ss')}`,
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 70, posY, { align: 'left', maxWidth: 175 });

          posY += 6;
          doc.setFontType('bold');
          doc.text(
            `${Language[languageStorage]['Tempo de execução (realizado)']}:`,
            10,
            posY,
            'left',
          );
          quebraLinha = doc.splitTextToSize(
            getDiffData(res.data_fim, res.data_inicio),
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 77, posY, { align: 'left', maxWidth: 175 });

          posY += 6;
          doc.setFontType('bold');
          doc.text(
            `${Language[languageStorage]['Tempo estimado']}:`,
            10,
            posY,
            'left',
          );
          quebraLinha = doc.splitTextToSize(
            getDiffData(
              `2022-01-01 ${res.horaFinalAmbiente}`,
              `2022-01-01 ${res.horaInicialAmbiente}`,
            ),
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 47, posY, { align: 'left', maxWidth: 175 });

          posY += 6;
          doc.setFontType('bold');
          doc.text(
            `${Language[languageStorage]['Tempo otimizado']}:`,
            10,
            posY,
            'left',
          );
          quebraLinha = doc.splitTextToSize(
            getDiffTimes(
              {
                timeEnd: res.data_fim,
                timeInit: res.data_inicio,
              },
              {
                timeInit: `2022-01-01 ${res.horaInicialAmbiente}`,
                timeEnd: `2022-01-01 ${res.horaFinalAmbiente}`,
              },
            ),
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 48, posY, { align: 'left', maxWidth: 175 });
        }
        if (type === 'detalhes') {
          posY += 6;
          doc.setFontType('bold');
          doc.text(
            `${Language[languageStorage].Procedimento}:`,
            10,
            posY,
            'left',
          );
          quebraLinha = doc.splitTextToSize(
            `${
              res.procedimento === null ||
              res.procedimento === undefined ||
              res.procedimento === ''
                ? '-'
                : res.procedimento
            }`,
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 42, posY, { align: 'left', maxWidth: 175 });
        }
        if (type === 'dadosHorarios') {
          posY += 6;
          doc.setFontType('bold');
          doc.text(
            `${Language[languageStorage].Procedimentos}:`,
            10,
            posY,
            'left',
          );
          quebraLinha = doc.splitTextToSize(
            `${
              res.procedAll === null ||
              res.procedAll === undefined ||
              res.procedAll === []
                ? '-'
                : res.procedAll.join(', ')
            }`,
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 45, posY, { align: 'left', maxWidth: 175 });
        }
        if (type === 'dadosHorarios' || type === 'detalhes') {
          posY += 6;
          doc.setFontType('bold');
          doc.text(
            `${Language[languageStorage].Observacao}:`,
            10,
            posY,
            'left',
          );
          quebraLinha = doc.splitTextToSize(res.observacao, 70);
          doc.setFontType('normal');
          doc.text(quebraLinha, 38, posY, { align: 'left', maxWidth: 175 });

          posY += 6;
          doc.setFontType('bold');
          doc.text(
            `${Language[languageStorage]['Horários das revisões (realizado)']}:`,
            10,
            posY,
            'left',
          );
          quebraLinha = doc.splitTextToSize(
            `${res.revisoes
              .map(i => `${i.horaIni} - ${i.horaEnd}`)
              .join(' ,')}`,
            70,
          );
          doc.setFontType('normal');
          doc.text(quebraLinha, 78, posY, { align: 'left', maxWidth: 120 });
        }

        if (posY > 207) {
          doc.addPage();
          posY = 0;
        }
      });

      doc.autoPrint();
      doc.output('dataurlnewwindow');
    },
    [dados.nameAmbiente, getDiffData, getDiffTimes, languageStorage],
  );

  const responseData = useMemo(() => {
    // let computedResponses: ResponseGetHistoric[] = [];
    // let computedResponsesExcelPdf: ResponseGetHistoric[] = [];
    let computedResponses = userToHistory;
    let computedResponsesExcelPdf = userToHistory;

    computedResponses = [
      ...new Map(computedResponses.map(item => [item.User.id, item])).values(),
    ];
    computedResponsesExcelPdf = [
      ...new Map(
        computedResponsesExcelPdf.map(item => [item.User.id, item]),
      ).values(),
    ];

    if (search) {
      computedResponses = computedResponses.filter(res =>
        [res.User.name, res.User.cpf].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        ),
      );

      computedResponsesExcelPdf = computedResponsesExcelPdf.filter(res =>
        [res.User.name, res.User.cpf].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        ),
      );
    }

    setDadosHistoricoExcelPdf(computedResponsesExcelPdf);
    return computedResponses;
  }, [search, userToHistory]);

  const responseDataDetalhes = useMemo(() => {
    let computedResponses = userToHistory || [];
    let computedResponsesExcelPdf = userToHistory || [];

    computedResponses = [
      ...new Map(
        computedResponses.map(item => [
          `${item.User.id}-${item.data_inicio.slice(0, 10)}`,
          item,
        ]),
      ).values(),
    ];
    computedResponsesExcelPdf = [
      ...new Map(
        computedResponsesExcelPdf.map(item => [
          `${item.User.id}-${item.data_inicio.slice(0, 10)}`,
          item,
        ]),
      ).values(),
    ];

    if (searchDatas) {
      computedResponses = computedResponses.filter(res => {
        const dataInicio = moment(new Date(res.data_inicio)).format(
          'DD/MM/YYYY HH:mm:ss',
        );
        const dataFim = moment(new Date(res.data_fim)).format(
          'DD/MM/YYYY HH:mm:ss',
        );

        return [dataInicio, dataFim].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(searchDatas.toString().toLowerCase()),
        );
      });

      computedResponsesExcelPdf = computedResponsesExcelPdf.filter(
        (res: ResponseGetHistoric) => {
          const dataInicio = moment(new Date(res.data_inicio)).format(
            'DD/MM/YYYY HH:mm:ss',
          );
          const dataFim = moment(new Date(res.data_fim)).format(
            'DD/MM/YYYY HH:mm:ss',
          );

          return [dataInicio, dataFim].some(
            (item: any) =>
              item &&
              item
                .toString()
                .toLowerCase()
                .includes(searchDatas.toString().toLowerCase()),
          );
        },
      );

      if (userResponseData) {
        computedResponsesExcelPdf = computedResponsesExcelPdf.filter(
          (res: ResponseGetHistoric) => res.User.id === userResponseData,
        );
      }

      if (userResponseData) {
        computedResponsesExcelPdf = computedResponsesExcelPdf.filter(
          (res: ResponseGetHistoric) => res.User.id === userResponseData,
        );
      }
    }

    setDetalhesHistoricoExcelPdf(computedResponsesExcelPdf);
    return computedResponses;
  }, [searchDatas, userResponseData, userToHistory]);

  const responseDataProcedimentos = useMemo(() => {
    let computedResponses = userToHistory || [];
    let computedResponsesExcelPdf = userToHistory || [];

    if (dateCreated && userResponseData) {
      computedResponses = computedResponses.filter(
        i =>
          i.data_inicio.slice(0, 10) === dateCreated &&
          i.User.id === userResponseData,
      );
    }

    if (searchProcedimento) {
      computedResponses = computedResponses.filter((res: ResponseGetHistoric) =>
        res.procedimento
          .toString()
          .toLowerCase()
          .includes(searchProcedimento.toLowerCase()),
      );
      computedResponsesExcelPdf = computedResponsesExcelPdf.filter(
        (res: ResponseGetHistoric) =>
          res.procedimento
            .toString()
            .toLowerCase()
            .includes(searchProcedimento.toLowerCase()),
      );
    }
    setProcedimentosHistoricoExcelPdf(computedResponsesExcelPdf);
    return computedResponses;
  }, [dateCreated, searchProcedimento, userResponseData, userToHistory]);

  const handleZoomImg = useCallback((imgString: string) => {
    setZoomImg('principal');
    setImg(imgString);
  }, []);

  const handleZoomImgRevisions = useCallback(
    (imgObjects: ResponseGetHistoric[]) => {
      setZoomImg('revisoes');
      setImg(imgObjects);
    },
    [],
  );

  const onClose = useCallback(() => {
    setSearch('');
    setSearchDatas('');
    setSearchProcedimento('');
    setZoomImg('');
    setNewDetalhesHistorico([]);
    setExibirDetalhes(false);
    setExibirDetalhesData(false);
    onRequestClose();
  }, [onRequestClose]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ContainerModal>
        {!exibirDetalhes && !exibirDetalhesData && (
          <>
            <ContainerHeaderDados>
              {Language[languageStorage]['Histórico do Ambiente']}
            </ContainerHeaderDados>

            <ContainerSearch>
              <Search
                onSearch={(value: string) => {
                  setSearch(value);
                }}
                nomePlaceHolder={Language[languageStorage].Buscar}
              />

              <strong>{dadosHistoricoDatas}</strong>
            </ContainerSearch>
          </>
        )}
        {exibirDetalhes && (
          <>
            <ContainerHeaderDados>
              {Language[languageStorage]['Datas do Histórico']}
            </ContainerHeaderDados>

            <ContainerSearch>
              <Search
                onSearch={(value: string) => {
                  setSearchDatas(value);
                }}
                nomePlaceHolder={Language[languageStorage]['Buscar datas']}
              />

              <strong>{dadosHistoricoDatas}</strong>
            </ContainerSearch>
          </>
        )}
        {exibirDetalhesData && zoomImg === '' && (
          <>
            <ContainerHeaderDados>
              {Language[languageStorage]['Detalhes do Histórico']}
            </ContainerHeaderDados>

            <ContainerSearch>
              <Search
                onSearch={(value: string) => {
                  setSearchProcedimento(value);
                }}
                nomePlaceHolder={
                  Language[languageStorage]['Buscar procedimento']
                }
              />

              <strong>{dadosHistoricoDatas}</strong>
            </ContainerSearch>
          </>
        )}

        {!exibirDetalhes && !exibirDetalhesData && (
          <ContainerHeader>
            {userToHistory.length !== 0 ? (
              <ContainerListCards>
                {responseData.map(res => (
                  <ContainerMap
                    key={`${dados.horaIni}-${dados.horaFim}-${res.User.id}`}
                  >
                    <>
                      <ul>
                        <li>
                          <strong>
                            {Language[languageStorage].Colaborador}:{' '}
                          </strong>
                          <span>
                            {res.User.name === null ||
                            res.User.name.trim() === ''
                              ? '-'
                              : res.User.name}
                          </span>
                        </li>
                        <li>
                          <strong>{Language[languageStorage].CPF}: </strong>
                          <span>
                            {res.User.cpf === null || res.User.cpf.trim() === ''
                              ? '-'
                              : maskCpf(res.User.cpf)}
                          </span>
                        </li>
                        <li>
                          <strong>
                            {Language[languageStorage]['E-mail']}:{' '}
                          </strong>
                          <span>
                            {res.User.email === null ||
                            res.User.email.trim() === ''
                              ? '-'
                              : res.User.email}
                          </span>
                        </li>
                        <li>
                          <strong>
                            {Language[languageStorage].Telefone}:{' '}
                          </strong>
                          <span>
                            {res.User.telefone === null ||
                            res.User.telefone.trim() === ''
                              ? '-'
                              : maskTel(res.User.telefone)}
                          </span>
                        </li>
                      </ul>

                      <article>
                        <IconeAtrasado>
                          {!!res.observacao && (
                            <FiAlertCircle
                              size={30}
                              style={{ cursor: 'default' }}
                            />
                          )}
                        </IconeAtrasado>

                        <ButtonDetalhes
                          type="button"
                          onClick={() => [
                            // setDadosHistoricoDataInicio([]),
                            // setDadosHistoricoDataFim([]),
                            // setNewDetalhesHistoricoData([]),
                            // handleButtonDate(res),
                            setUserResponseData(res.User.id),
                            // setNewDetalhesHistorico([res]),
                            setExibirDetalhes(true),
                          ]}
                        >
                          <IoIosArrowForward />
                        </ButtonDetalhes>
                      </article>
                    </>
                  </ContainerMap>
                ))}
              </ContainerListCards>
            ) : (
              <HistoricoVazio>
                <p>
                  {
                    Language[languageStorage][
                      'Dados não encontrados para o ambiente atual'
                    ]
                  }
                </p>
              </HistoricoVazio>
            )}
          </ContainerHeader>
        )}

        {exibirDetalhes && (
          <ContainerHeader>
            <ContainerListCards>
              {responseDataDetalhes.map((res, index) => (
                <ContainerMapDetails
                  key={`${index.toString()}-${res.horaInicialAmbiente}-${
                    res.horaFinalAmbiente
                  }`}
                >
                  <ul>
                    <li>
                      <strong>{Language[languageStorage].Colaborador}: </strong>
                      <span>
                        {res.User.name === null ? '-' : res.User.name}
                      </span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage].CPF}: </strong>
                      <span>
                        {res.User.cpf === null || res.User.cpf.trim() === ''
                          ? '-'
                          : maskCpf(res.User.cpf)}
                      </span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage]['E-mail']}: </strong>
                      <span>
                        {res.User.email === null ? '-' : res.User.email}
                      </span>
                    </li>
                    <li>
                      <strong>{Language[languageStorage].Telefone}: </strong>
                      <span>
                        {res.User.telefone === null
                          ? '-'
                          : maskTel(res.User.telefone)}
                      </span>
                    </li>
                    <li>
                      <strong>
                        {
                          Language[languageStorage][
                            'Data e hora inicial (realizado)'
                          ]
                        }
                        :
                      </strong>
                      <span>
                        {res.data_inicio === null
                          ? '-'
                          : moment(new Date(res.data_inicio)).format(
                              'DD/MM/YYYY HH:mm:ss',
                            )}
                      </span>
                    </li>
                    <li>
                      <strong>
                        {
                          Language[languageStorage][
                            'Data e hora final (realizado)'
                          ]
                        }
                        :
                      </strong>
                      <span>
                        {res.data_fim === null
                          ? '-'
                          : moment(new Date(res.data_fim)).format(
                              'DD/MM/YYYY HH:mm:ss',
                            )}
                      </span>
                    </li>
                  </ul>

                  <article>
                    <IconeAtrasado>
                      {!!res.observacao && (
                        <FiAlertCircle onClick={() => onButtonClick(res)} />
                      )}
                    </IconeAtrasado>

                    <ButtonDetalhes
                      type="button"
                      onClick={() => [
                        setDateCreated(res.data_inicio.slice(0, 10)),
                        setExibirDetalhes(false),
                        setExibirDetalhesData(true),
                      ]}
                    >
                      <IoIosArrowForward />
                    </ButtonDetalhes>
                  </article>
                </ContainerMapDetails>
              ))}
            </ContainerListCards>
          </ContainerHeader>
        )}

        {exibirDetalhesData &&
          zoomImg === '' &&
          responseDataProcedimentos !== undefined && (
            <ContainerHeader>
              <ContainerListCards>
                {responseDataProcedimentos
                  .filter(i => !i.isRevision)
                  .map(res => (
                    <ContainerMapDetails key={uuid()}>
                      {res.imagem === null ||
                      res.imagem.split('/').pop() === 'null' ? (
                        <ButtonImgNull type="button">
                          <AiOutlineException size={90} />
                        </ButtonImgNull>
                      ) : (
                        <ButtonImg
                          type="button"
                          onClick={() => handleZoomImg(res.imagem)}
                        >
                          <img
                            src={res.imagem}
                            alt={
                              res.procedimento === null ? '-' : res.procedimento
                            }
                          />
                        </ButtonImg>
                      )}

                      <ul>
                        <li>
                          <strong>
                            {Language[languageStorage].Colaborador}:{' '}
                          </strong>
                          <span>
                            {res.User.name === null ? '-' : res.User.name}
                          </span>
                        </li>
                        <li>
                          <strong>{Language[languageStorage].CPF}: </strong>
                          <span>
                            {res.User.cpf === null || res.User.cpf.trim() === ''
                              ? '-'
                              : maskCpf(res.User.cpf)}
                          </span>
                        </li>
                        <li>
                          <strong>
                            {Language[languageStorage]['E-mail']}:{' '}
                          </strong>
                          <span>
                            {res.User.email === null ? '-' : res.User.email}
                          </span>
                        </li>
                        <li>
                          <strong>
                            {Language[languageStorage].Telefone}:{' '}
                          </strong>
                          <span>
                            {res.User.telefone === null
                              ? '-'
                              : maskTel(res.User.telefone)}
                          </span>
                        </li>
                        <li>
                          <strong>
                            {
                              Language[languageStorage][
                                'Horário base (agendado)'
                              ]
                            }
                            :
                          </strong>
                          <span>
                            {!res.horaInicialAmbiente || !res.horaFinalAmbiente
                              ? '-'
                              : `${res.horaInicialAmbiente} - ${res.horaFinalAmbiente}`}
                          </span>
                        </li>
                        <li>
                          <strong>
                            {
                              Language[languageStorage][
                                'Horário da revisão (agendado)'
                              ]
                            }
                            :
                          </strong>
                          <span>
                            {res.revisoes
                              .map(i => `${i.horaIni} - ${i.horaEnd}`)
                              .join(' ,')}
                          </span>
                        </li>
                        <li>
                          <strong>
                            {
                              Language[languageStorage][
                                'Data e hora inicial (realizado)'
                              ]
                            }
                            :
                          </strong>
                          <span>
                            {res.data_inicio === null
                              ? '-'
                              : moment(new Date(res.data_inicio)).format(
                                  'DD/MM/YYYY HH:mm:ss',
                                )}
                          </span>
                        </li>
                        <li>
                          <strong>
                            {
                              Language[languageStorage][
                                'Data e hora final (realizado)'
                              ]
                            }
                            :
                          </strong>
                          <span>
                            {res.data_fim === null
                              ? '-'
                              : moment(new Date(res.data_fim)).format(
                                  'DD/MM/YYYY HH:mm:ss',
                                )}
                          </span>
                        </li>
                        <li>
                          <strong>
                            {Language[languageStorage].Procedimento}:{' '}
                          </strong>
                          <span>
                            {res.procedimento === null ||
                            res.procedimento === undefined ||
                            res.procedimento === ''
                              ? '-'
                              : res.procedimento}
                          </span>
                        </li>
                        <li>
                          <strong>
                            {Language[languageStorage].Observacao}:{' '}
                          </strong>
                          <span>{res.observacao}</span>
                        </li>
                        <li>
                          <strong>
                            {Language[languageStorage]['Revisões (realizado)']}:{' '}
                          </strong>
                          <Link
                            to="#"
                            onClick={() => {
                              handleZoomImgRevisions(
                                responseDataProcedimentos.filter(
                                  i => i.isRevision,
                                ),
                              );
                            }}
                          >
                            {Language[languageStorage]['Mostrar revisões']}
                          </Link>
                        </li>
                      </ul>
                    </ContainerMapDetails>
                  ))}
              </ContainerListCards>
            </ContainerHeader>
          )}

        {zoomImg === 'principal' && (
          <ZoomImg>
            <Zoom img={img} zoomScale={1} width="100%" height={380} />
          </ZoomImg>
        )}
        {zoomImg === 'revisoes' && (
          <ZoomImg>
            {img &&
              typeof img === 'object' &&
              img.map(imgRevision => (
                <section key={imgRevision.imagem}>
                  <article>
                    {imgRevision.revisoes
                      .map(i => `${i.horaIni} - ${i.horaEnd}`)
                      .join(', ')}
                  </article>

                  <span>{`${Language[languageStorage].Observacao}: ${imgRevision.observacao}`}</span>

                  <Zoom
                    img={imgRevision.imagem}
                    zoomScale={1}
                    width="100%"
                    height={380}
                  />
                </section>
              ))}

            {img && !img.length && (
              <h1>
                {Language[languageStorage]['Histórico de revisões vazio']}
              </h1>
            )}
          </ZoomImg>
        )}

        {!exibirDetalhes && !exibirDetalhesData && (
          <>
            <ContainerButtonAtualizar>
              <Button
                type="button"
                onClick={() => {
                  setSearch('');
                  setSearchDatas('');
                  setSearchProcedimento('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  setExibirDetalhesData(false);
                  onRequestClose();
                }}
              >
                {Language[languageStorage].Ok}
              </Button>
            </ContainerButtonAtualizar>

            <ContainerButtonsPdfExcel>
              <button
                type="button"
                id="pdf"
                onClick={() => {
                  handleDownloadPDF(responseData, 'dados');
                }}
              >
                <VscFilePdf size={22} />
              </button>

              <ReactHTMLTableToExcel
                type="button"
                id="excel"
                table="tableListagemDadosAmbientes"
                filename={`${
                  Language[languageStorage]['relatorio-ambientes']
                }-${moment().format('DD-MM-YYYY')}`}
                sheet="tablexls"
                buttonText={<RiFileExcel2Line size={22} />}
              />
            </ContainerButtonsPdfExcel>
          </>
        )}

        {exibirDetalhes && (
          <>
            <ContainerButtonDetalhes>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setSearch('');
                  setSearchDatas('');
                  setZoomImg('');
                  setSearchProcedimento('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  setExibirDetalhesData(false);
                  onRequestClose();
                }}
              >
                {Language[languageStorage].Ok}
              </Button>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setExibirDetalhes(false);
                  setSearch('');
                  setSearchDatas('');
                  setSearchProcedimento('');
                  setZoomImg('');
                }}
              >
                {Language[languageStorage].Voltar}
              </Button>
            </ContainerButtonDetalhes>

            <ContainerButtonsPdfExcel>
              <button
                type="button"
                id="pdf"
                onClick={() => {
                  handleDownloadPDF(responseDataDetalhes, 'dadosHorarios');
                }}
              >
                <VscFilePdf size={22} />
              </button>

              <ReactHTMLTableToExcel
                type="button"
                id="excel"
                table="tableListagemDetalhesAmbientes"
                filename={`${
                  Language[languageStorage]['relatorio-ambientes']
                }-${moment().format('DD-MM-YYYY')}`}
                sheet="tablexls"
                buttonText={<RiFileExcel2Line size={22} />}
              />
            </ContainerButtonsPdfExcel>
          </>
        )}

        {exibirDetalhesData && zoomImg === '' && (
          <>
            <ContainerButtonDetalhes>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setSearch('');
                  setSearchDatas('');
                  setZoomImg('');
                  setSearchProcedimento('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  setExibirDetalhesData(false);
                  onRequestClose();
                }}
              >
                {Language[languageStorage].Ok}
              </Button>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setExibirDetalhesData(false);
                  setExibirDetalhes(true);
                  setSearch('');
                  setSearchDatas('');
                  setSearchProcedimento('');
                  setZoomImg('');
                }}
              >
                {Language[languageStorage].Voltar}
              </Button>
            </ContainerButtonDetalhes>

            <ContainerButtonsPdfExcel>
              <button
                type="button"
                id="pdf"
                onClick={() => {
                  handleDownloadPDF(responseDataProcedimentos, 'detalhes');
                }}
              >
                <VscFilePdf size={22} />
              </button>

              <ReactHTMLTableToExcel
                type="button"
                id="excel"
                table="tableListagemProcedimentosAmbientes"
                filename={`${
                  Language[languageStorage]['relatorio-ambientes']
                }-${moment().format('DD-MM-YYYY')}`}
                sheet="tablexls"
                buttonText={<RiFileExcel2Line size={22} />}
              />
            </ContainerButtonsPdfExcel>
          </>
        )}

        {zoomImg !== '' && (
          <>
            <ContainerButtonDetalhes>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setSearch('');
                  setSearchDatas('');
                  setZoomImg('');
                  setSearchProcedimento('');
                  setNewDetalhesHistorico([]);
                  setExibirDetalhes(false);
                  setExibirDetalhesData(false);
                  onRequestClose();
                }}
              >
                {Language[languageStorage].Ok}
              </Button>
              <Button
                type="button"
                widthProps="20%"
                onClick={() => {
                  setZoomImg('');
                }}
              >
                {Language[languageStorage].Voltar}
              </Button>
            </ContainerButtonDetalhes>

            {zoomImg === 'revisoes' && (
              <ContainerButtonsPdfExcel>
                <ReactHTMLTableToExcel
                  type="button"
                  id="excel"
                  table="tableListagemDetalhesRevisoesAmbientes"
                  filename={`${
                    Language[languageStorage]['relatorio-revisoes-ambiente']
                  }-${moment().format('DD-MM-YYYY')}`}
                  sheet="tablexls"
                  buttonText={<RiFileExcel2Line size={22} />}
                />
              </ContainerButtonsPdfExcel>
            )}
          </>
        )}
      </ContainerModal>

      <ContainerTableMacon>
        <table id="tableListagemDadosAmbientes">
          <HeaderTable headers={headers} />
          <tbody>
            {dadosHistoricoExcelPdf !== undefined &&
              dadosHistoricoExcelPdf.map(res => (
                <tr key={uuid()}>
                  <td>
                    {dados.nameAmbiente === null ? '-' : dados.nameAmbiente}
                  </td>
                  <td>{res.User.name === null ? '-' : res.User.name}</td>
                  <td>{res.User.cpf === null ? '-' : maskCpf(res.User.cpf)}</td>
                  <td>{res.User.email === null ? '-' : res.User.email}</td>
                  <td>
                    {res.User.telefone === null
                      ? '-'
                      : maskTel(res.User.telefone)}
                  </td>
                  <td>
                    {dados.horaIni === null && dados.horaFim === null
                      ? '-'
                      : `${dados.horaIni} - ${dados.horaFim}`}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </ContainerTableMacon>

      <ContainerTableMacon>
        <table id="tableListagemDetalhesAmbientes">
          <HeaderTable headers={headersHorarios} />
          <tbody>
            {detalhesHistoricoExcelPdf !== undefined &&
              detalhesHistoricoExcelPdf.map(res => (
                <tr key={uuid()}>
                  <td>
                    {dados.nameAmbiente === null ? '-' : dados.nameAmbiente}
                  </td>
                  <td>{res.User.name === null ? '-' : res.User.name}</td>
                  <td>{res.User.cpf === null ? '-' : maskCpf(res.User.cpf)}</td>
                  <td>{res.User.email === null ? '-' : res.User.email}</td>
                  <td>
                    {res.User.telefone === null
                      ? '-'
                      : maskTel(res.User.telefone)}
                  </td>
                  <td>
                    {res.horaInicialAmbiente === null &&
                    res.horaFinalAmbiente === null
                      ? '-'
                      : `${res.horaInicialAmbiente} - ${res.horaFinalAmbiente}`}
                  </td>
                  <td>
                    {res.data_inicio === null
                      ? '-'
                      : moment(new Date(res.data_inicio)).format(
                          'DD/MM/YYYY HH:mm:ss',
                        )}
                  </td>
                  <td>
                    {res.data_fim === null
                      ? '-'
                      : moment(new Date(res.data_fim)).format(
                          'DD/MM/YYYY HH:mm:ss',
                        )}
                  </td>
                  <td>{res.observacao}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </ContainerTableMacon>

      <ContainerTableMacon>
        <table id="tableListagemProcedimentosAmbientes">
          <HeaderTable headers={headersRevisions} />
          <tbody>
            {procedimentosHistoricoExcelPdf !== undefined &&
              procedimentosHistoricoExcelPdf.map(res => (
                <tr key={uuid()}>
                  <td>
                    {dados.nameAmbiente === null ? '-' : dados.nameAmbiente}
                  </td>
                  <td>{res.User.name === null ? '-' : res.User.name}</td>
                  <td>{res.User.cpf === null ? '-' : maskCpf(res.User.cpf)}</td>
                  <td>{res.User.email === null ? '-' : res.User.email}</td>
                  <td>
                    {res.User.telefone === null
                      ? '-'
                      : maskTel(res.User.telefone)}
                  </td>
                  <td>
                    {res.horaInicialAmbiente === null &&
                    res.horaFinalAmbiente === null
                      ? '-'
                      : `${res.horaInicialAmbiente} - ${res.horaFinalAmbiente}`}
                  </td>
                  <td>
                    {res.revisoes
                      .map(i => `${i.horaIni} - ${i.horaEnd}`)
                      .join(', ')}
                  </td>
                  <td>
                    {res.data_inicio === null
                      ? '-'
                      : moment(new Date(res.data_inicio)).format(
                          'DD/MM/YYYY HH:mm:ss',
                        )}
                  </td>
                  <td>
                    {res.data_fim === null
                      ? '-'
                      : moment(new Date(res.data_fim)).format(
                          'DD/MM/YYYY HH:mm:ss',
                        )}
                  </td>
                  <td>
                    {res.procedimento === null || res.procedimento.trim() === ''
                      ? '-'
                      : res.procedimento}
                  </td>
                  <td>{res.observacao}</td>
                  <td>
                    {res.revisoes
                      .map(i => `${i.horaIni} - ${i.horaEnd}`)
                      .join(' ,')}
                  </td>
                  <td>
                    {res.imagem === null ||
                    res.imagem.split('/').pop() === 'null' ? (
                      Language[languageStorage]['Sem Foto']
                    ) : (
                      <a href={res.imagem === null ? '-' : res.imagem}>
                        {Language[languageStorage]['Ir para Foto']}
                      </a>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </ContainerTableMacon>

      <ContainerTableMacon>
        <table id="tableListagemDetalhesRevisoesAmbientes">
          <HeaderTable headers={headersRevisions} />
          <tbody>
            {responseDataProcedimentos !== undefined &&
              responseDataProcedimentos
                .filter(i => i.isRevision)
                .map(res => (
                  <tr key={uuid()}>
                    <td>
                      {dados.nameAmbiente === null ? '-' : dados.nameAmbiente}
                    </td>
                    <td>{res.User.name === null ? '-' : res.User.name}</td>
                    <td>
                      {res.User.cpf === null ? '-' : maskCpf(res.User.cpf)}
                    </td>
                    <td>{res.User.email === null ? '-' : res.User.email}</td>
                    <td>
                      {res.User.telefone === null
                        ? '-'
                        : maskTel(res.User.telefone)}
                    </td>
                    <td>
                      {res.horaInicialAmbiente === null &&
                      res.horaFinalAmbiente === null
                        ? '-'
                        : `${res.horaInicialAmbiente} - ${res.horaFinalAmbiente}`}
                    </td>
                    <td>
                      {res.revisoes
                        .map(i => `${i.horaIni} - ${i.horaEnd}`)
                        .join(', ')}
                    </td>
                    <td>
                      {res.data_inicio === null
                        ? '-'
                        : moment(new Date(res.data_inicio)).format(
                            'DD/MM/YYYY HH:mm:ss',
                          )}
                    </td>
                    <td>
                      {res.data_fim === null
                        ? '-'
                        : moment(new Date(res.data_fim)).format(
                            'DD/MM/YYYY HH:mm:ss',
                          )}
                    </td>
                    <td>
                      {res.procedimento === null ||
                      res.procedimento.trim() === ''
                        ? '-'
                        : res.procedimento}
                    </td>
                    <td>{res.observacao}</td>
                    <td>
                      {res.revisoes
                        .map(i => `${i.horaIni} - ${i.horaEnd}`)
                        .join(' ,')}
                    </td>
                    <td>
                      {res.imagem === null ||
                      res.imagem.split('/').pop() === 'null' ? (
                        Language[languageStorage]['Sem Foto']
                      ) : (
                        <a href={res.imagem === null ? '-' : res.imagem}>
                          {Language[languageStorage]['Ir para Foto']}
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </ContainerTableMacon>
    </Modal>
  );
};
