import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;

  header {
    padding-bottom: 30px;
    border-bottom: 0.6px solid ${props => props.theme.colors.grayBlack};
    margin-bottom: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 45px;
      color: ${props => props.theme.colors.textBlack};
    }
  }

  section {
    overflow: auto;

    table {
      width: 100%;
      background: ${props => props.theme.colors.whitePrimary};
      border-collapse: collapse;

      thead {
        position: sticky;
        top: 0;
        z-index: 2;
        background: ${props => props.theme.colors.whitePrimary};

        th {
          padding: 20px 30px;
          text-align: left;
          white-space: nowrap;

          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: ${props => props.theme.colors.textBlack};
        }

        th:nth-last-child(-n + 1) {
          text-align: center;
        }
      }

      tbody {
        tr {
          td {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: ${props => props.theme.colors.textBlack};
            padding: 20px 30px;
            white-space: nowrap;

            button {
              border: none;
              background: transparent;
              margin: 0 auto;

              display: flex;
              align-items: center;
              justify-content: center;
            }

            aside {
              display: flex;
              align-items: center;
              justify-content: center;

              button {
                background: transparent;
                border: none;
                color: ${props => props.theme.colors.grayBlack};

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                transition: color 0.2s;

                &:hover {
                  color: ${props => props.theme.colors.greenPrimary};
                }

                & + button {
                  margin-left: 15px;

                  transition: color 0.2s;

                  &:hover {
                    color: ${props => props.theme.colors.redPrimary};
                  }
                }

                svg {
                  width: 25px;
                  height: 25px;
                }
              }
            }
          }

          &:nth-child(odd) {
            background-color: ${props => props.theme.colors.grayWhite};
          }
        }
      }
    }
  }

  button[type='submit'] {
    margin: 30px auto 0 auto;
  }
`;
